import { type UserFlowType, type ViewId } from "../../../../constants";
import { type FormSubmitType } from "../../../../hooks/use-text-input-form-deprecated";
import { MemberNameType } from "../../../../model/user";
import { type UserActionTelemetryProps } from "../../../../telemetry-helpers/telemetry-helper";
import { UserActionName } from "../../../../telemetry-helpers/user-action-name";
import {
  type FormInputState,
  type SubmitTask,
  useFormSubmission,
} from "../../../inputs/hooks/use-form-submission";
import { type UsernameCollectionSubmitProps } from "../../username-collection-types";

export type UseUsernameCollectionFormFabricParams = {
  config: { viewId: ViewId; userFlowType: UserFlowType };
  form: {
    submitTask: SubmitTask<UsernameCollectionSubmitProps>;
    submitParams: UsernameCollectionSubmitProps;
    outlookOnSubmit?: FormSubmitType;
    easiOnSubmit?: FormSubmitType;
    phoneNumberState?: FormInputState;
  };
  logUserAction: ({ actionName, actionValue }: UserActionTelemetryProps) => void;
};

export type UseUsernameCollectionFormFabricResponse = {
  onSubmit: React.FormEventHandler;
};

/**
 * Hook for managing submission of the username collection form.
 * @param params The parameters for the hook
 * @returns The onSubmit handler for the username collection form
 */
export const useUsernameCollectionFormFabric = (
  params: UseUsernameCollectionFormFabricParams,
): UseUsernameCollectionFormFabricResponse => {
  const { config, form, logUserAction } = params;
  const { submitTask, submitParams } = form;
  const { usernameType } = form.submitParams;

  const phoneOnSubmit = useFormSubmission({
    inputState: form.phoneNumberState || {
      value: "",
      error: {
        serverError: "",
        setServerError: () => {},
        validationError: "",
        setValidationError: () => {},
        errorMessage: "",
        showErrorMessage: false,
        setShowErrorMessage: () => {},
        hasError: false,
      },
      setFocus: () => {},
      setUserHasSubmitted: () => {},
    },
    viewId: config.viewId,
    submitTask: form.submitTask,
    submitParams: form.submitParams,
  });

  const memberNameTypeOnSubmit = () => {
    if (usernameType === MemberNameType.Live && form.outlookOnSubmit) {
      return form.outlookOnSubmit(submitTask, config.viewId, submitParams);
    }

    if (usernameType === MemberNameType.EASI && form.easiOnSubmit) {
      return form.easiOnSubmit(submitTask, config.viewId, submitParams);
    }

    return phoneOnSubmit;
  };

  return {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
      logUserAction({
        actionName: UserActionName.UsernameCollectionInputTypeSubmit,
        actionValue: {
          usernameType: form.submitParams.usernameType,
          userFlowType: config.userFlowType,
        },
      });

      const onFormSubmit = memberNameTypeOnSubmit();
      onFormSubmit(event);
    },
  };
};
