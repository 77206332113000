import React from "react";
import { type SubmitTask } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import {
  type OnSwitchMemberNameType,
  type UsernameCollectionSubmitProps,
} from "@msidentity/SISU/components/username-collection/username-collection-types";
import { ViewId } from "@msidentity/SISU/constants";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { useEffectOnce } from "@msidentity/SISU/hooks";
import { type UserActionTelemetryProps } from "@msidentity/SISU/telemetry-helpers/telemetry-helper";
import { useTelemetry } from "@msidentity/SISU/telemetry-helpers/use-telemetry";
import { FormattedTextWithBindings } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { useCreateAccountError } from "../../../hooks/use-create-account-error";
import { useSendOttError } from "../../../hooks/use-send-ott-error";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import { type CreateAccountErrorStrings } from "../../../signup-types";
import { type CheckAvailableErrorMapResult } from "../check-available-signin-names-helper";
import { type GetNextViewForUsernameCollectionParams } from "../username-collection-view-types";
import { useCheckAvailableSignInNames } from "./use-check-available-signin-names";
import { useUsernameCollectionError } from "./use-username-collection-error";
import { useUsernameCollectionErrorBindings } from "./use-username-collection-error-bindings";

export type UseUsernameCollectionParams = {
  username: string;
  setCurrentInputType: OnSwitchMemberNameType;
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult;
  error: {
    setErrorMessage: (error: string | JSX.Element) => void;
    setShowError: (showError: boolean) => void;
    createAccountErrorStrings: CreateAccountErrorStrings;
  };
  chooseNextView: (params: GetNextViewForUsernameCollectionParams) => ViewId;
};

export type UseUsernameCollectionResponse = {
  submitTask: SubmitTask<UsernameCollectionSubmitProps>;
  logUserAction: (props: UserActionTelemetryProps) => void;
  getErrorMessage: (errorMessage: string) => JSX.Element;
};

export const useUsernameCollection = (
  props: UseUsernameCollectionParams,
): UseUsernameCollectionResponse => {
  const { username, setCurrentInputType, getCheckAvailableError, error } = props;
  const {
    globalState: { activeView, activeFlow },
  } = useGlobalContext();
  const { dispatchStateChange } = useSignUpContext();

  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const getEmbeddedLinkBindings = useUsernameCollectionErrorBindings(setCurrentInputType, username);
  const getErrorMessage = (errorMessage: string) => {
    const bindings = getEmbeddedLinkBindings();
    return <FormattedTextWithBindings text={errorMessage} embeddedBindings={{ ...bindings }} />;
  };

  const submitTask = useCheckAvailableSignInNames({
    getErrorMessage,
    ...props,
  });

  useUsernameCollectionError({
    ...error,
    getCheckAvailableError,
    getErrorMessage,
  });

  const showCreateAccountError = useCreateAccountError({
    ...error,
    errorStrings: error.createAccountErrorStrings,
    viewId: ViewId.UsernameCollection,
    setCurrentUsernameInputType: setCurrentInputType,
  });
  const showSendOttError = useSendOttError({ ...error });

  // Check if there is a SendOTT or CreateAccount API error (e.g., maximum daily OTT limit) that
  // should be shown. Otherwise, show the prefill speedbump based on context value.
  useEffectOnce(() => {
    if (showCreateAccountError || showSendOttError) {
      dispatchStateChange({ actionType: SignUpActionType.SetShowPrefillSpeedbump, payload: false });
    }
  });

  return {
    submitTask,
    logUserAction,
    getErrorMessage,
  };
};
