import React from "react";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import StylesConfig from "@msidentity/SISU/config/styles-config";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useActivateView, useBackButtonControl, useDocumentTitle } from "@msidentity/SISU/hooks";
import { commonSignUpStringsFabric } from "../../../fabric/common-signup-strings-fabric";
import { useLearnAboutParentalConsentView } from "../hooks/use-learn-about-parental-consent-view";
import { learnAboutParentalConsentViewStringsFabric as strings } from "./learn-about-parental-consent-view-strings";

/**
 * LearnAboutParentalConsentView Component Fabric
 * @returns A rendered instance of this component
 */
export const LearnAboutParentalConsentViewFabric: React.FC =
  function LearnAboutParentalConsentViewFabric() {
    const { isHosted } = GlobalConfig.instance;

    const { title, description, consentLinkText, consentLinkURL } = strings;

    const { useLearnAboutConsentViewStyles, useStaticCommonStyles } = StylesConfig.instance;
    useStaticCommonStyles();
    const styles = useLearnAboutConsentViewStyles();

    const { navigateToManageParentalConsent, navigateToLearnMore } =
      useLearnAboutParentalConsentView();

    const canGoBack = useBackButtonControl();

    useActivateView(ViewId.LearnAboutParentalConsent, FlowId.Signup, {
      showBackButtonOnActiveView: canGoBack,
      showIdentityBanner: false,
    });
    useDocumentTitle(strings.title);

    return (
      <ViewContainerFabric
        title={{ title, titleId: "learnParentalConsentTitle" }}
        description={{ description, descriptionId: "learnParentalConsentDescription" }}
        secondaryButton={{
          label: commonSignUpStringsFabric.backButton,
          type: "button",
          onClick: navigateToLearnMore,
          hasFocus: isHosted,
        }}
        applyAriaLive
      >
        <div className={styles.manageConsentLink}>
          {isHosted ? (
            <div id="parentConsentId">{consentLinkURL}</div>
          ) : (
            <LinkButton
              linkId="parentConsentLink"
              text={consentLinkText}
              onClick={navigateToManageParentalConsent}
              hasFocus
            />
          )}
        </div>
      </ViewContainerFabric>
    );
  };
