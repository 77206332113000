import { useCallback } from "react";
import { MemberNameType as UsernameType } from "../../../model/user";

/**
 * @param phoneErrorHandler The PhoneNumber submit error handler (optional)
 * @param outlookErrorHandler The Outlook submit error handler (optional)
 * @param easiErrorHandler The EASI submit error handler (optional)
 * @returns A function that takes the submit error and sets it on the current input type
 */
export const useUsernameCollectionSubmitErrorHandler = (
  phoneErrorHandler?: (error: string | JSX.Element) => void,
  outlookErrorHandler?: (error: string | JSX.Element) => void,
  easiErrorHandler?: (error: string | JSX.Element) => void,
) =>
  useCallback(
    (currentInputType: UsernameType) => (error: string | JSX.Element) => {
      if (currentInputType === UsernameType.Phone && phoneErrorHandler) {
        phoneErrorHandler(error);
      } else if (currentInputType === UsernameType.Live && outlookErrorHandler) {
        outlookErrorHandler(error);
      } else if (currentInputType === UsernameType.EASI && easiErrorHandler) {
        easiErrorHandler(error);
      }
    },
    [easiErrorHandler, outlookErrorHandler, phoneErrorHandler],
  );
