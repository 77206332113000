/* eslint-disable deprecation/deprecation */

import { type ChangeEvent, type FormEvent, useRef, useState } from "react";
import { type SubmitTask } from "../components/inputs/hooks/use-form-submission";
import { type InputValue } from "../components/inputs/input/input-types";
import { type ViewId } from "../constants/routing-constants";
import { useGlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import { type ICountryInfo } from "../utilities/country-helper";

export interface IFormSubmissionProps {
  /**
   * A value that should be sent to the server when the form is submitted
   */
  value?: InputValue;
  /**
   * Error handler that should be invoked if the form submission fails
   * @param error A string or element to display as an error message
   */
  errorHandler: (error: string | JSX.Element) => void;
  /*
   * Whether to use the input ref to focus upon submission
   * This is needed for the username collection component, which does not use the
   * focus state to set and track focus. We instead use this ref to set focus directly.
   */
  useElementRef?: boolean;
}

export type FormSubmitType<
  T extends IFormSubmissionProps = IFormSubmissionProps & {
    inputValue?: string;
    dropdownValue?: ICountryInfo;
  },
> = (
  submitTask: SubmitTask<T>,
  viewId: ViewId,
  args?: T,
) => (event: FormEvent<HTMLFormElement>) => {};

/**
 * @param defaultValue the initial value of the input
 * @deprecated
 * @returns text input state and a callback for form submission
 */
const useTextInputFormDeprecated = (defaultValue = "") => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [showError, setShowError] = useState(false);
  const [externalError, setExternalError] = useState<string | JSX.Element>("");
  const [validationError, setValidationError] = useState("");
  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();

  const onBlur = () => setFocus(false);
  const onFocus = () => setFocus(true);
  const onTextChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    setValue(typeof event === "string" ? event : event.target.value);
    setExternalError("");
  };

  const errorHandler = (error: string | JSX.Element) => {
    setExternalError(error);
  };

  const elementRef = useRef<HTMLInputElement>(null);

  const onFormSubmission: FormSubmitType =
    (
      submitTask: SubmitTask<
        IFormSubmissionProps & {
          inputValue?: string;
          dropdownValue?: ICountryInfo;
        }
      >,
      viewId: ViewId,
      args?: IFormSubmissionProps & { inputValue?: string; dropdownValue?: ICountryInfo },
    ) =>
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      // show progress indicator during call
      dispatchGlobal({
        type: GlobalActionType.BeginNavigate,
        source: viewId,
      });

      // initiate validation only upon submission
      setShowError(true);
      if (args?.useElementRef) {
        elementRef.current?.focus();
      } else {
        setFocus(true);
      }

      if (!externalError && !validationError) {
        if (args) {
          await submitTask(args);
        } else {
          await submitTask({ value, errorHandler });
        }
      }

      // re-enable after api completion
      dispatchGlobal({
        type: GlobalActionType.DataLoaded,
        view: viewId,
      });
    };

  return {
    focus,
    value,
    setValue,
    showError,
    externalError,
    validationError,
    onBlur,
    onFocus,
    onTextChange,
    onFormSubmission,
    setExternalError,
    setValidationError,
    setFocus,
    setShowError,
    errorHandler,
    elementRef,
  };
};

export default useTextInputFormDeprecated;
