import React from "react";
import { type AccessibleImageProps } from "@msidentity/SISU/components/accessible-image";
import { useUsernameCollectionFormFabric } from "@msidentity/sisu/components/username-collection/fabric/hooks/use-username-collection-form-fabric";
import { useUsernameStateFabric } from "@msidentity/SISU/components/username-collection/fabric/hooks/use-username-state-fabric";
import { useUsernameCollectionSubmitErrorHandler } from "@msidentity/SISU/components/username-collection/fabric/use-username-collection-submit-error-handler";
import { type UsernameCollectionFabricProps } from "@msidentity/SISU/components/username-collection/fabric/username-collection-fabric";
import { FlowId, UserFlowType, ViewId } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import {
  useActivateViewWithDocumentTitle,
  useNavigateOrRedirectBack,
  useShowProgressAndRedirect,
} from "@msidentity/SISU/hooks";
import { MemberNameType } from "@msidentity/SISU/model/user";
import {
  commonBindingsFabric,
  FormattedTextWithBindings,
} from "@msidentity/sisu/utilities/formatted-text-with-bindings";
import { addUser } from "@msidentity/SISU/utilities/image-helpers/accessible-images";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { type CommonSignUpStringsFabric } from "../../../../fabric/signup-types-fabric";
import SignUpConfig from "../../../../signup-config";
import { useSignUpContext } from "../../../../signup-context";
import { type CreateAccountErrorStrings } from "../../../../signup-types";
import { getSignUpUsername } from "../../../../utilities/signup-utilities";
import { useUsernameCollection } from "../../hooks/use-username-collection";
import { getCheckAvailableErrorMapFabric } from "../get-check-available-error-map-fabric";
import { getNextViewForUsernameCollectionFabric as chooseNextView } from "../get-next-view-for-username-collection-fabric";
import {
  type UsernameCollectionViewErrorStringsFabric,
  type UsernameCollectionViewStringsFabric,
} from "../username-collection-view-fabric-types";
import { useUsernameCollectionInputFabric } from "./use-username-collection-input-fabric";
import { useUsernameOnChangeFabric } from "./use-username-onchange-fabric";
import { useUsernameOptionsFabric } from "./use-username-options-fabric";

export type UseUsernameCollectionFabricParams = {
  strings: {
    commonSignUpStrings: CommonSignUpStringsFabric;
    usernameCollectionViewStrings: UsernameCollectionViewStringsFabric;
    usernameCollectionViewErrorStrings: UsernameCollectionViewErrorStringsFabric;
    createAccountErrorStrings: CreateAccountErrorStrings;
  };
};

export type UseUsernameCollectionFabricResponse = {
  backOnClick: () => void;
  hideBackButton: boolean;
  onSubmit: React.FormEventHandler;
  pageTitle: string;
  pageDescription: string;
  pageImage: AccessibleImageProps | undefined;
  prefillSpeedbumpDescription: JSX.Element;
  showPrefillSpeedbump: boolean;
  showSmsDisclaimer: boolean;
  usernameCollectionFabricInputProps: UsernameCollectionFabricProps;
};

/**
 * This hook aggregates all the properties that are required to render the Username view. It should be flavor independent, so that different implementations of the view can re-use the same hook.
 * @returns Username collection view properties
 * @param params Object containing the parameters required by this hook
 * @param params.strings Flavored strings that are used by this hook
 * @param params.strings.usernameCollectionViewStrings Strings that are specific to the username collection view
 * @param params.strings.usernameCollectionViewErrorStrings Error strings for the username collection view
 * @param params.strings.commonSignUpStrings Strings that are common to all signup views
 * @param params.strings.createAccountErrorStrings Strings that are used to display create account errors
 */
export const useUsernameCollectionFabric = ({
  strings: {
    commonSignUpStrings,
    usernameCollectionViewStrings,
    usernameCollectionViewErrorStrings,
    createAccountErrorStrings,
  },
}: UseUsernameCollectionFabricParams): UseUsernameCollectionFabricResponse => {
  const { signUpTitle } = commonSignUpStrings;
  const {
    newChildEmailTitle,
    existingChildEmailTitle,
    childUsernameDescription,
    existingAdultEmailTitle,
  } = usernameCollectionViewStrings;
  const {
    appBrandedSignUpDescription,
    defaultPhoneCountry,
    domainList,
    family: { isFamilyScenario },
    finalBackUrl,
    hideBackButton,
    isPhoneSupported,
    phoneCountryList,
    phoneNoPaEnabled,
    prefill,
    signUpFlow,
  } = SignUpConfig.instance;
  const {
    viewState: { showPrefillSpeedbump, memberNameInput },
  } = useSignUpContext();

  const {
    globalState: { backButtonHandler, userFlowType },
  } = useGlobalContext();

  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;
  const usernameCollectionOptions = useUsernameOptionsFabric({
    signUpFlow,
    isPhoneSupported,
    prefill,
    isParentFlow,
    strings: { usernameCollectionViewStrings },
  });
  const { initialInputType } = usernameCollectionOptions;

  const usernameCollectionFabricInput = useUsernameCollectionInputFabric({
    usernameCollectionInput: usernameCollectionOptions,
    strings: { usernameCollectionViewStrings, usernameCollectionViewErrorStrings },
    config: { defaultPhoneCountry, domainList, phoneCountryList },
  });

  const {
    inputStates: { easiInputState, outlookInputState, outlookDomainState, phoneNumberState },
    inputProps,
  } = usernameCollectionFabricInput;
  const { phoneInputProps, easiInputProps, outlookInputProps } = inputProps;

  const { currentInputType, setCurrentInputType, setShouldClearInputOnSwitch } =
    useUsernameStateFabric({
      inputTypeState: inputProps,
      easiState: { ...easiInputState },
      outlookState: { ...outlookInputState, ...outlookDomainState },
      phoneNumberState: {
        ...phoneNumberState.error,
        setValue: phoneNumberState.inputState.setValue,
      },
    });

  const onUsernameChange = useUsernameOnChangeFabric(
    { ...usernameCollectionFabricInput.inputStates },
    domainList,
    currentInputType,
    setCurrentInputType,
    setShouldClearInputOnSwitch,
  );

  if (phoneInputProps) {
    phoneInputProps.inputProps.onChange = onUsernameChange;
  }

  if (outlookInputProps) {
    outlookInputProps.onChange = onUsernameChange;
  }

  if (easiInputProps) {
    easiInputProps.onChange = onUsernameChange;
  }

  const usernameCollectionFabricInputProps = {
    ...inputProps,
    initialInputType,
    currentInputType,
    setCurrentInputType,
  };

  const { outlookOnSubmit, easiOnSubmit } = usernameCollectionFabricInput.formSubmit;
  const submitErrorHandler = useUsernameCollectionSubmitErrorHandler(
    phoneNumberState.error.setServerError,
    outlookInputState.errorHandler,
    easiInputState.errorHandler,
  );

  const isSimplifiedChildAccountCreationEasiFlow =
    isSimplifiedChildAccountCreation &&
    currentInputType === MemberNameType.EASI &&
    (userFlowType === UserFlowType.Adult || userFlowType === UserFlowType.AdultWithChild);

  let pageImage: AccessibleImageProps | undefined;
  let pageTitle = signUpTitle;
  let pageDescription = appBrandedSignUpDescription;

  if (isParentFlow) {
    if (currentInputType === MemberNameType.EASI) {
      pageTitle = existingChildEmailTitle;
    } else if (currentInputType === MemberNameType.Live) {
      pageTitle = newChildEmailTitle;
    }

    pageDescription = childUsernameDescription;

    pageImage = { urls: addUser, dataTestId: "addUser", role: "presentation" };
  } else if (isSimplifiedChildAccountCreationEasiFlow) {
    pageTitle = existingAdultEmailTitle;
  } else if (isFamilyScenario) {
    // Use a different title for family scenario
    pageTitle = usernameCollectionViewStrings.familyScenarioTitle;
  }

  useActivateViewWithDocumentTitle(pageTitle, ViewId.UsernameCollection, FlowId.Signup, {
    showIdentityBanner: false,
  });

  const username = getSignUpUsername(currentInputType, memberNameInput);

  const { strong } = commonBindingsFabric;
  const prefillSpeedbumpDescription = (
    <FormattedTextWithBindings
      text={replaceTokens(usernameCollectionViewStrings.prefillSpeedbumpDescription, username)}
      embeddedBindings={{ strong }}
    />
  );

  // Only show the back button if requested and not in parent flow.
  const shouldHideBackButton: boolean = isSimplifiedChildAccountCreation
    ? isParentFlow
    : hideBackButton;

  const defaultClickHandler = useNavigateOrRedirectBack();
  const showProgressAndRedirect = useShowProgressAndRedirect();
  const handleBackClick =
    isSimplifiedChildAccountCreation && userFlowType !== UserFlowType.Parent
      ? backButtonHandler || defaultClickHandler
      : () => showProgressAndRedirect(finalBackUrl);

  const getCheckAvailableError = getCheckAvailableErrorMapFabric(
    usernameCollectionViewErrorStrings,
  );

  const { submitTask, logUserAction } = useUsernameCollection({
    username,
    setCurrentInputType,
    chooseNextView,
    getCheckAvailableError,
    error: {
      setErrorMessage: submitErrorHandler(currentInputType),
      setShowError:
        currentInputType === MemberNameType.Phone
          ? phoneNumberState.error.setShowErrorMessage
          : () => {}, // This does not apply for EASI and Outlook since they are using the deprecated text input
      createAccountErrorStrings,
    },
  });

  const { onSubmit } = useUsernameCollectionFormFabric({
    config: { viewId: ViewId.UsernameCollection, userFlowType },
    form: {
      submitTask,
      submitParams: {
        username,
        usernameType: currentInputType,
        errorHandler: submitErrorHandler(currentInputType),
        useElementRef: true,
      },
      outlookOnSubmit,
      easiOnSubmit,
      phoneNumberState,
    },
    logUserAction,
  });

  const showSmsDisclaimer = phoneNoPaEnabled && currentInputType === MemberNameType.Phone;

  return {
    backOnClick: handleBackClick,
    hideBackButton: shouldHideBackButton,
    onSubmit,
    pageTitle,
    pageDescription,
    pageImage,
    prefillSpeedbumpDescription,
    showPrefillSpeedbump,
    showSmsDisclaimer,
    usernameCollectionFabricInputProps,
  };
};
