import React from "react";
import { useFormSubmission } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { useInput } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { FlowId, UserFlowType, ViewId } from "@msidentity/SISU/constants";
import featuresConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { useActivateView, useDocumentTitle } from "@msidentity/SISU/hooks";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { FormattedTextWithBindings } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { verificationCodeValidator } from "@msidentity/SISU/utilities/validators/verification-code-validator";
import { useCreateAccountError } from "../../../../hooks/use-create-account-error";
import { useMarketingOptIn } from "../../../../hooks/use-marketing-optin";
import SignUpConfig from "../../../../signup-config";
import { useVerificationView } from "../../hooks/use-verification-view";
import { useVerificationViewOnChange } from "../../hooks/use-verification-view-onchange";
import type {
  UseVerificationViewFabricProps,
  VerificationViewFabricProps,
  VerifyEmailFabricProps,
  VerifyPhoneFabricProps,
} from "../verification-view-types-fabric";

/**
 * Hook to aggregate the properties needed to render the verification view
 * @param params The parameters needed for the hook.
 * @param params.strings All strings to be used.
 * @param params.strings.verificationViewStrings Verification view strings.
 * @param params.strings.createAccountErrorStrings The error strings for the create account error.
 * @param params.strings.commonSignUpStrings Common SignUp strings
 * @param params.form The form properties.
 * @param params.form.submitTask The submit task for the verification view.
 * @returns The properties needed to render the verification view
 */
export const useVerificationViewFabric = ({
  strings: { verificationViewStrings, createAccountErrorStrings, commonSignUpStrings },
  form: { submitTask },
}: UseVerificationViewFabricProps): VerificationViewFabricProps => {
  const { emptyCodeError, invalidCodeError, enterCode } = verificationViewStrings;

  const { showKoreaConsentText } = SignUpConfig.instance;

  const {
    globalState: { userFlowType, showProgressIndicator },
  } = useGlobalContext();

  const {
    canGoBack,
    channel,
    isPhone,
    sendOtt,
    showView,
    memberName,
    memberNameType,
    verificationCode,
  } = useVerificationView();

  const { isOptInEmail, toggleIsOptInEmail } = useMarketingOptIn();
  const { showOptinEmail } = SignUpConfig.instance;
  const { isSimplifiedChildAccountCreation } = featuresConfig.instance;

  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;
  const showMarketingOptIn = showOptinEmail && memberNameType === MemberNameType.EASI;

  const primaryButtonText = showKoreaConsentText
    ? verificationViewStrings.koreaConsentText
    : commonSignUpStrings.nextButton;

  useActivateView(ViewId.SignUpVerification, FlowId.Signup, {
    showBackButtonOnActiveView: canGoBack,
  });
  useDocumentTitle(enterCode);

  const getVerifyEmailProperties = (): VerifyEmailFabricProps => {
    const { titleEASI, descriptionEASI } = verificationViewStrings;
    return {
      title: titleEASI,
      description: replaceTokens(descriptionEASI, memberName),
      descriptionBindings: {
        strong: (chunks: string[]) => <strong>{chunks[0]}</strong>,
        resendLink: (chunks: string[]) => (
          <LinkButton
            text={chunks[0]}
            onClick={() => sendOtt(channel)}
            linkId="resendCodeLink"
            linkDataTestId="resendCodeLink"
            ariaLabel={chunks[0]}
          />
        ),
      },
    };
  };

  const getVerifyPhoneProperties = (): VerifyPhoneFabricProps => {
    const { titlePhone, descriptionPhone, resendPhoneCode } = verificationViewStrings;
    const resendPhoneCodeMessage = (
      <FormattedTextWithBindings
        text={resendPhoneCode}
        embeddedBindings={{
          resendCode: (chunks: string[]) => (
            <LinkButton
              text={chunks[0]}
              onClick={() => sendOtt(channel)}
              linkId="resendCodeLink"
              linkDataTestId="resendCodeLink"
              ariaLabel={chunks[0]}
            />
          ),
        }}
      />
    );

    return {
      title: titlePhone,
      description: replaceTokens(descriptionPhone, memberName),
      resendPhoneCodeMessage,
    };
  };

  const viewParams = (isPhone ? getVerifyPhoneProperties : getVerifyEmailProperties)();

  const validator = verificationCodeValidator({
    strings: {
      validationErrorText: invalidCodeError,
      informationRequiredText: emptyCodeError,
    },
  });

  const inputState = useInput({
    hasInitialFocus: true,
    validationMethod: validator,
    initialValue: verificationCode,
  });

  const onSubmit = useFormSubmission({
    inputState,
    viewId: ViewId.SignUpVerification,
    submitTask,
  });

  const onChange = useVerificationViewOnChange(inputState);
  inputState.onChange = onChange;

  useCreateAccountError({
    errorStrings: createAccountErrorStrings,
    viewId: ViewId.SignUpVerification,
    setErrorMessage: inputState.error.setServerError,
    setShowError: inputState.error.setShowErrorMessage,
    setFocus: inputState.setFocus,
  });

  return {
    ...viewParams,
    primaryButtonText,
    inputState,
    isOptInEmail,
    isParentFlow,
    isPhone,
    onSubmit,
    showMarketingOptIn,
    toggleIsOptInEmail,
    showProgressIndicator,
    showView,
  };
};
