import React from "react";
import { type DropdownProps } from "@msidentity/SISU/components/inputs/dropdown/dropdown";
import { DropdownFabric } from "@msidentity/SISU/components/inputs/dropdown/fabric/dropdown-fabric";
import useFabricStyles from "@msidentity/SISU/styles/fabric/fabric.styles";
import { type ICountryRegionInfo } from "@msidentity/SISU/utilities/country-helper";

export interface ICountryRegionProps {
  countryList: ICountryRegionInfo[];
  hasFocus?: boolean;
  labelText?: string;
  selectedCountryIso: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

/**
 * Country/Region component for fabric flavor.
 * @param props The properties for this component
 *  - props.countryList The list of countries/regions to show in the dropdown
 *  - props.hasFocus Whether the dropdown has focus
 *  - props.labelText The label text for the dropdown
 *  - props.selectedCountryIso The ISO of the current selected country/region
 *  - props.onChange The change handler for the dropdown
 * @returns A country/region component
 */
export const CountryRegionDropdownFabric: React.FC<ICountryRegionProps> =
  function CountryRegionDropdownFabric(props) {
    const { countryList, hasFocus, labelText, onChange, selectedCountryIso } = props;

    const fabricStyles = useFabricStyles();

    const dropdownProps: DropdownProps = {
      selectOptions: countryList.map((country) => ({
        text: country.name,
        value: country.iso,
      })),
      value: selectedCountryIso,
      hasFocus,
      onChange,
    };

    return (
      <>
        {labelText && (
          <label
            htmlFor="countryRegionDropdown"
            className={fabricStyles.formFieldLabel}
            data-testid="countryRegionLabel"
          >
            {labelText}
          </label>
        )}
        <DropdownFabric id="countryRegionDropdown" {...dropdownProps} />
      </>
    );
  };
