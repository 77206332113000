export enum CheckAvailableErrorCode {
  domainExistsInAad = "1184",
  domainExistsInAadSupportedLogin = "1185",
  domainIsReserved = "1181",
  domainNotAllowed = "1117",
  signupBlocked = "1220",
  invalidEmailFormat = "1062",
  invalidPhoneFormat = "1063",
  invalidMemberNameFormat = "1064",
  phoneSignupBlocked = "1066",
  memberNameTaken = "1058",
  memberNameTakenEasi = "1242",
  memberNameTakenPhone = "1052",
  assocIdAlreadyTaken = "1178",
}

export type CheckAvailableSignInNamesErrorStrings = {
  domainExistsInAad: string;
  domainExistsInAadEasiOnly: string;
  domainExistsInAadSupportedLogin: string;
  domainNotAllowed: string;
  genericService: string;
  invalidEmailFormat: string;
  invalidPhoneFormat: string;
  memberNameTaken: string;
  memberNameTakenEasi: string;
  memberNameTakenNoSuggestions: string;
  memberNameTakenPhone: string;
  phoneRequired: string;
  phoneSignupBlocked: string;
  signupBlocked: string;
  verificationThrottled: string;
};
