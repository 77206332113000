import React from "react";
import { useHumanIframe } from "./hooks/use-human-iframe";
import { useHumanIframeStyles } from "./human-iframe.styles";

/**
 * @returns the human iframe component
 * Human Iframe will load the HUMAN bot sensing script and
 * will add the risk related cookies to their domain as well
 * as send the cookies to us via postmessages. We will copy
 * these cookies to our domain so that they are available
 * during subsequent requests
 */
export const HumanIframe = function HumanIframe() {
  const {
    iframeAttributes: { humanIframeUrl, iframeRef },
  } = useHumanIframe();
  const humanStyles = useHumanIframeStyles();

  if (humanIframeUrl) {
    // title here is not needed but required by the linting rules.
    // no localisation necessary
    return (
      <iframe
        title="Human Iframe"
        src={humanIframeUrl}
        className={humanStyles.iframe}
        ref={iframeRef}
        sandbox="allow-scripts allow-same-origin"
        aria-hidden="true"
        data-testid="humanIframe"
      />
    );
  }

  return null;
};
