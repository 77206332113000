import { type ResponseBody } from "@msidentity/SISU/constants";
import { postApiRequest } from "@msidentity/SISU/utilities/request/request-helper";
import {
  getAPIUrlWithCurrentWindowQS,
  setCommonAccountPostRequestData,
} from "../api-request-helper";
import { type DefaultApiResponse } from "../general-api-types";

export interface ICheckAvailableSigninNamesRequest {
  /** The device flow token. */
  dft?: string;
  /** Whether the signin name was available based on the server side CheckAvailableSigninNames API call. */
  isAvailableOnServerCache?: boolean;
  /** Whether the account being checked is an EASI account. */
  isEasiCheck?: boolean;
  /** Whether or not membername suggestions is included in the response. */
  includeSuggestions?: boolean;
  /** Membername suggestions tokens. */
  mnstokens?: string;
  /** The provided membername. */
  signInName: string;
  /** Whether to skip the actual SAPI call. */
  skipBECall?: boolean;
}

export interface ICheckAvailableSigninNamesResponse extends ResponseBody, DefaultApiResponse {
  /** Whether or not the membername is available. */
  isAvailable?: boolean;
  /** Whether or not the membername provided exists as a proof. */
  isProof?: boolean;
  /** Whether or not no-password (noPa) is allowed for the provided membername. */
  nopaAllowed?: boolean;
  /** Whether or not the membername provided is in eviction state. */
  possibleEviction?: boolean;
  /** The list of membername suggestions. */
  suggestions?: string[];
  /** The membername type. */
  type?: string;
}

/**
 * This a wrapper method that makes a POST request to the CheckAvailableSigninNames API.
 * @param checkAvailableSigninNamesUrl The CheckAvailableSigninNames request URL.
 * @param requestParams The request parameters.
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const checkAvailableSigninNames = (
  checkAvailableSigninNamesUrl: string,
  requestParams: ICheckAvailableSigninNamesRequest,
): Promise<ICheckAvailableSigninNamesResponse> => {
  const requestBody = {
    ...requestParams,
    ...setCommonAccountPostRequestData(),
  };

  const options = { body: JSON.stringify(requestBody) };
  return postApiRequest<ICheckAvailableSigninNamesResponse>(
    getAPIUrlWithCurrentWindowQS(checkAvailableSigninNamesUrl),
    options,
  );
};
