import { type DropdownOption } from "../components/inputs/dropdown/dropdown";
import { DateOrder } from "../utilities/birthdate-helper";
import { ConfigWrapper } from "../utilities/config-wrapper";
import { type ServerData } from "../utilities/server-data";

export const defaultDateConfig = {
  dateOrder: DateOrder.MDY,
  dayOptions: [] as DropdownOption[],
  maxBirthYear: "",
  minBirthYear: "",
  monthOptions: [] as DropdownOption[],
};

export type DateConfigType = typeof defaultDateConfig;

/**
 * The config wrapper that should be used to access Date config properties
 */
// eslint-disable-next-line deprecation/deprecation
const configWrapper = new ConfigWrapper(defaultDateConfig);
export default configWrapper;

/**
 * Initialize the Date config object from ServerData
 * @param serverData The server data object that should be used to initialize the Date config
 */
export function initDateConfig(serverData: ServerData) {
  const mappedServerData: Partial<DateConfigType> = {};

  if (serverData?.arrDayOptions) {
    mappedServerData.dayOptions = serverData.arrDayOptions.map((option) => ({
      text: option.Value ?? "",
      value: option.Key ?? "",
    }));
  }

  if (serverData?.arrMonthOptions) {
    mappedServerData.monthOptions = serverData.arrMonthOptions.map((option) => ({
      text: option.Value ?? "",
      value: option.Key ?? "",
    }));
  }

  if (serverData?.iMinBirthYear) {
    mappedServerData.minBirthYear = String(serverData.iMinBirthYear);
  }

  if (serverData?.iMaxBirthYear) {
    mappedServerData.maxBirthYear = String(serverData.iMaxBirthYear);
  }

  if (serverData?.sDateOrder) {
    mappedServerData.dateOrder = DateOrder[serverData.sDateOrder as keyof typeof DateOrder];
  }

  configWrapper.initialize(mappedServerData);
}
