import React from "react";
import { Input } from "../input/input";
import { type BaseInputProps } from "../input/input-types";
import { type RequiredNumberInputProps } from "./number-input-types";
import { buildNumberInputProps } from "./number-input-utils";

export type NumberInputProps = Omit<BaseInputProps, "type"> & RequiredNumberInputProps;

/**
 * NumberInput component
 * @param props The properties for this component
 * @returns A number input component
 */
export const NumberInput: React.FC<NumberInputProps> = function NumberInput(props) {
  const freshProps = buildNumberInputProps<NumberInputProps>(props);

  return <Input {...freshProps} />;
};
