import { NonNumericRegex } from "@msidentity/SISU/components/inputs/input-regex-constants";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateView, useBackButtonControl, useDocumentTitle } from "@msidentity/SISU/hooks";
import { appendOrReplaceQueryStringParams } from "@msidentity/SISU/utilities/strings-helper";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";

export type UseEvictionSpeedbumpResponse = {
  memberName: string;
  signInUrl: string;
};

export const useEvictionSpeedbump = (documentTitle: string): UseEvictionSpeedbumpResponse => {
  const { evictionSignInUrl } = SignUpConfig.instance;

  const canGoBack = useBackButtonControl();

  useActivateView(ViewId.SignUpEvictionSpeedbump, FlowId.Signup, {
    showBackButtonOnActiveView: canGoBack,
  });
  useDocumentTitle(documentTitle);

  const {
    viewState: { memberName },
  } = useSignUpContext();
  // Remove the formatting in the membername, as causes issue with eviction sign in when prefilled
  const unformattedMemberName = memberName.replace(NonNumericRegex, "");
  const signInUrl = appendOrReplaceQueryStringParams(evictionSignInUrl, {
    username: encodeURI(unformattedMemberName),
  });

  return { memberName, signInUrl };
};
