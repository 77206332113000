import { getDaysInMonth } from "../datetime-helper";

/**
 * Calculate the age of a subject based on their birthdate.
 * @param day Day of the month: 1-31
 * @param month Month of the year: 1-12
 * @param year Year
 * @returns The age of the subject
 */
export const calculateAge = (day: number, month: number, year: number): number => {
  const today = new Date();
  // Months in JavaScript are 0-based
  const birthDate = new Date(year, month - 1, day);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age;
};

export interface BirthdateValidationParams {
  strings: {
    informationRequiredError: string;
    invalidBirthdateError: string;
    minAgeError?: string;
  };
  birthDate: {
    day: string | number;
    month: string | number;
    year: string | number;
    minYear: string | number;
    maxYear: string | number;
  };
  /** Minimum age threshold */
  minAge?: number;
}

/**
 * Validate a birthdate
 * @param params
 *  - params.strings.informationRequiredError: The error message to display if the birthdate is missing information
 *  - params.strings.invalidBirthdateError: The error message to display if the birthdate is invalid
 *  - params.strings.minAgeError: The error message to display if the birthdate indicates the subject is underage
 *  - params.birthDate.day: The day of the birthdate to validate
 *  - params.birthDate.month: The month of the birthdate to validate
 *  - params.birthDate.year: The year of the birthdate to validate
 *  - params.birthDate.minYear: The minimum year allowed for the birthdate
 *  - params.birthDate.maxYear: The maximum year allowed for the birthdate
 *  - params.minAge: The minimum age threshold to be considered underage
 *
 * @returns The error message to display if the birthdate is invalid, or an empty string if the birthdate is valid
 */
export const validateBirthdate = (params: BirthdateValidationParams): string => {
  const {
    strings: { informationRequiredError, invalidBirthdateError, minAgeError },
    birthDate: { day, month, year, minYear, maxYear },
    minAge,
  } = params;
  if (!day || !month || !year) {
    return informationRequiredError;
  }

  const yearNumber = Number(year);
  const dayNumber = Number(day);
  const monthNumber = Number(month);

  if (Number.isNaN(yearNumber) || Number.isNaN(dayNumber) || Number.isNaN(monthNumber)) {
    return invalidBirthdateError;
  }

  if (monthNumber < 1 || monthNumber > 12 || dayNumber < 1) {
    return invalidBirthdateError;
  }

  const daysInMonth = getDaysInMonth(yearNumber, monthNumber);
  if (dayNumber > daysInMonth) {
    return invalidBirthdateError;
  }

  const minYearNumber = Number(minYear);
  const maxYearNumber = Number(maxYear);

  if (
    (!Number.isNaN(minYearNumber) && yearNumber < minYearNumber) ||
    (!Number.isNaN(maxYearNumber) && yearNumber > maxYearNumber)
  ) {
    return invalidBirthdateError;
  }

  if (minAge && calculateAge(dayNumber, monthNumber, yearNumber) <= minAge) {
    return minAgeError ?? "";
  }

  return "";
};
