import React from "react";
import { BirthdateFabric } from "@msidentity/SISU/components";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import useFabricStyles from "@msidentity/SISU/styles/fabric/fabric.styles";
import { addUser } from "@msidentity/sisu/utilities/image-helpers/accessible-images";
import { mergeClasses } from "@msidentity/SISU/utilities/merge-classes";
import { CountryRegionDropdownFabric } from "../../../../../components/country-region-dropdown/fabric/country-region-dropdown-fabric";
import { commonSignUpStringsFabric } from "../../../fabric/common-signup-strings-fabric";
import { createAccountErrorStringsFabric as createAccountErrorStrings } from "../../../fabric/create-account-error-strings-fabric";
import { useCountryBirthdateFabric } from "./hooks/use-country-birthdate-fabric";
import { countryBirthdateViewStringsFabric as viewStrings } from "./country-birthdate-view-strings-fabric";

/**
 * Date of birth and country collection view component.
 * @returns A rendered instance of this component
 */
export const CountryBirthdateViewFabric: React.FC = function CountryBirthdateViewFabric() {
  const {
    legalWarning,
    countryList,
    onCountryChange,
    birthdateProps,
    onSubmitHandler,
    selectedCountryIso,
    title,
    description,
    isParentFlow,
  } = useCountryBirthdateFabric({ viewStrings, createAccountErrorStrings });

  const { nextButton: nextButtonLabel } = commonSignUpStringsFabric;
  const titleId = "countryBirthdateViewTitle";
  const descriptionId = "countryBirthdateViewDescription";

  const fabricStyles = useFabricStyles();

  const secondaryContentClass = mergeClasses(fabricStyles.row, fabricStyles.legalWarning);

  return (
    <ViewContainerFabric
      title={{
        title,
        titleId,
      }}
      primaryButton={{
        ariaDescribedBy: `${titleId} ${descriptionId}`,
        type: "submit",
        label: nextButtonLabel,
        buttonId: "nextButton",
      }}
      form={{
        onSubmit: onSubmitHandler,
      }}
      image={
        isParentFlow ? { urls: addUser, dataTestId: "addUser", role: "presentation" } : undefined
      }
      description={{ descriptionId, description }}
    >
      <div className={fabricStyles.row}>
        <div className={fabricStyles.formGroup}>
          <CountryRegionDropdownFabric
            countryList={countryList}
            selectedCountryIso={selectedCountryIso}
            labelText={viewStrings.countryLabel}
            hasFocus
            onChange={(event) => {
              onCountryChange(event.target.value);
            }}
          />
        </div>
      </div>

      <div className={fabricStyles.row}>
        <BirthdateFabric {...birthdateProps} />
      </div>

      {legalWarning && <div className={secondaryContentClass}>{legalWarning}</div>}
    </ViewContainerFabric>
  );
};
