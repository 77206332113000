import { type ViewId } from "@msidentity/SISU/constants";
import { type MemberNameType } from "@msidentity/SISU/model/user";
import { type CountryBirthdateFormValues } from "./views/country-birthdate/country-birthdate-view-types";
import { type SignupNameCollectionFormValues } from "./views/name-collection/signup-name-collection-view-interface";
import type { IMemberNameReporting, KoreaConsentState, SignUpState } from "./signup-context";
import type { MemberNameInput } from "./signup-types";

export enum SignUpActionType {
  ConsentToChinaPipl,
  SetCountryBirthdate,
  SetCreateAccountErrorCode,
  SetCreateAccountErrorShown,
  SetCreateAccountErrorViewId,
  SetFirstNameLastName,
  SetIsOptInEmail,
  SetIsPossibleEvicted,
  SetLastMemberNameUsedOnSendOtt,
  SetLoginUrl,
  SetMemberName,
  SetMemberNameInput,
  SetMemberNameSuggestions,
  SetMemberNameType,
  SetNextViewId,
  SetNoPaAllowedOnEviction,
  SetPassword,
  SetUsernameCollectionError,
  SetUsernameCollectionErrorShown,
  SetPreviouslyCheckedMemberName,
  SetShowPrefillSpeedbump,
  SetSendOttError,
  SetSendOttErrorShown,
  SetVerificationCode,
  SetVerificationSlt,
  UpdateCheckAvailableStateMap,
  UpdateEvictionWarningMemberName,
  UpdateKoreaConsent,
  UpdateMemberNameReporting,
}

export type ConsentToChinaPipl = {
  actionType: SignUpActionType.ConsentToChinaPipl;
  payload: boolean;
};

export type SetCountryBirthdate = {
  actionType: SignUpActionType.SetCountryBirthdate;
  payload: CountryBirthdateFormValues;
};

export type SetCreateAccountErrorCode = {
  actionType: SignUpActionType.SetCreateAccountErrorCode;
  payload: string;
};

export type SetCreateAccountErrorShown = {
  actionType: SignUpActionType.SetCreateAccountErrorShown;
  payload: boolean;
};

export type SetCreateAccountErrorViewId = {
  actionType: SignUpActionType.SetCreateAccountErrorViewId;
  payload: ViewId;
};

export type SetFirstNameLastNameAction = {
  actionType: SignUpActionType.SetFirstNameLastName;
  payload: SignupNameCollectionFormValues;
};

export type SetNextViewIdAction = {
  actionType: SignUpActionType.SetNextViewId;
  payload: ViewId;
};

export type SetLastMemberNameUsedOnSendOttAction = {
  actionType: SignUpActionType.SetLastMemberNameUsedOnSendOtt;
  payload: string;
};

export type SetLoginUrlAction = {
  actionType: SignUpActionType.SetLoginUrl;
  payload: string;
};

export type SetPreviouslyCheckedMemberNameAction = {
  actionType: SignUpActionType.SetPreviouslyCheckedMemberName;
  payload: string;
};

export type SetIsOptInEmailAction = {
  actionType: SignUpActionType.SetIsOptInEmail;
  payload: boolean;
};

export type SetMemberNameAction = {
  actionType: SignUpActionType.SetMemberName;
  payload: string;
};

export type SetMemberNameInputAction = {
  actionType: SignUpActionType.SetMemberNameInput;
  payload: Partial<MemberNameInput>;
};

export type SetMemberNameTypeAction = {
  actionType: SignUpActionType.SetMemberNameType;
  payload: MemberNameType;
};

export type SetIsPossibleEvicted = {
  actionType: SignUpActionType.SetIsPossibleEvicted;
  payload: boolean;
};

export type SetNoPaAllowedOnEviction = {
  actionType: SignUpActionType.SetNoPaAllowedOnEviction;
  payload: boolean;
};

export type SetPassword = {
  actionType: SignUpActionType.SetPassword;
  payload: string;
};

export type SetUsernameCollectionError = {
  actionType: SignUpActionType.SetUsernameCollectionError;
  payload: string;
};

export type SetUsernameCollectionErrorShown = {
  actionType: SignUpActionType.SetUsernameCollectionErrorShown;
  payload: boolean;
};

export type SetMemberNameSuggestionsAction = {
  actionType: SignUpActionType.SetMemberNameSuggestions;
  payload: string[];
};

export type SetSendOttErrorAction = {
  actionType: SignUpActionType.SetSendOttError;
  payload: string;
};

export type SetSendOttErrorShownAction = {
  actionType: SignUpActionType.SetSendOttErrorShown;
  payload: boolean;
};

export type SetShowPrefillSpeedbump = {
  actionType: SignUpActionType.SetShowPrefillSpeedbump;
  payload: boolean;
};

export type SetVerificationCode = {
  actionType: SignUpActionType.SetVerificationCode;
  payload: string;
};

export type SetVerificationSlt = {
  actionType: SignUpActionType.SetVerificationSlt;
  payload: string;
};

export type UpdateCheckAvailableStateMap = {
  actionType: SignUpActionType.UpdateCheckAvailableStateMap;
  payload: string;
};

export type UpdateEvictionWarningMemberName = {
  actionType: SignUpActionType.UpdateEvictionWarningMemberName;
  payload: string;
};

export type UpdateKoreaConsentAction = {
  actionType: SignUpActionType.UpdateKoreaConsent;
  payload: Partial<KoreaConsentState>;
};

export type UpdateMemberNameReporting = {
  actionType: SignUpActionType.UpdateMemberNameReporting;
  payload: Partial<IMemberNameReporting>;
};

export type SignUpActions =
  | ConsentToChinaPipl
  | SetCountryBirthdate
  | SetCreateAccountErrorCode
  | SetCreateAccountErrorShown
  | SetCreateAccountErrorViewId
  | SetFirstNameLastNameAction
  | SetLastMemberNameUsedOnSendOttAction
  | SetLoginUrlAction
  | SetPreviouslyCheckedMemberNameAction
  | SetMemberNameSuggestionsAction
  | SetNextViewIdAction
  | SetIsOptInEmailAction
  | SetMemberNameAction
  | SetMemberNameInputAction
  | SetMemberNameTypeAction
  | SetIsPossibleEvicted
  | SetNoPaAllowedOnEviction
  | SetPassword
  | SetUsernameCollectionError
  | SetUsernameCollectionErrorShown
  | SetSendOttErrorAction
  | SetSendOttErrorShownAction
  | SetShowPrefillSpeedbump
  | SetVerificationCode
  | SetVerificationSlt
  | UpdateCheckAvailableStateMap
  | UpdateEvictionWarningMemberName
  | UpdateKoreaConsentAction
  | UpdateMemberNameReporting;

/**
 * This method is used to determine whether to enable/disable the next button on the korea consent view
 * based on changes to the user's consent.
 * @param state The existing Signup state
 * @param stateToChange The KoreaConsentState object to apply
 * @returns the updated KoreaConsent state
 */
export const updateKoreaConsent = function updateKoreaConsent(
  state: SignUpState,
  stateToChange: Partial<KoreaConsentState>,
): KoreaConsentState {
  const newState = {
    ...state.koreaConsent,
    ...stateToChange,
  };
  newState.nextButtonEnabled =
    newState.dataCollection.userConsented && newState.dataProvision.userConsented;

  return newState;
};

/**
 * SignUp state reducer.
 * @param state The current SignUp state
 * @param action The action to perform on the current state
 * @returns The updated SignUp state
 */
export default function signUpReducer(state: SignUpState, action: SignUpActions): SignUpState {
  const { actionType } = action;
  switch (actionType) {
    case SignUpActionType.ConsentToChinaPipl:
      return { ...state, isUserConsentedToChinaPIPL: action.payload };
    case SignUpActionType.SetCountryBirthdate:
      return {
        ...state,
        birthDay: action.payload.birthDay,
        birthMonth: action.payload.birthMonth,
        birthYear: action.payload.birthYear,
        country: action.payload.country,
      };
    case SignUpActionType.SetCreateAccountErrorCode:
      return { ...state, createAccountErrorCode: action.payload };
    case SignUpActionType.SetCreateAccountErrorShown:
      return { ...state, createAccountErrorShown: action.payload };
    case SignUpActionType.SetCreateAccountErrorViewId:
      return {
        ...state,
        createAccountErrorViewId: action.payload,
      };
    case SignUpActionType.SetLoginUrl:
      return { ...state, loginUrl: action.payload };
    case SignUpActionType.SetPreviouslyCheckedMemberName:
      return { ...state, previouslyCheckedMemberName: action.payload };
    case SignUpActionType.SetMemberName:
      return { ...state, memberName: action.payload };
    case SignUpActionType.SetMemberNameInput:
      return {
        ...state,
        memberNameInput: {
          ...state.memberNameInput,
          ...action.payload,
        },
      };
    case SignUpActionType.SetMemberNameType:
      return { ...state, memberNameType: action.payload };
    case SignUpActionType.SetPassword:
      return { ...state, password: action.payload };
    case SignUpActionType.SetNextViewId:
      return { ...state, nextViewId: action.payload };
    case SignUpActionType.SetUsernameCollectionError:
      return { ...state, usernameCollectionErrorCode: action.payload };
    case SignUpActionType.SetUsernameCollectionErrorShown:
      return { ...state, usernameCollectionErrorShown: action.payload };
    case SignUpActionType.SetIsOptInEmail:
      return { ...state, isOptInEmail: action.payload };
    case SignUpActionType.SetFirstNameLastName:
      return { ...state, firstName: action.payload.firstName, lastName: action.payload.lastName };
    case SignUpActionType.SetMemberNameSuggestions:
      return { ...state, memberNameSuggestions: action.payload };
    case SignUpActionType.SetIsPossibleEvicted:
      return { ...state, isPossibleEvicted: action.payload };
    case SignUpActionType.SetNoPaAllowedOnEviction:
      return { ...state, noPaAllowedOnEviction: action.payload };
    case SignUpActionType.SetLastMemberNameUsedOnSendOtt:
      return { ...state, lastMemberNameUsedOnSendOtt: action.payload };
    case SignUpActionType.SetSendOttError:
      return { ...state, sendOttError: action.payload };
    case SignUpActionType.SetSendOttErrorShown:
      return { ...state, sendOttErrorShown: action.payload };
    case SignUpActionType.SetShowPrefillSpeedbump:
      return { ...state, showPrefillSpeedbump: action.payload };
    case SignUpActionType.SetVerificationCode:
      return { ...state, verificationCode: action.payload };
    case SignUpActionType.SetVerificationSlt:
      return { ...state, verificationSlt: action.payload };
    case SignUpActionType.UpdateCheckAvailableStateMap:
      return {
        ...state,
        checkAvailableStateMap: [...state.checkAvailableStateMap, action.payload],
      };
    case SignUpActionType.UpdateEvictionWarningMemberName:
      return {
        ...state,
        evictionWarningMemberName: [...state.evictionWarningMemberName, action.payload],
      };
    case SignUpActionType.UpdateKoreaConsent:
      return {
        ...state,
        koreaConsent: updateKoreaConsent(state, action.payload),
      };
    case SignUpActionType.UpdateMemberNameReporting:
      return {
        ...state,
        reporting: {
          ...state.reporting,
          ...action.payload,
        },
      };
    default:
      throw new Error(`SignUpReducer received unexpected action ${actionType}`);
  }
}
