import { type FormEvent, type SetStateAction } from "react";
import { type Birthdate } from "@msidentity/SISU/components/birthdate/hooks/use-birthdate";
import { UserFlowType, ViewId } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { calculateAge } from "@msidentity/SISU/utilities/validators/birthdate-validator";
import { useCreateAccount } from "../../../../hooks/use-create-account";
import SignUpConfig from "../../../../signup-config";
import { useSignUpContext } from "../../../../signup-context";
import { SignUpActionType } from "../../../../signup-reducer";

export type UseCountryBirthdateSubmitFabricParams = {
  country: string;
  birthdate: Birthdate;
  setUserHasSubmitted: React.Dispatch<SetStateAction<boolean>>;
  hasValidationError: boolean;
  firstBirthdateElement:
    | React.RefObject<HTMLSelectElement>
    | React.RefObject<HTMLInputElement>
    | undefined;
};

/**
 * Hook to create the submit handler for the country/birthdate collection view
 * @param params Parameters for this hook
 * @param params.country The country form value
 * @param params.birthdate The birthdate form value
 * @param params.setUserHasSubmitted The setter for the userHasSubmitted state
 * @param params.hasValidationError True if the birthdate currently has a validation error
 * @param params.firstBirthdateElement The first birthdate element to focus on if there is a validation error
 * @returns a submit handler for the country/birthdate collection view
 */
export const useCountryBirthdateSubmitFabric = ({
  country,
  birthdate,
  setUserHasSubmitted,
  hasValidationError,
  firstBirthdateElement,
}: UseCountryBirthdateSubmitFabricParams) => {
  const { birthDay, birthMonth, birthYear } = birthdate;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const { countryDetailsMap } = SignUpConfig.instance;
  const { viewState, dispatchStateChange: dispatchSignUp } = useSignUpContext();
  const {
    globalState: { userFlowType },
    dispatchStateChange: dispatchGlobal,
  } = useGlobalContext();
  const createAccountRequest = useCreateAccount();
  const navigate = useNavigateDirection();

  return async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const setUserFlowType = (payload: UserFlowType) => {
      dispatchGlobal({
        type: GlobalActionType.SetUserFlowType,
        payload: payload.toString(),
      });
    };

    const dispatchDataLoaded = () => {
      dispatchGlobal({
        type: GlobalActionType.DataLoaded,
        view: ViewId.CountryBirthdate,
      });
    };

    setUserHasSubmitted(true);

    if (hasValidationError) {
      // country/region can't be invalid because it's a dropdown with a default value
      // so if there is an error, it's on the birthdate control.
      // Use setTimeout to wait for DOM rendering to complete before focusing.
      setTimeout(() => {
        firstBirthdateElement?.current?.focus();
      });
      dispatchDataLoaded();
      return;
    }

    // update country/birthdate in context before continuing
    dispatchSignUp({
      actionType: SignUpActionType.SetCountryBirthdate,
      payload: {
        country,
        ...birthdate,
      },
    });

    if (isSimplifiedChildAccountCreation && userFlowType !== UserFlowType.Parent) {
      if (userFlowType === UserFlowType.AdultWithChild) {
        navigate(ViewId.CountryBirthdate, ViewId.UsernameCollection);
      } else {
        // case for unknown, child and adult user flow types
        const countryChildAge = countryDetailsMap[country]?.childAge;
        const age = calculateAge(Number(birthDay), Number(birthMonth), Number(birthYear));

        if (countryChildAge && age > countryChildAge) {
          setUserFlowType(UserFlowType.Adult);
          navigate(ViewId.CountryBirthdate, ViewId.UsernameCollection);
        } else {
          // User is under the child age threshold for the region
          setUserFlowType(UserFlowType.Child);
          navigate(ViewId.CountryBirthdate, ViewId.ParentalHandover);
        }
      }
    } else {
      await createAccountRequest(
        {
          ...viewState,
          country,
          ...birthdate,
        },
        ViewId.CountryBirthdate,
      );
    }

    // re-enable after api completion
    dispatchDataLoaded();
  };
};
