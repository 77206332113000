import { MemberNameType as UsernameType } from "@msidentity/SISU/model/user";
import { type AccountPrefillInfo } from "@msidentity/SISU/types/account-prefill-types";
import SignUpConfig from "../signup-config";
import { type MemberNameInput, SignUpFlowType } from "../signup-types";

/**
 * Maps the signup flow to the membername input type that should be shown initially based on the
 * signup flow (set by the fl query param), the prefill membername object and whether phone signup
 * is supported or not.
 * @param config The signup config
 * @param config.isPhoneSupported Flag for whether phone sign up is supported or not
 * @param config.prefill The prefill object
 * @param config.signUpFlow The Signup flow type
 * @returns The initial input type based on the signup flow type, prefill object, and phone supported flag.
 */
export const signUpFlowToInitialInputType = (config: {
  isPhoneSupported: boolean;
  signUpFlow: string;
  prefill: Partial<AccountPrefillInfo>;
}): UsernameType => {
  const { signUpFlow } = config;
  const memberNamePrefill = config.prefill.oMemberName;

  if (memberNamePrefill) {
    const { oEasi, oLive, oPhone } = memberNamePrefill;

    const defaultEasiNoEasiPrefill =
      (signUpFlow === SignUpFlowType.DefaultEasi ||
        signUpFlow === SignUpFlowType.DefaultEasiNoLive) &&
      !oEasi;
    const defaultLiveNoLivePrefill = signUpFlow === SignUpFlowType.DefaultLive && !oLive;
    const defaultPhoneNoPhonePrefill =
      (signUpFlow === SignUpFlowType.DefaultPhone ||
        signUpFlow === SignUpFlowType.AlwaysDefaultPhone ||
        signUpFlow === SignUpFlowType.DefaultPhoneNoLive) &&
      !oPhone;

    // Handle edge case where the signup flow is a default flow, and there is no prefill
    // for the default username type, but there is prefill for the other username types.
    if ((defaultEasiNoEasiPrefill || defaultLiveNoLivePrefill) && oPhone) {
      return config.isPhoneSupported ? UsernameType.Phone : UsernameType.EASI;
    }

    if ((defaultPhoneNoPhonePrefill || defaultLiveNoLivePrefill) && oEasi) {
      return UsernameType.EASI;
    }

    if ((defaultPhoneNoPhonePrefill || defaultEasiNoEasiPrefill) && oLive) {
      return UsernameType.Live;
    }
  }

  switch (signUpFlow) {
    case SignUpFlowType.Email:
      if (memberNamePrefill?.oLive) {
        return UsernameType.Live;
      }

      return UsernameType.EASI;
    case SignUpFlowType.Easi:
    case SignUpFlowType.DefaultEasi:
    case SignUpFlowType.DefaultEasiNoLive:
      return UsernameType.EASI;
    case SignUpFlowType.Live:
    case SignUpFlowType.DefaultLive:
      return UsernameType.Live;
    case SignUpFlowType.Phone:
    case SignUpFlowType.DefaultPhone:
    case SignUpFlowType.AlwaysDefaultPhone:
    case SignUpFlowType.DefaultPhoneNoLive:
      // If phone number sign up is not supported; change initialInputType to fallback to EASI
      if (!config.isPhoneSupported) {
        return UsernameType.EASI;
      }

      return UsernameType.Phone;
    default:
      return UsernameType.EASI;
  }
};

/**
 * @param memberName The memberName to determine the type for
 * @returns the MemberNameType for the given memberName
 */
export const getMemberNameType = function getMemberNameType(memberName?: string) {
  const { domainList } = SignUpConfig.instance;

  if (memberName) {
    if (memberName.indexOf("@") >= 0) {
      const memberNameTokens = memberName.split("@");
      if (memberNameTokens.length === 2) {
        // Can't use indexOf here since we need to do case-insensitive search.
        const domain = memberNameTokens[1].toLowerCase();
        for (let idx = 0; idx < domainList.length; idx += 1) {
          if (domainList[idx].toLowerCase() === domain) {
            return UsernameType.Live;
          }
        }

        return UsernameType.EASI;
      }

      return UsernameType.Unknown;
    }

    return UsernameType.Phone;
  }

  return UsernameType.Unknown;
};

/**
 * @param memberNameType The type of memberName
 * @param memberNameInput The memberName input object
 * @returns the full signup username based on the memberName type
 */
export const getSignUpUsername = (
  memberNameType: UsernameType,
  memberNameInput: MemberNameInput,
) => {
  switch (memberNameType) {
    case UsernameType.EASI:
      return memberNameInput.input;
    case UsernameType.Live:
      return `${memberNameInput.input}@${memberNameInput.domain}`;
    case UsernameType.Phone:
      return `+${memberNameInput.phoneCountry.code}${memberNameInput.input}`;
    default:
      return "";
  }
};
