import React from "react";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { commonSignUpStringsFabric } from "../../../fabric/common-signup-strings-fabric";
import SignUpConfig from "../../../signup-config";
import { useMembernameTakenLearnMore } from "../hooks/use-membername-taken-learn-more";
import { membernameTakenLearnMoreViewStringsFabric } from "./membername-taken-learn-more-view-strings-fabric";

/**
 * This simple view is shown during the SignUp flow when the user enters a phone number that is already taken
 * and the flow is hosted. The view shows a description containing an aka.ms link that outlines why the input
 * phone number is not allowed and the options available to the user.
 * @returns A rendered instance of the MembernameTakenLearnMoreViewFabric component
 */
export const MembernameTakenLearnMoreViewFabric: React.FC =
  function MembernameTakenLearnMoreViewFabric() {
    const { nextButton } = commonSignUpStringsFabric;
    const { visitLink } = membernameTakenLearnMoreViewStringsFabric;

    const { primaryButtonClickHandler } = useMembernameTakenLearnMore({
      strings: commonSignUpStringsFabric,
    });

    return (
      <ViewContainer
        description={{
          description: replaceTokens(visitLink, SignUpConfig.instance.phoneNumberTakenUrl),
          descriptionId: "membernameTakenLearnMoreDescription",
        }}
        primaryButton={{
          label: nextButton,
          onClick: primaryButtonClickHandler,
        }}
      />
    );
  };
