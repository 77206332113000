import { FlowId, ViewId } from "@msidentity/SISU/constants/routing-constants";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useShowProgressAndRedirect } from "@msidentity/SISU/hooks";
import { useTelemetry } from "@msidentity/SISU/telemetry-helpers/use-telemetry";
import { UserActionName } from "@msidentity/SISU/telemetry-helpers/user-action-name";
import { useNavigateToNextViewId } from "../../../hooks/use-navigate-to-next-viewid";
import SignupConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

/**
 * @returns event handlers for the China PIPL view
 */
export const useEventHandlers = function useEventHandlers() {
  const { telemetry, activeFlavor } = GlobalConfig.instance;
  const { finalBackUrl } = SignupConfig.instance;
  const { dispatchStateChange } = useSignUpContext();
  const { logUserAction } = useTelemetry(telemetry, {
    activeView: ViewId.SignUpChinaPipl,
    activeFlow: FlowId.Signup,
    activeFlavor,
  });
  const showProgressAndRedirect = useShowProgressAndRedirect();

  const primaryButtonOnClick = () => {
    logUserAction({ actionName: UserActionName.Signup_ChinaPiplConsent, actionValue: "Accept" });
    dispatchStateChange({ actionType: SignUpActionType.ConsentToChinaPipl, payload: true });
  };

  const secondaryButtonOnClick = () => {
    logUserAction({ actionName: UserActionName.Signup_ChinaPiplConsent, actionValue: "Decline" });
    showProgressAndRedirect(finalBackUrl);
  };

  const formOnSubmit = useNavigateToNextViewId(ViewId.SignUpChinaPipl);

  return {
    primaryButtonOnClick,
    secondaryButtonOnClick,
    formOnSubmit,
  };
};
