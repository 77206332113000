import { type ChangeEvent } from "react";
import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

/**
 * Provides an onChange handler for the verification view
 * @param inputState Input state to bubble up the onChange event
 * @returns An onChange handler for the verification view
 */
export const useVerificationViewOnChange = (inputState: InputState) => {
  const { dispatchStateChange } = useSignUpContext();
  const { onChange: originalOnChange } = inputState;

  return (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue: string = typeof event === "string" ? event : event.target.value;
    originalOnChange(newValue);
    dispatchStateChange({
      actionType: SignUpActionType.SetVerificationCode,
      payload: newValue,
    });

    return newValue;
  };
};
