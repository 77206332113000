import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateViewWithDocumentTitle, useNavigateDirection } from "@msidentity/SISU/hooks";
import { type CommonSignUpStringsFabric } from "../../../fabric/signup-types-fabric";

export interface IUseMembernameTakenLearnMoreParams {
  strings: CommonSignUpStringsFabric;
}

export interface IUseMembernameTakenLearnMoreResponse {
  primaryButtonClickHandler: () => void;
}

/**
 * @param params Parameters for this hook
 * @param params.strings Localized strings common to the signup flow to use in this hook
 * @returns {IUseMembernameTakenLearnMoreResponse} Returns membername taken learn more view properties
 */
export const useMembernameTakenLearnMore = ({
  strings,
}: IUseMembernameTakenLearnMoreParams): IUseMembernameTakenLearnMoreResponse => {
  const { signUpTitle } = strings;
  useActivateViewWithDocumentTitle(
    signUpTitle,
    ViewId.SignUpMembernameTakenLearnMore,
    FlowId.Signup,
    { showIdentityBanner: false },
  );

  const navigate = useNavigateDirection();
  const primaryButtonClickHandler = () =>
    navigate(ViewId.SignUpMembernameTakenLearnMore, ViewId.UsernameCollection);

  return { primaryButtonClickHandler };
};
