import { type ChangeEvent } from "react";
import { type EmailInputState } from "@msidentity/SISU/components/inputs/email-input/hooks/use-email-input";
import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { type InputValue } from "@msidentity/SISU/components/inputs/input/input-types";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { useTelemetry } from "@msidentity/SISU/telemetry-helpers/use-telemetry";
import { UserActionName } from "@msidentity/SISU/telemetry-helpers/user-action-name";
import { type ICountryInfo } from "@msidentity/SISU/utilities/country-helper";
import { emailDomainValidator } from "@msidentity/SISU/utilities/validators/email-validator";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

export const useUsernameOnChange = () => {
  const { dispatchStateChange: dispatchSignupStateChange } = useSignUpContext();

  return (value: InputValue) => {
    const newValue = String(value);

    dispatchSignupStateChange({
      actionType: SignUpActionType.SetMemberNameInput,
      payload: {
        input: newValue,
      },
    });
  };
};

export const useUsernameOnChangeWithAutoSwitch = (
  currentInputType: MemberNameType,
  setCurrentInputType: React.Dispatch<React.SetStateAction<MemberNameType>>,
  setShouldClearInputOnSwitch: React.Dispatch<React.SetStateAction<boolean>>,
  easiInputState: InputState,
  outlookInputState: EmailInputState,
) => {
  const { domainList } = SignUpConfig.instance;
  const { dispatchStateChange: dispatchSignupStateChange } = useSignUpContext();

  const easiOnChangeInput = easiInputState.onChange;
  const outlookOnChangeInput = outlookInputState.onChangeInput;

  return (event: ChangeEvent<HTMLInputElement> | string) => {
    let newValue = typeof event === "string" ? event : event.target.value;

    // If the user enters a Live domain in the Easi input, we switch to the Live input
    if (currentInputType === MemberNameType.EASI) {
      const liveDomain = emailDomainValidator(newValue, domainList);

      if (liveDomain) {
        // A Live domain was found, so separate the input into alias and domain
        const outlookAlias = newValue.split("@")[0];
        newValue = outlookAlias;

        // Set the Outlook input to show the alias and domain, and prevent it from clearing on switch
        setShouldClearInputOnSwitch(false);
        outlookInputState.onChangeDomain(liveDomain);
        outlookOnChangeInput(outlookAlias);

        // Do the auto-switch
        setCurrentInputType(MemberNameType.Live);

        dispatchSignupStateChange({
          actionType: SignUpActionType.SetMemberNameInput,
          payload: {
            domain: liveDomain,
          },
        });
      } else {
        // If auto-switching was not needed, call the original onChange handler for Easi
        easiOnChangeInput(newValue);
      }
    }
    // If the user enters an @ symbol in the Live input, we switch to the Easi input
    else if (currentInputType === MemberNameType.Live) {
      if (newValue.slice(-1) === "@") {
        // Set the Easi input to the value entered (i.e. "name@"), and prevent it from clearing on switch
        setShouldClearInputOnSwitch(false);
        easiOnChangeInput(newValue);

        // Do the auto-switch
        setCurrentInputType(MemberNameType.EASI);
      } else {
        // If auto-switching was not needed, call the original onChange handler for Outlook
        outlookOnChangeInput(newValue);
      }
    }

    dispatchSignupStateChange({
      actionType: SignUpActionType.SetMemberNameInput,
      payload: {
        input: newValue,
      },
    });
  };
};

/**
 * Provides an onChange handler that updates signup context with the phone country value
 * @returns onChange handler for the phone country dropdown value on username collection view
 */
export const usePhoneCountryOnChange = () => {
  const { dispatchStateChange: dispatchSignupStateChange } = useSignUpContext();

  return (value: ICountryInfo) => {
    dispatchSignupStateChange({
      actionType: SignUpActionType.SetMemberNameInput,
      payload: {
        phoneCountry: value,
      },
    });
  };
};

/**
 * Provides an onChange handler that updates signup context with the domain value
 * @returns onChange handler for the domain value on username collection input
 */
export const useDomainOnChange = () => {
  const { dispatchStateChange: dispatchSignupStateChange } = useSignUpContext();
  const {
    globalState: { activeView, activeFlow, userFlowType },
  } = useGlobalContext();
  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  return (domain: string) => {
    logUserAction({
      actionName: UserActionName.DomainSelectClicked,
      actionValue: { domain, userFlowType },
    });
    dispatchSignupStateChange({
      actionType: SignUpActionType.SetMemberNameInput,
      payload: {
        domain,
      },
    });
  };
};
