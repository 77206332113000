import { useState } from "react";

export type Birthdate = {
  birthDay: string;
  birthMonth: string;
  birthYear: string;
};

export const useBirthdate = (initialBirthdate: Birthdate) => {
  const [birthDay, setBirthDayValue] = useState(initialBirthdate.birthDay);
  const [birthMonth, setBirthMonthValue] = useState(initialBirthdate.birthMonth);
  const [birthYear, setBirthYearValue] = useState(initialBirthdate.birthYear);

  return {
    birthdate: { birthDay, birthMonth, birthYear },
    setBirthdate: (birthdate: Birthdate) => {
      setBirthDayValue(birthdate.birthDay);
      setBirthMonthValue(birthdate.birthMonth);
      setBirthYearValue(birthdate.birthYear);
    },
  };
};
