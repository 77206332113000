import GlobalConfig from "@msidentity/SISU/global-config";
import { useShowProgressAndRedirect } from "@msidentity/SISU/hooks";
import SignUpConfig from "../../../signup-config";

export type UseRiskApiBlockedResponse = {
  learnMoreUrl: string;
  buttonClickHandler: () => void;
  showLearnMoreLink: boolean;
  showButton: boolean;
};

/**
 * Common hook for Risk API Blocked view
 * @returns Returns common risk api blocked view properties
 */
export const useRiskApiBlocked = (): UseRiskApiBlockedResponse => {
  const { isHosted } = GlobalConfig.instance;
  const { finalBackUrl, hideBackButton, riskBlockLearnMoreUrl } = SignUpConfig.instance;

  const showButton = !hideBackButton && !!finalBackUrl;
  const showProgressAndRedirect = useShowProgressAndRedirect();

  return {
    learnMoreUrl: riskBlockLearnMoreUrl,
    buttonClickHandler: () => showProgressAndRedirect(finalBackUrl),
    showLearnMoreLink: !isHosted,
    showButton,
  };
};
