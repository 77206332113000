import React from "react";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import StylesConfig from "@msidentity/SISU/config/styles-config";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateView, useDocumentTitle } from "@msidentity/SISU/hooks";
import { peopleShield } from "@msidentity/sisu/utilities/image-helpers/accessible-images";
import { useParentalHandoverView } from "../hooks/use-parental-handover-view";
import { parentalHandoverViewStringsFabric as strings } from "./parental-handover-view-strings-fabric";

/**
 * ParentHandoverView Component
 * @returns A rendered instance of this component
 */
export const ParentalHandoverViewFabric: React.FC = function ParentalHandoverViewFabric() {
  const { title, description, signInButton, learnMore } = strings;

  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  const { onClickParentSignIn, onClickLearnMore } = useParentalHandoverView();

  useActivateView(ViewId.ParentalHandover, FlowId.Signup, {
    showBackButtonOnActiveView: false,
    showIdentityBanner: false,
  });
  useDocumentTitle(strings.title);

  return (
    <ViewContainerFabric
      title={{ title, titleId: "parentalHandoverViewTitle" }}
      primaryButton={{
        label: signInButton,
        type: "submit",
        onClick: onClickParentSignIn,
      }}
      image={{ urls: peopleShield, dataTestId: "peopleShield", role: "presentation" }}
      description={{ description, descriptionId: "parentalHandoverDescription" }}
      applyAriaLive
    >
      <div className={commonStyles.subText}>
        <LinkButton text={learnMore} onClick={onClickLearnMore} hasFocus />
      </div>
    </ViewContainerFabric>
  );
};
