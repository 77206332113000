import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteAnimation from "@msidentity/SISU/components/route-animation/fabric/route-animation-fabric";
import { AgreementViewRoute, FlowId, ViewId } from "@msidentity/SISU/constants/routing-constants";
import { getRouteFromViewId } from "@msidentity/SISU/utilities/routing-helper";
import { AgreementViewFabric } from "@msidentity/SISU/views";
import { AlternateMembernameSuggestionsViewFabric } from "../views/alternate-membername-suggestions/fabric/alternate-membername-suggestions-view-fabric";
import { SignupBlockedViewFabric } from "../views/blocked/fabric/signup-blocked-view-fabric";
import { ChallengeViewFabricSignup } from "../views/challenge/fabric/challenge-view-fabric-signup";
import { ChinaPiplViewFabric } from "../views/china-pipl/fabric/china-pipl-view-fabric";
import { CountryBirthdateViewFabric } from "../views/country-birthdate/fabric/country-birthdate-view-fabric";
import { EvictionErrorViewFabric } from "../views/eviction-error/fabric/eviction-error-view-fabric";
import { EvictionSpeedbumpViewFabric } from "../views/eviction-speedbump/fabric/eviction-speedbump-view-fabric";
import { SignUpInitialViewPickerFabric } from "../views/initial-view-picker/fabric/signup-initial-view-picker-fabric";
import { KoreaPrivacyConsentViewFabric } from "../views/korea-privacy-consent/fabric/korea-privacy-consent-view-fabric";
import { LearnAboutParentalConsentViewFabric } from "../views/learn-parental-consent/fabric/learn-about-parental-consent-view-fabric";
import { MembernameTakenLearnMoreViewFabric } from "../views/membername-taken-learn-more/fabric/membername-taken-learn-more-view-fabric";
import { SignupNameCollectionViewFabric } from "../views/name-collection/fabric/signup-name-collection-view-fabric";
import { ParentalHandoverViewFabric } from "../views/parental-handover/fabric/parental-handover-view-fabric";
import { PasswordCollectionViewFabric } from "../views/password-collection/fabric/password-collection-view-fabric";
import { RiskApiBlockedViewFabric } from "../views/risk-api-blocked/fabric/risk-api-blocked-view-fabric";
import { UsernameCollectionViewFabric } from "../views/username-collection/fabric/username-collection-view-fabric";
import { UsernameRecoverySpeedbumpViewFabric } from "../views/username-recovery-speedbump/fabric/username-recovery-speedbump-view-fabric";
import { VerificationViewFabric } from "../views/verification/fabric/verification-view-fabric";

/**
 * The Signup flow component
 * @returns An instance of the Signup flow component
 */
export const SignUpFlowFabric: React.FC = function SignUpFlowFabric() {
  return (
    <Routes>
      <Route path="*" element={<SignUpInitialViewPickerFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.UsernameCollection)}
        element={<UsernameCollectionViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.CountryBirthdate)}
        element={<CountryBirthdateViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignupNameCollection)}
        element={<SignupNameCollectionViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.Challenge)} element={<ChallengeViewFabricSignup />} />
      <Route
        path={getRouteFromViewId(ViewId.SignUpPasswordCollection)}
        element={<PasswordCollectionViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpUsernameRecoverySpeedbump)}
        element={<UsernameRecoverySpeedbumpViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpVerification)}
        element={<VerificationViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpBlocked)}
        element={<SignupBlockedViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignupKoreaPrivacyConsent)}
        element={<KoreaPrivacyConsentViewFabric />}
      />
      <Route path={getRouteFromViewId(ViewId.SignUpChinaPipl)} element={<ChinaPiplViewFabric />} />
      <Route
        path={getRouteFromViewId(ViewId.SignUpEvictionError)}
        element={<EvictionErrorViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.ParentalHandover)}
        element={<ParentalHandoverViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.LearnAboutParentalConsent)}
        element={<LearnAboutParentalConsentViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpMembernameTakenLearnMore)}
        element={<MembernameTakenLearnMoreViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpEvictionSpeedbump)}
        element={<EvictionSpeedbumpViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpAlternateMembernameSuggestions)}
        element={<AlternateMembernameSuggestionsViewFabric />}
      />
      <Route
        path={getRouteFromViewId(ViewId.SignUpRiskApiBlocked)}
        element={<RiskApiBlockedViewFabric />}
      />
    </Routes>
  );
};

/**
 * A wrapper component for the routes defined for Signup app
 * @returns An instance of the sign up routes fabric component
 */
export const SignupRoutesFabric = function SignupRoutesFabric() {
  return (
    <RouteAnimation>
      <Route path="*" element={<SignUpFlowFabric />} />
      <Route
        path={AgreementViewRoute}
        element={<AgreementViewFabric hostingFlow={FlowId.Signup} />}
      />
    </RouteAnimation>
  );
};
