import React from "react";
import ReactDOMServer from "react-dom/server";
import GlobalConfig from "@msidentity/SISU/global-config";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";

export enum Markets {
  EN_CA = "en-ca",
  FR_CA = "fr-ca",
  // add more markets as needed (all lowercase)
}

export type GetMarketingOptInCELATextProps = {
  strings: {
    emailOptInCELA: string;
    emailOptInCELACobranding: string;
    emailOptInCELAEnCa: string;
    emailOptInCELAEnCaHosted: string;
    emailOptInCELAFrCa: string;
    emailOptInCELAFrCaHosted: string;
  };
  friendlyAppName?: string;
  partnerTag?: string;
  getMarketingOptinTextWithBindings: (text: string, url: string) => JSX.Element;
};

/**
 * Retrieves the marketing opt-in CELA text.
 *
 * @param args function arguments
 * @param args.strings localized strings
 * @param args.friendlyAppName friendly app name
 * @param args.partnerTag parnter tag
 * @returns The marketing opt-in text.
 */
export const getMarketingOptInCELAText = (
  args: GetMarketingOptInCELATextProps,
): string | JSX.Element => {
  const { strings, friendlyAppName, partnerTag, getMarketingOptinTextWithBindings } = args;
  const { isHosted, market, microsoftSupportCommMgrUrl } = GlobalConfig.instance;

  if (market.toLowerCase() === Markets.EN_CA) {
    if (isHosted) {
      return replaceTokens(strings.emailOptInCELAEnCaHosted, microsoftSupportCommMgrUrl);
    }

    return getMarketingOptinTextWithBindings(
      strings.emailOptInCELAEnCa,
      microsoftSupportCommMgrUrl,
    );
  }

  if (market.toLowerCase() === Markets.FR_CA) {
    if (isHosted) {
      return replaceTokens(strings.emailOptInCELAFrCaHosted, microsoftSupportCommMgrUrl);
    }

    return getMarketingOptinTextWithBindings(
      strings.emailOptInCELAFrCa,
      microsoftSupportCommMgrUrl,
    );
  }

  if (friendlyAppName && partnerTag) {
    return replaceTokens(strings.emailOptInCELACobranding, friendlyAppName);
  }

  return strings.emailOptInCELA;
};

export const getMarketingOptInAriaLabel = (label: string | JSX.Element): string => {
  if (!React.isValidElement(label)) {
    return label as string;
  }

  const marketingOptInLabelMarkup = ReactDOMServer.renderToStaticMarkup(label);
  const parser = new DOMParser();
  const parsed = parser.parseFromString(marketingOptInLabelMarkup, "text/html");
  return parsed.body.textContent || "";
};
