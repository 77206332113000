import { ViewId } from "@msidentity/SISU/constants";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { MemberNameType } from "@msidentity/SISU/model/user";
import SignUpConfig from "../signup-config";
import { useSignUpContext } from "../signup-context";
import { useCreateAccount } from "./use-create-account";

/**
 * Hook for speedbump views like UsernameRecovery and EvictionSpeedbump to handle the next step for creating an account.
 * @param currentViewId The current view id
 * @returns a function that will navigate to the next view in the signup flow
 */
export const useSpeedbumpCreate = (currentViewId: ViewId) => {
  const { collectBirthDateCountry, collectFirstNameLastName, emailNoPaEnabled, phoneNoPaEnabled } =
    SignUpConfig.instance;
  const { viewState } = useSignUpContext();
  const navigate = useNavigateDirection();
  const createAccountRequest = useCreateAccount();

  // Collect password if and only if
  // 1. nopa is not enabled for the current membername type OR
  // 2. nopa is disallowed for evicted membernames and we are on the Eviction Speedbump view (implying the user entered an evicted phone number)
  const collectPassword =
    (!(emailNoPaEnabled && viewState.memberNameType === MemberNameType.EASI) &&
      !(phoneNoPaEnabled && viewState.memberNameType === MemberNameType.Phone)) ||
    (currentViewId === ViewId.SignUpEvictionSpeedbump && !viewState.noPaAllowedOnEviction);

  return async () => {
    if (collectPassword) {
      navigate(currentViewId, ViewId.SignUpPasswordCollection);
    } else if (collectFirstNameLastName) {
      navigate(currentViewId, ViewId.SignupNameCollection);
    } else if (collectBirthDateCountry) {
      navigate(currentViewId, ViewId.CountryBirthdate);
    } else {
      await createAccountRequest(
        {
          ...viewState,
        },
        currentViewId,
      );
    }
  };
};
