import React from "react";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateView, useDocumentTitle } from "@msidentity/SISU/hooks";
import { useCommonStylesFabric } from "@msidentity/SISU/styles/fabric";
import { useAlternateMembernameSuggestions } from "../hooks/use-alternate-membername-suggestions";
import { altMembernameSuggestionsStringsFabric } from "./alternate-membername-suggestions-strings-fabric";

/**
 * This view displays alternate member name suggestions for the user to choose from.
 * This will always be a "Live" member name.
 * If a user chooses one of these suggestions, they will be taken back to UsernameCollection with the selection.
 * If they click "Back", they will be taken back to UsernameCollection with the same error that got them here.
 * @returns A rendered instance of this component
 */
export const AlternateMembernameSuggestionsViewFabric: React.FC =
  function AlternateMembernameSuggestionsViewFabric() {
    const { secondaryButton, title, suggestions } = useAlternateMembernameSuggestions(
      altMembernameSuggestionsStringsFabric,
    );
    useActivateView(ViewId.SignUpAlternateMembernameSuggestions, FlowId.Signup, {
      showIdentityBanner: false,
    });
    useDocumentTitle(title.title);

    const { buttonMargin } = useCommonStylesFabric();

    return (
      <ViewContainerFabric title={title} secondaryButton={secondaryButton}>
        <div className={buttonMargin}>{suggestions}</div>
      </ViewContainerFabric>
    );
  };
