import { useEffect } from "react";
import { useSignUpContext } from "../signup-context";
import { SignUpActionType } from "../signup-reducer";

export interface UseSendOttErrorParams {
  setErrorMessage: (error: string | JSX.Element) => void;
  setShowError?: (showError: boolean) => void;
  setFocus?: (focus: boolean) => void;
}

/**
 * This hook is used to determine if a SendOtt error was provided from a request made on a different view and hasn't been displayed to the user yet.
 * It sets the error message and focus on an input and updates SignUpContext to indicate that the error has been shown so it does not get displayed again on a different view.
 * @param params
 * - setErrorMessage: A function to set the error message to be displayed to the user
 * - setShowError: A function to set whether the error message should be displayed to the user
 * - setFocus: A function to set whether the focus should be set to an input
 * @returns An indicator that the error message should be shown to the user in the consuming view
 */
export const useSendOttError = (params: UseSendOttErrorParams) => {
  const {
    viewState: { sendOttError, sendOttErrorShown },
    dispatchStateChange,
  } = useSignUpContext();
  const { setErrorMessage, setShowError = () => {}, setFocus = () => {} } = params;

  const shouldShowError = sendOttError !== "" && !sendOttErrorShown;

  useEffect(() => {
    if (shouldShowError) {
      dispatchStateChange({
        actionType: SignUpActionType.SetSendOttErrorShown,
        payload: true,
      });
      setErrorMessage(sendOttError);
      setShowError(true);
      setFocus(true);
    }
  }, [
    sendOttError,
    sendOttErrorShown,
    setErrorMessage,
    dispatchStateChange,
    shouldShowError,
    setShowError,
    setFocus,
  ]);

  return shouldShowError;
};
