import React from "react";
import { type MemberNameType as UsernameType } from "../../../model/user";
import { LinkButton } from "../../link-button";
import { type OnSwitchMemberNameType } from "../username-collection-types";

/**
 *
 * @param props props
 * @param props.setCurrentInputType A callback to update the current input type when the switch link is clicked
 * @param props.SwitchLinkContainer A wrapper component to go around a single switch link to apply flavor-specific styles
 * @param props.inputType The enum input type to switch to when the switch link is clicked
 * @param props.switchLinkText The text to display in the switch link
 * @param props.linkId The id of the switch link
 * @returns The SwitchLinkFabric component, a flavored link that switches the input type when clicked
 */
export function SwitchLinkFabric(props: {
  inputType: UsernameType;
  setCurrentInputType: OnSwitchMemberNameType;
  switchLinkText?: string;
  SwitchLinkContainer?: React.ElementType;
  linkId?: string;
}): JSX.Element | null {
  const { inputType, setCurrentInputType, switchLinkText, SwitchLinkContainer, linkId } = props;
  if (!switchLinkText) {
    return null;
  }

  return SwitchLinkContainer ? (
    <SwitchLinkContainer>
      <LinkButton
        text={switchLinkText}
        onClick={() => setCurrentInputType(inputType)}
        linkId={linkId}
      />
    </SwitchLinkContainer>
  ) : (
    <LinkButton text={switchLinkText} onClick={() => setCurrentInputType(inputType)} />
  );
}
