import React from "react";
import { type InputState } from "../../inputs/hooks/use-input";
import { InputFabric as Input } from "../../inputs/input/fabric/input-fabric";
import { NameCollection } from "../name-collection";

export type NameCollectionFabricProps = {
  strings: {
    firstNamePlaceholder: string;
    lastNamePlaceholder: string;
  };
  config: {
    isFirstLastOrder: boolean;
    firstNameInputState: InputState;
    lastNameInputState: InputState;
  };
};

/**
 * NameCollectionFabric component
 * @param props
 *  - props.strings.firstNamePlaceholder: placeholder for the first name input.
 *  - props.strings.lastNamePlaceholder: placeholder for the last name input.
 *  - props.config.isFirstLastOrder: boolean to determine if the first name input should be displayed before the last name input.
 *  - props.config.firstNameInputState: state for the first name input.
 *  - props.config.lastNameInputState: state for the last name input.
 * @returns A rendered instance of this component
 */
export const NameCollectionFabric: React.FC<NameCollectionFabricProps> =
  function NameCollectionFabric(props) {
    const {
      strings: { firstNamePlaceholder, lastNamePlaceholder },
      config: { isFirstLastOrder, firstNameInputState, lastNameInputState },
    } = props;

    const firstNameInput = (
      <Input
        type="text"
        id="firstNameInput"
        name="firstNameInput"
        key="firstNameInput"
        placeholder={firstNamePlaceholder}
        {...firstNameInputState}
        {...firstNameInputState.error}
      />
    );

    const lastNameInput = (
      <Input
        type="text"
        id="lastNameInput"
        name="lastNameInput"
        key="lastNameInput"
        placeholder={lastNamePlaceholder}
        {...lastNameInputState}
        {...lastNameInputState.error}
      />
    );

    const nameCollectionProps = {
      firstNameInput,
      lastNameInput,
      isFirstLastOrder,
    };

    return <NameCollection {...nameCollectionProps} />;
  };
