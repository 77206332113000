import { type SignupNameCollectionViewStringsFabric } from "./signup-name-collection-view-fabric-types";

export const signupNameCollectionViewStringsFabric: SignupNameCollectionViewStringsFabric = {
  title: getLocalString("WhatsYourName"),
  description: getLocalString("MoreInfoForAccount"),
  firstName: getLocalString("General_FirstName"),
  lastName: getLocalString("General_LastName"),
  informationRequiredError: getLocalString("Error_1018"),
  invalidCharactersError: getLocalString("NameContainsInvalidCharacters"),
  titleParentFlow: getLocalString("Signup_ChildName_PageTitle"),
  descriptionParentFlow: getLocalString("Signup_ChildName_Description"),
};
