import { updateCodeStringForTestHook } from "@msidentity/SISU/components/code-entry/update-code-string-for-test-hook";
import {
  type DataApiError,
  type ResponseBody,
  type ResponseError,
} from "@msidentity/sisu/constants";
import { postApiRequest } from "@msidentity/sisu/utilities/request/request-helper";
import {
  type CreatePassportsChallengeRequest,
  type CreatePassportsRepMapRequest,
} from "@msidentity/SISU/views/challenge/challenge-types";
import {
  getAPIUrlWithCurrentWindowQS,
  setCommonAccountPostRequestData,
} from "../api-request-helper";
import { type DefaultApiResponse } from "../general-api-types";

export type ICreateAccountRequest = {
  /** Alternative email for the account */
  AltEmail?: string;
  /** Birthdate in format DD:MM:YYYY */
  BirthDate?: string;
  /** Map of member name to available state, used to track CheckAvailable call information */
  CheckAvailStateMap?: string[];
  /** Password to sign up with */
  CipherValue?: string;
  /** Password (plain text, no encyrption) to sign up with */
  Password?: string;
  /** Partner tag to be sent to AQS */
  CobrandingPartnerTag?: string;
  /** Country for the account */
  Country?: string;
  /** Device flow token, used for Xbox OOBE sign up */
  DFT?: string;
  /** Device ticket */
  DT?: string;
  /** Whether the eviction warning speedbump was shown */
  EvictionWarningShown?: string[];
  /** First name for the account */
  FirstName?: string;
  /** Genuine win phone certificate */
  GWPC?: string;
  /** Whether user selected to opt out of marketing */
  IsOptOutEmail?: boolean;
  /** Default state of marketing preferences during initial load */
  IsOptOutEmailDefault?: boolean;
  /** Whether Marketing preferences checkbox is shown to user */
  IsOptOutEmailShown?: boolean;
  /** Whether the flow is in retail demo mode */
  IsRDM: boolean;
  /** Whether a suggested name was used */
  IsSuggestedNameUsed?: boolean;
  /** Flag to indicate the user has consented to China PIPL */
  IsUserConsentedToChinaPIPL?: boolean;
  /** Last name for the account */
  LastName?: string;
  /** Whether the signup is lightweight */
  LW?: boolean;
  /** Username to sign up with */
  MemberName: string;
  /* The number of times the user changes the member name input, and the member name is available
   * Note: Not all member name changes would trigger a new CheckavailableSigninNames API call,
   * because for prefills we would have already made a batch call on server side and would have burnt the results */
  MemberNameAvailableCount?: number;
  /* The number of times user changes the member name input.
   * Ideally this should be 1, where the user doesn't change the member name. */
  MemberNameChangeCount?: number;
  /** The number of times user changes the member name input, and the member name is NOT available */
  MemberNameUnavailableCount?: number;
  /** The phone country code */
  PhoneCountry?: string;
  /** Phone number for the account */
  PhoneNumber?: string;
  /** Prefill EASI domain */
  PrefillDomain?: string;
  /** Whether any prefill was passed */
  PrefillMemberNamePassed?: boolean;
  /** Whether the prefilled member name is used */
  PrefillMemberNameUsed?: boolean;
  /** Prefill types passed, ex. Phone, EASI */
  PrefillTypesPassed?: string;
  /** Time stamp for this request, ex. 2023-10-02T21:54:48.107Z */
  RequestTimeStamp?: string;
  /** Return url */
  ReturnUrl?: string;
  /** Signup return url */
  SignupReturnUrl?: string;
  /** Site Id */
  SiteId: string;
  /** Subject key identifier / public key */
  SKI?: string;
  /** Suggested account type (EASI, Outlook, Phone) */
  SuggestedAccountType?: string;
  /** Suggestion type (Locked, Prefer, Default) */
  SuggestionType?: string;
  /** Error code test hook */
  TestErrorCode?: string;
  /** Flow token sent by Login for creating a Mojang account during upgrade flow */
  UpgradeFlowToken?: string;
  /** The verification code (ex. SMS, email) entered */
  VerificationCode?: string;
  /* Encrypted blob that may be returned as part of CreatePassports failure. It can be
  used instead of OTT to make CreatePassports call again. This is to enhance user experience and not have
  them to round-trip another code in case of an error (for example, solving captcha challenge). */
  VerificationCodeSlt?: string;
  /** WGA ticket */
  WGA?: string;
  /** Login return url */
  WReply?: string;
} & CreatePassportsChallengeRequest &
  CreatePassportsRepMapRequest;

export interface ICreateAccountApiError extends DataApiError {
  /** Error type (ex. hipEnforcement, password) */
  field: string;
}

export interface ICreateAccountResponse extends ResponseBody, DefaultApiResponse {
  /** Encrypted child info value */
  childInfo?: string;
  /** Encrypted puid */
  encPuid?: string;
  /** Error response object */
  error?: ICreateAccountApiError;
  /** Flow token used for Family Child Consent interrupt */
  kcft?: string;
  /** Redirect url */
  redirectUrl?: string;
  /** The username for the account that was created */
  signinName?: string;
  /** Short lived token */
  slt?: string;
}

export type CreateAccountApiError = ResponseError & {
  responseBody?: ICreateAccountApiError;
};

/**
 * This a wrapper method that makes a POST request to the CreateAccount API.
 * @param url The CreateAccount API url.
 * @param params The CreateAccount request parameters.
 * @returns A promise that either resolves to the parsed response body or rejects with an error.
 */
export const createAccount = (
  url: string,
  params: ICreateAccountRequest,
): Promise<ICreateAccountResponse> => {
  let verificationCode;
  if (params.VerificationCode) {
    verificationCode = updateCodeStringForTestHook(params.VerificationCode);
  }

  const requestBody = {
    ...params,
    VerificationCode: verificationCode,
    ...setCommonAccountPostRequestData(),
  };

  const options = { body: JSON.stringify(requestBody) };

  return postApiRequest<ICreateAccountResponse>(getAPIUrlWithCurrentWindowQS(url), options);
};
