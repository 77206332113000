import React from "react";
import { mergeClasses } from "@griffel/react";
import useFabricStyles from "../../../../styles/fabric/fabric.styles";
import { type NumberInputProps, NumberInput } from "../number-input";

/**
 * NumberInputFabric component
 * @param props The properties for this component
 * @returns A number input component for Fabric
 */
export const NumberInputFabric: React.FC<NumberInputProps> = function NumberInputFabric(props) {
  const { customCss } = props;
  const fabricStyles = useFabricStyles();

  return <NumberInput {...props} customCss={mergeClasses(fabricStyles.formControl, customCss)} />;
};
