import { type ViewId } from "@msidentity/SISU/constants";
import { useEffectOnce } from "@msidentity/SISU/hooks";
import { useSignUpContext } from "../../signup-context";
import { SignUpActionType } from "../../signup-reducer";

export type SignUpInitialViewPickerProps = {
  viewIdToViewMap: { [key: string]: JSX.Element };
  usernameCollectionView: JSX.Element;
  initialViewId: ViewId;
  nextViewId: ViewId;
};

/**
 * The initial view picker component for the sign up flow.
 * Certain views may be delayed to first show another view, such as the Korea privacy consent view.
 * @param props Input properties for the SignUpInitialViewPicker component
 * @returns The initial View to render for the sign up flow
 */
export const SignUpInitialViewPicker: React.FC<SignUpInitialViewPickerProps> =
  function SignUpInitialViewPicker(props) {
    const { initialViewId, nextViewId, viewIdToViewMap, usernameCollectionView } = props;

    const { dispatchStateChange } = useSignUpContext();

    useEffectOnce(() => {
      dispatchStateChange({ actionType: SignUpActionType.SetNextViewId, payload: nextViewId });
    });

    return viewIdToViewMap[initialViewId] || usernameCollectionView;
  };
