import { type MarketingOptInStringsFabric } from "./marketing-optin-types-fabric";

export const marketingOptInStringsFabric: MarketingOptInStringsFabric = {
  emailOptInCELA: getLocalString("EmailOptIn_CELA_Text"),
  emailOptInCELACobranding: getLocalString("EmailOptIn_CELA_Text_Cobranding"),
  emailOptInCELAEnCa: getLocalString("EmailOptIn_CELA_Text_EN_CA"),
  emailOptInCELAEnCaHosted: getLocalString("EmailOptIn_CELA_Text_EN_CA_Hosted"),
  emailOptInCELAFrCa: getLocalString("EmailOptIn_CELA_Text_FR_CA"),
  emailOptInCELAFrCaHosted: getLocalString("EmailOptIn_CELA_Text_FR_CA_Hosted"),
};
