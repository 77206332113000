import { type SubmitTask } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { type UsernameCollectionSubmitProps } from "@msidentity/SISU/components/username-collection/username-collection-types";
import { UserFlowType } from "@msidentity/SISU/constants";
import { ViewId } from "@msidentity/SISU/constants/routing-constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { isViewId } from "@msidentity/SISU/utilities/routing-helper";
import { useCreateAccount } from "../../../hooks/use-create-account";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import {
  type CheckAvailableErrorMapResult,
  type CheckAvailableSigninNamesResult,
  getCheckAvailableSigninNamesResult,
} from "../check-available-signin-names-helper";
import { type GetNextViewForUsernameCollectionParams } from "../username-collection-view-types";

export type UseCheckAvailableSigninNamesParams = {
  getErrorMessage: (errorMessage: string) => JSX.Element;
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult;
  chooseNextView: (params: GetNextViewForUsernameCollectionParams) => ViewId;
};

/**
 * Hook to call CheckAvailableSigninNames API during Signup to check if the username is available.
 * Handles the result of the API call and navigates to the next view or shows an error based on the result.
 * @param props The params for this hook
 * @param props.getErrorMessage Function to apply bindings and return the error message JSX element
 * @param props.getCheckAvailableError Function that takes the error code and returns the error message string or error view id
 * @param props.chooseNextView Function to determine the next view based on the result of the CheckAvailableSigninNames API
 * @returns A function to submit the username to CheckAvailableSigninNames API and handle the result
 */
export const useCheckAvailableSignInNames = (
  props: UseCheckAvailableSigninNamesParams,
): SubmitTask<UsernameCollectionSubmitProps> => {
  const { getCheckAvailableError, chooseNextView, getErrorMessage } = props;
  const {
    collectBirthDateCountry,
    collectFirstNameLastName,
    checkAvailableSigninNamesUrl,
    emailNoPaEnabled,
    phoneNoPaEnabled,
    prefill,
    showUsernameRecoverySpeedbump,
  } = SignUpConfig.instance;

  const { viewState, dispatchStateChange: dispatchSignUpStateChange } = useSignUpContext();
  const {
    isPossibleEvicted: isPossibleEviction,
    noPaAllowedOnEviction,
    previouslyCheckedMemberName,
    reporting: { memberNameChangeCount, memberNameAvailableCount, memberNameUnavailableCount },
  } = viewState;
  const {
    dispatchStateChange,
    globalState: { userFlowType },
  } = useGlobalContext();
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;

  const navigate = useNavigateDirection();
  const createAccountRequest = useCreateAccount();

  return async (submitParams: UsernameCollectionSubmitProps) => {
    const { username, usernameType, errorHandler } = submitParams;

    // TODO: Refactor this branch to a separate submit handler for Fabric since Fluent will have different logic
    if (username === undefined || usernameType === undefined) {
      // If the needed params are not defined, don't block the user since CheckAvail is
      // a best effort call. Instead navigate to the next view.
      if (
        !(emailNoPaEnabled && usernameType === MemberNameType.EASI) &&
        !(phoneNoPaEnabled && usernameType === MemberNameType.Phone) &&
        !(isPossibleEviction && noPaAllowedOnEviction)
      ) {
        navigate(ViewId.UsernameCollection, ViewId.SignUpPasswordCollection);
      } else if (isParentFlow) {
        navigate(ViewId.UsernameCollection, ViewId.SignUpPasswordCollection);
      } else if (collectFirstNameLastName) {
        navigate(ViewId.UsernameCollection, ViewId.SignupNameCollection);
      } else if (collectBirthDateCountry && !isSimplifiedChildAccountCreation) {
        navigate(ViewId.UsernameCollection, ViewId.CountryBirthdate);
      } else if (isSimplifiedChildAccountCreation && userFlowType !== UserFlowType.Parent) {
        await createAccountRequest(
          {
            ...viewState,
          },
          ViewId.UsernameCollection,
        );
      }

      return;
    }

    if (previouslyCheckedMemberName !== username) {
      dispatchSignUpStateChange({
        actionType: SignUpActionType.SetPreviouslyCheckedMemberName,
        payload: username,
      });
    }

    const params = {
      includeSuggestions: true,
      signInName: username,
    };

    const result: Partial<CheckAvailableSigninNamesResult> =
      await getCheckAvailableSigninNamesResult(
        checkAvailableSigninNamesUrl,
        params,
        getCheckAvailableError,
        {
          memberNamePrefill: prefill.oMemberName,
          usernameType,
        },
      );

    // Handle the CheckAvailableSignInNames result
    dispatchSignUpStateChange({
      actionType: SignUpActionType.SetMemberNameSuggestions,
      payload: result.memberNameSuggestions || [],
    });

    if (result.isValid) {
      dispatchSignUpStateChange({
        actionType: SignUpActionType.UpdateMemberNameReporting,
        payload: {
          memberNameChangeCount:
            previouslyCheckedMemberName === username
              ? memberNameChangeCount
              : memberNameChangeCount + 1,
          memberNameAvailableCount: result.isAvailable
            ? memberNameAvailableCount + 1
            : memberNameAvailableCount,
          memberNameUnavailableCount: result.isAvailable
            ? memberNameUnavailableCount
            : memberNameUnavailableCount + 1,
        },
      });

      dispatchSignUpStateChange({
        actionType: SignUpActionType.SetMemberName,
        payload: username,
      });

      if (isParentFlow) {
        // We don’t want to edit the display username because that’s the parent’s. We only wish to update the username, not display (parent) username.
        dispatchStateChange({
          type: GlobalActionType.SetUser,
          payload: { username },
        });
      } else {
        dispatchStateChange({
          type: GlobalActionType.SetUser,
          payload: { username, displayUsername: username },
        });
      }

      dispatchSignUpStateChange({
        actionType: SignUpActionType.SetMemberNameType,
        payload: usernameType,
      });

      if (result.isAvailable) {
        dispatchSignUpStateChange({
          actionType: SignUpActionType.UpdateCheckAvailableStateMap,
          payload: `${username}:${!!result.isPossibleEvicted}`,
        });
      }

      dispatchSignUpStateChange({
        actionType: SignUpActionType.SetIsPossibleEvicted,
        payload: result.isPossibleEvicted || false,
      });

      if (result.isPossibleEvicted) {
        dispatchSignUpStateChange({
          actionType: SignUpActionType.SetNoPaAllowedOnEviction,
          payload: result.isNoPaAllowed || false,
        });
      }

      const nextView = chooseNextView({
        showUsernameRecoverySpeedbump,
        isProof: result.isProof,
        isPossibleEvicted: result.isPossibleEvicted,
        emailNoPaEnabled,
        phoneNoPaEnabled,
        usernameType,
      });

      navigate(ViewId.UsernameCollection, nextView);
    } else {
      dispatchSignUpStateChange({
        actionType: SignUpActionType.UpdateMemberNameReporting,
        payload: {
          memberNameChangeCount:
            previouslyCheckedMemberName === username
              ? memberNameChangeCount
              : memberNameChangeCount + 1,
          memberNameUnavailableCount: memberNameUnavailableCount + 1,
        },
      });

      // If the error result is a view id, navigate to the view; otherwise, display the error message.
      if (result.errorResult) {
        if (isViewId(result.errorResult)) {
          dispatchSignUpStateChange({
            actionType: SignUpActionType.SetMemberName,
            payload: username,
          });
          dispatchStateChange({
            type: GlobalActionType.SetUser,
            payload: { username, displayUsername: username },
          });

          navigate(ViewId.UsernameCollection, result.errorResult);
        } else {
          const error = getErrorMessage(result.errorResult);
          errorHandler(error);
        }
      }
    }
  };
};
