import { getValidationMethod } from "@msidentity/SISU/utilities/input-helper";

/**
 * Provides a name collection validation handler for the name collection view
 * @param invalidCharactersError The error message to show when invalid characters are found
 * @param informationRequiredError The error message to show when the name is empty
 * @param invalidCharactersRegex The regex pattern for invalid characters
 * @returns A name collection validation handler for the name collection view
 */
export const nameCollectionValidationHandler = (
  invalidCharactersError: string,
  informationRequiredError: string,
  invalidCharactersRegex: string,
) => {
  const isValid = (value: string) => !value.match(invalidCharactersRegex);
  const validationMethod = getValidationMethod(
    isValid,
    invalidCharactersError,
    informationRequiredError,
  );

  return validationMethod;
};
