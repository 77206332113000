import { type SubmitTask } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { type INameCollectionSubmitProps } from "@msidentity/SISU/components/name-collection/hooks/use-name-collection-form";
import { UserFlowType, ViewId } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { useCreateAccount } from "../../../../hooks/use-create-account";
import SignUpConfig from "../../../../signup-config";
import { useSignUpContext } from "../../../../signup-context";
import { SignUpActionType } from "../../../../signup-reducer";

export const useNameCollectionSubmitFabric = (): SubmitTask<INameCollectionSubmitProps> => {
  const { collectBirthDateCountry } = SignUpConfig.instance;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const { dispatchStateChange: dispatchSignupStateChange, viewState } = useSignUpContext();
  const navigate = useNavigateDirection();
  const createAccountRequest = useCreateAccount();

  return async (submitParams: INameCollectionSubmitProps) => {
    const firstName = submitParams.firstName?.trim() ?? "";
    const lastName = submitParams.lastName?.trim() ?? "";

    dispatchSignupStateChange({
      actionType: SignUpActionType.SetFirstNameLastName,
      payload: {
        firstName,
        lastName,
      },
    });

    if (
      (collectBirthDateCountry && !isSimplifiedChildAccountCreation) ||
      (isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent)
    ) {
      navigate(ViewId.SignupNameCollection, ViewId.CountryBirthdate);
    } else {
      await createAccountRequest(
        {
          ...viewState,
          firstName,
          lastName,
        },
        ViewId.SignupNameCollection,
      );
    }
  };
};
