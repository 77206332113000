import { usePostRedirectContext } from "@msidentity/sisu/post-redirect-context";
import { PostRedirectActionType } from "@msidentity/sisu/post-redirect-reducer";
import { type RedirectPostParams } from "@msidentity/SISU/utilities/api-helpers/get-credential-type/get-credential-type-helper";

export type SignInPostData = {
  slt?: string;
  ignoresso?: number;
  /** Is simplified child account creation flag */
  scac?: string;
  /** Is gaming flow flag */
  gf?: string;
  /** Is simplified child account creation suppress interrupt flag */
  scacsi?: string;
  /** User flow type */
  uft?: string;
};

/**
 * Hook for making a signin post redirect
 * @returns a function that submits the post redirect
 */
export const useSignInPostRedirect = () => {
  const { dispatchStateChange } = usePostRedirectContext();
  return (redirectUrl: string, postData: SignInPostData) => {
    dispatchStateChange({
      type: PostRedirectActionType.SubmitPostRedirect,
      payload: { url: redirectUrl, postParams: postData as RedirectPostParams, submitForm: true },
    });
  };
};
