import type { ResponseBody, ResponseError } from "@msidentity/sisu/constants";
import {
  type AssociationType,
  type ChannelType,
  type SmsActionType,
} from "@msidentity/SISU/model/proof";
import { postApiRequest } from "@msidentity/sisu/utilities/request/request-helper";
import {
  type SendOttChallengeRequest,
  type SendOttRepMapRequest,
  type SendOttRepMapSuccessResponse,
  HipErrorsSendOtt,
} from "@msidentity/SISU/views/challenge/challenge-types";
import {
  getAPIUrlWithCurrentWindowQS,
  setCommonAccountPostRequestData,
} from "../api-request-helper";
import type { DefaultApiResponse } from "../general-api-types";

export enum Context {
  ALS = "ALS",
  MP = "MP",
}

export const SendOttErrorCode = {
  DailyLimitIDsReached: "450",
  MaximumOTTDailyError: "1204",
  SmsNumberFormatInvalid: "1208",
  ExpiredCredentials: "6001",
  InvalidEmailFormat: "1062",
  InvalidPhoneFormat: "1063",
  ...HipErrorsSendOtt,
};

export type SendOttRequest = {
  /** The scenario we need an OTT for */
  action: SmsActionType;
  /** Email or phone number to use for the OTT */
  proofId: string;
  /** The method we will use to send the OTT to the user  */
  channel: ChannelType;
  /** The unencrypted proof country iso. This is only valid for phone proofs (channel = "SMS") */
  proofCountryIso?: string;
  /** Bool Value which is true only for phone proofs (channel = "SMS") */
  isPhoneProof?: boolean;
  /** True if the OTT is being sent for auto-verification */
  autoVerification?: boolean;
  /** Used to distinguish between flows */
  associationType?: AssociationType;
  /** Additional context information for the OTT */
  cxt?: Context;
  /** whether to return the sms destination in the response */
  needsSmsDestination?: boolean;
  /** NetId of the current user */
  netId?: string;
  /** Error code for testing */
  TestErrorCode?: string;
} & SendOttChallengeRequest &
  SendOttRepMapRequest;

export type SendOttResponse = {
  /** The session lookup key used to poll for session approval */
  sessionLookupKey?: string;
  /** Formatted string of international phone number used for the OTT */
  formattedInternationalPhoneNumber?: string;
  /** The encrypted name that the OTT was sent to */
  encryptedName?: string;
  /** The destination the OTT was sent to */
  smsDestination?: string;
} & ResponseBody &
  DefaultApiResponse &
  SendOttRepMapSuccessResponse;

export type SendOttResponseError = ResponseError & {
  responseBody?: SendOttResponse;
};

/**
 * This a wrapper method that makes a POST request to the SendOtt API.
 * @param url the URL to the SendOtt API. In most cases, this will be the SendOttUrl from the GlobalConfig.
 * @param params The SendOtt request parameters.
 * @returns A promise of the API response.
 */
export const sendOtt = (url: string, params: SendOttRequest): Promise<SendOttResponse> => {
  const requestBody = {
    ...params,
    ...setCommonAccountPostRequestData(),
  };

  const options = { body: JSON.stringify(requestBody) };
  return postApiRequest<SendOttResponse>(getAPIUrlWithCurrentWindowQS(url), options);
};
