import { type KeyboardEvent, useContext, useState } from "react";
import { type AccessibleImageProps } from "@msidentity/SISU/components/accessible-image";
import { useFormSubmission } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { ViewId } from "@msidentity/SISU/constants";
import { type IFormSubmissionProps } from "@msidentity/SISU/hooks";
import { shouldSuppressCapsLockWarning } from "@msidentity/SISU/utilities/browser-helper";
import { addUser } from "@msidentity/SISU/utilities/image-helpers/accessible-images";
import { SignUpContext } from "../../../signup-context";
import {
  type PasswordCollectionErrorStrings,
  type PasswordCollectionViewBaseStrings,
} from "../password-collection-view-interface";
import {
  passwordValidationErrorMap,
  passwordValidationHandler,
} from "../password-validation-handler";

/**
 * Creates the state required for checking if the show password checkbox is selected. Used to toggle the password visibility in the input field.
 * @returns The show password state and handler.
 */
export const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return {
    showPassword,
    toggleShowPassword,
  };
};

/**
 * Creates the state required for checking if caps lock is on. Used to display a warning to the user when caps lock is on.
 * @returns The caps lock state and handler.
 */
export const useCheckCapsLock = () => {
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const checkCapsLock = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.getModifierState("CapsLock")) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  return {
    checkCapsLock,
    isCapsLockOn,
    suppressCapsLockWarning: shouldSuppressCapsLockWarning(),
  };
};

export type PasswordCollectionFormState = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  pageTitle: string;
  pageDescription: string;
  pageImage: AccessibleImageProps | undefined;
};

/**
 * A hook used to generate a validation method for the PasswordCollection view.
 * @param strings - The PasswordCollectionErrorStrings for the view
 * @param fallbackErrorMessage - The generic error message to show when a mapped error message is not found
 * @returns a validation method to use for PasswordCollection
 */
export const usePasswordCollectionValidation = (
  strings: PasswordCollectionErrorStrings,
  fallbackErrorMessage: string,
) => {
  const {
    viewState: { memberNameType, memberName },
  } = useContext(SignUpContext);

  const validationErrorMessages = passwordValidationErrorMap(strings, memberNameType);
  const validationMethod = passwordValidationHandler(
    memberName,
    validationErrorMessages,
    fallbackErrorMessage,
  );

  return validationMethod;
};

/**
 * This hook is used for retrieving flavor-agnostic view properties for the Password Collection view.
 * @param strings Strings that are specific to the password collection view
 * @param submitTask The handler that is called when the form is submitted
 * @param inputState The input state for the Password input
 * @param applyParentStrings Whether to use the parent strings
 * @returns Base view properties and onSubmit operation
 */
export const usePasswordCollectionForm = (
  strings: PasswordCollectionViewBaseStrings,
  submitTask: (value: IFormSubmissionProps) => Promise<void>,
  inputState: InputState,
  applyParentStrings: boolean,
): PasswordCollectionFormState => {
  let pageTitle: string;
  let pageDescription: string;
  let pageImage: AccessibleImageProps | undefined;

  if (applyParentStrings) {
    pageTitle = strings.titleParentFlow;
    pageDescription = strings.descriptionParentFlow;
    pageImage = { urls: addUser, dataTestId: "addUser", role: "presentation" };
  } else {
    pageTitle = strings.title;
    pageDescription = strings.description;
  }

  const onSubmit = useFormSubmission({
    inputState,
    viewId: ViewId.SignUpPasswordCollection,
    submitTask,
  });

  return {
    onSubmit,
    pageTitle,
    pageDescription,
    pageImage,
  };
};
