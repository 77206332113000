import { type AccessibleImageProps } from "@msidentity/SISU/components/accessible-image";
import { useInput } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { InvalidNameCharactersRegex } from "@msidentity/SISU/components/inputs/input-regex-constants";
import { type NameCollectionFabricProps } from "@msidentity/SISU/components/name-collection/fabric/name-collection-fabric";
import { UserFlowType } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import SignUpConfig from "../../../../signup-config";
import { useSignUpContext } from "../../../../signup-context";
import { type CreateAccountErrorStrings } from "../../../../signup-types";
import { useSignupNameCollection } from "../../hooks/use-signup-name-collection";
import {
  useSignupFirstNameOnChange,
  useSignupLastNameOnChange,
} from "../../hooks/use-signup-name-collection-onchange";
import { nameCollectionValidationHandler } from "../../name-collection-validation-handler";
import { type SignupNameCollectionViewStringsFabric } from "../signup-name-collection-view-fabric-types";
import { useNameCollectionSubmitFabric } from "./use-name-collection-submit-fabric";

export type UseNameCollectionFabricParams = {
  viewStrings: SignupNameCollectionViewStringsFabric;
  createAccountErrorStrings: CreateAccountErrorStrings;
};

export type UseNameCollectionFabricResponse = {
  form: {
    nameCollectionProps: NameCollectionFabricProps;
    onSubmit: React.FormEventHandler;
  };
  pageTitle: string;
  pageDescription: string;
  pageImage: AccessibleImageProps | undefined;
};

/**
 * Aggregate-hook for the Signup Name Collection View Fabric
 * @param props Input properties for the Name Collection View Fabric
 * @param props.viewStrings The flavored strings to be used in the view
 * @param props.createAccountErrorStrings The flavored error strings for the CreateAccount API
 * @returns Properties for the Name Collection View Fabric
 */
export const useNameCollectionFabric = function useNameCollectionFabric(
  props: UseNameCollectionFabricParams,
): UseNameCollectionFabricResponse {
  const { viewStrings, createAccountErrorStrings } = props;
  const {
    isFirstLastOrder,
    family: { isFamilyScenario },
    prefill,
  } = SignUpConfig.instance;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const { viewState } = useSignUpContext();

  const validationMethod = nameCollectionValidationHandler(
    viewStrings.invalidCharactersError,
    viewStrings.informationRequiredError,
    InvalidNameCharactersRegex,
  );

  const firstNameInputState = useInput({
    hasInitialFocus: isFirstLastOrder,
    validationMethod,
    initialValue: viewState.firstName || prefill.sFirstName,
  });
  const lastNameInputState = useInput({
    hasInitialFocus: !isFirstLastOrder,
    validationMethod,
    initialValue: viewState.lastName || prefill.sLastName,
  });

  firstNameInputState.onChange = useSignupFirstNameOnChange(firstNameInputState);
  lastNameInputState.onChange = useSignupLastNameOnChange(lastNameInputState);

  const nameCollectionProps: NameCollectionFabricProps = {
    strings: {
      firstNamePlaceholder: viewStrings.firstName,
      lastNamePlaceholder: viewStrings.lastName,
    },
    config: {
      isFirstLastOrder,
      firstNameInputState,
      lastNameInputState,
    },
  };

  const submitTask = useNameCollectionSubmitFabric();

  const { onSubmit, pageDescription, pageImage, pageTitle } = useSignupNameCollection({
    strings: {
      signupNameCollectionStrings: viewStrings,
      createAccountErrorStrings,
    },
    config: {
      showBackButtonOnActiveView: true,
      submitTask,
      applyParentStrings: isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent,
    },
    state: {
      firstNameInputState,
      lastNameInputState,
    },
  });

  return {
    form: {
      nameCollectionProps,
      onSubmit,
    },
    pageTitle,
    pageDescription: isFamilyScenario ? "" : pageDescription, // Hide description for Family Scenario
    pageImage,
  };
};
