import { ViewId } from "@msidentity/SISU/constants";
import { useSpeedbumpCreate } from "../../../../hooks/use-speedbump-create";
import { useUsernameRecoverySpeedbump } from "../../hooks/use-username-recovery-speedbump";
import { type UsernameRecoverySpeedbumpViewStringsFabric } from "../username-recovery-speedbump-view-fabric-types";

export type UseUsernameRecoverySpeedbumpFabricParams = {
  strings: UsernameRecoverySpeedbumpViewStringsFabric;
};

export type UseUsernameRecoverySpeedbumpFabricResponse = {
  memberName: string;
  signInOnClick: () => void;
  recoveryOnClick: () => void;
  onContinueToCreate: () => void;
};

/**
 * View hook for the Fabric username recovery speedbump view
 * @param params Parameters for this hook
 * @param params.strings Localized strings for the view
 * @returns {UseUsernameRecoverySpeedbumpFabricResponse} Returns username recovery speedbump view properties
 */
export const useUsernameRecoverySpeedbumpFabric = ({
  strings,
}: UseUsernameRecoverySpeedbumpFabricParams): UseUsernameRecoverySpeedbumpFabricResponse => {
  const { memberName, recoveryOnClick, signInOnClick } = useUsernameRecoverySpeedbump({
    strings,
  });

  const onContinueToCreate = useSpeedbumpCreate(ViewId.SignUpUsernameRecoverySpeedbump);

  return {
    memberName,
    signInOnClick,
    recoveryOnClick,
    onContinueToCreate,
  };
};
