import { type CountryBirthdateViewStringsFabric } from "./country-birthdate-view-types-fabric";

export const countryBirthdateViewStringsFabric: CountryBirthdateViewStringsFabric = {
  titleDefault: getLocalString("Signup_CountryBirthdate_Title"),
  titleParent: getLocalString("Signup_BirthdateAccrual_Child_PageTitle"),
  descriptionDefault: getLocalString("Signup_CountryBirthdate_Description"),
  descriptionAdult: getLocalString("Signup_BirthdateAccrual_AgeUnknownDescription"),
  descriptionParent: getLocalString("Signup_BirthdateAccrual_Child_Description"),
  legalContentDefault: getLocalString("Signup_CountryBirthdate_LegalContent"),
  legalContentParent: getLocalString("Signup_BirthdateAccrual_Child_Subtext"),
  birthdateLabel: getLocalString("Birthdate_Label"),
  countryLabel: getLocalString("General_CountryRegion"),
  invalidBirthdateError: getLocalString("Birthdate_Is_Invalid"),
  informationRequiredError: getLocalString("Error_1018"),
  parentMustBeAdultError: getLocalString("Signup_BirthdateAccrual_ErrorMustBeAdult"),
};
