import { type UntrustedExternalInputText } from "../../utilities/untrusted-external-input-text";

/**
 * Interface for current active user sessions
 */
export interface ISession {
  /** The alias that was used to sign in */
  displayName?: UntrustedExternalInputText;
  /** The first name and the last name separated by a space */
  fullName?: UntrustedExternalInputText;
  /**
   * One of the design goals for AXIS is to not have any idp-specific logic in views/flows.
   * As a result, reading or writing this `session.idp` property is currently forbidden.
   * If the component / hook you are working on requires this property, please figure out
   * how to implement the logic in an IDP-agnostic way.
   */
  idp?: never;
  id?: string;
  /** Whether this is an SSO session with a Samsung bound account */
  isSamsungSso?: boolean;
  isSignedIn?: boolean;
  /** The alias that was used to sign in */
  name?: UntrustedExternalInputText;
}

export interface IUser {
  displayUsername: UntrustedExternalInputText;
  username: UntrustedExternalInputText;
}

export type ResourceTenant = {
  /** The friendly name to display for the tenant */
  displayName: string;
  /** The tenant's domain */
  domain: string;
  /** The tenant id */
  id: string;
  /** Whether this tenant is the last one used to sign in */
  isLastUsedTenant?: boolean;
  /** The tile logo branding URL for this tenant */
  tileLogoUrl?: string;
};

export enum MemberNameType {
  Unknown = "Unknown",
  Live = "Live",
  EASI = "EASI",
  Phone = "Phone",
}

export type MemberNameTypeValues = `${MemberNameType}`;
