import { useCallback } from "react";
import { ViewId } from "@msidentity/SISU/constants";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import { useNavigateDirection, useRedirect } from "@msidentity/SISU/hooks";
import { getRouteFromViewId } from "@msidentity/SISU/utilities/routing-helper";

export const useLearnAboutParentalConsentView = () => {
  const { manageConsentUrl } = GlobalConfig.instance;
  const { dispatchStateChange } = useGlobalContext();

  const redirect = useRedirect();
  const navigate = useNavigateDirection();

  const navigateToManageParentalConsent = useCallback(() => {
    redirect(manageConsentUrl);
  }, [redirect, manageConsentUrl]);

  const navigateToLearnMore = useCallback(() => {
    dispatchStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.LearnAboutParentalConsent,
      destination: ViewId.ParentalHandover,
      displayOptions: { navigationDirection: "back" },
    });
    navigate(ViewId.LearnAboutParentalConsent, getRouteFromViewId(ViewId.ParentalHandover));
  }, [dispatchStateChange, navigate]);

  return { navigateToManageParentalConsent, navigateToLearnMore };
};
