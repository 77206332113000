import { UserFlowType } from "@msidentity/SISU/constants";
import { ViewId } from "@msidentity/SISU/constants/routing-constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { type IFormSubmissionProps } from "@msidentity/SISU/hooks";
import { useNavigateDirection } from "@msidentity/SISU/hooks/use-navigate-direction";
import { useCreateAccount } from "../../../hooks/use-create-account";
import { useSignUpContext } from "../../../signup-context";

/**
 * Provides a submit handler for the password collection view
 * @param collectFirstNameLastName If first and last name should be collected
 * @param collectBirthDateCountry If date of birth should be collected
 * @returns A submit handler for the password collection view
 */
export const usePasswordCollectionSubmit = (
  collectFirstNameLastName: boolean,
  collectBirthDateCountry: boolean,
) => {
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  const navigate = useNavigateDirection();
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const { viewState } = useSignUpContext();
  const createAccountRequest = useCreateAccount();

  return async (formSubmissionProps: IFormSubmissionProps) => {
    const passwordInputValue = String(formSubmissionProps.value);
    if (
      collectFirstNameLastName ||
      (isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent)
    ) {
      navigate(ViewId.SignUpPasswordCollection, ViewId.SignupNameCollection);
    } else if (collectBirthDateCountry && !isSimplifiedChildAccountCreation) {
      navigate(ViewId.SignUpPasswordCollection, ViewId.CountryBirthdate);
    } else {
      await createAccountRequest(
        {
          ...viewState,
          password: passwordInputValue,
        },
        ViewId.SignUpPasswordCollection,
      );
    }
  };
};
