import React from "react";
import { useChallengeContext } from "@msidentity/SISU/challenge-context";
import { ApiNames } from "@msidentity/SISU/constants";
import { FlowId } from "@msidentity/SISU/constants/routing-constants";
import { ChallengeViewRendererFabric } from "@msidentity/SISU/views/challenge/fabric/challenge-view-renderer-fabric";
import { useChallengeViewPropertiesFabric } from "@msidentity/SISU/views/challenge/fabric/hooks/use-challenge-view-properties-fabric";
import { useSignupChallengeHandler } from "../hooks/use-signup-challenge-handler";

/**
 * @returns the ChallengeViewFabricSignup component
 */
export const ChallengeViewFabricSignup = function ChallengeViewFabricSignup() {
  const {
    challengeState: { apiName = ApiNames.Unknown },
  } = useChallengeContext();
  const challengeSuccessHandler = useSignupChallengeHandler(apiName);
  const challengeViewProperties = useChallengeViewPropertiesFabric(challengeSuccessHandler);

  return (
    <ChallengeViewRendererFabric
      challengeViewProps={challengeViewProperties}
      flowId={FlowId.Signup}
    />
  );
};
