import { useCallback } from "react";
import { UserFlowType, ViewId } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import { useNavigateDirection } from "@msidentity/SISU/hooks/use-navigate-direction";
import { useTelemetry } from "@msidentity/SISU/telemetry-helpers/use-telemetry";
import { UserActionName } from "@msidentity/SISU/telemetry-helpers/user-action-name";
import { getRouteFromViewId } from "@msidentity/SISU/utilities/routing-helper";
import {
  appendOrReplaceQueryStringParams,
  extractQueryStringParam,
} from "@msidentity/SISU/utilities/strings-helper";
import { useSignInPostRedirect } from "../../../hooks/use-signin-post-redirect";
import { useSignUpContext } from "../../../signup-context";

export const useParentalHandoverView = () => {
  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow },
    dispatchStateChange,
  } = useGlobalContext();
  const { isGamingFlow, isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const {
    viewState: { loginUrl, birthDay, birthMonth, birthYear },
  } = useSignUpContext();

  const sru = decodeURIComponent(extractQueryStringParam("sru", window.location.href) || "");
  // we want the birthdate in the format of DD:MM:YYYY
  const birthDate = `${birthDay}:${birthMonth}:${birthYear}`;

  const updatedLoginUrl = appendOrReplaceQueryStringParams(sru || loginUrl, {
    scac: isSimplifiedChildAccountCreation ? 1 : 0,
    gf: isGamingFlow ? 1 : 0,
    uft: UserFlowType.Child.toString(),
    bdate: birthDate,
  });

  const signInPostRedirect = useSignInPostRedirect();

  const onClickParentSignIn = useCallback(() => {
    logUserAction({ actionName: UserActionName.ParentalHandoverViewSignInClicked });

    dispatchStateChange({
      type: GlobalActionType.SetUserFlowType,
      payload: UserFlowType.Child.toString(),
    });

    signInPostRedirect(updatedLoginUrl, {});
  }, [signInPostRedirect, updatedLoginUrl, logUserAction, dispatchStateChange]);

  const navigate = useNavigateDirection();

  const onClickLearnMore = useCallback(() => {
    logUserAction({ actionName: UserActionName.ParentalHandoverViewLearnMoreClicked });

    dispatchStateChange({
      type: GlobalActionType.BeginNavigate,
      source: ViewId.ParentalHandover,
      destination: ViewId.LearnAboutParentalConsent,
      displayOptions: { navigationDirection: "forward" },
    });
    navigate(ViewId.ParentalHandover, getRouteFromViewId(ViewId.LearnAboutParentalConsent));
  }, [dispatchStateChange, navigate, logUserAction]);

  return { onClickParentSignIn, onClickLearnMore };
};
