import React from "react";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import StylesConfig from "@msidentity/SISU/config/styles-config";
import { mergeClasses } from "@msidentity/SISU/utilities/merge-classes";
import { useRiskApiBlockedFabric } from "./hooks/use-risk-api-blocked-fabric";
import { riskApiBlockedViewStringsFabric as viewStrings } from "./risk-api-blocked-view-strings-fabric";

/**
 * This view is shown when the account that the user is trying to create is flagged as suspicious by the Risk API.
 * The view shows a title and description informing the user that suspicious activity has been detected and that
 * account creation has been blocked as a result. The view also shows a learn more link that takes the user to a
 * short article explaining the reason behind the block as well as steps they may take to unblock themselves.
 * @returns A rendered instance of the RiskApiBlockedViewFabric component
 */
export const RiskApiBlockedViewFabric: React.FC = function RiskApiBlockedViewFabric() {
  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();

  const { learnMoreDivRef, learnMoreUrl, okButtonClickHandler, showLearnMoreLink, showOkButton } =
    useRiskApiBlockedFabric({
      viewStrings,
    });

  return (
    <ViewContainer
      title={{ title: viewStrings.title, titleId: "riskApiBlockedViewTitle" }}
      description={{
        description: viewStrings.description,
        descriptionId: "riskApiBlockedViewDescription",
      }}
      primaryButton={
        showOkButton
          ? {
              ariaDescribedBy: "riskApiBlockedViewTitle riskApiBlockedViewDescription",
              type: "submit",
              label: viewStrings.primaryButtonLabel,
              hasFocus: true,
              onClick: okButtonClickHandler,
            }
          : undefined
      }
    >
      {showLearnMoreLink && (
        <div
          ref={learnMoreDivRef}
          className={mergeClasses(commonStyles.textBody, commonStyles.formGroup)}
        >
          <a
            id="riskApiBlockedViewLearnMoreLink"
            href={learnMoreUrl}
            className={commonStyles.anchorSize}
            target="_blank"
            rel="noreferrer noopener"
            aria-describedby="riskApiBlockedViewTitle riskApiBlockedViewDescription"
          >
            {viewStrings.learnMore}
          </a>
        </div>
      )}
    </ViewContainer>
  );
};
