import {
  type UsernameCollectionViewErrorStringsFabric,
  type UsernameCollectionViewStringsFabric,
} from "./username-collection-view-fabric-types";

export const usernameCollectionViewStringsFabric: UsernameCollectionViewStringsFabric = {
  childUsernameDescription: getLocalString("Signup_UsernameChild_Description"),
  easiChildSwitchLink: getLocalString("Signup_UsernameChildNew_ExistingEmail"),
  easiSwitchLink: getLocalString("SwitchToEasi"),
  emailDomainAriaLabel: getLocalString("EmailDomainOptionsAriaLabel"),
  existingAdultEmailTitle: getLocalString("Signup_UsernameAdultExisting_PageTitle"),
  existingChildEmailTitle: getLocalString("Signup_UsernameChildExisting_PageTitle"),
  familyScenarioTitle: getLocalString("SignUp_Title_Family"),
  newChildEmailTitle: getLocalString("Signup_UsernameChildNew_PageTitle"),
  newEmailLabel: getLocalString("NewEmail"),
  outlookSwitchLink: getLocalString("SwitchToLive"),
  phoneSwitchLink: getLocalString("SwitchToPhone"),
  prefillSpeedbumpDescription: getLocalString("Signup_UsernamePrefill_SpeedbumpDescription"),
  smsDisclaimer: getLocalString("Signup_SMSDisclaimer"),
  usernamePlaceholderEasi: getLocalString("ExampleEmail"),
  usernamePlaceholderPhone: getLocalString("General_PhoneNumber"),
};

export const usernameCollectionViewErrorStringsFabric: UsernameCollectionViewErrorStringsFabric = {
  domainExistsInAad: getLocalString("DomainExistsInAad"),
  domainExistsInAadEasiOnly: getLocalString("Signup_UsernameError_EmailExistsInEntra"),
  domainExistsInAadSupportedLogin: getLocalString("DomainExistsInAadSupportedLogin"),
  domainNotAllowed: getLocalString("DomainReserved"),
  emailRequired: getLocalString("EmailRequired"),
  emailMustStartWithLetter: getLocalString("EmailMustStartWithLetter"),
  genericService: getLocalString("Generic_Service_Error"),
  invalidEmailFormat: getLocalString("InvalidEmailFormat"),
  invalidPhoneFormat: getLocalString("InvalidPhoneFormat"),
  memberNameTaken: getLocalString("AliasAccrual_OutlookEmailTaken_ViewSuggestions"),
  memberNameTakenEasi: getLocalString("UsernameTakenEasi"),
  memberNameTakenNoSuggestions: getLocalString("UsernameTakenNoSuggestions"),
  memberNameTakenPhone: getLocalString("Signup_MemberNameTakenPhone_Error"),
  phoneRequired: getLocalString("PhoneRequired"),
  phoneSignupBlocked: getLocalString("PhoneSignupBlocked"),
  signInLinkMemberNameTaken: getLocalString("Signup_SignInLinkMemberNameTaken_Error"),
  signInLinkMemberNameTakenEasi: getLocalString("Signup_SignInLinkMemberNameTakenEasi_Error"),
  signInLinkMemberNameTakenPhone: getLocalString("Signup_SignInLinkMemberNameTakenPhone_Error"),
  signupBlocked: getLocalString("UsernameNotAvailable"),
  verificationThrottled: getLocalString("Errors_Too_Many_Codes_Requested"),
};
