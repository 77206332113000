import React, { useRef } from "react";
import { type BirthdateProps } from "@msidentity/SISU/components/birthdate/fabric/birthdate-fabric";
import DateConfig from "@msidentity/SISU/config/date-config";
import { UserFlowType, ViewId } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { DatePart } from "@msidentity/SISU/utilities/birthdate-helper";
import { type ICountryRegionInfo } from "@msidentity/SISU/utilities/country-helper";
import { FormattedTextWithBindings } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { useCreateAccountError } from "../../../../hooks/use-create-account-error";
import SignUpConfig from "../../../../signup-config";
import { type CreateAccountErrorStrings } from "../../../../signup-types";
import { getFirstBirthdateElement, useCountryBirthdate } from "../../hooks/use-country-birthdate";
import { type CountryBirthdateViewStringsFabric } from "../country-birthdate-view-types-fabric";
import { useCountryBirthdateSubmitFabric } from "./use-country-birthdate-submit-fabric";

export type UseCountryBirthdateFabricParams = {
  viewStrings: CountryBirthdateViewStringsFabric;
  createAccountErrorStrings: CreateAccountErrorStrings;
};

export type UseCountryBirthdateFabricResponse = {
  legalWarning?: JSX.Element | string;
  countryList: ICountryRegionInfo[];
  onCountryChange: (iso: string) => void;
  selectedCountryIso: string;
  birthdateProps: BirthdateProps;
  onSubmitHandler: React.FormEventHandler;
  /** View title */
  title: string;
  /** View description */
  description: string;
  /** Flag represents is isSimplifiedChildAccountCreation and UserFlowType is Parent  */
  isParentFlow: boolean;
};

/**
 * @param params Parameters for this hook
 * @param params.viewStrings Localized strings for the view
 * @param params.createAccountErrorStrings Localized strings for create account errors
 * @returns {UseCountryBirthdateFabricResponse} Returns country/birthdate collection view properties
 */
export const useCountryBirthdateFabric = ({
  viewStrings,
  createAccountErrorStrings,
}: UseCountryBirthdateFabricParams): UseCountryBirthdateFabricResponse => {
  const { birthdateLabel } = viewStrings;
  const { isHosted } = GlobalConfig.instance;
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const {
    familySafetyAppUrl,
    family: { isFamilyScenario },
  } = SignUpConfig.instance;
  const { dateOrder, dayOptions, monthOptions, minBirthYear, maxBirthYear } = DateConfig.instance;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  const isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;

  let showIdentityBanner = true;
  if (isSimplifiedChildAccountCreation && userFlowType !== UserFlowType.Parent) {
    showIdentityBanner = false;
  }

  const {
    countryList,
    selectedCountryIso,
    onCountryChange,
    birthdate,
    onBirthDateChange,
    errorMessage,
    setErrorMessage,
    userHasSubmitted,
    setUserHasSubmitted,
    title,
    description,
    legalContent,
  } = useCountryBirthdate({
    viewStrings,
    viewOptions: {
      showBackButtonOnActiveView: true,
      showIdentityBanner,
    },
    applyParentStrings: isParentFlow,
  });
  const { birthDay, birthMonth, birthYear } = birthdate;

  const birthdateProps: BirthdateProps = {
    hasFocus: false,
    labelText: birthdateLabel,
    dateOrder,
    errorMessage: userHasSubmitted ? errorMessage : "",
    birthDayProps: {
      id: DatePart.BirthDay,
      name: DatePart.BirthDay,
      selectOptions: dayOptions,
      value: birthDay,
      onChange: (e) => {
        onBirthDateChange(e.target.value, birthMonth, birthYear);
      },
      elementRef: useRef<HTMLSelectElement>(null),
    },
    birthMonthProps: {
      id: DatePart.BirthMonth,
      name: DatePart.BirthMonth,
      selectOptions: monthOptions,
      value: birthMonth,
      onChange: (e) => {
        onBirthDateChange(birthDay, e.target.value, birthYear);
      },
      elementRef: useRef<HTMLSelectElement>(null),
    },
    birthYearProps: {
      id: DatePart.BirthYear,
      name: DatePart.BirthYear,
      min: minBirthYear,
      max: maxBirthYear,
      value: birthYear,
      onChange: (e) => {
        // Enforce max length here since HTML type=number input doesn't support maxLength
        if (e.target.value.length <= 4) {
          onBirthDateChange(birthDay, birthMonth, e.target.value);
        }
      },
      elementRef: useRef<HTMLInputElement>(null),
    },
  };

  const firstBirthdateElementRef = getFirstBirthdateElement(
    birthdateProps.dateOrder,
    birthdateProps.birthDayProps.elementRef,
    birthdateProps.birthMonthProps.elementRef,
    birthdateProps.birthYearProps.elementRef,
  );

  const onSubmitHandler = useCountryBirthdateSubmitFabric({
    country: selectedCountryIso,
    birthdate,
    setUserHasSubmitted,
    hasValidationError: !!errorMessage,
    firstBirthdateElement: firstBirthdateElementRef,
  });

  useCreateAccountError({
    errorStrings: createAccountErrorStrings,
    viewId: ViewId.CountryBirthdate,
    setErrorMessage: setErrorMessage as (error: string | JSX.Element) => void,
    setShowError: setUserHasSubmitted,
    setFocus: (hasFocus: boolean) => hasFocus && firstBirthdateElementRef?.current?.focus(),
  });

  let viewDescription = description;
  let legalWarning;
  if (isSimplifiedChildAccountCreation) {
    if (userFlowType === UserFlowType.Parent) {
      legalWarning = legalContent;
    } else {
      viewDescription = viewStrings.descriptionAdult;
      legalWarning = undefined;
    }
  } else {
    const familySafetyAppLink = () =>
      isHosted ? (
        familySafetyAppUrl
      ) : (
        <a id="dobHelpLink" href={familySafetyAppUrl} target="_blank" rel="noreferrer noopener">
          {familySafetyAppUrl}
        </a>
      );

    legalWarning = (
      <FormattedTextWithBindings text={legalContent} embeddedBindings={{ familySafetyAppLink }} />
    );
  }

  return {
    countryList,
    selectedCountryIso,
    onCountryChange,
    birthdateProps,
    onSubmitHandler,
    title,
    description: isFamilyScenario ? "" : viewDescription, // Hide the description for family scenarios
    legalWarning,
    isParentFlow,
  };
};
