import { NonNumericRegex } from "@msidentity/SISU/components/inputs/input-regex-constants";
import { FlowId, QueryString, ViewId } from "@msidentity/SISU/constants";
import {
  useActivateView,
  useBackButtonControl,
  useDocumentTitle,
  useShowProgressAndRedirect,
} from "@msidentity/SISU/hooks";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { addQueryStrings } from "@msidentity/SISU/utilities/strings-helper";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";

export type UseUsernameRecoverySpeedbumpParams = {
  strings: { title: string };
  showIdentityBanner?: boolean;
};

export type UseUsernameRecoverySpeedbumpResponse = {
  memberName: string;
  signInOnClick: () => void;
  recoveryOnClick: () => void;
};

export const useUsernameRecoverySpeedbump = (
  params: UseUsernameRecoverySpeedbumpParams,
): UseUsernameRecoverySpeedbumpResponse => {
  const { strings, showIdentityBanner = true } = params;
  const { usernameRecoverySpeedbumpSignInUrl, usernameRecoveryUrl } = SignUpConfig.instance;
  const {
    viewState: { memberName, memberNameType },
  } = useSignUpContext();

  const queryStringParams = new Map<string, string>();
  // Remove the formatting in the membername, as causes issue with UsernameRecovery when prefilled
  const unrMembername =
    memberNameType === MemberNameType.Phone ? memberName.replace(NonNumericRegex, "") : memberName;
  queryStringParams.set(QueryString.membername, unrMembername);
  let updatedUsernameRecoveryUrl = addQueryStrings(usernameRecoveryUrl, queryStringParams);
  updatedUsernameRecoveryUrl = updatedUsernameRecoveryUrl.replace(
    "prefillUserName",
    encodeURI(unrMembername),
  );

  const canGoBack = useBackButtonControl();

  useActivateView(ViewId.SignUpUsernameRecoverySpeedbump, FlowId.Signup, {
    showBackButtonOnActiveView: canGoBack,
    showIdentityBanner,
  });
  useDocumentTitle(strings.title);

  const showProgressAndRedirect = useShowProgressAndRedirect();

  return {
    memberName,
    signInOnClick: () => showProgressAndRedirect(usernameRecoverySpeedbumpSignInUrl),
    recoveryOnClick: () => showProgressAndRedirect(updatedUsernameRecoveryUrl),
  };
};
