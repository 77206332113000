import React from "react";
import { InputFabric } from "@msidentity/SISU/components/inputs/input/fabric/input-fabric";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import StylesConfig from "@msidentity/SISU/config/styles-config";
import { addUser } from "@msidentity/sisu/utilities/image-helpers/accessible-images";
import { mergeClasses } from "@msidentity/SISU/utilities/merge-classes";
import { MarketingOptInFabric } from "../../../../../components/marketing-optin/fabric/marketing-optin-fabric";
import { LegalContentFabric } from "../../../components/legal-content/fabric/legal-content-fabric";
import { commonSignUpStringsFabric as commonSignUpStrings } from "../../../fabric/common-signup-strings-fabric";
import { createAccountErrorStringsFabric as createAccountErrorStrings } from "../../../fabric/create-account-error-strings-fabric";
import { useVerificationViewSubmitCreateAccount } from "../hooks/use-verification-view-submit-create-account";
import { useVerificationViewFabric } from "./hooks/use-verification-view-fabric";
import { verificationViewStringsFabric as strings } from "./verification-view-strings-fabric";

/**
 * @returns A rendered instance of VerificationView component
 */
export const VerificationViewFabric: React.FC = function VerificationViewFabric() {
  const submitTask = useVerificationViewSubmitCreateAccount();

  const {
    description,
    descriptionBindings,
    inputState,
    isOptInEmail,
    isParentFlow,
    isPhone,
    onSubmit,
    primaryButtonText,
    resendPhoneCodeMessage,
    showMarketingOptIn,
    title,
    toggleIsOptInEmail,
    showProgressIndicator,
    showView,
  } = useVerificationViewFabric({
    strings: { verificationViewStrings: strings, createAccountErrorStrings, commonSignUpStrings },
    form: { submitTask },
  });

  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  useStaticCommonStyles();
  const { row, textCaption } = useCommonStyles();

  return showView ? (
    <ViewContainerFabric
      title={{
        title,
        titleId: "verificationViewTitle",
      }}
      primaryButton={{
        label: primaryButtonText,
        type: "submit",
        buttonId: "nextButton",
        disabled: showProgressIndicator,
      }}
      form={{
        name: "VerificationForm",
        onSubmit,
      }}
      image={
        isParentFlow ? { urls: addUser, dataTestId: "addUser", role: "presentation" } : undefined
      }
      description={{
        descriptionId: "verificationViewDescription",
        description,
        embeddedBindings: descriptionBindings,
      }}
    >
      <InputFabric
        id="VerificationCode"
        name="VerificationCode"
        hasInitialFocus
        errorId="verificationCodeError"
        placeholder={strings.enterCode}
        type="tel"
        autoComplete="off"
        aria-describedby="verificationViewDescription verificationCodeError"
        aria-label={strings.enterCode}
        {...inputState}
        {...inputState.error}
      />

      {isPhone && <div className={mergeClasses(row, textCaption)}>{resendPhoneCodeMessage}</div>}

      {showMarketingOptIn && (
        <div className={row}>
          <MarketingOptInFabric checked={isOptInEmail} onChange={toggleIsOptInEmail} />
        </div>
      )}

      {!isParentFlow && <LegalContentFabric />}
    </ViewContainerFabric>
  ) : null;
};
