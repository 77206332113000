import { ViewId } from "@msidentity/SISU/constants";
import { useSpeedbumpCreate } from "../../../../hooks/use-speedbump-create";
import { useEvictionSpeedbump } from "../../hooks/use-eviction-speedbump";

export type UseEvictionSpeedbumpFabricResponse = {
  memberName: string;
  signInUrl: string;
  onContinueToCreate: () => void;
};

/**
 * Aggregate-hook for the Eviction Speedbump View Fabric
 * @param title The title string for the view
 * @returns Properties for the Eviction Speedbump View Fabric
 */
export const useEvictionSpeedbumpFabric = function useEvictionSpeedbumpFabric(
  title: string,
): UseEvictionSpeedbumpFabricResponse {
  const { memberName, signInUrl } = useEvictionSpeedbump(title);
  const onContinueToCreate = useSpeedbumpCreate(ViewId.SignUpEvictionSpeedbump);

  return {
    memberName,
    signInUrl,
    onContinueToCreate,
  };
};
