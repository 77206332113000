import React from "react";
import { NameCollectionFabric as NameCollection } from "@msidentity/SISU/components/name-collection/fabric/name-collection-fabric";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { commonSignUpStringsFabric as commonStrings } from "../../../fabric/common-signup-strings-fabric";
import { createAccountErrorStringsFabric as createAccountErrorStrings } from "../../../fabric/create-account-error-strings-fabric";
import { useNameCollectionFabric } from "./hooks/use-name-collection-fabric";
import { signupNameCollectionViewStringsFabric as viewStrings } from "./signup-name-collection-view-strings-fabric";

/**
 * SignupNameCollectionViewFabric component
 * @returns A rendered instance of this component
 */
export const SignupNameCollectionViewFabric: React.FC = function SignupNameCollectionViewFabric() {
  const {
    form: { nameCollectionProps, onSubmit },
    pageTitle,
    pageDescription,
    pageImage,
  } = useNameCollectionFabric({ viewStrings, createAccountErrorStrings });

  return (
    <ViewContainer
      title={{ title: pageTitle, titleId: "signupNameCollectionViewTitle" }}
      description={{
        description: pageDescription,
        descriptionId: "signupNameCollectionViewDescription",
      }}
      image={pageImage}
      primaryButton={{
        ariaDescribedBy: "signupNameCollectionViewTitle signupNameCollectionViewDescription",
        type: "submit",
        label: commonStrings.nextButton,
        buttonId: "nextButton",
      }}
      form={{
        onSubmit,
      }}
    >
      <NameCollection {...nameCollectionProps} />
    </ViewContainer>
  );
};
