import { type ViewId } from "../../../constants";
import { type IFormSubmissionProps } from "../../../hooks";
import { type SubmitTask, useFormSubmission } from "../../inputs/hooks/use-form-submission";
import { type InputState } from "../../inputs/hooks/use-input";

export interface INameCollectionSubmitProps extends IFormSubmissionProps {
  firstName?: string;
  lastName?: string;
}

export interface UseNameCollectionFormParams {
  config: {
    isFirstLastOrder: boolean;
    viewID: ViewId;
  };
  form: {
    submitTask: SubmitTask<INameCollectionSubmitProps>;
    submitParams?: INameCollectionSubmitProps;
  };
  state: {
    firstNameInputState: InputState;
    lastNameInputState: InputState;
  };
}

export interface UseNameCollectionFormResponse {
  onSubmit: React.FormEventHandler;
}

/**
 * Hook for managing and submission of the name collection form.
 * @param params
 *  - config.isFirstLastOrder: If true, the form will collect first name first, otherwise it will collect last name first.
 *  - config.viewID: The view ID of the name collection view.
 *  - form.submitTask: The task to execute when the form is submitted if there are no validation errors.
 *  - form.submitParams: The parameters to pass to the submitTask. If not provided, the default parameters will be used.
 *  - state.firstNameInputState: The input state to use for the first name input.
 *  - state.lastNameInputState: The input state to use for the last name input.
 * @returns
 *  - onSubmit: The form submission handler. Once invoked, the form will be validated and if there are no errors, submitTask will be called.
 */
export const useNameCollectionForm = (
  params: UseNameCollectionFormParams,
): UseNameCollectionFormResponse => {
  const { config, form, state } = params;
  const { firstNameInputState, lastNameInputState } = state;

  const inputState = config.isFirstLastOrder
    ? [firstNameInputState, lastNameInputState]
    : [lastNameInputState, firstNameInputState];
  const onSubmit = useFormSubmission({
    inputState,
    viewId: config.viewID,
    submitTask: form.submitTask,
    submitParams: form.submitParams || {
      firstName: String(firstNameInputState.value),
      lastName: String(lastNameInputState.value),
      errorHandler: (error) => {
        firstNameInputState.error.setServerError(error);
        lastNameInputState.error.setServerError(error);
      },
    },
  });

  return {
    onSubmit,
  };
};
