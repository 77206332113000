import React from "react";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { useSignupBlockedFabric } from "./hooks/use-signup-blocked-fabric";
import { signupBlockedViewStringsFabric as viewStrings } from "./signup-blocked-view-strings-fabric";

/**
 * SignupBlockedViewFabric component
 * @returns A rendered instance of this component
 */
export const SignupBlockedViewFabric: React.FC = function SignupBlockedViewFabric() {
  const { hideBackButton, cancelOnClick } = useSignupBlockedFabric({ strings: viewStrings });

  return (
    <ViewContainer
      title={{ title: viewStrings.title, titleId: "blockedViewTitle" }}
      description={{
        description: viewStrings.description,
        descriptionId: "blockedViewDescription",
      }}
      secondaryButton={
        !hideBackButton
          ? {
              ariaDescribedBy: "blockedViewTitle blockedViewDescription",
              type: "submit",
              label: viewStrings.button,
              onClick: cancelOnClick,
              hasFocus: true,
            }
          : undefined
      }
    />
  );
};
