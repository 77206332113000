import { type ViewId } from "@msidentity/SISU/constants";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { getRouteFromViewId } from "@msidentity/SISU/utilities/routing-helper";
import { useSignUpContext } from "../signup-context";

export const useNavigateToNextViewId = (currentViewId: ViewId) => {
  const {
    viewState: { nextViewId },
  } = useSignUpContext();
  const navigate = useNavigateDirection();

  // Return an onSubmit handler that will navigate to the next view
  return function onSubmit(event: React.FormEvent) {
    event.preventDefault();
    navigate(currentViewId, getRouteFromViewId(nextViewId));
  };
};
