import GlobalConfig from "@msidentity/sisu/global-config";

/**
 * Sets the common post request properties with values from the global config.
 * @returns The common post request data which can be included in a request payload.
 */
export const setCommonAccountPostRequestData = function setCommonAccountPostRequestData() {
  const { uiFlavor, scenarioId, unauthenticatedSessionId, initialPageId } = GlobalConfig.instance;
  return {
    uiflvr: uiFlavor,
    scid: scenarioId,
    uaid: unauthenticatedSessionId,
    hpgid: initialPageId,
  };
};

/**
 * Gets the updated URL for an API which appends the window's current QS.
 * This helps to pass the current QS to the API URL which is used for consistent telemetry between
 * client and server especially on AccountUX.
 * Example, passing the client id, correlation id, etc.
 * @param apiUrl The API URL to update with current QS.
 * @returns The updated API URL with current qs appended.
 */
export const getAPIUrlWithCurrentWindowQS = function getAPIUrlWithCurrentWindowQS(apiUrl: string) {
  const currentQS = window.location.search;

  // If there are no query string parameters, return the original URL.
  if (!currentQS) {
    return apiUrl;
  }

  // Append to the existing query string.
  const separator = apiUrl.includes("?") ? "&" : "?";
  return `${apiUrl}${separator}${currentQS.substring(1)}`;
};
