import { makeStyles, shorthands } from "@griffel/react";

export const useHumanIframeStyles = makeStyles({
  iframe: {
    height: "0px",
    width: "0px",
    position: "absolute",
    left: "-9999px",
    ...shorthands.border("0px", "none", "none"),
  },
});
