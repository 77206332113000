import { useState } from "react";
import { useDomainSelect } from "@msidentity/sisu/components/domain-select/use-domain-select";
import {
  LiveMembernameRegex,
  MembernameRegex,
  PhoneNumberFormat,
  StartsWithALetterRegex,
} from "@msidentity/SISU/components/inputs/input-regex-constants";
import { usePhoneNumberFabric } from "@msidentity/SISU/components/inputs/phone-number/fabric/hooks/use-phone-number-fabric";
import { type PhoneNumberFabricProps } from "@msidentity/SISU/components/inputs/phone-number/fabric/phone-number-fabric";
import { type PhoneNumberState } from "@msidentity/SISU/components/inputs/phone-number/hooks/use-phone-number";
import { type UsernameCollectionFabricProps } from "@msidentity/SISU/components/username-collection/fabric/username-collection-fabric";
import useTextInputFormDeprecated, {
  type FormSubmitType,
} from "@msidentity/SISU/hooks/use-text-input-form-deprecated";
import { getCustomPhoneInputValidation } from "@msidentity/SISU/model/alias/phone-number";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { type ICountryInfo, getDefaultCountry } from "@msidentity/SISU/utilities/country-helper";
import {
  multipleRegexInputValidation,
  simpleInputValidation,
} from "@msidentity/SISU/utilities/input-helper";
import { useSignUpContext } from "../../../../signup-context";
import { useDomainOnChange } from "../../hooks/use-username-collection-onchange";
import {
  type UsernameCollectionViewErrorStringsFabric,
  type UsernameCollectionViewStringsFabric,
} from "../username-collection-view-fabric-types";
import { usePhoneCountryOnChangeFabric } from "./use-username-onchange-fabric";
import { type UseUsernameOptionsFabricProps } from "./use-username-options-fabric";

export type UsernameCollectionInputFabricParams = {
  usernameCollectionInput: UseUsernameOptionsFabricProps;
  strings: {
    usernameCollectionViewStrings: UsernameCollectionViewStringsFabric;
    usernameCollectionViewErrorStrings: UsernameCollectionViewErrorStringsFabric;
  };
  config: {
    defaultPhoneCountry: string;
    domainList: string[];
    phoneCountryList: ICountryInfo[];
  };
};

export type UsernameCollectionInputFabricProps = {
  inputProps: UsernameCollectionFabricProps;
  formSubmit: {
    outlookOnSubmit?: FormSubmitType;
    easiOnSubmit?: FormSubmitType;
    phoneNumberState: PhoneNumberState;
  };
  inputStates: {
    // eslint-disable-next-line deprecation/deprecation
    easiInputState: ReturnType<typeof useTextInputFormDeprecated>;
    // eslint-disable-next-line deprecation/deprecation
    outlookInputState: ReturnType<typeof useTextInputFormDeprecated>;
    outlookDomainState: {
      domain: string;
      setDomain: React.Dispatch<React.SetStateAction<string>>;
      defaultDomain: string;
    };
    phoneNumberState: PhoneNumberState;
  };
};

/**
 * @param props Props for usernameCollectionInputFabric
 * @param props.usernameCollectionInput Common props for username collection component
 * @param props.strings Flavored strings
 * @param props.strings.usernameCollectionViewStrings Strings for username collection Fabric view
 * @param props.strings.usernameCollectionViewErrorStrings Strings for username collection Fabric view errors
 * @param props.config Config properties
 * @returns The props for username collection inputs on Fabric flavor.
 * These will be passed to username collection component.
 */
export const useUsernameCollectionInputFabric = ({
  usernameCollectionInput,
  strings: { usernameCollectionViewStrings, usernameCollectionViewErrorStrings: errorStrings },
  config,
}: UsernameCollectionInputFabricParams): UsernameCollectionInputFabricProps => {
  const { emailDomainAriaLabel, newEmailLabel, usernamePlaceholderEasi, usernamePlaceholderPhone } =
    usernameCollectionViewStrings;
  const { easiSwitchLinkText, outlookSwitchLinkText, phoneSwitchLinkText } =
    usernameCollectionInput;
  const { phoneCountryList, defaultPhoneCountry, domainList } = config;
  const {
    viewState: { memberNameInput },
  } = useSignUpContext();

  const [currentInputType, setCurrentInputType] = useState(
    usernameCollectionInput.initialInputType,
  );

  const isLiveInitialInput = currentInputType === MemberNameType.Live;
  const isPhoneInitialInput = currentInputType === MemberNameType.Phone;
  const isEasiInitialInput = currentInputType === MemberNameType.EASI;

  const phoneNumberState = usePhoneNumberFabric({
    defaultCountry: isPhoneInitialInput
      ? memberNameInput.phoneCountry
      : getDefaultCountry(phoneCountryList, defaultPhoneCountry),
    input: {
      initialValue: isPhoneInitialInput ? memberNameInput.input : "",
      hasInitialFocus: true,
      useElementRef: true,
    },
    validationMethod: getCustomPhoneInputValidation(
      PhoneNumberFormat,
      errorStrings.invalidPhoneFormat,
      errorStrings.phoneRequired,
    ),
  });

  // eslint-disable-next-line deprecation/deprecation
  const outlookInputState = useTextInputFormDeprecated(
    isLiveInitialInput ? memberNameInput.input : "",
  );
  // TODO: Add a fatal error if domainList is empty
  const defaultDomain =
    isLiveInitialInput && memberNameInput.domain ? memberNameInput.domain : domainList[0];
  const { domain, setDomain } = useDomainSelect(defaultDomain);

  // eslint-disable-next-line deprecation/deprecation
  const easiInputState = useTextInputFormDeprecated(
    isEasiInitialInput ? memberNameInput.input : "",
  );

  const inputProps: Partial<UsernameCollectionFabricProps> = {};
  const commonInputProps = {
    id: "usernameInput",
    errorId: "usernameInputError",
    type: "text",
    ariaDescribedBy: "usernameCollectionViewTitle usernameInputError",
    hasInitialFocus: true,
    onBlur: undefined, // Override the props related to focus state since we are using refs instead
    focus: undefined,
    setFocus: undefined,
    onFocus: undefined,
  };

  if (isEasiInitialInput || easiSwitchLinkText) {
    const easiInputProps = {
      ...easiInputState,
      ...commonInputProps,
      name: newEmailLabel,
      placeholder: usernamePlaceholderEasi,
      ariaLabel: newEmailLabel,
      inputValidationFunc: simpleInputValidation(
        errorStrings.invalidEmailFormat,
        MembernameRegex,
        errorStrings.emailRequired,
      ),
      showErrorInline: easiInputState.showError,
      validationErrorHandler: (err: string) => easiInputState.setValidationError(err),
    };

    inputProps.easiInputProps = easiInputProps;
  }

  const onDomainChange = useDomainOnChange();

  if (isLiveInitialInput || outlookSwitchLinkText) {
    const outlookInputProps = {
      ...outlookInputState,
      ...commonInputProps,
      name: newEmailLabel,
      placeholder: newEmailLabel,
      ariaLabel: newEmailLabel,
      inputValidationFunc: multipleRegexInputValidation(
        [StartsWithALetterRegex, LiveMembernameRegex],
        [errorStrings.emailMustStartWithLetter, errorStrings.invalidEmailFormat],
        errorStrings.emailRequired,
      ),
      showErrorInline: outlookInputState.showError,
      validationErrorHandler: (err: string) => outlookInputState.setValidationError(err),
      displayDomain: true,
      domainSelectProps: {
        ariaLabel: emailDomainAriaLabel,
        domainList,
        domainPrefix: "@",
        selectedDomain: domain,
        setSelectedDomain: setDomain,
        selectId: "domainSelect",
        onDomainChange,
      },
    };

    inputProps.outlookInputProps = outlookInputProps;
  }

  const onPhoneCountryChange = usePhoneCountryOnChangeFabric(phoneNumberState);

  if (isPhoneInitialInput || phoneSwitchLinkText) {
    const { dropdownValue, error } = phoneNumberState;
    const { value, elementRef } = phoneNumberState.inputState;
    const phoneInputProps: PhoneNumberFabricProps = {
      errorMessage: error.errorMessage,
      showErrorMessage: error.showErrorMessage,
      dropdownProps: {
        countryData: phoneCountryList,
        value: dropdownValue,
        useInlinePhoneNumber: true,
        onChange: onPhoneCountryChange,
      },
      inputProps: {
        ...commonInputProps,
        type: "tel",
        name: usernamePlaceholderPhone,
        placeholder: usernamePlaceholderPhone,
        "aria-label": usernamePlaceholderPhone,
        value,
        elementRef,
      },
    };

    inputProps.phoneInputProps = phoneInputProps;
  }

  return {
    inputProps: {
      ...usernameCollectionInput,
      ...inputProps,
      currentInputType,
      setCurrentInputType,
    },
    inputStates: {
      easiInputState,
      outlookInputState,
      outlookDomainState: {
        domain,
        setDomain,
        defaultDomain,
      },
      phoneNumberState,
    },
    formSubmit: {
      outlookOnSubmit: outlookInputState.onFormSubmission,
      easiOnSubmit: easiInputState.onFormSubmission,
      phoneNumberState,
    },
  };
};
