import { useState } from "react";

export const useDomainSelect = (defaultDomain: string) => {
  const [domain, setDomain] = useState(defaultDomain);

  return {
    domain,
    setDomain,
  };
};
