import React from "react";

export type NameCollectionProps = {
  firstNameInput: JSX.Element;
  lastNameInput: JSX.Element;
  isFirstLastOrder: boolean;
};

/**
 * NameCollection component
 * @param props The props object
 * @param props.firstNameInput element for the first name input.
 * @param props.lastNameInput element for the last name input.
 * @param props.isFirstLastOrder boolean to determine if the first name input should be displayed before the last name input.
 * @returns A rendered instance of this component
 */
export const NameCollection: React.FC<NameCollectionProps> = function NameCollection(props) {
  const { firstNameInput, lastNameInput, isFirstLastOrder } = props;

  const inputs = isFirstLastOrder
    ? [firstNameInput, lastNameInput]
    : [lastNameInput, firstNameInput];

  return <div data-testid="NameCollection">{inputs}</div>;
};
