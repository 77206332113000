import { commonBindingsFabric } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { type KoreaConsentStrings } from "../korea-privacy-consent-types";

const { boldText } = commonBindingsFabric;

export const koreaConsentStringsFabric: KoreaConsentStrings = {
  dataCollectionDesc: getLocalString("Signup_KoreaPrivacyConsent_CollectData_Desc"),
  dataProvisionDesc: getLocalString("Signup_KoreaPrivacyConsent_DataShare_Desc"),
  title: getLocalString("Signup_KoreaPrivacyConsent_Title"),
  description: getLocalString("Signup_KoreaPrivacyConsent_Desc"),
  descriptionBindings: {
    boldText,
  },
  primaryButtonLabel: getLocalString("ConsentButton"),
};
