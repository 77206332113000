import { ViewId } from "@msidentity/SISU/constants";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { type EmbeddedFC } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { getAgreementViewRoute } from "@msidentity/SISU/utilities/routing-helper";
import { AgreementType } from "@msidentity/SISU/views";

type PiplDescriptionBindings = { learnMoreLink: EmbeddedFC; privacyLink?: EmbeddedFC };

export type PiplDescriptionProp = {
  string: string;
  embedPrivacy: boolean;
  dataTestId?: string;
};

export type PiplDescriptionReturn = {
  children: string;
  embeddedBindings: PiplDescriptionBindings;
  dataTestId?: string;
};

/**
 * @param piplDescriptionStrings an array of descriptions, indicating whether to embed a privacy link
 * @param createLinkHandler The flavor specific createLink handler
 * @returns an array of descriptions with embedded bindings
 */
export const useDescriptions = function useDescriptions(
  piplDescriptionStrings: PiplDescriptionProp[],
  createLinkHandler: (onClick: () => void) => EmbeddedFC,
): PiplDescriptionReturn[] {
  const navigate = useNavigateDirection();

  return piplDescriptionStrings.map(({ embedPrivacy, string, dataTestId }) => {
    // Every description gets a learnMoreLink
    const learnMoreLink = createLinkHandler(() => {
      navigate(ViewId.SignUpChinaPipl, getAgreementViewRoute(AgreementType.ChinaPiplLearnMore));
    });
    const embeddedBindings: PiplDescriptionBindings = {
      learnMoreLink,
    };

    // Add the privacyLink to the third one
    if (embedPrivacy) {
      embeddedBindings.privacyLink = createLinkHandler(() => {
        navigate(ViewId.SignUpChinaPipl, getAgreementViewRoute(AgreementType.ChinaPrivacy));
      });
    }

    return {
      children: string,
      embeddedBindings,
      dataTestId,
    };
  });
};
