import React, { useMemo } from "react";
import { MemberNameType as UsernameType } from "../../../../model/user";
import { type OnSwitchMemberNameType } from "../../username-collection-types";
import { SwitchLinkFabric } from "../switch-link-fabric";

export const useUsernameCollectionSwitchLinksFabric = (
  setCurrentInputType: OnSwitchMemberNameType,
  showEasiInput: boolean,
  showOutlookInput: boolean,
  showPhoneInput: boolean,
  outlookSwitchLinkText?: string,
  easiSwitchLinkText?: string,
  phoneSwitchLinkText?: string,
  switchLinksCustomCss?: string,
  SwitchLinkContainer?: React.ElementType,
) => {
  const switchLinksComponent = useMemo(() => {
    const switchLinkGroup = (
      <>
        {!showPhoneInput && (
          <SwitchLinkFabric
            inputType={UsernameType.Phone}
            setCurrentInputType={setCurrentInputType}
            SwitchLinkContainer={SwitchLinkContainer}
            switchLinkText={phoneSwitchLinkText}
            linkId="phoneSwitch"
          />
        )}
        {!showOutlookInput && !showPhoneInput && (
          <SwitchLinkFabric
            inputType={UsernameType.Live}
            setCurrentInputType={setCurrentInputType}
            SwitchLinkContainer={SwitchLinkContainer}
            switchLinkText={outlookSwitchLinkText}
            linkId="liveSwitch"
          />
        )}
        {!showEasiInput && (
          <SwitchLinkFabric
            inputType={UsernameType.EASI}
            setCurrentInputType={setCurrentInputType}
            SwitchLinkContainer={SwitchLinkContainer}
            switchLinkText={easiSwitchLinkText}
            linkId="easiSwitch"
          />
        )}
      </>
    );

    return switchLinksCustomCss ? (
      <div className={switchLinksCustomCss} data-testid="switchLinksWrapper">
        {switchLinkGroup}
      </div>
    ) : (
      switchLinkGroup
    );
  }, [
    setCurrentInputType,
    SwitchLinkContainer,
    easiSwitchLinkText,
    outlookSwitchLinkText,
    phoneSwitchLinkText,
    showEasiInput,
    showOutlookInput,
    showPhoneInput,
    switchLinksCustomCss,
  ]);

  return { switchLinksComponent };
};
