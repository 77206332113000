import { ViewId } from "@msidentity/SISU/constants";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { getAgreementViewRoute } from "@msidentity/SISU/utilities/routing-helper";
import { AgreementType } from "@msidentity/SISU/views";
import { type RequiredConsentProps } from "../../../components/required-consent/required-consent-types";
import { useNavigateToNextViewId } from "../../../hooks/use-navigate-to-next-viewid";
import { type RequiredConsentState, useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import { type KoreaConsentStrings } from "../korea-privacy-consent-types";

/**
 * This method is used to generate the RequiredConsentProps
 * @param consentState The current state of the consent
 * @param updateConsentState A callback to update the state of the consent
 * @param agreementType The type of agreement to display (used to generate the route to the AgreementView)
 * @param descriptionText The text for the description of the agreement
 * @param doNavigateOnLearnMore Flag indicating whether navigation should be done on LearnMore click
 * @returns The required consent properties for one type of consent
 */
export const useRequiredConsent = function useRequiredConsent(
  consentState: RequiredConsentState,
  updateConsentState: (newConsentState: RequiredConsentState) => void,
  agreementType: AgreementType,
  descriptionText: string,
  doNavigateOnLearnMore?: boolean,
): RequiredConsentProps {
  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();
  const navigate = useNavigateDirection();
  const agreementViewRoute = getAgreementViewRoute(agreementType);

  return {
    // The route in the AgreementView to navigate to when the user clicks the "Learn More" link
    // example korea consent data collection
    agreementViewRoute,
    text: descriptionText,
    checked: consentState.userConsented && consentState.userViewed,
    onClickCheckbox: () => {
      // Only when a user has viewed an agreement can they toggle the consent checkmark on/off
      if (consentState.userViewed) {
        updateConsentState({ ...consentState, userConsented: !consentState.userConsented });
      }
    },
    onClickLearnMore: () => {
      updateConsentState({ userConsented: true, userViewed: true });

      if (doNavigateOnLearnMore) {
        navigate(ViewId.SignupKoreaPrivacyConsent, agreementViewRoute);
      } else {
        dispatchGlobal({
          type: GlobalActionType.BeginNavigate,
          source: ViewId.SignupKoreaPrivacyConsent,
          destination: ViewId.ViewAgreement,
          displayOptions: { navigationDirection: "forward" },
        });
      }
    },
  };
};

/**
 * The common aggregator hook to return common properties used across flavors
 * @param strings The KoreaConsent strings
 * @param doNavigateOnLearnMore Flag to be passed to useRequiredConsent
 * @returns The required consent array and form submission handler
 */
export const useKoreaConsent = function useKoreaConsent(
  strings: KoreaConsentStrings,
  doNavigateOnLearnMore?: boolean,
) {
  const { dataCollectionDesc, dataProvisionDesc } = strings;
  const {
    viewState: {
      koreaConsent: { dataCollection, dataProvision },
    },
    dispatchStateChange,
  } = useSignUpContext();

  // Data Collection
  const dataCollectionConsent = useRequiredConsent(
    dataCollection,
    (newDataCollectionState) => {
      // When the user opens the Data Collection agreement, we will note this to display a checkmark
      dispatchStateChange({
        actionType: SignUpActionType.UpdateKoreaConsent,
        payload: { dataCollection: newDataCollectionState },
      });
    },
    AgreementType.KoreaConsentDataCollection,
    dataCollectionDesc,
    doNavigateOnLearnMore,
  );
  // Data Provision
  const dataProvisionConsent = useRequiredConsent(
    dataProvision,
    (newDataProvisionState) => {
      // When the user opens the Data Provision agreement, we will note this to display a checkmark
      dispatchStateChange({
        actionType: SignUpActionType.UpdateKoreaConsent,
        payload: { dataProvision: newDataProvisionState },
      });
    },
    AgreementType.KoreaConsentDataProvision,
    dataProvisionDesc,
    doNavigateOnLearnMore,
  );

  const onFormSubmit = useNavigateToNextViewId(ViewId.SignupKoreaPrivacyConsent);

  return {
    requiredConsent: [dataCollectionConsent, dataProvisionConsent],
    onFormSubmit,
  };
};
