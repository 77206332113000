import React, { useRef } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../config/styles-config";
import { useFocusHandling } from "./hooks/use-focus-handling";

export type ICheckboxProps = {
  label: string | JSX.Element;
  htmlFor: string;
  ariaLabel: string;
  value: string;
  id: string;
  name: string;
  onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  hasInitialFocus?: boolean;
};

/**
 * Checkbox component
 * @param props The properties for this component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const Checkbox: React.FC<ICheckboxProps> = function Checkbox(props) {
  const {
    label,
    htmlFor,
    ariaLabel,
    value,
    id,
    name,
    onChangeHandler,
    checked,
    disabled,
    hidden,
    hasInitialFocus,
  } = props;
  const { useCommonStyles, useCheckboxStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const checkboxStyles = useCheckboxStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  // Provide initial focus, if requested
  useFocusHandling({ elementRef: inputRef, hasInitialFocus });

  return (
    <div className={commonStyles.row} hidden={hidden}>
      <div className={mergeClasses(commonStyles.formGroup, checkboxStyles.checkbox)}>
        <label htmlFor={htmlFor}>
          <input
            ref={inputRef}
            id={id}
            data-testid={id}
            name={name}
            type="checkbox"
            value={value}
            aria-label={ariaLabel}
            onChange={onChangeHandler}
            checked={checked}
            disabled={disabled}
          />
          <span>{label}</span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
