import { useEffect } from "react";
import { isViewId } from "@msidentity/SISU/utilities/routing-helper";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import { type CheckAvailableErrorMapResult } from "../check-available-signin-names-helper";

export interface UseUsernameCollectionErrorParams {
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult;
  getErrorMessage: (errorMessage: string) => JSX.Element;
  setErrorMessage: (error: string | JSX.Element) => void;
  setShowError?: (showError: boolean) => void;
}

/**
 * This hook is used to determine if there was a username prefill error that hasn't been displayed to the user yet.
 * It sets the error message and focus on the username input and updates SignUpContext to indicate that the error has been shown.
 * The error would be from a server-side call to CheckAvailableSigninNames API, such as username taken error or
 * Outlook username not available with alternate username suggestions.
 * @param params
 * - getCheckAvailableError: A function to get the error message based on the error code
 * - getErrorMessage: A function to apply any embedded bindings to the error message
 * - setErrorMessage: A function to set the error message to be displayed to the user
 * - setShowError: A function to set whether the error message should be displayed to the user
 */
export const useUsernameCollectionError = (params: UseUsernameCollectionErrorParams) => {
  const {
    viewState: {
      memberName,
      memberNameType,
      memberNameSuggestions,
      usernameCollectionErrorCode,
      usernameCollectionErrorShown,
    },
    dispatchStateChange,
  } = useSignUpContext();
  const {
    getCheckAvailableError,
    getErrorMessage,
    setErrorMessage,
    setShowError = () => {},
  } = params;

  const errorText = getCheckAvailableError(
    usernameCollectionErrorCode,
    memberName,
    memberNameType,
    memberNameSuggestions.length > 0,
  );

  // Show the error message if it hasn't been shown yet and there isn't a separate view for displaying the error
  const shouldShowError =
    usernameCollectionErrorCode !== "" && !usernameCollectionErrorShown && !isViewId(errorText);
  useEffect(() => {
    if (shouldShowError) {
      dispatchStateChange({
        actionType: SignUpActionType.SetUsernameCollectionErrorShown,
        payload: true,
      });
      const error = getErrorMessage(errorText);
      setErrorMessage(error);
      setShowError(true);
    }
  }, [
    setErrorMessage,
    dispatchStateChange,
    shouldShowError,
    setShowError,
    getErrorMessage,
    errorText,
  ]);
};
