import { useRef } from "react";
import GlobalConfig from "../../../global-config";
import { useLoadResourceTelemetry } from "../../../hooks/use-load-resource-telemetry";
import { useMessage } from "../../../hooks/use-message";
import { ServiceDiagEventNames } from "../../../telemetry-helpers/service-diag-event-names";
import { setCookie } from "../../../utilities/cookie-helper";

export interface HumanMessageEventData {
  name: string;
  value: string;
  type: string;
  expires: Date;
}

interface IHumanCookies {
  px3: string;
  pxde: string;
  pxvid: string;
}

const HumanCookies: IHumanCookies = {
  px3: "_px3",
  pxde: "_pxde",
  pxvid: "_pxvid",
};

export interface ISetHumanCookieParams {
  event: HumanMessageEventData;
  humanCookieDomain: string;
}

export const setHumanCookie = (setCookieParams: ISetHumanCookieParams) => {
  const {
    event: { name, value, expires },
    humanCookieDomain,
  } = setCookieParams;
  setCookie(name, value, humanCookieDomain, true, undefined, new Date(expires));
};

export const useHumanIframe = () => {
  const { humanIframeUrl, humanCookieDomain } = GlobalConfig.instance;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeLoadStartTimeRef = useRef<number>(new Date().getTime());
  const iframeAttributes = {
    humanIframeUrl,
    iframeRef,
  };

  // add load telemetry here for the iframe
  useLoadResourceTelemetry(
    iframeRef,
    iframeLoadStartTimeRef,
    ServiceDiagEventNames.IframeLoadDurationHuman,
  );

  // message listener for human iframe messages
  useMessage<HumanMessageEventData>(
    (event: HumanMessageEventData) => {
      // We want to make sure that only cookies allowed to be copied
      // to our domain are the ones we define here to protect against
      // cookie stuffing attack
      if (
        event.type === "cookie" &&
        (event.name === HumanCookies.px3 ||
          event.name === HumanCookies.pxde ||
          event.name === HumanCookies.pxvid)
      ) {
        setHumanCookie({ event, humanCookieDomain });
      }
    },
    { iFrameUrl: humanIframeUrl },
  );

  return {
    iframeAttributes,
  };
};
