import React from "react";
import { Link, useLocation } from "react-router-dom";
import FooterConfig from "@msidentity/sisu/components/footer/footer-config";
import { ViewId } from "@msidentity/SISU/constants";
import GlobalConfig from "@msidentity/sisu/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import useFabricStyles from "@msidentity/SISU/styles/fabric/fabric.styles";
import {
  type FormattedTextWithBindingsProps,
  commonBindingsFabric,
  FormattedTextWithBindings,
} from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { mergeClasses } from "@msidentity/SISU/utilities/merge-classes";
import { getAgreementViewRoute } from "@msidentity/SISU/utilities/routing-helper";
import { AgreementType } from "@msidentity/SISU/views";
import SignUpConfig from "../../../signup-config";
import { legalContentStringsFabric as legalStrings } from "./legal-content-strings-fabric";
import { type LegalContentStringsFabric } from "./legal-content-types-fabric";

/**
 * Function to generate the legal content for SignUp Fabric flow
 * @param strings The flavored strings for the legal content
 * @param strings.touAgreementFooter The terms of use agreement text
 * @param strings.touAgreementFooterKorea The terms of use agreement text for Korean market
 * @param strings.requiredLegalTextFrench The additional required legal text for French market
 * @param onClick The onClick handler for the legal links in non-hosted environments
 * @returns An array of FormattedTextWithBindingsProps for each legal text block
 */
export const getSignUpLegalContentFabric = (
  strings: LegalContentStringsFabric,
  onClick: () => void,
): Array<FormattedTextWithBindingsProps> => {
  const { market, isHosted } = GlobalConfig.instance;
  const { retentionPeriodUrl, privacyKRUrl, useOfPersonalDataUrl, personalDataCollectedUrl } =
    SignUpConfig.instance;
  const { termsOfUseUrl, privacyUrl } = FooterConfig.instance;

  const isKoreanMarket = (): boolean => market.toLowerCase() === "ko-kr";
  const isFrenchMarket = (): boolean => market.toLowerCase() === "fr-fr";

  const getLink = (url: string, agreement: AgreementType) =>
    function createLink([text]: string[]) {
      return isHosted ? (
        <Link to={getAgreementViewRoute(agreement)} onClick={onClick}>
          {text}
        </Link>
      ) : (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );
    };

  const { strong, lineBreak } = commonBindingsFabric;

  const legalContent: Array<FormattedTextWithBindingsProps> = [];
  const text = isKoreanMarket() ? strings.touAgreementFooterKorea : strings.touAgreementFooter;

  legalContent.push({
    text,
    embeddedBindings: {
      strong,
      legalLink: getLink(termsOfUseUrl, AgreementType.TermsOfUse),
      privacyLink: getLink(privacyUrl, AgreementType.Privacy),
      privacyKRLink: getLink(privacyKRUrl, AgreementType.KoreaConsentDataCollection),
      personalDataCollectedLink: getLink(
        personalDataCollectedUrl,
        AgreementType.KoreaConsentDataCollection,
      ),
      useOfPersonalDataLink: getLink(useOfPersonalDataUrl, AgreementType.KoreaConsentDataProvision),
      retentionPeriodLink: getLink(retentionPeriodUrl, AgreementType.KoreaConsentDataCollection),
    },
  });

  if (isFrenchMarket()) {
    legalContent.push({
      text: strings.requiredLegalTextFrench,
      embeddedBindings: { break: lineBreak },
    });
  }

  return legalContent;
};

/**
 * LegalContentFabric component
 * @returns An instance of this component
 */
export const LegalContentFabric: React.FC = function LegalContentFabric() {
  const fabricStyles = useFabricStyles();
  const legalContentClass = mergeClasses(fabricStyles.row, fabricStyles.legalWarning);

  const { dispatchStateChange: dispatchGlobal } = useGlobalContext();
  const location = useLocation();
  const currentPath = location.pathname;

  const hostedLinkOnClick = () =>
    dispatchGlobal({
      type: GlobalActionType.BeginNavigate,
      source: currentPath,
      destination: ViewId.ViewAgreement,
      displayOptions: { navigationDirection: "forward" },
    });

  const legalContent = getSignUpLegalContentFabric(legalStrings, hostedLinkOnClick);

  return (
    <>
      {legalContent.map((legalTextProps, index) => (
        <div
          className={legalContentClass}
          key={legalTextProps.text}
          data-testid={`legalContentFabric${index}`}
          // These specific ids are used on tests in AccountUX.
          id={index === 0 ? "ftrText" : "ftrLegalText"}
        >
          <FormattedTextWithBindings {...legalTextProps} />
        </div>
      ))}
    </>
  );
};
