import React, { useRef } from "react";
import { useFocusHandling } from "../hooks/use-focus-handling";
import { type IInputProps, type SelectElement } from "../input-interface";

export type DropdownOption = {
  /** The display text for the option */
  text: string;
  /** The value for the option */
  value: string;
};

export interface DropdownProps extends IInputProps<SelectElement> {
  /** The list of dropdown options */
  selectOptions: DropdownOption[];
  /*
   * The input ref used to focus upon submission
   * This is needed for the username collection component, which does not use the
   * focus state to set and track focus. We instead use this ref to set focus directly.
   */
  elementRef?: React.RefObject<HTMLSelectElement>;
}

/**
 * Dropdown component
 * @param props The properties for this component
 * @returns A dropdown component
 */
export const Dropdown: React.FC<DropdownProps> = function Dropdown(props) {
  const {
    "aria-describedby": ariaDescribedBy,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-required": ariaRequired,
    customCss,
    disabled = false,
    hasFocus = false,
    hasInitialFocus = false,
    id,
    name,
    selectOptions,
    value = "",
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    elementRef,
  } = props;

  let ref = useRef<HTMLSelectElement>(null);
  if (elementRef) {
    ref = elementRef;
  }

  useFocusHandling({ elementRef: ref, hasInitialFocus, hasFocus });

  return (
    <select
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-required={ariaRequired}
      className={customCss}
      disabled={disabled}
      data-testid={id}
      id={id}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      ref={ref}
    >
      {selectOptions.map((option: DropdownOption) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
};
