import React from "react";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { commonBindingsFabric } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { useEvictionError } from "../hooks/use-eviction-error";
import { evictionErrorViewStringsFabric as viewStrings } from "./eviction-error-view-strings-fabric";

/**
 * EvictionErrorViewFabric component
 * @returns A rendered instance of this component
 */
export const EvictionErrorViewFabric: React.FC = function EvictionErrorViewFabric() {
  const { title, description, primaryButtonLabel } = viewStrings;
  const { nextOnClick } = useEvictionError(title);

  const { strong } = commonBindingsFabric;

  return (
    <ViewContainer
      title={{ title, titleId: "evictionErrorViewTitle" }}
      description={{
        description,
        descriptionId: "evictionErrorViewDescription",
        embeddedBindings: { strong },
        dataTestId: "descriptionId",
      }}
      primaryButton={{
        type: "submit",
        label: primaryButtonLabel,
        onClick: nextOnClick,
        hasFocus: true,
        buttonId: "nextButton",
      }}
      applyAriaLive
    />
  );
};
