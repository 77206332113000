import { useSignUpContext } from "../../../../signup-context";
import { useKoreaConsent } from "../../hooks/use-korea-consent";
import { type KoreaConsentStrings } from "../../korea-privacy-consent-types";
import { type KoreaPrivacyConsentViewFabricProps } from "../korea-privacy-consent-view-fabric";

/**
 * This hook will use SignupContext to maintain the KoreaConsent state and update it based on user interaction.
 * When a user clicks the "Learn More" link, we will note this to display a checkmark.
 * Only when a user has viewed a link can then toggle the consent checkmark on/off.
 * @param strings The strings to use for the KoreaConsentView
 * @returns The essential properties to provide to the KoreaPrivacyConsentView.
 */
export const useKoreaConsentFabric = (
  strings: KoreaConsentStrings,
): KoreaPrivacyConsentViewFabricProps => {
  const { title, description, descriptionBindings, primaryButtonLabel } = strings;
  const {
    viewState: {
      koreaConsent: { nextButtonEnabled },
    },
  } = useSignUpContext();

  const { requiredConsent, onFormSubmit } = useKoreaConsent(strings);

  return {
    title: {
      title,
    },
    description: {
      description,
      embeddedBindings: descriptionBindings,
    },
    primaryButton: {
      label: primaryButtonLabel,
      disabled: !nextButtonEnabled,
      type: "submit",
      buttonId: "consentButton",
    },
    requiredConsent,
    onFormSubmit,
  };
};
