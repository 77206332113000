import { type CreateAccountErrorStrings } from "../signup-types";

export const createAccountErrorStringsFabric: CreateAccountErrorStrings = {
  bannedPassword: getLocalString("Password_Already_Seen_Error"),
  domainExistsInAad: getLocalString("DomainExistsInAad"),
  domainExistsInAadSupportedLogin: getLocalString("DomainExistsInAadSupportedLogin"),
  domainNotAllowed: getLocalString("DomainReserved"),
  emailMustStartWithLetter: getLocalString("Email_Must_Start_With_Letter"),
  forbiddenWord: getLocalString("Prohibited_Name"),
  passwordIncorrect: getLocalString("Password_Not_Allowed_Error"),
  passwordRequired: getLocalString("Error_PasswordRequired"),
  proofAlreadyExists: getLocalString("Error_1246"),
  invalidBirthDate: getLocalString("Birthdate_Is_Invalid"),
  invalidEmailFormat: getLocalString("Invalid_Email_Format"),
  invalidFirstName: getLocalString("NameContainsInvalidCharacters"),
  invalidLastName: getLocalString("NameContainsInvalidCharacters"),
  invalidPhoneFormat: getLocalString("Error_1208"),
  oneTimeCodeInvalid: getLocalString("Error_1215"),
  usernameTakenEasi: getLocalString("UsernameTakenEasi"),
  usernameTakenNoSuggestions: getLocalString("UsernameTakenNoSuggestions"),
  usernameTakenPhone: getLocalString("Signup_MemberNameTakenPhone_Error"),
  verificationThrottled: getLocalString("Errors_Too_Many_Codes_Requested"),
};
