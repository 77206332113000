import { simpleInputValidation } from "../input-helper";

/**
 * Min number of digits for verification code length.
 */
const MinNumberOfDigits = 1;

/**
 * Max number of digits for verification code length.
 */
const MaxNumberOfDigits = 10;

export type VerificationCodeValidatorStrings = {
  validationErrorText: string;
  informationRequiredText: string;
};

export type VerificationCodeValidator = (params: {
  strings: VerificationCodeValidatorStrings;
  minNumberOfDigits?: number;
  maxNumberOfDigits?: number;
}) => ReturnType<typeof simpleInputValidation>;
/**
 * Creates a custom regex for a numeric value between minDigits-value and maxDigits-value digits.
 * @param minDigits Minimum number of digits
 * @param maxDigits Maximum number of digits
 * @returns A regex string
 */
export const buildDigitsRegex = (minDigits: number, maxDigits: number) => {
  const regex = new RegExp(`^\\d{${minDigits},${maxDigits}}$`).toString();
  return regex.substring(1, regex.length - 1);
};

/**
 * Validates digit-string for min and max length requirements.
 * @param params Hook parameters
 * @param params.strings Translated user-facing strings
 * @param params.minNumberOfDigits Minimum number of digit characters
 * @param params.maxNumberOfDigits Maximum number of digit characters
 * @returns Handlers for implementing verification code collection.
 */
export const verificationCodeValidator: VerificationCodeValidator = ({
  strings,
  minNumberOfDigits = MinNumberOfDigits,
  maxNumberOfDigits = MaxNumberOfDigits,
}) =>
  simpleInputValidation(
    strings.validationErrorText,
    buildDigitsRegex(minNumberOfDigits, maxNumberOfDigits),
    strings.informationRequiredText,
  );
