import React, { useEffect } from "react";
import { type OnSwitchMemberNameType } from "@msidentity/SISU/components/username-collection/username-collection-types";
import { type ViewId } from "@msidentity/SISU/constants";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { FormattedTextWithBindings } from "@msidentity/sisu/utilities/formatted-text-with-bindings";
import {
  appendOrReplaceQueryStringParams,
  replaceTokens,
} from "@msidentity/SISU/utilities/strings-helper";
import { ErrorCode } from "../../../utilities/api-helpers/create-account/create-account-interface";
import SignUpConfig from "../signup-config";
import { useSignUpContext } from "../signup-context";
import { SignUpActionType } from "../signup-reducer";
import { type CreateAccountErrorStrings } from "../signup-types";
import { useUsernameCollectionErrorBindings } from "../views/username-collection/hooks/use-username-collection-error-bindings";

export const memberNameTakenError = (
  errorCode: string,
  errorStrings: CreateAccountErrorStrings,
  memberName: string,
  memberNameType: MemberNameType,
  loginUrl: string,
): string => {
  const { showSignInLinkInUsernameError } = SignUpConfig.instance;
  const { usernameTakenNoSuggestions } = errorStrings;
  let memberNameTakenEasi = "";

  if (showSignInLinkInUsernameError) {
    const signinUrl = appendOrReplaceQueryStringParams(loginUrl, { memberName });
    memberNameTakenEasi = replaceTokens(errorStrings.usernameTakenEasi, memberName, signinUrl);
  } else {
    memberNameTakenEasi = replaceTokens(errorStrings.usernameTakenEasi, memberName);
  }

  switch (errorCode) {
    case ErrorCode.MembernameTaken:
      return memberNameType === MemberNameType.Phone
        ? errorStrings.usernameTakenPhone
        : usernameTakenNoSuggestions;
    case ErrorCode.MembernameTakenEasi:
      return memberNameTakenEasi;
    case ErrorCode.MembernameTakenPhone:
      return errorStrings.usernameTakenPhone;
    default:
      return "";
  }
};

/**
 * Hook to get the error strings for a Create Account error code
 * @param errorStrings - Create account error strings
 * @param setCurrentInputType - Function to set the username view input type to Outlook in the domain exists error string (Optional)
 * @returns A function for getting the error strings for a Create Account error code
 */
export const useGetCreateAccountErrorMessage = (
  errorStrings: CreateAccountErrorStrings,
  setCurrentInputType: OnSwitchMemberNameType = () => {},
) => {
  const {
    viewState: { loginUrl, memberName, memberNameType },
  } = useSignUpContext();
  const getEmbeddedBindings = useUsernameCollectionErrorBindings(setCurrentInputType, memberName);
  const usernameErrorWithBindings = (errorMessage: string) => {
    const bindings = getEmbeddedBindings();
    return <FormattedTextWithBindings text={errorMessage} embeddedBindings={{ ...bindings }} />;
  };

  const getCreateAccountError = (errorCode: string): string | JSX.Element => {
    switch (errorCode) {
      case ErrorCode.OneTimeCodeInvalid:
        return errorStrings.oneTimeCodeInvalid;
      case ErrorCode.MembernameTaken:
      case ErrorCode.MembernameTakenEasi:
      case ErrorCode.MembernameTakenPhone:
        return usernameErrorWithBindings(
          memberNameTakenError(errorCode, errorStrings, memberName, memberNameType, loginUrl),
        );
      case ErrorCode.DomainNotAllowed:
        return errorStrings.domainNotAllowed;
      case ErrorCode.ForbiddenWord:
        return errorStrings.forbiddenWord;
      case ErrorCode.PasswordIncorrect:
        return errorStrings.passwordIncorrect;
      case ErrorCode.PasswordRequired:
        return errorStrings.passwordRequired;
      case ErrorCode.InvalidEmailFormat:
        return errorStrings.invalidEmailFormat;
      case ErrorCode.EmailMustStartWithLetter:
        return errorStrings.emailMustStartWithLetter;
      case ErrorCode.ProofAlreadyExistsError:
        return errorStrings.proofAlreadyExists;
      case ErrorCode.InvalidPhoneFormat:
        return errorStrings.invalidPhoneFormat;
      case ErrorCode.InvalidBirthDate:
        return errorStrings.invalidBirthDate;
      case ErrorCode.InvalidFirstName:
        return errorStrings.invalidFirstName;
      case ErrorCode.InvalidLastName:
        return errorStrings.invalidLastName;
      case ErrorCode.MaximumOTTDailyError:
        return errorStrings.verificationThrottled;
      case ErrorCode.BannedPassword:
        return errorStrings.bannedPassword;
      case ErrorCode.DomainExistsInAad: {
        return usernameErrorWithBindings(errorStrings.domainExistsInAad);
      }

      case ErrorCode.DomainExistsInAadSupportedLogin:
        return replaceTokens(errorStrings.domainExistsInAadSupportedLogin, memberName);
      default:
        return "";
    }
  };

  return getCreateAccountError;
};

export interface UseCreateAccountErrorParams {
  errorStrings: CreateAccountErrorStrings;
  viewId: ViewId;
  setErrorMessage: (error: string | JSX.Element) => void;
  setShowError?: (hasError: boolean) => void;
  setFocus?: (hasFocus: boolean) => void;
  elementRef?: React.RefObject<HTMLInputElement>;
  setCurrentUsernameInputType?: OnSwitchMemberNameType;
}

/**
 * This hook is used to determine if a Create Account error was provided from a request made on a different view and hasn't been displayed to the user yet.
 * It sets the error message and focus on an input and updates SignUpContext to indicate that the error has been shown so it does not get displayed again on a different view.
 * @param params
 * - errorStrings - Create account error strings
 * - viewId - The view id to show the error on
 * - setErrorMessage - Function to set the error message
 * - setShowError - Function to set whether the error should be shown (Optional)
 * - setFocus - Function to set whether the input should be focused (Optional)
 * - elementRef - The input element reference to focus on (Optional)
 * - setCurrentUsernameInputType - Function to set the username view input type to Outlook in the domain exists error string (Optional)
 * @returns An indicator that the error message should be shown to the user in the consuming view
 */
export const useCreateAccountError = (params: UseCreateAccountErrorParams) => {
  const {
    viewState: { createAccountErrorShown, createAccountErrorCode, createAccountErrorViewId },
    dispatchStateChange,
  } = useSignUpContext();
  const {
    errorStrings,
    viewId,
    setErrorMessage,
    setShowError = () => {},
    setFocus = () => {},
    elementRef,
    setCurrentUsernameInputType = () => {},
  } = params;
  const getError = useGetCreateAccountErrorMessage(errorStrings, setCurrentUsernameInputType);
  const errorText = getError(createAccountErrorCode);

  const viewShouldShowError =
    !!errorText && !createAccountErrorShown && viewId === createAccountErrorViewId;

  useEffect(() => {
    if (viewShouldShowError) {
      dispatchStateChange({
        actionType: SignUpActionType.SetCreateAccountErrorShown,
        payload: true,
      });
      setErrorMessage(errorText);
      setShowError(true);
      setFocus(true);
      elementRef?.current?.focus();
    }
  }, [
    viewShouldShowError,
    errorText,
    dispatchStateChange,
    setErrorMessage,
    setFocus,
    setShowError,
    elementRef,
  ]);

  return viewShouldShowError;
};
