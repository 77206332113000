export enum PasswordValidationResult {
  Ok = "Ok",
  Empty = "Empty",
  TooShort = "TooShort",
  InvalidCharacters = "InvalidCharacters",
  NotComplex = "NotComplex",
  NotStrong = "NotStrong",
  ContainsMemberName = "ContainsMemberName",
}

const MIN_COMPLEXITY = 2;
const MIN_COMPLEXITY_STRONG = 3;
const MIN_LENGTH = 8;
const VALID_CHAR_REGEX = /^[\x20-\x7E]+$/;
const CHARSETS = [
  /[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+/,
  /[\x30-\x39]+/,
  /[\x41-\x5A]+/,
  /[\x61-\x7A]+/,
];

export type ValidationErrorMessages = Partial<Record<PasswordValidationResult, string>>;

/**
 * Validates password complexity
 * @param password password to validate
 * @param requireStrong if true, password must contain at least one character from each character set
 * @param memberName if provided, checks if password contains member name
 * @returns error message if password is not valid, empty string otherwise
 */
export const validatePassword = (
  password: string,
  requireStrong: boolean = false,
  memberName: string = "",
): PasswordValidationResult => {
  if (password === "") {
    return PasswordValidationResult.Empty;
  }

  if (password.length < MIN_LENGTH) {
    return PasswordValidationResult.TooShort;
  }

  if (!password.match(VALID_CHAR_REGEX)) {
    return PasswordValidationResult.InvalidCharacters;
  }

  if (memberName && password.toLowerCase().indexOf(memberName.toLowerCase().split("@")[0]) !== -1) {
    return PasswordValidationResult.ContainsMemberName;
  }

  let characterClassCount = 0;
  for (let i = 0; i < CHARSETS.length; i += 1) {
    if (password.match(CHARSETS[i])) {
      characterClassCount += 1;
    }

    if (
      characterClassCount >= MIN_COMPLEXITY &&
      (!requireStrong || characterClassCount >= MIN_COMPLEXITY_STRONG)
    ) {
      break;
    }
  }

  if (characterClassCount < MIN_COMPLEXITY) {
    return PasswordValidationResult.NotComplex;
  }

  if (requireStrong) {
    if (characterClassCount < MIN_COMPLEXITY_STRONG) {
      return PasswordValidationResult.NotStrong;
    }
  }

  return PasswordValidationResult.Ok;
};
