import { useCallback, useState } from "react";
import GlobalConfig from "../../../../global-config";
import { useGlobalContext } from "../../../../global-context";
import { useEffectAfterInitialRender } from "../../../../hooks/use-effect-after-initial-render";
import { type MemberNameType } from "../../../../model/user";
import { useTelemetry } from "../../../../telemetry-helpers/use-telemetry";
import { UserActionName } from "../../../../telemetry-helpers/user-action-name";
import { type OnSwitchMemberNameType } from "../../username-collection-types";

export type UseUsernameStateParams = {
  inputTypeState: {
    currentInputType: MemberNameType;
    setCurrentInputType: OnSwitchMemberNameType;
  };
  /* The state for the EASI input component */
  easiState?: {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    setExternalError: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  };
  /* The state for the Outlook input component */
  outlookState?: {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    domain: string;
    defaultDomain: string;
    setDomain: React.Dispatch<React.SetStateAction<string>>;
    setExternalError: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  };
  /* The state for the Phone input component */
  phoneNumberState?: {
    setValue: React.Dispatch<React.SetStateAction<string | number | readonly string[]>>;
    setServerError: (error: string | JSX.Element) => void;
  };
};

export type UsernameState = {
  currentInputType: MemberNameType;
  setCurrentInputType: OnSwitchMemberNameType;
  setShouldClearInputOnSwitch: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useUsernameStateFabric = (options: UseUsernameStateParams): UsernameState => {
  const defaultCallback = useCallback(() => {}, []);
  const { activeFlavor, telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeView, activeFlow, userFlowType },
  } = useGlobalContext();
  const { logUserAction } = useTelemetry(telemetry, {
    activeView,
    activeFlow,
    activeFlavor,
  });

  const {
    inputTypeState: { currentInputType, setCurrentInputType },
    easiState: {
      setValue: setEasiInputValue = defaultCallback,
      setExternalError: setEasiExternalError = defaultCallback,
    } = {},
    outlookState: {
      defaultDomain = "",
      setValue: setOutlookInputValue = defaultCallback,
      setDomain: setOutlookDomain = defaultCallback,
      setExternalError: setOutlookExternalError = defaultCallback,
    } = {},
    phoneNumberState: {
      setValue: setPhoneNumberValue = defaultCallback,
      setServerError: setPhoneNumberExternalError = defaultCallback,
    } = {},
  } = options;

  const onSetCurrentInputType = (value: MemberNameType) => {
    setCurrentInputType(value);
    logUserAction({
      actionName: UserActionName.UsernameCollectionSwitchInputTypeClicked,
      actionValue: { inputType: value, userFlowType },
    });
  };

  const [shouldClearInputOnSwitch, setShouldClearInputOnSwitch] = useState(true);

  useEffectAfterInitialRender(() => {
    // Instead of checking the different input types, we can just clear all values
    // This is so that if the user switches back and forth between input types, the input is empty
    if (shouldClearInputOnSwitch) {
      setEasiInputValue("");
      setOutlookInputValue("");
      setOutlookDomain(defaultDomain);
      setPhoneNumberValue("");
    } else {
      // The shouldClearInputOnSwitch flag is used to keep showing the input when switching between input
      // types in certain scenarios. It should only prevent clearing input once, so reset it afterwards.
      setShouldClearInputOnSwitch(true);
    }

    // Clear any server errors that may have been set
    setEasiExternalError("");
    setOutlookExternalError("");
    setPhoneNumberExternalError("");
  }, [
    currentInputType,
    setEasiExternalError,
    setEasiInputValue,
    setOutlookDomain,
    setOutlookExternalError,
    setOutlookInputValue,
    setPhoneNumberExternalError,
    setPhoneNumberValue,
  ]);

  return {
    currentInputType,
    setCurrentInputType: onSetCurrentInputType,
    setShouldClearInputOnSwitch,
  };
};
