import { type DataApiError, type ResponseError, type ViewId } from "@msidentity/SISU/constants";
import { MemberNameType } from "@msidentity/SISU/model/user";
import {
  type MemberName,
  type PrefillEmailDetails,
  type PrefillPhoneDetails,
} from "@msidentity/SISU/types/account-prefill-types";
import {
  type ICheckAvailableSigninNamesRequest,
  type ICheckAvailableSigninNamesResponse,
  checkAvailableSigninNames,
} from "../../../../utilities/api-helpers/check-available-signin-names/check-available-signin-names-request";
import { CheckAvailableErrorCode as ErrorCode } from "../../../../utilities/api-helpers/check-available-signin-names/check-available-signin-names-types";

export interface CheckAvailableResponseError extends ResponseError {
  responseBody?: ICheckAvailableSigninNamesResponse;
}

export type CheckAvailableSigninNamesResult = {
  isValid: boolean;
  errorResult?: CheckAvailableErrorMapResult;
  memberNameSuggestions?: string[];
  isAvailable?: boolean;
  isPossibleEvicted?: boolean;
  isProof?: boolean;
  isNoPaAllowed?: boolean;
};

export type CheckAvailableSigninNamesResponseParams = {
  memberNamePrefill?: Partial<MemberName>;
  usernameType: MemberNameType;
};

export type CheckAvailableErrorMapResult = string | ViewId;

const cache: Map<string, CheckAvailableSigninNamesResult> = new Map();

/**
 *
 * @param response The response from the CheckAvailableSignInNames endpoint
 * @param username The username
 * @param options Config properties needed to build the result
 * @param getCheckAvailableError Method to get the error message from the error code
 * @returns Result object that contains the error message to show and member name suggestions if available
 */
export const buildCheckAvailableSigninNamesError = (
  response: CheckAvailableResponseError,
  username: string,
  options: CheckAvailableSigninNamesResponseParams,
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult,
): CheckAvailableSigninNamesResult => {
  const result: CheckAvailableSigninNamesResult = {
    isValid: false,
    errorResult: "",
  };

  const { responseBody } = response;

  if (responseBody) {
    const { suggestions } = responseBody;
    const hasSuggestions = !!(suggestions && suggestions.length > 0);
    const errorCode = String((responseBody.error as unknown as DataApiError).code);

    if (errorCode !== undefined) {
      const errorResult = getCheckAvailableError(
        errorCode,
        username,
        options.usernameType,
        hasSuggestions,
      );
      result.errorResult = errorResult;
    }

    if (hasSuggestions) {
      result.memberNameSuggestions = suggestions;
    }
  }

  // If we couldn't get an error message from the response, do not block the user as CheckAvailableSignInNames is a best effort call. Choose the next view instead.
  if (!result.errorResult) {
    return {
      isValid: true,
      isAvailable: true,
    };
  }

  cache.set(username, result);
  return result;
};

/**
 *
 * @param response Response from the CheckAvailableSignInNames endpoint
 * @param username The username
 * @param getCheckAvailableError Method to get the error message from the error code
 * @returns Result object that indicates whether the username is available or not, the error to show
 * or the next view to switch to
 */
export const buildCheckAvailableSigninNamesResult = (
  response: ICheckAvailableSigninNamesResponse,
  username: string,
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult,
): CheckAvailableSigninNamesResult => {
  const { isAvailable, isProof, nopaAllowed, suggestions, type } = response;
  const hasSuggestions = !!(suggestions && suggestions.length > 0);
  const isPossibleEvicted = response.possibleEviction;

  if (!isAvailable) {
    const notAvailableResult = {
      isValid: false,
      isAvailable: false,
      memberNameSuggestions: suggestions,
      errorResult:
        type === MemberNameType.Live
          ? getCheckAvailableError(ErrorCode.memberNameTaken, username, type, hasSuggestions)
          : getCheckAvailableError(
              ErrorCode.memberNameTakenEasi,
              username,
              type || "",
              hasSuggestions,
            ),
    };

    cache.set(username, notAvailableResult);

    return notAvailableResult;
  }

  const result: CheckAvailableSigninNamesResult = {
    isValid: true,
    isAvailable,
    isPossibleEvicted,
    isProof,
    isNoPaAllowed: nopaAllowed,
    memberNameSuggestions: suggestions,
  };

  cache.set(username, result);

  return result;
};

/**
 * Function to build the CheckAvailableSigninNames result from the prefill membername object
 * @param username The username that we need to build the result for
 * @param options Config properties needed to build the result
 * @param getCheckAvailableError Method to get the error message or error view from the error code
 * @returns The CheckAvailableSigninNames result if the username was in the prefill object. Otherwise undefined.
 */
export const buildPrefillResult = (
  username: string,
  options: CheckAvailableSigninNamesResponseParams,
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult,
): CheckAvailableSigninNamesResult | undefined => {
  let result;
  const { usernameType, memberNamePrefill = {} } = options;

  let prefillList: PrefillEmailDetails[] | PrefillPhoneDetails[] = [];
  if (usernameType === MemberNameType.Live && memberNamePrefill.oLive) {
    prefillList = memberNamePrefill.oLive;
  } else if (usernameType === MemberNameType.EASI && memberNamePrefill.oEasi) {
    prefillList = memberNamePrefill.oEasi;
  } else if (usernameType === MemberNameType.Phone && memberNamePrefill.oPhone) {
    prefillList = memberNamePrefill.oPhone;
  }

  if (prefillList.length > 0) {
    for (let index = 0; index < prefillList.length; index += 1) {
      const prefill = prefillList[index];

      // If the username is in the prefill membername object, return the result
      if (
        ("sPhoneNumber" in prefill &&
          "sCountryCode" in prefill &&
          `+${prefill.sCountryCode}${prefill.sPhoneNumber}` === username) ||
        ("sEmail" in prefill && prefill.sEmail === username)
      ) {
        if (prefill.fIsAvailable) {
          const isPossibleEvicted = "fIsPossibleEviction" in prefill && prefill.fIsPossibleEviction;
          const isNoPaAllowed = isPossibleEvicted ? prefill.fIsNopaAllowed : false;

          result = {
            isValid: true,
            isAvailable: true,
            isPossibleEvicted,
            isProof: prefill.fIsProof,
            isNoPaAllowed,
          };

          cache.set(username, result);
        } else if (prefill.sErrorCode) {
          const errorResult = getCheckAvailableError(
            prefill.sErrorCode,
            username,
            usernameType,
            false,
          );

          result = {
            isValid: false,
            errorResult,
          };

          cache.set(username, result);
        }

        break;
      }
    }
  }

  return result;
};

/**
 * Main function that will call the CheckAvailableSignInNames endpoint, parse the response, return result, and check for cached response
 * @param url The url for the CheckAvailableSignInNames endpoint
 * @param requestParams The request parameters for the CheckAvailableSignInNames endpoint
 * @param getCheckAvailableError Method to get the error message or error view given an error code
 * @param options Params needed to build the result from the response
 * @returns Result object with properties such as isValid and errorMessage
 */
export const getCheckAvailableSigninNamesResult = async (
  url: string,
  requestParams: ICheckAvailableSigninNamesRequest,
  getCheckAvailableError: (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => CheckAvailableErrorMapResult,
  options: CheckAvailableSigninNamesResponseParams,
): Promise<Partial<CheckAvailableSigninNamesResult>> => {
  const { signInName } = requestParams;

  // If we already made the call for this username, return the cached result
  const cachedResult = cache.get(signInName);
  if (cachedResult) {
    return cachedResult;
  }

  // If the username is in the prefill membername object, check if the CheckAvailableSignInNames
  // call was already made on the server side and return the result
  if (options.memberNamePrefill?.fCheckAvailDone) {
    const prefillResult = buildPrefillResult(signInName, options, getCheckAvailableError);

    if (prefillResult) {
      return prefillResult;
    }
  }

  return checkAvailableSigninNames(url, requestParams)
    .then((response: ICheckAvailableSigninNamesResponse) =>
      buildCheckAvailableSigninNamesResult(response, signInName, getCheckAvailableError),
    )
    .catch((err: CheckAvailableResponseError) =>
      buildCheckAvailableSigninNamesError(err, signInName, options, getCheckAvailableError),
    );
};
