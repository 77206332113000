import type React from "react";
import {
  type InputErrorState,
  type InputState,
  useInput,
} from "@msidentity/SISU/components/inputs/hooks/use-input";
import { type BaseInputWithErrorProps } from "@msidentity/SISU/components/inputs/input/input-types";
import { UserFlowType } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { useMarketingOptIn } from "../../../../hooks/use-marketing-optin";
import SignUpConfig from "../../../../signup-config";
import { useSignUpContext } from "../../../../signup-context";
import { usePasswordCollection } from "../../hooks/use-password-collection";
import {
  type PasswordCollectionFormState,
  useCheckCapsLock,
  usePasswordCollectionValidation,
} from "../../hooks/use-password-collection-form";
import { usePasswordCollectionOnChange } from "../../hooks/use-password-collection-onchange";
import { usePasswordCollectionSubmit } from "../../hooks/use-password-collection-submit";
import { type PasswordCollectionStringsFabric } from "../password-collection-fabric-types";

type InputProps = InputState &
  InputErrorState &
  Partial<BaseInputWithErrorProps> & {
    onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  };

export type PasswordCollectionFormStateFabric = PasswordCollectionFormState & {
  showLegalContent: boolean;
  showCapsLockWarning: boolean;
  checkCapsLock: React.KeyboardEventHandler<HTMLInputElement>;
  showSmsDisclaimer: boolean;
  marketingOptIn: {
    show: boolean;
    checked: boolean;
    onChange: () => void;
  };
  inputProps: InputProps;
};

/**
 * An aggregate hook that creates the validationMethod, inputState, and defines the onChange handler for the password collection input on Fabric.
 * This is intended to be used in combination with the usePasswordCollection hook to also create the onSubmit handler.
 * @param strings The PasswordCollectionStringsFabric for the view
 * @returns The InputState for the password collection input on Fabric, including the appropriate validation and onChange handlers
 */
export const usePasswordCollectionInputFabric = (strings: PasswordCollectionStringsFabric) => {
  const {
    viewState: { password },
  } = useSignUpContext();
  const validationMethod = usePasswordCollectionValidation(
    strings.passwordCollectionViewStrings,
    strings.passwordCollectionViewStrings.errorGeneric,
  );

  const inputState = useInput({
    hasInitialFocus: true,
    validationMethod,
    initialValue: password,
  });

  inputState.onChange = usePasswordCollectionOnChange(inputState);

  return inputState;
};

/**
 * This aggregate hook creates the properties required to render the password collection view on Fabric.
 * This includes the onSubmit handler and the passwordState for the password input.
 * @param strings The PasswordCollectionStringsFabric for the view
 * @returns The properties required to render the password collection view for Fabric
 */
export const usePasswordCollectionFabric = (
  strings: PasswordCollectionStringsFabric,
): PasswordCollectionFormStateFabric => {
  const { collectBirthDateCountry, collectFirstNameLastName, showOptinEmail } =
    SignUpConfig.instance;
  const {
    viewState: { memberNameType },
  } = useSignUpContext();
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  const onSubmit = usePasswordCollectionSubmit(collectFirstNameLastName, collectBirthDateCountry);

  const { checkCapsLock, isCapsLockOn, suppressCapsLockWarning } = useCheckCapsLock();
  const { isOptInEmail, toggleIsOptInEmail } = useMarketingOptIn();

  const inputState = usePasswordCollectionInputFabric(strings);
  const baseProps = usePasswordCollection({
    strings,
    onSubmit,
    inputState,
    applyParentStrings: isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent,
  });

  return {
    ...baseProps,
    showCapsLockWarning: !suppressCapsLockWarning && isCapsLockOn,
    checkCapsLock,
    marketingOptIn: {
      show: showOptinEmail && memberNameType === MemberNameType.Live,
      checked: isOptInEmail,
      onChange: toggleIsOptInEmail,
    },
    showLegalContent: memberNameType === MemberNameType.Live,
    showSmsDisclaimer: memberNameType === MemberNameType.Phone,
    inputProps: {
      ...inputState,
      ...inputState.error,
      onKeyUp: checkCapsLock,
      maxLength: 127, // Max length of password field on AccountUX
    },
  };
};
