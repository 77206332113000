// Create Account API error codes
export const ErrorCode = {
  HipValidationError: "1043",
  HipNeeded: "1040",
  HipEnforcementNeeded: "1041",
  HipSMSNeeded: "1042",
  DailyLimitIDsReached: "450",
  OneTimeCodeInvalid: "1304",
  VerificationSltInvalid: "1324",
  MembernameTaken: "1058",
  DomainNotAllowed: "1117",
  DomainIsReserved: "1181",
  ForbiddenWord: "403",
  PasswordIncorrect: "1002",
  PasswordConflict: "1009",
  InvalidEmailFormat: "1062",
  InvalidPhoneFormat: "1063",
  PhoneSignupBlocked: "1066",
  InvalidBirthDate: "1039",
  InvalidGender: "1243",
  InvalidFirstName: "1240",
  InvalidLastName: "1241",
  MaximumOTTDailyError: "1204",
  BannedPassword: "1217",
  ProofAlreadyExistsError: "1246",
  DomainExistsInAad: "1184",
  DomainExistsInAadSupportedLogin: "1185",
  MembernameTakenEasi: "1242",
  MembernameTakenPhone: "1052",
  SignupBlocked: "1220",
  InvalidMemberNameFormat: "1064",
  PasswordRequired: "1330",
  EmailMustStartWithLetter: "1256",
  EvictionWarningRequired: "1334",
  HipCaptchaNeededOnSendOTT: "1339",
  HipEnforcementNeededOnSendOTT: "1340",
  FraudBlocked: "1346",
  SignupRiskBlock: "1347",
  NotAllowedToAddMember: "Family.NotAllowedToAddMember",
  NotAllowedToInviteMember: "Family.NotAllowedToInviteMember",
  FamilyInvitationAlreadyExists: "Family.InvitationAlreadyExists",
  MemberAlreadyInFamily: "Family.MemberAlreadyInFamily",
  CannotInviteExistingMember: "Family.CannotInviteExistingMember",
  FamilySizeExceedLimit: "Family.FamilySizeExceedLimit",
  MemberExistsInOtherFamily: "Family.MemberExistsInOtherFamily",
  FirstMemberAdminMissingAccountInfo: "Family.FirstMemberAdminMissingAccountInfo",
  UnknownFamily: "Family.Unknown",
};
