import { type AccessibleImageProps } from "@msidentity/SISU/components/accessible-image";
import { type SubmitTask } from "@msidentity/SISU/components/inputs/hooks/use-form-submission";
import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import {
  type INameCollectionSubmitProps,
  useNameCollectionForm,
} from "@msidentity/SISU/components/name-collection/hooks/use-name-collection-form";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateView, useBackButtonControl, useDocumentTitle } from "@msidentity/SISU/hooks";
import { addUser } from "@msidentity/SISU/utilities/image-helpers/accessible-images";
import { ErrorCode } from "../../../../../utilities/api-helpers/create-account/create-account-interface";
import { useCreateAccountError } from "../../../hooks/use-create-account-error";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { type CreateAccountErrorStrings } from "../../../signup-types";

export type UseSignupNameCollectionParams = {
  strings: {
    signupNameCollectionStrings: {
      title: string;
      titleParentFlow: string;
      description: string;
      descriptionParentFlow: string;
    };
    createAccountErrorStrings: CreateAccountErrorStrings;
  };
  config: {
    showBackButtonOnActiveView?: boolean;
    submitTask: SubmitTask<INameCollectionSubmitProps>;
    applyParentStrings: boolean;
  };
  state: {
    firstNameInputState: InputState;
    lastNameInputState: InputState;
  };
};

export type UseSignupNameCollectionResponse = {
  onSubmit: React.FormEventHandler;
  pageTitle: string;
  pageDescription: string;
  pageImage: AccessibleImageProps | undefined;
};

/**
 * Common hook for the Signup Name Collection View
 * @param props Props object for this hook
 * @param props.strings.signupNameCollectionStrings The flavored strings to be used in the view
 * @param props.strings.createAccountErrorStrings The flavored error strings for the CreateAccount API
 * @param props.config.showBackButtonOnActiveView Whether to show the back button on the active view
 * @param props.config.submitTask The task to execute when the form is submitted if there are no validation errors
 * @param props.config.applyParentStrings Whether to use the parent strings
 * @param props.state.firstNameInputState The state of the first name input
 * @param props.state.lastNameInputState The state of the last name input
 * @returns Common properties for the Signup Name Collection View
 */
export const useSignupNameCollection = (
  props: UseSignupNameCollectionParams,
): UseSignupNameCollectionResponse => {
  const { strings, config, state } = props;
  const { firstNameInputState, lastNameInputState } = state;
  const { signupNameCollectionStrings: viewStrings, createAccountErrorStrings: errorStrings } =
    strings;
  const { isFirstLastOrder, skipCredentialsPage } = SignUpConfig.instance;
  const { viewState } = useSignUpContext();

  const canGoBack = useBackButtonControl();
  const showBackButtonOnActiveView =
    config.showBackButtonOnActiveView && (skipCredentialsPage ? canGoBack : true);

  let pageTitle = viewStrings.title;
  let pageDescription = viewStrings.description;
  let pageImage: AccessibleImageProps | undefined;
  if (config.applyParentStrings) {
    pageTitle = viewStrings.titleParentFlow;
    pageDescription = viewStrings.descriptionParentFlow;
    pageImage = { urls: addUser, dataTestId: "addUser", role: "presentation" };
  }

  useActivateView(ViewId.SignupNameCollection, FlowId.Signup, {
    showBackButtonOnActiveView,
  });
  useDocumentTitle(pageTitle);

  const { onSubmit } = useNameCollectionForm({
    config: {
      isFirstLastOrder,
      viewID: ViewId.SignupNameCollection,
    },
    form: {
      submitTask: config.submitTask,
    },
    state,
  });

  const inputState =
    viewState.createAccountErrorCode === ErrorCode.InvalidFirstName
      ? firstNameInputState
      : lastNameInputState;

  useCreateAccountError({
    errorStrings,
    viewId: ViewId.SignupNameCollection,
    setErrorMessage: inputState.error.setServerError,
    setShowError: inputState.error.setShowErrorMessage,
    setFocus: inputState.setFocus,
    elementRef: inputState.elementRef,
  });

  return {
    onSubmit,
    pageTitle,
    pageDescription,
    pageImage,
  };
};
