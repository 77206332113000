import { useRef } from "react";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateViewWithDocumentTitle } from "@msidentity/SISU/hooks";
import { useRiskApiBlocked } from "../../hooks/use-risk-api-blocked";
import { type RiskApiBlockedViewStringsFabric } from "../risk-api-blocked-view-fabric-types";

export type UseRiskApiBlockedFabricParams = {
  viewStrings: RiskApiBlockedViewStringsFabric;
};

export type UseRiskApiBlockedFabricResponse = {
  learnMoreDivRef?: React.RefObject<HTMLDivElement>;
  learnMoreUrl: string;
  okButtonClickHandler: () => void;
  showLearnMoreLink: boolean;
  showOkButton: boolean;
};

/**
 * Aggregate-hook for the Risk Api Blocked View Fabric
 * @param params Parameters for this hook
 * @param params.viewStrings The flavored strings to be used in the view
 * @returns Properties for the Risk Api Blocked View Fabric
 */
export const useRiskApiBlockedFabric = (
  params: UseRiskApiBlockedFabricParams,
): UseRiskApiBlockedFabricResponse => {
  const {
    viewStrings: { title },
  } = params;

  const {
    showLearnMoreLink,
    showButton: showOkButton,
    buttonClickHandler: okButtonClickHandler,
    learnMoreUrl,
  } = useRiskApiBlocked();

  const learnMoreDivRef = useRef<HTMLDivElement>(null);
  useActivateViewWithDocumentTitle(title, ViewId.SignUpRiskApiBlocked, FlowId.Signup, {
    focusEleRef: showLearnMoreLink && !showOkButton ? learnMoreDivRef.current : undefined,
    showBackButtonOnActiveView: false,
    showIdentityBanner: true,
  });

  return {
    learnMoreDivRef,
    learnMoreUrl,
    okButtonClickHandler,
    showLearnMoreLink,
    showOkButton,
  };
};
