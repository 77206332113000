import React from "react";
import StylesConfig from "../../../config/styles-config";
import { MemberNameType } from "../../../model/user";
import { type IEmailInputProps, EmailInput } from "../../inputs/email-input";
import {
  type PhoneNumberFabricProps,
  PhoneNumberFabric,
} from "../../inputs/phone-number/fabric/phone-number-fabric";
import { UsernameCollection } from "../username-collection";
import { type OnSwitchMemberNameType } from "../username-collection-types";
import { useUsernameCollectionSwitchLinksFabric } from "./hooks/use-username-collection-switch-links-fabric";

export type UsernameCollectionFabricProps = {
  currentInputType: MemberNameType;
  setCurrentInputType: OnSwitchMemberNameType;
  outlookInputProps?: IEmailInputProps;
  easiInputProps?: IEmailInputProps;
  phoneInputProps?: PhoneNumberFabricProps;
  outlookSwitchLinkText?: string;
  easiSwitchLinkText?: string;
  phoneSwitchLinkText?: string;
};

/**
 * Renders the Fabric username collection component.
 * @param props The component props.
 * @returns The Fabric flavored component for username collection.
 */
export const UsernameCollectionFabric: React.FC<UsernameCollectionFabricProps> =
  function UsernameCollectionFabric(props) {
    const {
      setCurrentInputType,
      currentInputType,
      outlookInputProps,
      easiInputProps,
      phoneInputProps,
      outlookSwitchLinkText,
      easiSwitchLinkText,
      phoneSwitchLinkText,
    } = props;

    const { useCommonStyles, useSecondaryContentStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const secondaryContentStyles = useSecondaryContentStyles();
    const switchLinksCustomCss = secondaryContentStyles.smallFontSize;

    const showOutlookInput = currentInputType === MemberNameType.Live;
    const showEasiInput = currentInputType === MemberNameType.EASI;
    const showPhoneInput = currentInputType === MemberNameType.Phone;

    // Create the inputs that should exist based on the initial input type and switch links provided
    const createOutlookInput = (showOutlookInput || outlookSwitchLinkText) && outlookInputProps;
    const createEasiInput = (showEasiInput || easiSwitchLinkText) && easiInputProps;
    const createPhoneInput = (showPhoneInput || phoneSwitchLinkText) && phoneInputProps;

    const outlookInputComponent = createOutlookInput ? (
      <EmailInput {...outlookInputProps} />
    ) : undefined;
    const easiInputComponent = createEasiInput ? <EmailInput {...easiInputProps} /> : undefined;
    const phoneInputComponent = createPhoneInput ? (
      <PhoneNumberFabric {...phoneInputProps} />
    ) : undefined;

    const switchLinkContainer = ({ children }: { children: JSX.Element }) => (
      <div className={commonStyles.row}>
        <div className={commonStyles.formGroup}>{children}</div>
      </div>
    );

    const { switchLinksComponent } = useUsernameCollectionSwitchLinksFabric(
      setCurrentInputType,
      showEasiInput,
      showOutlookInput,
      showPhoneInput,
      outlookSwitchLinkText,
      easiSwitchLinkText,
      phoneSwitchLinkText,
      switchLinksCustomCss,
      switchLinkContainer,
    );

    const baseComponentProps = {
      ...props,
      outlookInputComponent,
      easiInputComponent,
      phoneInputComponent,
      switchLinksComponent,
    };

    return <UsernameCollection {...baseComponentProps} />;
  };
