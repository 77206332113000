import { makeStyles } from "@griffel/react";

export const useBirthDateStyles = makeStyles({
  formField: {
    width: "33.33333%",
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
    float: "left",
    ":global([dir='rtl'])": {
      float: "right",
    },
  },
  rowAfter: {
    "::after": {
      content: '""',
      display: "table",
      clear: "both",
    },
  },
});
