import { commonSignUpStringsFabric } from "../../../fabric/common-signup-strings-fabric";
import { type PasswordCollectionViewStringsFabric } from "./password-collection-fabric-types";

export const passwordCollectionViewStringsFabric: PasswordCollectionViewStringsFabric = {
  capsLockWarning: getLocalString("Warning_CapsLock"),
  description: getLocalString("AddPassword_Description"),
  passwordPlaceholder: getLocalString("CreatePassword"),
  showPasswordLabel: getLocalString("Password_ShowPasswordLabel"),
  title: getLocalString("CreateAPassword"),
  errorContainsMemberNameEmail: getLocalString("Password_Alias_Error"),
  errorContainsMemberNamePhoneNumber: getLocalString("Error_PasswordContainsPhoneNumber"),
  errorGeneric: getLocalString("Generic_Service_Error"),
  errorInvalidCharacters: getLocalString("Password_Not_Allowed_Error"),
  errorNotComplexEnough: getLocalString("Help_password_short"),
  errorPasswordRequired: getLocalString("Error_PasswordRequired"),
  errorPasswordShort: getLocalString("Help_password_short"),
  smsDisclaimer: getLocalString("Signup_SMSDisclaimer"),
  titleParentFlow: getLocalString("Signup_PasswordChild_PageTitle"),
  descriptionParentFlow: getLocalString("Signup_PasswordChild_Description"),
  primaryButtonText: commonSignUpStringsFabric.nextButton,
};
