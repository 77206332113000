import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import {
  type IFormSubmissionProps,
  useActivateView,
  useBackButtonControl,
  useDocumentTitle,
} from "@msidentity/SISU/hooks";
import { useCreateAccountError } from "../../../hooks/use-create-account-error";
import { type CreateAccountErrorStrings } from "../../../signup-types";
import { type PasswordCollectionViewBaseStrings } from "../password-collection-view-interface";
import {
  type PasswordCollectionFormState,
  usePasswordCollectionForm,
} from "./use-password-collection-form";

export type PasswordCollectionStrings = {
  passwordCollectionViewStrings: PasswordCollectionViewBaseStrings;
  createAccountErrorStrings: CreateAccountErrorStrings;
};

export type PasswordCollectionHookParams = {
  strings: PasswordCollectionStrings;
  onSubmit: (formSubmissionProps: IFormSubmissionProps) => Promise<void>;
  inputState: InputState;
  applyParentStrings: boolean;
};

export type PasswordCollectionViewProperties = {
  description: string;
  title: string;
};

/**
 * This hook aggregates all the properties that are required to render the password view. It should be flavor independent, so that different implementations of the view can re-use the same hook.
 * @param params Object containing the parameters required by this hook
 * @param params.strings Flavored strings that are used by this hook
 * @param params.strings.passwordCollectionViewStrings Strings that are specific to the password collection view
 * @param params.strings.createAccountErrorStrings Create account error strings
 * @param params.onSubmit Handler that is called when the form is submitted
 * @param params.inputState The input state for the Password input
 * @param params.applyParentStrings Whether to use the parent strings
 * @returns Password collection properties
 */
export const usePasswordCollection = ({
  strings: { passwordCollectionViewStrings, createAccountErrorStrings },
  onSubmit,
  inputState,
  applyParentStrings,
}: PasswordCollectionHookParams): PasswordCollectionFormState => {
  const { title, titleParentFlow } = passwordCollectionViewStrings;
  const canGoBack = useBackButtonControl();

  // Page admin
  useActivateView(ViewId.SignUpPasswordCollection, FlowId.Signup, {
    showBackButtonOnActiveView: canGoBack,
  });
  useDocumentTitle(applyParentStrings ? titleParentFlow : title);

  useCreateAccountError({
    errorStrings: createAccountErrorStrings,
    viewId: ViewId.SignUpPasswordCollection,
    setErrorMessage: inputState.error.setServerError,
    setShowError: inputState.error.setShowErrorMessage,
    setFocus: inputState.setFocus,
  });

  // Input properties and handlers
  const formProps = usePasswordCollectionForm(
    passwordCollectionViewStrings,
    onSubmit,
    inputState,
    applyParentStrings,
  );
  return formProps;
};
