import React from "react";
import { Link } from "react-router-dom";
import { ImageButtonFabric } from "@msidentity/SISU/components/image-button/fabric/image-button-fabric";
import { useCommonStylesFabric } from "@msidentity/SISU/styles/fabric";
import {
  koreaConsentCheck,
  koreaConsentUncheck,
} from "@msidentity/SISU/utilities/image-helpers/accessible-images";
import { useRequiredConsentStylesFabric } from "./required-consent-fabric.styles";
import { type RequiredConsentPropsFabric } from "./required-consent-types-fabric";

/**
 * A RequiredConsent component is meant to show a checkbox next to some agreement the user must view and agree to before continuing.
 * @param consentRequired The properties for this component to build a required consent component
 * @returns a required consent component. This contains a checkbox, text, and a link to learn more
 */
export const RequiredConsentFabric: React.FC<RequiredConsentPropsFabric> =
  function RequiredConsentFabric(consentRequired) {
    const {
      checked,
      text,
      agreementViewRoute,
      onClickCheckbox,
      onClickLearnMore,
      consentTextId,
      linkAriaDescribedBy,
    } = consentRequired;
    const fabricStyles = useCommonStylesFabric();
    const { checkbox } = useRequiredConsentStylesFabric();

    return (
      <div className={fabricStyles.textBody}>
        <ImageButtonFabric
          image={{
            urls: checked ? koreaConsentCheck : koreaConsentUncheck,
            role: "presentation",
          }}
          onClick={onClickCheckbox}
          className={checkbox}
          ariaLabelledBy={consentTextId}
          buttonId={`${consentTextId}_Checkbox`}
        />
        &nbsp;
        <span id={consentTextId}>{text}</span>
        &nbsp;
        <Link
          to={agreementViewRoute}
          onClick={onClickLearnMore}
          aria-describedby={linkAriaDescribedBy}
        >
          {getLocalString("General_LearnMore")}
        </Link>
      </div>
    );
  };
