import { type ChangeEvent } from "react";
import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import { SignupNameCollectionInputType } from "../signup-name-collection-view-interface";

export const useSignupNameOnChange = (
  inputState: InputState,
  onChangeType: SignupNameCollectionInputType,
) => {
  const { dispatchStateChange: dispatchSignupStateChange, viewState } = useSignUpContext();
  const { onChange: originalOnChange } = inputState;

  return (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue = typeof event === "string" ? event : event.target.value;

    const firstName =
      onChangeType === SignupNameCollectionInputType.FirstName
        ? String(newValue)
        : viewState.firstName;
    const lastName =
      onChangeType === SignupNameCollectionInputType.LastName
        ? String(newValue)
        : viewState.lastName;

    originalOnChange(newValue);
    dispatchSignupStateChange({
      actionType: SignUpActionType.SetFirstNameLastName,
      payload: {
        firstName,
        lastName,
      },
    });
    return newValue;
  };
};

export const useSignupFirstNameOnChange = (firstNameInputState: InputState) =>
  useSignupNameOnChange(firstNameInputState, SignupNameCollectionInputType.FirstName);
export const useSignupLastNameOnChange = (lastNameInputState: InputState) =>
  useSignupNameOnChange(lastNameInputState, SignupNameCollectionInputType.LastName);
