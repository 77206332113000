import {
  AltEmailRegEx,
  MagicOneTimeCodeRegex,
} from "../../components/inputs/input-regex-constants";
import { simpleInputValidation } from "../input-helper";
import { type VerificationCodeValidatorStrings } from "./verification-code-validator";

export type ValidationStatus = "Empty" | "Invalid" | "Valid";

export type EmailAddressValidator = (email: string) => ValidationStatus;

/**
 * Validates email address wether it is empty, invalid or valid.
 * @param email Email address to validate
 * @returns ValidationStatus error code
 */
export const emailAddressValidator: EmailAddressValidator = (email) => {
  if (!email || email.trim() === "") {
    return "Empty";
  }

  if (!email.match(AltEmailRegEx)) {
    return "Invalid";
  }

  return "Valid";
};

export type SimpleEmailAddressValidator = (params: {
  strings: VerificationCodeValidatorStrings;
}) => ReturnType<typeof simpleInputValidation>;

export type SimpleEmailProofConfirmationAddressValidator = (
  params: {
    strings: VerificationCodeValidatorStrings;
  },
  emailClearDigits?: string,
) => ReturnType<typeof simpleInputValidation>;

/**
 * Validates digit-string for min and max length requirements.
 * @param params Hook parameters
 * @param params.strings Translated user-facing strings
 * @returns Handlers for implementing verification code collection.
 */
export const emailSimpleAddressValidator: SimpleEmailAddressValidator = ({ strings }) =>
  simpleInputValidation(
    strings.validationErrorText,
    AltEmailRegEx,
    strings.informationRequiredText,
  );

/**
 * Validates email for Int - crucially allows Magic 0 number
 * IMPORTANT: ensure env is being check before using this, should not be used for Prof
 * @param params Hook parameters
 * @param params.strings Translated user-facing strings
 * @returns Handlers for implementing verification code collection.
 */
export const emailSimpleAddressValidatorForIntEnv: SimpleEmailAddressValidator = ({ strings }) =>
  simpleInputValidation(
    strings.validationErrorText,
    `${AltEmailRegEx}|${MagicOneTimeCodeRegex}`,
    strings.informationRequiredText,
  );

/**
 * Checks whether an email address has a domain in the provided list
 * @param email Email address to check
 * @param domainList List of domains to check against
 * @returns The domain that was found in the email address. If none were found, an empty string.
 */
export const emailDomainValidator = (email: string, domainList: string[]): string | null => {
  const escapedStrings = domainList.map((domain) => domain.replace(/\./g, "\\."));
  const joinedPatterns = escapedStrings.join("|");
  const regexPattern = `@(${joinedPatterns})$`;

  const match = email.match(regexPattern);
  return match ? match[0].slice(1) : null;
};

/**
 * Returns the regex for the email which starts with the provided input chars.
 * @param emailClearDigits Email clear digits
 * @returns Modified Regex for the email with the same start digits as the clear digits.
 */
export const getStartsWithEmailRegex = (emailClearDigits?: string) =>
  `${`^${`${emailClearDigits}`}${AltEmailRegEx.split("@")[0].substring(1)}`}@${
    AltEmailRegEx?.split("@")[1]
  }`;

/**
 * Validates digit-string for min and max length requirements.
 * @param params Hook parameters
 * @param params.strings Translated user-facing strings
 * @param emailClearDigits Clear digits of the email to validate
 * @returns Handlers for implementing verification code collection.
 */
export const emailConfirmationAndSimpleAddressValidator: SimpleEmailProofConfirmationAddressValidator =
  ({ strings }, emailClearDigits) =>
    simpleInputValidation(
      strings.validationErrorText,
      getStartsWithEmailRegex(emailClearDigits),
      strings.informationRequiredText,
    );

/**
 * @param params Hook parameters
 * @param params.strings Translated user-facing strings
 * @param emailClearDigits Clear digits of the email to validate
 * @returns Handlers for implementing verification code collection.
 */
export const emailConfirmationAndSimpleAddressValidatorForIntEnv: SimpleEmailProofConfirmationAddressValidator =
  ({ strings }, emailClearDigits) =>
    simpleInputValidation(
      strings.validationErrorText,
      `${getStartsWithEmailRegex(emailClearDigits)}|${MagicOneTimeCodeRegex}`,
      strings.informationRequiredText,
    );

/**
 * @param isINT Environment flag
 * @param hasConfirmation Flag to check if confirmation is present
 * @returns Handlers for email validation based on the params.
 */
export const getEmailValidator = (
  isINT: boolean,
  hasConfirmation: Boolean,
): SimpleEmailProofConfirmationAddressValidator | SimpleEmailAddressValidator => {
  const emailValidator = isINT ? emailSimpleAddressValidatorForIntEnv : emailSimpleAddressValidator;
  const emailConfirmationValidator = isINT
    ? emailConfirmationAndSimpleAddressValidatorForIntEnv
    : emailConfirmationAndSimpleAddressValidator;

  return hasConfirmation ? emailConfirmationValidator : emailValidator;
};
