import { FlowId, ViewId } from "@msidentity/SISU/constants";
import {
  useActivateView,
  useDocumentTitle,
  useShowProgressAndRedirect,
} from "@msidentity/SISU/hooks";
import SignUpConfig from "../../../../signup-config";
import { type SignupBlockedViewStringsFabric } from "../signup-blocked-view-fabric-types";

export type UseSignupBlockedFabricParams = {
  strings: SignupBlockedViewStringsFabric;
};

export type UseSignupBlockedFabricResponse = {
  hideBackButton: boolean;
  cancelOnClick: () => void;
};

export const useSignupBlockedFabric = (
  params: UseSignupBlockedFabricParams,
): UseSignupBlockedFabricResponse => {
  const { strings } = params;
  const { hideBackButton, finalBackUrl } = SignUpConfig.instance;

  useActivateView(ViewId.SignUpBlocked, FlowId.Signup, {
    showBackButtonOnActiveView: false,
  });
  useDocumentTitle(strings.title);

  const showProgressAndRedirect = useShowProgressAndRedirect();

  return {
    hideBackButton,
    cancelOnClick: () => showProgressAndRedirect(finalBackUrl),
  };
};
