import { createContext, useContext } from "react";
import { ViewId } from "@msidentity/SISU/constants";
import { type IViewContext, createViewProvider } from "@msidentity/SISU/flows/view-context";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { getDefaultCountry } from "@msidentity/SISU/utilities/country-helper";
import { type ServerData } from "@msidentity/SISU/utilities/server-data";
import { CheckAvailableErrorCode } from "../../utilities/api-helpers";
import signUpReducer, { type SignUpActions } from "./signup-reducer";
import { type MemberNameInput } from "./signup-types";

export interface IMemberNameReporting {
  memberNameChangeCount: number;
  memberNameAvailableCount: number;
  memberNameUnavailableCount: number;
}

export type RequiredConsentState = {
  userViewed: boolean;
  userConsented: boolean;
};

export type KoreaConsentState = {
  dataCollection: RequiredConsentState;
  dataProvision: RequiredConsentState;
  nextButtonEnabled: boolean;
};

export type SignUpState = {
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  checkAvailableStateMap: string[];
  country: string;
  createAccountErrorCode: string;
  createAccountErrorShown: boolean;
  createAccountErrorViewId: ViewId;
  evictionWarningMemberName: string[];
  firstName: string;
  initialViewId: ViewId;
  isOptInEmail: boolean;
  isPossibleEvicted: boolean;
  isUserConsentedToChinaPIPL: boolean;
  koreaConsent: KoreaConsentState;
  lastName: string;
  lastMemberNameUsedOnSendOtt: string;
  loginUrl: string;
  /* The full member name, including the domain or phone country code */
  memberName: string;
  /* The member name input values from the username fields and dropdowns */
  memberNameInput: MemberNameInput;
  /* The member name type, i.e. Easi/Live/Phone */
  memberNameType: MemberNameType;
  /* The member name suggestions sent by the server when a Live username is already taken */
  memberNameSuggestions: string[];
  nextViewId: ViewId;
  noPaAllowedOnEviction: boolean;
  password: string;
  usernameCollectionErrorCode: string;
  usernameCollectionErrorShown: boolean;
  previouslyCheckedMemberName: string;
  reporting: IMemberNameReporting;
  sendOttError: string;
  sendOttErrorShown: boolean;
  showPrefillSpeedbump: boolean;
  verificationCode: string;
  verificationSlt: string;
};

export const defaultKoreaConsentState: KoreaConsentState = {
  dataCollection: {
    userViewed: false,
    userConsented: false,
  },
  dataProvision: {
    userViewed: false,
    userConsented: false,
  },
  nextButtonEnabled: false,
};

export const defaultSignUpState: SignUpState = {
  birthDay: "",
  birthMonth: "",
  birthYear: "",
  checkAvailableStateMap: [],
  country: "",
  createAccountErrorCode: "",
  createAccountErrorShown: false,
  createAccountErrorViewId: ViewId.None,
  evictionWarningMemberName: [],
  firstName: "",
  initialViewId: ViewId.UsernameCollection,
  isOptInEmail: false,
  isPossibleEvicted: false,
  isUserConsentedToChinaPIPL: false,
  koreaConsent: {
    ...defaultKoreaConsentState,
  },
  lastName: "",
  lastMemberNameUsedOnSendOtt: "",
  loginUrl: "",
  memberName: "",
  memberNameInput: {
    input: "",
    domain: "",
    phoneCountry: getDefaultCountry([], ""),
  },
  memberNameType: MemberNameType.Unknown,
  memberNameSuggestions: [],
  nextViewId: ViewId.None,
  noPaAllowedOnEviction: false,
  password: "",
  usernameCollectionErrorCode: "",
  usernameCollectionErrorShown: false,
  previouslyCheckedMemberName: "",
  reporting: {
    memberNameChangeCount: 0,
    memberNameAvailableCount: 0,
    memberNameUnavailableCount: 0,
  },
  sendOttError: "",
  sendOttErrorShown: false,
  showPrefillSpeedbump: false,
  verificationCode: "",
  verificationSlt: "",
};

export const SignUpContext = createContext<IViewContext<SignUpState, SignUpActions>>({
  viewState: defaultSignUpState,
  dispatchStateChange: () => {
    throw new Error("SignUp Context not initialized");
  },
});

export const SignUpProvider: React.FC<{ initialState: SignUpState }> = createViewProvider<
  SignUpState,
  SignUpActions
>(SignUpContext, signUpReducer);

export const useSignUpContext = () => useContext(SignUpContext);

/* ********* ServerData helpers  ********** */

/**
 * Create a SignUp state object from ServerData
 * @param serverData The server data object that should be used to create the initial SignUp state
 * @param initialMemberNameType The initial member name type to use when creating the SignUp state
 * @param isParentFlow Whether the flow is a parent flow
 * @returns The SignUp state object created from the server data
 */
export function createSignUpState(
  serverData: ServerData,
  initialMemberNameType: MemberNameType,
  isParentFlow?: boolean,
): SignUpState {
  const SignUpState = { ...defaultSignUpState };

  if (serverData.arrDomainList) {
    const defaultDomain = serverData.arrDomainList[0];
    SignUpState.memberNameInput.domain = defaultDomain;
  }

  if (serverData.fCheckOptinEmail !== undefined) {
    SignUpState.isOptInEmail = !isParentFlow && serverData.fCheckOptinEmail;
  }

  if (serverData.oPrefill?.sCountry) {
    SignUpState.memberNameInput.phoneCountry = getDefaultCountry(
      serverData.arrPhoneCountryList || [],
      serverData.oPrefill?.sCountry,
    );
  }

  if (serverData.oPrefill?.oMemberName) {
    const memberNamePrefill = serverData.oPrefill.oMemberName;

    // If there are prefill values for the membername, set the initial membername input values
    if (memberNamePrefill) {
      let prefillErrorCode = "";
      let prefillIsProof = false;
      let prefillIsPossibleEvicted = false;

      if (initialMemberNameType === MemberNameType.Phone && memberNamePrefill.oPhone) {
        const phoneNumber = memberNamePrefill.oPhone[0];
        SignUpState.memberNameInput.input = phoneNumber.sPhoneNumber;
        SignUpState.memberName = phoneNumber.sFormattedPhoneNumber || "";
        SignUpState.memberNameType = MemberNameType.Phone;

        if (phoneNumber.sErrorCode) {
          prefillErrorCode = phoneNumber.sErrorCode;
        }

        if (phoneNumber.fIsProof) {
          prefillIsProof = phoneNumber.fIsProof;
        }

        if (phoneNumber.fIsPossibleEviction) {
          prefillIsPossibleEvicted = phoneNumber.fIsPossibleEviction;

          if (phoneNumber.fIsNopaAllowed) {
            SignUpState.noPaAllowedOnEviction = phoneNumber.fIsNopaAllowed;
          }
        }
      } else if (initialMemberNameType === MemberNameType.Live && memberNamePrefill.oLive) {
        const memberNameTokens = memberNamePrefill.oLive[0].sEmail.split("@");
        // TODO: Default values??
        const memberNameValue = memberNameTokens[0];
        const domainValue = memberNameTokens[1];

        SignUpState.memberNameInput.input = memberNameValue;
        SignUpState.memberNameInput.domain = domainValue;
        SignUpState.memberName = memberNamePrefill.oLive[0].sEmail;

        SignUpState.memberNameType = MemberNameType.Live;

        if (memberNamePrefill.oLive[0].sErrorCode) {
          prefillErrorCode = memberNamePrefill.oLive[0].sErrorCode;
        }

        if (memberNamePrefill.oLive[0].fIsProof) {
          prefillIsProof = memberNamePrefill.oLive[0].fIsProof;
        }
      } else if (initialMemberNameType === MemberNameType.EASI && memberNamePrefill.oEasi) {
        SignUpState.memberNameInput.input = memberNamePrefill.oEasi[0].sEmail;
        SignUpState.memberName = memberNamePrefill.oEasi[0].sEmail;
        SignUpState.memberNameType = MemberNameType.EASI;

        if (memberNamePrefill.oEasi[0].sErrorCode) {
          prefillErrorCode = memberNamePrefill.oEasi[0].sErrorCode;
        }

        if (memberNamePrefill.oEasi[0].fIsProof) {
          prefillIsProof = memberNamePrefill.oEasi[0].fIsProof;
        }
      }

      if (prefillErrorCode !== "") {
        SignUpState.usernameCollectionErrorCode = prefillErrorCode;
        // If there's a prefill membername taken easi error, show the Known Email Alias view. In Fabric, this view is not
        // used and will instead default to username collection with an error message, and we still need to set the
        // usernameCollectionErrorCode so that the error message is displayed.
        if (prefillErrorCode === CheckAvailableErrorCode.memberNameTakenEasi) {
          SignUpState.initialViewId = ViewId.SignUpKnownEmailAlias;
        }
      } else if (serverData.fShowUsernameRecoverySpeedbump && prefillIsProof) {
        SignUpState.initialViewId = ViewId.SignUpUsernameRecoverySpeedbump;
      } else if (prefillIsPossibleEvicted) {
        SignUpState.initialViewId = ViewId.SignUpEvictionSpeedbump;
      } else if (SignUpState.memberName !== "") {
        // If the membername was prefilled but there is no error, show the prefill speedbump description on username collection view
        SignUpState.showPrefillSpeedbump = true;
      }

      SignUpState.previouslyCheckedMemberName = SignUpState.memberName;
      SignUpState.checkAvailableStateMap = [
        `${SignUpState.memberName}:${!!prefillIsPossibleEvicted}`,
      ];
    }
  }

  if (serverData.urlLogin) {
    SignUpState.loginUrl = serverData.urlLogin;
  }

  return SignUpState;
}
