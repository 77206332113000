import { type ChangeEvent } from "react";
import { type PhoneNumberState } from "@msidentity/SISU/components/inputs/phone-number/hooks/use-phone-number";
import { type OnSwitchMemberNameType } from "@msidentity/SISU/components/username-collection/username-collection-types";
import type useTextInputFormDeprecated from "@msidentity/SISU/hooks/use-text-input-form-deprecated";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { type ICountryInfo } from "@msidentity/SISU/utilities/country-helper";
import { emailDomainValidator } from "@msidentity/SISU/utilities/validators/email-validator";
import { useSignUpContext } from "../../../../signup-context";
import { SignUpActionType } from "../../../../signup-reducer";

/**
 * Provides an onChange handler that updates signup context with the current username value
 * @param inputStates The input states for the username collection component
 * @param inputStates.phoneNumberState The Phone Number input state
 * @param inputStates.easiInputState The EASI input state
 * @param inputStates.outlookInputState The Outlook input state
 * @param inputStates.outlookDomainState The Outlook domain state
 * @param inputStates.outlookDomainState.setDomain The domain setter for the Outlook input
 * @param domainList The list of domains to validate against
 * @param currentInputType The current input type
 * @param setCurrentInputType The setter for the current input type
 * @param setShouldClearInputOnSwitch The setter for the shouldClearInputOnSwitch state
 * @returns onChange handler for the input on username collection view
 */
export const useUsernameOnChangeFabric = (
  inputStates: {
    phoneNumberState?: PhoneNumberState;
    // eslint-disable-next-line deprecation/deprecation
    easiInputState?: ReturnType<typeof useTextInputFormDeprecated>;
    // eslint-disable-next-line deprecation/deprecation
    outlookInputState?: ReturnType<typeof useTextInputFormDeprecated>;
    outlookDomainState?: {
      setDomain: React.Dispatch<React.SetStateAction<string>>;
    };
  },
  domainList: string[],
  currentInputType: MemberNameType,
  setCurrentInputType: OnSwitchMemberNameType,
  setShouldClearInputOnSwitch: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { dispatchStateChange: dispatchSignupStateChange } = useSignUpContext();
  const { phoneNumberState, easiInputState, outlookInputState, outlookDomainState } = inputStates;

  let originalOnChange: (event: ChangeEvent<HTMLInputElement> | string) => void;

  if (currentInputType === MemberNameType.Phone && phoneNumberState) {
    originalOnChange = phoneNumberState.onChangeInput;
  } else if (currentInputType === MemberNameType.EASI && easiInputState) {
    originalOnChange = easiInputState.onTextChange;
  } else if (currentInputType === MemberNameType.Live && outlookInputState) {
    originalOnChange = outlookInputState.onTextChange;
  }

  return (event: ChangeEvent<HTMLInputElement> | string) => {
    let newValue = typeof event === "string" ? event : event.target.value;
    originalOnChange(newValue);

    if (currentInputType === MemberNameType.EASI) {
      // If the user enters a Live domain in the Easi input, we switch to the Live input
      const liveDomain = emailDomainValidator(newValue, domainList);

      if (liveDomain && outlookInputState && outlookDomainState) {
        const outlookAlias = newValue.split("@")[0];
        newValue = outlookAlias;

        setShouldClearInputOnSwitch(false);
        outlookInputState.setValue(outlookAlias);
        outlookDomainState.setDomain(liveDomain);
        setCurrentInputType(MemberNameType.Live);

        dispatchSignupStateChange({
          actionType: SignUpActionType.SetMemberNameInput,
          payload: {
            domain: liveDomain,
          },
        });
      }
    } else if (currentInputType === MemberNameType.Live) {
      // If the user enters an @ symbol in the Live input, we switch to the Easi input
      if (newValue.slice(-1) === "@") {
        if (easiInputState) {
          setShouldClearInputOnSwitch(false);
          easiInputState.setValue(newValue);

          setCurrentInputType(MemberNameType.EASI);
        }
      }
    }

    dispatchSignupStateChange({
      actionType: SignUpActionType.SetMemberNameInput,
      payload: {
        input: newValue,
      },
    });
  };
};

/**
 * Provides an onChange handler that updates signup context with the phone country value
 * @param inputState The Phone Number input state
 * @returns onChange handler for the phone country dropdown value on username collection view
 */
export const usePhoneCountryOnChangeFabric = (inputState: PhoneNumberState) => {
  const { dispatchStateChange: dispatchSignupStateChange } = useSignUpContext();
  const { onChangeDropdown } = inputState;

  return (value: ICountryInfo) => {
    onChangeDropdown(value);

    dispatchSignupStateChange({
      actionType: SignUpActionType.SetMemberNameInput,
      payload: {
        phoneCountry: value,
      },
    });
  };
};
