import { makeStyles, shorthands } from "@griffel/react";

export const useRequiredConsentStylesFabric = makeStyles({
  checkbox: {
    backgroundColor: "transparent",
    ...shorthands.borderWidth("0"),
    ...shorthands.padding("0"),
    verticalAlign: "text-top",
    height: "20px",
    width: "20px",
    boxSizing: "content-box",
  },
});

export type RequiredConsentStylesFabric = ReturnType<typeof useRequiredConsentStylesFabric>;
