import { UserFlowType } from "@msidentity/SISU/constants";
import { ViewId } from "@msidentity/SISU/constants/routing-constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { MemberNameType } from "@msidentity/SISU/model/user";
import SignupConfig from "../../../signup-config";

/**
 * This method determines if any views need to be shown before UsernameCollection and render them first
 * @param initialViewIdFromContext - The initial view id to be shown that comes from initial context. This is UsernameCollection by default.
 * @param userFlowType - user flow type used for simplified child account creation flow
 * @param memberNameTypeFromContext - The member name type that comes from initial context
 * @returns the initial view and the next view to be shown (if any)
 */
export const getInitialViewsFabric = function getInitialViewsFabric(
  initialViewIdFromContext: ViewId,
  userFlowType: UserFlowType,
  memberNameTypeFromContext: MemberNameType,
) {
  const { showKoreaPrivacyConsentView, showChinaPiplConsentView, skipCredentialsPage } =
    SignupConfig.instance;
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  let initialViewId = initialViewIdFromContext;
  let nextViewId = ViewId.None;

  // Users from Teams mobile come to sign-up only with a prefilled username
  // For these users we want to skip the credentials page
  // if the user is signing in with a phone number or EASI account, we want to show the username collection view first
  // otherwise, we want to show the password collection view first
  if (skipCredentialsPage && initialViewId === ViewId.UsernameCollection) {
    if (
      memberNameTypeFromContext === MemberNameType.Phone ||
      memberNameTypeFromContext === MemberNameType.EASI
    ) {
      // Teams mobile app has NOPA sign up enabled for Phone and EASI
      initialViewId = ViewId.SignupNameCollection;
    } else {
      initialViewId = ViewId.SignUpPasswordCollection;
    }
  }

  // Korea Privacy Consent and China PIPL Views will be shown before the initial view
  if (showKoreaPrivacyConsentView) {
    // TODO: Don't do this if we need to do device ticket!
    nextViewId = initialViewId;
    initialViewId = ViewId.SignupKoreaPrivacyConsent;
  }

  if (showChinaPiplConsentView) {
    // TODO: Don't do this if we need to do device ticket!
    nextViewId = initialViewId;
    initialViewId = ViewId.SignUpChinaPipl;
  }

  // default view for simplified child account creation is CountryBirthdate
  // if UserFlowType === Parent, initialViewIdFromContext is shown first
  if (isSimplifiedChildAccountCreation && userFlowType !== UserFlowType.Parent) {
    if (nextViewId === ViewId.None) {
      nextViewId = initialViewId;
      initialViewId = ViewId.CountryBirthdate;
    } else {
      nextViewId = ViewId.CountryBirthdate;
    }
  }

  return {
    initialViewId,
    nextViewId,
  };
};
