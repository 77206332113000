import React from "react";
import { ViewId } from "@msidentity/SISU/constants/routing-constants";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { useSignUpContext } from "../../../signup-context";
import { ChinaPiplViewFabric } from "../../china-pipl/fabric/china-pipl-view-fabric";
import { CountryBirthdateViewFabric } from "../../country-birthdate/fabric/country-birthdate-view-fabric";
import { EvictionSpeedbumpViewFabric } from "../../eviction-speedbump/fabric/eviction-speedbump-view-fabric";
import { KoreaPrivacyConsentViewFabric } from "../../korea-privacy-consent/fabric/korea-privacy-consent-view-fabric";
import { SignupNameCollectionViewFabric } from "../../name-collection/fabric/signup-name-collection-view-fabric";
import { PasswordCollectionViewFabric } from "../../password-collection/fabric/password-collection-view-fabric";
import { UsernameCollectionViewFabric } from "../../username-collection/fabric/username-collection-view-fabric";
import { UsernameRecoverySpeedbumpViewFabric } from "../../username-recovery-speedbump/fabric/username-recovery-speedbump-view-fabric";
import { SignUpInitialViewPicker } from "../signup-initial-view-picker";
import { getInitialViewsFabric } from "./get-initial-views-fabric";

export const ViewIdToViewMap: { [key: string]: JSX.Element } = {
  [ViewId.UsernameCollection]: <UsernameCollectionViewFabric />,
  [ViewId.SignUpUsernameRecoverySpeedbump]: <UsernameRecoverySpeedbumpViewFabric />,
  [ViewId.SignUpEvictionSpeedbump]: <EvictionSpeedbumpViewFabric />,
  [ViewId.SignupKoreaPrivacyConsent]: <KoreaPrivacyConsentViewFabric />,
  [ViewId.SignUpChinaPipl]: <ChinaPiplViewFabric />,
  [ViewId.CountryBirthdate]: <CountryBirthdateViewFabric />,
  [ViewId.SignupNameCollection]: <SignupNameCollectionViewFabric />,
  [ViewId.SignUpPasswordCollection]: <PasswordCollectionViewFabric />,
};

/**
 * The initial view picker component for the Fabric sign up flow.
 * Certain views may be delayed to first show another view, such as the Korea privacy consent view.
 * @returns The initial View to render for the sign up flow
 */
export const SignUpInitialViewPickerFabric: React.FC = function SignUpInitialViewPickerFabric() {
  const {
    globalState: { userFlowType },
  } = useGlobalContext();

  const {
    viewState: {
      initialViewId: initialViewIdFromContext,
      memberNameType: memberNameTypeFromContext,
    },
  } = useSignUpContext();

  const { initialViewId, nextViewId } = getInitialViewsFabric(
    initialViewIdFromContext,
    userFlowType,
    memberNameTypeFromContext,
  );

  return (
    <SignUpInitialViewPicker
      initialViewId={initialViewId}
      nextViewId={nextViewId}
      viewIdToViewMap={ViewIdToViewMap}
      usernameCollectionView={<UsernameCollectionViewFabric />}
    />
  );
};
