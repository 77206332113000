import { ViewId } from "@msidentity/SISU/constants/routing-constants";
import { type IFormSubmissionProps } from "@msidentity/SISU/hooks";
import { useCreateAccount } from "../../../hooks/use-create-account";
import { useSignUpContext } from "../../../signup-context";

/**
 * @returns A create account submit handler for the verification view
 */
export const useVerificationViewSubmitCreateAccount = () => {
  const createAccountRequest = useCreateAccount();
  const { viewState } = useSignUpContext();

  const submitTask = async (formSubmissionProps: IFormSubmissionProps) => {
    await createAccountRequest(
      {
        ...viewState,
        verificationCode: String(formSubmissionProps.value),
      },
      ViewId.SignUpVerification,
    );
  };

  return submitTask;
};
