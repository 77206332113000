import React from "react";
import ReactDOM from "react-dom";
import { MemoryRouter } from "react-router-dom";
import { type ChallengeState } from "@msidentity/SISU/challenge-context";
import { ChallengeProvider } from "@msidentity/SISU/challenge-reducer";
import { DfpIframe } from "@msidentity/SISU/components/device-finger-printing/dfp-iframe";
import { HumanIframe } from "@msidentity/sisu/components/human-iframe/human-iframe";
import Shell from "@msidentity/SISU/components/shell/fabric/shell-fabric";
import { Flavors, FlowId } from "@msidentity/SISU/constants";
import {
  type BaseSisuStateFabric,
  BaseSisuProvidersFabric,
} from "@msidentity/SISU/context/sisu-providers";
import { initExp } from "@msidentity/SISU/utilities/client-experimentation/client-experiment-helper";
import { SignupRoutesFabric } from "../../flows/signup/fabric/signup-flow-fabric";
import { type SignUpState, SignUpProvider } from "../../flows/signup/signup-context";
import { initSignupAppFabric } from "./init-signup-app-fabric";

const serverData = window.ServerData || {};
const { initialBaseSisuState, initialSignUpState, initialChallengeState } = initSignupAppFabric(
  serverData,
  Flavors.Fabric,
);

initExp(Flavors.Fabric, FlowId.Signup);

export type SignUpAppProps = {
  signUpState: SignUpState;
  challengeState: ChallengeState;
  baseSisuState: BaseSisuStateFabric;
};

/**
 * The app encapsulating the SignUp flow. This is the outermost component
 * and will be rendered onto the page using ReactDOM.render.
 * @param props Optional props used to override the state generated from ServerData for testing purposes
 * @returns An instance of the SignUp app
 */
export const SignupFabric: React.FC<Partial<SignUpAppProps>> = function SignupFabric(props) {
  const {
    baseSisuState = initialBaseSisuState,
    challengeState = initialChallengeState,
    signUpState = initialSignUpState,
  } = props || {};

  return (
    <BaseSisuProvidersFabric {...baseSisuState}>
      <SignUpProvider initialState={signUpState}>
        <ChallengeProvider initialState={challengeState}>
          <Shell>
            <SignupRoutesFabric />
            <DfpIframe />
            <HumanIframe />
          </Shell>
        </ChallengeProvider>
      </SignUpProvider>
    </BaseSisuProvidersFabric>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <MemoryRouter>
      <SignupFabric />
    </MemoryRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
