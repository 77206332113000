export enum SignUpQueryParams {
  Scenario = "scid",
  UserFlowType = "uft",
  SimplifiedChildAccountCreation = "scac",
}

// Associated values for SignUpQueryParams.Scenario
export enum SignUpScenario {
  Family = "family",
  ParentFlow = "2",
  isSimplifiedChildAccountCreation = "1",
}
