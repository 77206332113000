import { type VerificationViewStringsFabric } from "./verification-view-types-fabric";

export const verificationViewStringsFabric: VerificationViewStringsFabric = {
  titleEASI: getLocalString("VerifyProof_Email"),
  titlePhone: getLocalString("VerifyProof_Phone"),
  descriptionEASI: getLocalString("Signup_ResendCode_Email"),
  descriptionPhone: getLocalString("VerificationCodeSent_Description"),
  enterCode: getLocalString("Generic_EnterCode"),
  resendPhoneCode: getLocalString("SendSms_DidntReceiveText"),
  emptyCodeError: getLocalString("Error_1018"),
  invalidCodeError: getLocalString("Error_1215"),
  koreaConsentText: getLocalString("General_Buttons_CreateAccount_Korea"),
};
