import React from "react";
import { MemberNameType as UsernameType } from "../../model/user";
import { type BaseUsernameCollectionProps } from "./username-collection-types";

/**
 * @param props The component props
 * @returns Flavorless Username Collection component
 */
export const UsernameCollection: React.FC<BaseUsernameCollectionProps> =
  function UsernameCollection(props) {
    const {
      outlookInputComponent,
      easiInputComponent,
      phoneInputComponent,
      switchLinksComponent,
      currentInputType,
    } = props;

    const showOutlookInput = currentInputType === UsernameType.Live;
    const showEasiInput = currentInputType === UsernameType.EASI;
    const showPhoneInput = currentInputType === UsernameType.Phone;

    return (
      <>
        {showOutlookInput && outlookInputComponent}
        {showEasiInput && easiInputComponent}
        {showPhoneInput && phoneInputComponent}

        {switchLinksComponent}
      </>
    );
  };
