import { useState } from "react";
import { useEffectOnce } from "@msidentity/SISU/hooks";
import { type ChannelType } from "@msidentity/SISU/model/proof";
import { useSignUpSendOtt } from "../../../hooks/use-signup-send-ott";

/**
 * Hook to send the verification code
 * Make the call sendOtt on initial render to start the verification process.
 * Skip the initial sendOtt call if it was already made for the current membername
 * If sendOtt fails, the error will be handled by the sendOtt hook and showView will be false.
 * @param memberName The current member name
 * @param lastMemberNameUsedOnSendOtt The last member name used on send ott
 * @param channel The ChannelType for the verification code
 * @returns The sendOtt function and the showView state
 */
export const useSendVerificationCode = (
  memberName: string,
  lastMemberNameUsedOnSendOtt: string,
  channel: ChannelType,
) => {
  const sendOtt = useSignUpSendOtt();

  const skipInitialSendOtt = lastMemberNameUsedOnSendOtt === memberName;
  const [showView, setShowView] = useState(skipInitialSendOtt);

  useEffectOnce(() => {
    if (!skipInitialSendOtt) {
      sendOtt(channel, { setShowView });
    }
  });

  return {
    showView,
    sendOtt,
  };
};
