import React from "react";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { type OnSwitchMemberNameType } from "@msidentity/SISU/components/username-collection/username-collection-types";
import { ViewId } from "@msidentity/SISU/constants/routing-constants";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useRedirect } from "@msidentity/SISU/hooks";
import { useNavigateDirection } from "@msidentity/SISU/hooks/use-navigate-direction";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { appendOrReplaceQueryStringParams } from "@msidentity/SISU/utilities/strings-helper";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

export type UsernameCollectionErrorBindings = {
  learnMoreLink: (chunks: string[]) => JSX.Element;
  signInLink: (chunks: string[]) => JSX.Element;
  suggLink: (chunks: string[]) => JSX.Element;
  switchToOutlookLink: (chunks: string[]) => JSX.Element;
};

/**
 * Creates a dynamic React component for a LinkButton, to be used in the FormattedTextWithBindings component.
 * It expects the formatted text to have text content inside the link.
 * @param onClick The onClick handler for the link
 * @param linkId The id for the link
 * @returns a React functional component that renders a link with the given onClick handler.
 */
export const createLinkBinding = function createLinkBinding(onClick: () => void, linkId?: string) {
  return function TemplateLink(chunks: string[]) {
    const [text] = chunks;
    return <LinkButton linkId={linkId} text={text} onClick={onClick} />;
  };
};

/**
 * This hook is used to get the signup and recover username link bindings, which is shown in the username error message.
 * @param setCurrentInput The callback to set the current input type
 * @param username The username to display in the error message
 * @returns a function that provides embedded links for the username error message
 */
export const useUsernameCollectionErrorBindings = (
  setCurrentInput: OnSwitchMemberNameType,
  username: string,
) => {
  const { isHosted } = GlobalConfig.instance;
  const { phoneNumberTakenUrl } = SignUpConfig.instance;
  const navigate = useNavigateDirection();
  const redirect = useRedirect();
  const {
    viewState: { loginUrl },
    dispatchStateChange,
  } = useSignUpContext();

  const getEmbeddedLinkBindings = (): UsernameCollectionErrorBindings => {
    const signInUrl = appendOrReplaceQueryStringParams(loginUrl, {
      username: encodeURIComponent(username),
    });

    // Create the link button bindings for the formatted message
    const suggLink = createLinkBinding(() => {
      dispatchStateChange({
        actionType: SignUpActionType.SetMemberNameType,
        payload: MemberNameType.Live,
      });
      navigate(ViewId.UsernameCollection, ViewId.SignUpAlternateMembernameSuggestions);
    }, "suggLink");
    const switchToOutlookLink = createLinkBinding(() => setCurrentInput(MemberNameType.Live));
    const signInLink = createLinkBinding(() => redirect(signInUrl), "signInLink");
    const learnMoreLink = createLinkBinding(
      () =>
        isHosted
          ? navigate(ViewId.UsernameCollection, ViewId.SignUpMembernameTakenLearnMore)
          : redirect(phoneNumberTakenUrl),
      "learnMoreLink",
    );

    return {
      learnMoreLink,
      signInLink,
      suggLink,
      switchToOutlookLink,
    };
  };

  return getEmbeddedLinkBindings;
};
