import React, { useEffect, useRef } from "react";
import StylesConfig from "../../config/styles-config";

export interface IDomainSelectProps {
  /** The aria label for the domain select component */
  ariaLabel?: string;
  /** Whether the component should have focus */
  hasFocus?: boolean;
  /** The initial selected domain from the provided list  */
  selectedDomain: string;
  /** Customized direction (ltr or rtl) to override the global setting */
  dir?: string;
  /** The id for the domain label */
  labelId?: string;
  /** The id for the domain select component */
  selectId?: string;
  /** The title for the domain select component */
  selectTitle?: string;
  /** The list of avialable email domains to choose from  */
  domainList?: string[];
  /** The prefix to be added to the domain lablel (e.g "@") */
  domainPrefix?: string;
  /** Event function on domain select change */
  onDomainChange?: (e: string) => void;
  /** Event function on domain select change */
  setSelectedDomain?: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * DomainSelect component
 * @param props The properties for this component
 * @returns The select component
 */
export const DomainSelect: React.FC<IDomainSelectProps> = function DomainSelect(props) {
  const {
    selectedDomain,
    setSelectedDomain = () => {},
    dir = "",
    hasFocus = false,
    ariaLabel = "",
    labelId = "",
    selectId = "",
    selectTitle = "",
    domainList = [],
    domainPrefix = "",
    onDomainChange = () => {},
  } = props;

  const ref = useRef<HTMLSelectElement>(null);

  const { useTextInputStyles } = StylesConfig.instance;
  const { emailInputDomainWrapper, emailInputDomainSelect, emailInputDomainSpan } =
    useTextInputStyles();

  useEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    }
  }, [hasFocus]);

  const onDomainChangeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>,
    handleDomainChange: (domainValue: string) => void,
  ) => {
    const newDomain = e.target.value;
    if (newDomain) {
      setSelectedDomain(newDomain);

      // Call the callback passed in as props by consuming component
      handleDomainChange(newDomain);
    }
  };

  return (
    <div className={emailInputDomainWrapper} dir={dir}>
      {domainList.length > 1 ? (
        <select
          ref={ref}
          id={selectId}
          title={selectTitle}
          className={emailInputDomainSelect}
          value={selectedDomain}
          onChange={(e) => onDomainChangeHandler(e, onDomainChange)}
          aria-label={ariaLabel}
        >
          {domainList.map((domain) => (
            <option key={domain} value={domain}>
              {domainPrefix}
              {domain}
            </option>
          ))}
        </select>
      ) : (
        <span id={labelId} className={emailInputDomainSpan}>
          {domainPrefix}
          {selectedDomain}
        </span>
      )}
    </div>
  );
};
