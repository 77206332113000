/* eslint-disable deprecation/deprecation */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../../config/styles-config";
import { useCustomizationContext } from "../../../../context/customization-context";
import * as styleConstants from "../../../../styles/fabric/input-constants-fabric.styles";
import ErrorContainerFabric from "../../../error-container/fabric/error-container-fabric";

const TEXT_INPUT_FOCUS_TIMEOUT = 200;

/* Supported autocomplete options. Add new options as needed.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
 */
export type AutoCompleteOptions =
  | "current-password"
  | "email"
  | "new-password"
  | "off"
  | "username";

// This type shouldn't be used anymore, but is kept until we remove TextInputFabric
export interface DeprecatedTextInputProps {
  /** Input field ID */
  id?: string;
  /** Custom error field ID */
  errorId?: string;
  /** Input field name */
  name: string;
  /** Input field placeholder - hint for the input */
  placeholder?: string;
  /** Input field type */
  type: string;
  /** Input field value */
  value?: string;
  /** Maximum allowed characters for the given field */
  maxLength?: number;
  /** Whether the input is currently focused */
  hasFocus?: boolean;
  /** Aria label value for the input field (when an aria labelled-by value is not applicable). */
  ariaLabel?: string;
  /** Aria described-by value for the input field */
  ariaDescribedBy?: string;
  /** Aria labelled-by value for the input field */
  ariaLabelledBy?: string;
  /** Description of attribute above */
  autocomplete?: AutoCompleteOptions;
  /** Whether the input field is disabled */
  disabled?: boolean;
  /** Input change callback - see form-stub.tsx for examples */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @deprecated
 */
export type TextInputFabricPropsDeprecated = DeprecatedTextInputProps & {
  /** Customized direction (ltr or rtl) to override the global setting */
  dir?: string;
  /** Whether the input field is focused by default */
  hasInitialFocus?: boolean;
  /** Whether the field is showing an error or not */
  showErrorInline?: boolean;
  /**
   * Error string to be passed by the surrounding component to be displayed;
   * if showErrorInline is false but externalError is passed, its assumed its the initial ServerData provided error and is shown on render of component
   */
  externalError?: string | JSX.Element;
  /** Additional css classes that can be passed to the component wrapper */
  customCssWrapper?: string;
  /** Additional css classes that can be passed to the input field */
  customCss?: string;
  /** Input focus callback */
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Input blur callback */
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Callback for when there is validation error to the input */
  validationErrorHandler?: (e: string) => void;
  /** The validation to use for inline validation */
  inputValidationFunc?: (value: string) => string;
  /** Whether the error message will instead be displayed in an external error container */
  useExternalErrorContainer?: boolean;
  /** Additional componet to render along side the text input such as a domain select componet */
  inlineComponent?: JSX.Element;
  /** Externally created ref to use for the text input */
  elementRef?: React.RefObject<HTMLInputElement>;
};

/**
 * @deprecated
 * TextInputFabric component
 * @param props The properties for this component
 * @returns The text input component for Fabric
 */
export const TextInputFabricDeprecated: React.FC<TextInputFabricPropsDeprecated> =
  function TextInputFabricDeprecated(props) {
    const {
      id,
      dir,
      errorId,
      name,
      placeholder,
      type,
      value = "",
      maxLength = 120,
      showErrorInline = false,
      hasFocus = false,
      hasInitialFocus = false,
      ariaLabel,
      ariaLabelledBy,
      ariaDescribedBy,
      disabled = false,
      externalError = "",
      customCssWrapper,
      customCss,
      autocomplete = undefined,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      validationErrorHandler = () => {},
      inputValidationFunc = () => "",
      useExternalErrorContainer = false,
      inlineComponent = undefined,
      elementRef,
    } = props;

    let inputRef = useRef<HTMLInputElement>(null);
    if (elementRef) {
      inputRef = elementRef;
    }

    const { useCommonStyles, useTextInputStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();
    const inputStyles = useTextInputStyles();
    const [hover, setHover] = useState(false);
    const [error, setError] = useState("");

    // Grab the app-branded focus color for the primary button. This will also be the focus color of
    // the underline for the text input box
    const {
      customizationState: {
        styles: {
          accentColors: { primaryButtonDefaultColor },
        },
      },
    } = useCustomizationContext();

    // Determine which colors the text input component's border should be in default, focus and error states
    const borderColorDefault = styleConstants.BORDER_COLOR;
    const borderColorHover = styleConstants.BORDER_COLOR_HOVER;
    const borderColorFocus = primaryButtonDefaultColor || styleConstants.BORDER_COLOR_FOCUS;
    const borderColorError = styleConstants.BORDER_COLOR_FOCUS_HAS_ERROR;

    // These handler functions act as listeners for the `hover` state variable
    const mouseOverHandler = () => setHover(true);
    const mouseOutHandler = () => setHover(false);

    const [currentBorderColor, setBorderColor] = useState(
      hasInitialFocus ? borderColorFocus : borderColorDefault,
    );

    // Get the error text to be displayed based on the following conditions:
    // If showErrorInline is false but externalError is present; this indicates
    // a ServerData provided error. Show it immediately
    // OR if showErrorInline and error or externalError are present
    const getErrorText = useCallback((): string | JSX.Element | null => {
      if (!useExternalErrorContainer && !showErrorInline && externalError) {
        return externalError;
      }

      if (showErrorInline && (error || externalError)) {
        return error || externalError;
      }

      return null;
    }, [useExternalErrorContainer, showErrorInline, externalError, error]);

    useEffect(() => {
      // Delay focus until view transition animations are completed.
      if (hasInitialFocus) {
        setTimeout(() => {
          inputRef?.current?.focus();
        }, TEXT_INPUT_FOCUS_TIMEOUT);
      }
    }, [hasInitialFocus]);

    useEffect(() => {
      if (hasFocus) {
        inputRef?.current?.focus();
      }
    }, [hasFocus]);

    useEffect(() => {
      const errorMessage = inputValidationFunc(value);
      setError(errorMessage);
      validationErrorHandler(errorMessage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, showErrorInline]);

    const errorText = getErrorText();

    useEffect(() => {
      // Apply border color according to component state. Note that the error color takes precedence
      // over all the other colors
      let borderColor = borderColorDefault;
      if (errorText) {
        borderColor = borderColorError;
      } else if (hasFocus) {
        borderColor = borderColorFocus;
      } else if (hover) {
        borderColor = borderColorHover;
      }

      setBorderColor(borderColor);
    }, [
      borderColorError,
      hasFocus,
      borderColorFocus,
      hover,
      borderColorHover,
      borderColorDefault,
      errorText,
    ]);

    const styleAttribute = elementRef ? undefined : { borderColor: currentBorderColor };

    return (
      <div
        className={mergeClasses(commonStyles.row, customCssWrapper)}
        data-testid="inputComponentWrapper"
      >
        {!useExternalErrorContainer && errorText && (
          <ErrorContainerFabric id={errorId ?? `${id}Error`}>{getErrorText()}</ErrorContainerFabric>
        )}
        <div className={inputStyles.withInlineComponentWrapper} dir={dir}>
          <div
            className={mergeClasses(
              commonStyles.formControl,
              commonStyles.formGroup,
              inputStyles.textbox,
              customCss,
              inlineComponent ? inputStyles.inlineInput : "",
            )}
          >
            <input
              ref={inputRef}
              id={id}
              name={name}
              placeholder={placeholder}
              type={type}
              value={value}
              maxLength={maxLength}
              disabled={disabled}
              aria-label={ariaLabel}
              aria-labelledby={ariaLabelledBy}
              aria-describedby={ariaDescribedBy}
              className={mergeClasses(errorText ? "has-error" : "", customCss)}
              autoComplete={autocomplete}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onMouseOver={mouseOverHandler}
              onMouseOut={mouseOutHandler}
              style={styleAttribute}
            />
          </div>
          {inlineComponent}
        </div>
      </div>
    );
  };
