import { createEmbeddedLinkButtonFabric } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { type PiplDescriptionProp, useDescriptions } from "../../hooks/use-descriptions";
import { useEventHandlers } from "../../hooks/use-event-handlers";

/**
 * Aggregate-hook for the China PIPL View
 * @param piplDescriptionStrings an array of descriptions, indicating whether to embed a privacy link
 * @returns the descriptions to render as well as the event handlers for the China PIPL view
 */
export const useChinaPiplFabric = function useChinaPiplFabric(
  piplDescriptionStrings: PiplDescriptionProp[],
) {
  const descriptions = useDescriptions(piplDescriptionStrings, createEmbeddedLinkButtonFabric);
  const eventHandlers = useEventHandlers();

  return {
    descriptions,
    ...eventHandlers,
  };
};
