import { ExperimentFeature } from "@msidentity/SISU/config/experiments";
import { ApiNames, ViewId } from "@msidentity/SISU/constants";
import { useExperiment, useNavigateDirection } from "@msidentity/SISU/hooks";
import { ChannelType } from "@msidentity/SISU/model/proof";
import { MemberNameType } from "@msidentity/SISU/model/user";
import {
  useBuildRepMapRequest,
  useSaveRepMapResponse,
} from "@msidentity/SISU/views/challenge/hooks/use-repmap-params";
import { useTriggerChallenge } from "@msidentity/SISU/views/challenge/hooks/use-trigger-challenge";
import { useValidateChallenge } from "@msidentity/SISU/views/challenge/hooks/use-validate-challenge";
import {
  type SendOttRequest,
  type SendOttResponse,
  sendOtt as sendOttRequest,
} from "../../../../../utilities/api-helpers";
import { createAccount as createAccountRequest } from "../../../../../utilities/api-helpers/create-account/create-account-request";
import {
  setupRequestData,
  useCreateAccountFailureHandler,
  useCreateAccountSuccessHandler,
} from "../../../hooks/use-create-account";
import { useSendOttFailureHandler } from "../../../hooks/use-signup-send-ott";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

export const useSendOttSuccess = () => {
  const navigate = useNavigateDirection();
  const { viewState, dispatchStateChange: dispatchSignUpStateChange } = useSignUpContext();
  const { memberName } = viewState;
  const saveRepmapResponse = useSaveRepMapResponse();

  return (response: SendOttResponse) => {
    saveRepmapResponse(response);
    dispatchSignUpStateChange({
      actionType: SignUpActionType.SetLastMemberNameUsedOnSendOtt,
      payload: memberName,
    });
    navigate(ViewId.Challenge, ViewId.SignUpVerification, false, { replace: true });
  };
};

export const useSignupChallengeHandler = (apiName: ApiNames) => {
  const createAccountSuccess = useCreateAccountSuccessHandler();
  const createAccountError = useCreateAccountFailureHandler();
  const triggerChallenge = useTriggerChallenge();
  const sendOttSuccess = useSendOttSuccess();
  const sendOttErrorHandover = useSendOttFailureHandler();
  const { viewState } = useSignUpContext();
  const { memberNameInput, memberNameType } = viewState;
  const {
    instance: {
      createAccountUrl,
      sendCodeInfo: { sendOttAction, sendOttUrl },
      defaultPhoneCountry,
    },
  } = SignUpConfig;
  const buildRepMapRequest = useBuildRepMapRequest();
  const { treatment: jsDeprecationTreatment } = useExperiment(
    ExperimentFeature.EnableJsPublicKeyDeprecationExperiment,
  );

  const createAccountParams = {
    ...setupRequestData(viewState, jsDeprecationTreatment),
    ...buildRepMapRequest(ApiNames.CreateAccount),
  };

  const sendOttParams: SendOttRequest = {
    action: sendOttAction,
    channel: ChannelType.Unknown,
    proofId: memberNameInput.input,
    ...buildRepMapRequest(ApiNames.SendOtt),
  };

  if (memberNameType === MemberNameType.EASI) {
    sendOttParams.channel = ChannelType.Email;
  } else if (memberNameType === MemberNameType.Phone) {
    sendOttParams.proofCountryIso = memberNameInput.phoneCountry.iso || defaultPhoneCountry;
    sendOttParams.channel = ChannelType.SMS;
  }

  const validateCreateAccount = useValidateChallenge({
    requestHandler: createAccountRequest,
    validationSuccessHandler: createAccountSuccess,
    validationErrorHandler: triggerChallenge,
    handoverErrorHandler: createAccountError,
    url: createAccountUrl,
    flowRequestParams: createAccountParams,
  });

  const validateSendOtt = useValidateChallenge({
    requestHandler: sendOttRequest,
    validationSuccessHandler: sendOttSuccess,
    validationErrorHandler: triggerChallenge,
    handoverErrorHandler: sendOttErrorHandover,
    url: sendOttUrl,
    flowRequestParams: sendOttParams,
  });

  switch (apiName) {
    case ApiNames.CreateAccount: {
      return validateCreateAccount;
    }

    case ApiNames.SendOtt: {
      return validateSendOtt;
    }

    default:
      return () => {};
  }
};
