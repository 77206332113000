import { Encrypt } from "@msidentity/sisu/js/encryptor/encryptor";

enum EncryptIntent {
  ChangePassword = "chgpwd",
  NewPassword = "newPwd",
  Proof = "proof",
  SelectedActionProof = "saproof",
}

/**
 * Encrypts password using PublicKey and SKI from window object
 * @param newPassword new user password
 * @returns Encrypted password
 */
export const encryptNewPassword = (newPassword: string): string =>
  Encrypt("", "", EncryptIntent.NewPassword, newPassword);

/**
 * Encrypts password using PublicKey and SKI from window object
 * @param oldPassword current user password
 * @param newPassword new user password
 * @returns Encrypted password
 */
export const encryptChangePassword = (oldPassword: string, newPassword: string): string =>
  Encrypt(oldPassword, "", EncryptIntent.ChangePassword, newPassword);

/**
 * Encrypts verification code using PublicKey and SKI from window object
 * @param verificationCode verification code
 * @returns Encrypted verification code
 */
export const encryptVerificationCode = (verificationCode: string): string =>
  Encrypt("", verificationCode, EncryptIntent.SelectedActionProof, "");

/**
 * Encrypts SQSA verification code using PublicKey and SKI from window object
 * @param verificationCode SQSA verification code
 * @returns Encrypted SQSA verification code
 */
export const encryptSQSAVerificationCode = (verificationCode: string): string => {
  const trimmedCode = verificationCode.trim().substring(0, 32);

  let encryptedCode = Encrypt("", trimmedCode, EncryptIntent.Proof, "");
  encryptedCode += `:::${Encrypt("", trimmedCode, EncryptIntent.SelectedActionProof, "")}`;

  return encryptedCode;
};
