import { useContext } from "react";
import { SignUpContext } from "../signup-context";
import { SignUpActionType } from "../signup-reducer";

/**
 * This hook aggregates the OptIn state and the function to set it.
 * It should be flavor independent.
 * @returns The marketing-optin state and handler.
 */
export const useMarketingOptIn = () => {
  const {
    viewState: { isOptInEmail },
    dispatchStateChange: dispatchSignupStateChange,
  } = useContext(SignUpContext);

  const toggleIsOptInEmail = () => {
    dispatchSignupStateChange({
      actionType: SignUpActionType.SetIsOptInEmail,
      payload: !isOptInEmail,
    });
  };

  return {
    isOptInEmail,
    toggleIsOptInEmail,
  };
};
