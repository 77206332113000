import React from "react";
import ErrorContainerFabric from "@msidentity/SISU/components/error-container/fabric/error-container-fabric";
import Checkbox from "@msidentity/SISU/components/inputs/checkbox";
import { InputFabric } from "@msidentity/SISU/components/inputs/input/fabric/input-fabric";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import StylesConfig from "@msidentity/SISU/config/styles-config";
import { mergeClasses } from "@msidentity/SISU/utilities/merge-classes";
import { MarketingOptInFabric } from "../../../../../components/marketing-optin/fabric/marketing-optin-fabric";
import { LegalContentFabric } from "../../../components/legal-content/fabric/legal-content-fabric";
import { commonSignUpStringsFabric as commonSignUpStrings } from "../../../fabric/common-signup-strings-fabric";
import { createAccountErrorStringsFabric as createAccountErrorStrings } from "../../../fabric/create-account-error-strings-fabric";
import { useShowPassword } from "../hooks/use-password-collection-form";
import { usePasswordCollectionFabric } from "./hooks/use-password-collection-fabric";
import { passwordCollectionViewStringsFabric as passwordCollectionStrings } from "./password-collection-view-strings-fabric";

const strings = {
  passwordCollectionViewStrings: passwordCollectionStrings,
  createAccountErrorStrings,
};

/**
 * PasswordCollectionView component
 * @returns A rendered instance of this component
 */
export const PasswordCollectionViewFabric: React.FC = function PasswordCollectionViewFabric() {
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  const {
    onSubmit,
    showLegalContent,
    inputProps,
    showCapsLockWarning,
    marketingOptIn,
    showSmsDisclaimer,
    pageTitle,
    pageDescription,
    pageImage,
  } = usePasswordCollectionFabric(strings);
  const { showPassword, toggleShowPassword } = useShowPassword();

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  return (
    <ViewContainerFabric
      title={{ title: pageTitle, titleId: "passwordCollectionViewTitle" }}
      primaryButton={{
        label: commonSignUpStrings.nextButton,
        type: "submit",
        buttonId: "nextButton",
      }}
      image={pageImage}
      description={{
        description: pageDescription,
        descriptionId: "passwordCollectionViewDescription",
      }}
      form={{
        name: "PasswordCollectionForm",
        onSubmit,
      }}
    >
      {showCapsLockWarning && (
        <ErrorContainerFabric id="CapsWarning">
          {passwordCollectionStrings.capsLockWarning}
        </ErrorContainerFabric>
      )}
      <InputFabric
        id="Password"
        name="Password"
        hasInitialFocus
        errorId="passwordError"
        placeholder={passwordCollectionStrings.passwordPlaceholder}
        type={showPassword ? "text" : "password"}
        autoComplete="new-password"
        aria-describedby="passwordCollectionViewDescription passwordError"
        aria-label={passwordCollectionStrings.passwordPlaceholder}
        {...inputProps}
      />
      <div className={commonStyles.row}>
        <Checkbox
          id="ShowHidePasswordCheckbox"
          name="ShowHidePasswordCheckbox"
          label={passwordCollectionStrings.showPasswordLabel}
          value={passwordCollectionStrings.showPasswordLabel}
          htmlFor="ShowHidePasswordCheckbox"
          ariaLabel={passwordCollectionStrings.showPasswordLabel}
          checked={showPassword}
          onChangeHandler={toggleShowPassword}
        />
      </div>
      {marketingOptIn.show && (
        <div className={commonStyles.row}>
          <MarketingOptInFabric
            checked={marketingOptIn.checked}
            onChange={marketingOptIn.onChange}
          />
        </div>
      )}

      {(showSmsDisclaimer || showLegalContent) && (
        <div className={commonStyles.positionButtons}>
          <div>
            {showSmsDisclaimer && (
              <div
                data-testid="smsDisclaimer"
                className={mergeClasses(commonStyles.textCaption, commonStyles.formGroup)}
              >
                {passwordCollectionStrings.smsDisclaimer}
              </div>
            )}
            {showLegalContent && <LegalContentFabric />}
          </div>
        </div>
      )}
    </ViewContainerFabric>
  );
};
