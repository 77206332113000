import type React from "react";
import { useState } from "react";
import {
  type Birthdate,
  useBirthdate,
} from "@msidentity/SISU/components/birthdate/hooks/use-birthdate";
import DateConfig from "@msidentity/SISU/config/date-config";
import { FlowId, UserFlowType, ViewId } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { type ViewOptions } from "@msidentity/SISU/global-reducer";
import { useActivateView, useDocumentTitle, useEffectOnce } from "@msidentity/SISU/hooks";
import { DateOrder } from "@msidentity/SISU/utilities/birthdate-helper";
import { type ICountryRegionInfo } from "@msidentity/SISU/utilities/country-helper";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { validateBirthdate } from "@msidentity/SISU/utilities/validators/birthdate-validator";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

export type UseCountryBirthdateParams = {
  viewStrings: {
    titleDefault: string;
    titleParent: string;
    descriptionDefault: string;
    descriptionParent: string;
    legalContentDefault: string;
    legalContentParent: string;
    informationRequiredError: string;
    invalidBirthdateError: string;
    parentMustBeAdultError: string;
  };
  viewOptions?: Partial<ViewOptions>;
  applyParentStrings: boolean;
};

export type UseCountryBirthdateResponse = {
  countryList: ICountryRegionInfo[];
  onCountryChange: (iso: string) => void;
  selectedCountryIso: string;
  birthdate: Birthdate;
  onBirthDateChange: (day: string, month: string, year: string) => void;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  userHasSubmitted: boolean;
  setUserHasSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  description: string;
  legalContent: string;
};

export const getFirstBirthdateElement = (
  dateOrder: DateOrder,
  birthDayRef: React.RefObject<HTMLSelectElement> | undefined,
  birthMonthRef: React.RefObject<HTMLSelectElement> | undefined,
  birthYearRef: React.RefObject<HTMLInputElement> | undefined,
) => {
  let firstBirthdateElement:
    | React.RefObject<HTMLInputElement>
    | React.RefObject<HTMLSelectElement>
    | undefined;

  switch (dateOrder) {
    case DateOrder.DMY:
      firstBirthdateElement = birthDayRef;
      break;
    case DateOrder.MDY:
      firstBirthdateElement = birthMonthRef;
      break;
    case DateOrder.YMD:
    case DateOrder.YDM:
      firstBirthdateElement = birthYearRef;
      break;
    default:
      firstBirthdateElement = birthMonthRef; // default to MDY
  }

  return firstBirthdateElement;
};

/**
 * @param params Parameters for this hook
 * @param params.viewStrings Localized strings for the view
 * @param params.viewOptions Options for the activateView hook
 * @param params.applyParentStrings Whether to use the parent strings
 * @returns {UseCountryBirthdateResponse} Returns country/birthdate collection view properties
 */
export const useCountryBirthdate = ({
  viewStrings,
  viewOptions = {},
  applyParentStrings,
}: UseCountryBirthdateParams): UseCountryBirthdateResponse => {
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const {
    viewState: { firstName },
  } = useSignUpContext();
  const { prefill, countryDetailsMap, countryList } = SignUpConfig.instance;
  const { minBirthYear, maxBirthYear } = DateConfig.instance;
  const {
    dispatchStateChange: dispatchSignupStateChange,
    viewState: {
      country,
      birthYear: initialBirthYear,
      birthMonth: initialBirthMonth,
      birthDay: initialBirthDay,
    },
  } = useSignUpContext();
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;

  let title = viewStrings.titleDefault;
  let description = viewStrings.descriptionDefault;
  let legalContent = viewStrings.legalContentDefault;
  if (applyParentStrings) {
    title = replaceTokens(viewStrings.titleParent, firstName);
    description = viewStrings.descriptionParent;
    legalContent = viewStrings.legalContentParent;
  }

  useActivateView(ViewId.CountryBirthdate, FlowId.Signup, viewOptions);
  useDocumentTitle(title);

  // Prefill birthdate is in the format "d:m:y"
  const [prefillBirthDay, prefillBirthMonth, prefillBirthYear] = prefill?.sBirthdate?.split(
    ":",
  ) || ["", "", ""];

  const { birthdate, setBirthdate } = useBirthdate({
    birthDay: initialBirthDay || prefillBirthDay,
    birthMonth: initialBirthMonth || prefillBirthMonth,
    birthYear: initialBirthYear || prefillBirthYear,
  });
  const { birthDay, birthMonth, birthYear } = birthdate;
  const [userHasSubmitted, setUserHasSubmitted] = useState(false);
  const [selectedCountryIso, setSelectedCountryIso] = useState(
    country || prefill.sCountry || "", // This covers the default country case as well since prefill data has that info
  );
  const [errorMessage, setErrorMessage] = useState("");

  const validate = (day: string, month: string, year: string) => {
    const validateCountryChildAge =
      isSimplifiedChildAccountCreation && userFlowType === UserFlowType.AdultWithChild;

    const validationError = validateBirthdate({
      birthDate: {
        day,
        month,
        year,
        minYear: minBirthYear,
        maxYear: maxBirthYear,
      },
      strings: {
        informationRequiredError: viewStrings.informationRequiredError,
        invalidBirthdateError: viewStrings.invalidBirthdateError,
        minAgeError: viewStrings.parentMustBeAdultError,
      },
      minAge: validateCountryChildAge ? countryDetailsMap[selectedCountryIso]?.childAge : undefined,
    });
    setErrorMessage(validationError);
  };

  // Validate the initial value
  useEffectOnce(() => {
    validate(birthDay, birthMonth, birthYear);
  });

  const onBirthDateChange = (day: string, month: string, year: string) => {
    setBirthdate({ birthDay: day, birthMonth: month, birthYear: year });

    dispatchSignupStateChange({
      actionType: SignUpActionType.SetCountryBirthdate,
      payload: {
        country: selectedCountryIso,
        birthDay: day,
        birthMonth: month,
        birthYear: year,
      },
    });

    validate(day, month, year);
  };

  return {
    countryList,
    onCountryChange: setSelectedCountryIso,
    selectedCountryIso,
    birthdate,
    onBirthDateChange,
    errorMessage,
    setErrorMessage,
    userHasSubmitted,
    setUserHasSubmitted,
    title,
    description,
    legalContent,
  };
};
