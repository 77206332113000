import { useBackButtonControl } from "@msidentity/SISU/hooks";
import { ChannelType } from "@msidentity/SISU/model/proof";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { useSignUpContext } from "../../../signup-context";
import { useSendVerificationCode } from "./use-send-verification-code";

/**
 * Shared verification view hook
 * Sets up common properties and kicks off the send code process, if needed.
 * @returns Shared verification view properties
 */
export const useVerificationView = () => {
  const {
    viewState: { memberName, memberNameType, lastMemberNameUsedOnSendOtt, verificationCode },
  } = useSignUpContext();

  const canGoBack = useBackButtonControl();
  const isPhone = memberNameType === MemberNameType.Phone;
  const channel = isPhone ? ChannelType.SMS : ChannelType.Email;

  const { showView, sendOtt } = useSendVerificationCode(
    memberName,
    lastMemberNameUsedOnSendOtt,
    channel,
  );

  return {
    canGoBack,
    channel,
    isPhone,
    memberName,
    memberNameType,
    sendOtt,
    showView,
    verificationCode,
  };
};
