import { type UsernameRecoverySpeedbumpViewStringsFabric } from "./username-recovery-speedbump-view-fabric-types";

export const usernameRecoverySpeedbumpViewStringsFabric: UsernameRecoverySpeedbumpViewStringsFabric =
  {
    title: getLocalString("Signup_UsernameRecoverySpeedbump_Title"),
    description: getLocalString("Signup_UsernameRecoverySpeedbump_Desc"),
    recoveryLinkText: getLocalString("Signup_UsernameRecoverySpeedbump_RecoveryLink"),
    createLinkText: getLocalString("Signup_UsernameRecoverySpeedbump_ContinueSignupLinkText"),
    recoveryAriaLabel: getLocalString("Signup_UsernameRecoveryLinkAriaLabel"),
    createAriaLabel: getLocalString("CreateNewMsAccount"),
    signInButtonText: getLocalString("SignIn_Text"),
  };
