import { type ICountryInfo } from "@msidentity/SISU/utilities/country-helper";

export type CreateAccountErrorStrings = {
  bannedPassword: string;
  domainExistsInAad: string;
  domainExistsInAadSupportedLogin: string;
  domainNotAllowed: string;
  emailMustStartWithLetter: string;
  forbiddenWord: string;
  passwordIncorrect: string;
  passwordRequired: string;
  proofAlreadyExists: string;
  invalidBirthDate: string;
  invalidEmailFormat: string;
  invalidFirstName: string;
  invalidLastName: string;
  invalidPhoneFormat: string;
  oneTimeCodeInvalid: string;
  usernameTakenEasi: string;
  usernameTakenNoSuggestions: string;
  usernameTakenPhone: string;
  verificationThrottled: string;
};

export enum SignUpFlowType {
  Easi = "EASI",
  Live = "Live",
  Phone = "Phone",
  Email = "Email",
  DefaultEasi = "Default_EASI",
  DefaultLive = "Default_Live",
  DefaultPhone = "Default_Phone",
  AlwaysDefaultPhone = "Always_Default_Phone",
  DefaultEasiNoLive = "Default_EASI_NoLive",
  DefaultPhoneNoLive = "Default_Phone_NoLive",
}

export type MemberNameInput = {
  input: string;
  domain: string;
  phoneCountry: ICountryInfo;
};
