import { type EffectCallback, useEffect, useRef } from "react";

/**
 * React hook that runs if any elements in the dependency array change, but not on initial render.
 *
 * This hook is used when we only want the effect to run when the dependencies change and not on initial render.
 *
 * @param effect The effect callback to run when dependencies change
 * @param dependencies The dependency list that triggers the effect when any element changes
 */
export const useEffectAfterInitialRender = (
  effect: EffectCallback,
  dependencies: React.DependencyList,
) => {
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
