import React from "react";
import { DescriptionFabric } from "@msidentity/SISU/components";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateView, useDocumentTitle } from "@msidentity/SISU/hooks";
import { useChinaPiplFabric } from "./hooks/use-china-pipl-fabric";
import { ChinaPiplStringsFabric } from "./china-pipl-strings-fabric";

const { piplDescriptionStrings, primaryButtonLabel, secondaryButtonLabel, title } =
  ChinaPiplStringsFabric;

/**
 * The China Privacy and Information Protection Law (PIPL) view for Signup (Fabric).
 * @returns the China PIPL view
 */
export const ChinaPiplViewFabric: React.FC = function ChinaPiplViewFabric() {
  const { descriptions, formOnSubmit, primaryButtonOnClick, secondaryButtonOnClick } =
    useChinaPiplFabric(piplDescriptionStrings);

  useActivateView(ViewId.SignUpChinaPipl, FlowId.Signup, { showIdentityBanner: false });
  useDocumentTitle(title);

  return (
    <ViewContainerFabric
      title={{
        title,
      }}
      form={{
        onSubmit: formOnSubmit,
        dataTestId: "china-pipl-form",
      }}
      primaryButton={{
        label: primaryButtonLabel,
        onClick: primaryButtonOnClick,
        type: "submit",
        hasFocus: true,
        buttonId: "nextButton",
      }}
      secondaryButton={{
        label: secondaryButtonLabel,
        onClick: secondaryButtonOnClick,
        isPrimary: true, // China PIPL requires us to style both buttons as primary buttons so we aren't influencing the choice
        buttonId: "cancelButton",
      }}
    >
      {descriptions.map((descriptionProps) => (
        <DescriptionFabric {...descriptionProps} key={descriptionProps.children} />
      ))}
    </ViewContainerFabric>
  );
};
