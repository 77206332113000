import React from "react";
import Checkbox from "@msidentity/SISU/components/inputs/checkbox";
import { useCustomizationContext } from "@msidentity/SISU/context/customization-context";
import { FormattedTextWithBindings } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { getMarketingOptInAriaLabel, getMarketingOptInCELAText } from "../marketing-optin-helpers";
import { type MarketingOptInProps } from "../marketing-optin-types";
import { marketingOptInStringsFabric as strings } from "./marketing-optin-strings-fabric";

export type MarketingOptInFabricProps = MarketingOptInProps & {
  value?: string;
};

export const getMarketingOptinTextWithBindings = (text: string, url: string): JSX.Element => {
  const commMgrLinkEmbeddedBindings = {
    CommMgrLink: (chunks: string[]) => (
      <a id="CommMgr" href={url} target="_blank" rel="noreferrer">
        {chunks}
      </a>
    ),
  };

  return <FormattedTextWithBindings embeddedBindings={commMgrLinkEmbeddedBindings} text={text} />;
};

/**
 * Marketing Opt-in Fabric component
 * @param props MarketingOptInProps properties for this component
 * @returns An instance of this component
 */
export const MarketingOptInFabric: React.FC<MarketingOptInFabricProps> =
  function MarketingOptInFabric(props) {
    const { id = "iOptinEmail", value = "", checked = false, name, onChange } = props;

    const {
      customizationState: {
        styles: { friendlyAppName, partnerTag },
      },
    } = useCustomizationContext();

    const label = getMarketingOptInCELAText({
      strings,
      friendlyAppName,
      partnerTag,
      getMarketingOptinTextWithBindings,
    });
    const ariaLabel = getMarketingOptInAriaLabel(label);

    return (
      <Checkbox
        label={label}
        htmlFor={id}
        ariaLabel={ariaLabel}
        value={value}
        id={id}
        name={name ?? id}
        onChangeHandler={onChange}
        checked={checked}
      />
    );
  };
