import React from "react";
import { type TextButtonFabricProps } from "@msidentity/SISU/components/text-button/fabric/text-button-fabric";
import { type ITitleFabricProps } from "@msidentity/SISU/components/title/fabric/title-fabric";
import {
  type IDescriptionViewContainerProps,
  ViewContainerFabric,
} from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { FlowId, ViewId } from "@msidentity/SISU/constants";
import { useActivateView, useDocumentTitle } from "@msidentity/SISU/hooks";
import { useCommonStylesFabric } from "@msidentity/SISU/styles/fabric";
import { RequiredConsentFabric } from "../../../components/required-consent/fabric/required-consent-fabric";
import { type RequiredConsentProps } from "../../../components/required-consent/required-consent-types";
import { useKoreaConsentFabric } from "./hooks/use-korea-consent-fabric";
import { koreaConsentStringsFabric } from "./korea-privacy-consent-view-strings-fabric";

export type KoreaPrivacyConsentViewFabricProps = {
  requiredConsent: RequiredConsentProps[];
  title: ITitleFabricProps & Required<Pick<ITitleFabricProps, "title">>;
  description: IDescriptionViewContainerProps;
  primaryButton: TextButtonFabricProps;
  onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

/**
 * The KoreaPrivacyConsentView for Fabric
 * @returns a RequiredConsentView specific for Fabric for the Korea Privacy Consent View
 */
export const KoreaPrivacyConsentViewFabric: React.FC = function KoreaPrivacyConsentViewFabric() {
  const { requiredConsent, title, description, primaryButton, onFormSubmit } =
    useKoreaConsentFabric(koreaConsentStringsFabric);
  const { formGroup } = useCommonStylesFabric();

  useActivateView(ViewId.SignupKoreaPrivacyConsent, FlowId.Signup, { showIdentityBanner: false });
  useDocumentTitle(title.title);

  // Provide a titleId for a11y, if not provided
  if (title.titleId === undefined) {
    title.titleId = "requiredConsentTitle";
  }

  // Provide a descriptionId for a11y, if not provided
  if (description.descriptionId === undefined) {
    description.descriptionId = "requiredConsentDescription";
  }

  const { titleId } = title;
  const { descriptionId } = description;

  const requiredConsentList = requiredConsent.map((consentRequired, idx) => {
    const consentTextId = `requiredConsentText_${idx}`;
    return (
      <RequiredConsentFabric
        {...consentRequired}
        key={consentTextId}
        consentTextId={consentTextId}
        linkAriaDescribedBy={[titleId, descriptionId, consentTextId].join(" ")}
      />
    );
  });

  return (
    <ViewContainerFabric
      title={title}
      description={description}
      primaryButton={primaryButton}
      rowClassName={formGroup}
      form={{ onSubmit: onFormSubmit }}
    >
      {requiredConsentList}
    </ViewContainerFabric>
  );
};
