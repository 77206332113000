import { ViewId } from "@msidentity/SISU/constants";
import { MemberNameType } from "@msidentity/SISU/model/user";
import SignUpConfig from "../../../signup-config";
import { type GetNextViewForUsernameCollectionParams } from "../username-collection-view-types";

/**
 * @param options Config properties needed to build the result
 * @returns The next view after calling CheckAvailableSigninNames from the username collection view
 */
export const getNextViewForUsernameCollectionFabric = (
  options: GetNextViewForUsernameCollectionParams,
): ViewId => {
  const { collectBirthDateCountry, collectFirstNameLastName } = SignUpConfig.instance;
  let switchView = ViewId.SignUpVerification;

  if (options.showUsernameRecoverySpeedbump && options.isProof) {
    switchView = ViewId.SignUpUsernameRecoverySpeedbump;
  } else if (options.isPossibleEvicted) {
    switchView = ViewId.SignUpEvictionSpeedbump;
  } else if (
    !(options.emailNoPaEnabled && options.usernameType === MemberNameType.EASI) &&
    !(options.phoneNoPaEnabled && options.usernameType === MemberNameType.Phone)
  ) {
    switchView = ViewId.SignUpPasswordCollection;
  } else if (collectFirstNameLastName) {
    switchView = ViewId.SignupNameCollection;
  } else if (collectBirthDateCountry) {
    switchView = ViewId.CountryBirthdate;
  }

  return switchView;
};
