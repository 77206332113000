import React from "react";
import { type IDomainSelectProps, DomainSelect } from "../domain-select/domain-select";
import {
  type TextInputFabricPropsDeprecated,
  TextInputFabricDeprecated,
} from "./text-input/fabric/text-input-fabric-deprecated";

// eslint-disable-next-line deprecation/deprecation
export interface IEmailInputProps extends TextInputFabricPropsDeprecated {
  /** Customized direction (ltr or rtl) to override the global setting */
  dir?: string;
  /** whether to display Email Domain component next to the text input */
  displayDomain?: boolean;
  /** domain component properties that shows up next to email text input */
  domainSelectProps?: IDomainSelectProps;
}

/**
 * EmailInput component
 * Extends all props from TextInputFabric component and adds:
 * @param props The properties for this component
 * @returns EmailInput component, which implements TextInputFabric component and adds an external e-mail domain to it
 */
export const EmailInput: React.FC<IEmailInputProps> = function EmailInput(props) {
  const {
    id,
    name,
    errorId,
    placeholder,
    type,
    value = "",
    dir = "",
    maxLength = 120,
    showErrorInline = false,
    hasFocus = false,
    hasInitialFocus = false,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    disabled = false,
    externalError = "",
    customCss,
    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    validationErrorHandler = () => {},
    inputValidationFunc = () => "",
    useExternalErrorContainer = false,
    displayDomain = false,
    domainSelectProps = {
      selectedDomain: "",
    },
    elementRef,
  } = props;

  return (
    // eslint-disable-next-line deprecation/deprecation
    <TextInputFabricDeprecated
      id={id}
      dir={dir}
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      maxLength={maxLength}
      showErrorInline={showErrorInline}
      hasFocus={hasFocus}
      hasInitialFocus={hasInitialFocus}
      externalError={externalError}
      errorId={errorId}
      customCss={customCss}
      disabled={disabled}
      ariaLabel={ariaLabel}
      ariaLabelledBy={ariaLabelledBy}
      ariaDescribedBy={ariaDescribedBy}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      validationErrorHandler={validationErrorHandler}
      inputValidationFunc={inputValidationFunc}
      useExternalErrorContainer={useExternalErrorContainer}
      elementRef={elementRef}
      inlineComponent={displayDomain ? <DomainSelect {...domainSelectProps} /> : undefined}
    />
  );
};
