import React from "react";
import { mergeClasses } from "@griffel/react";
import useFabricStyles from "../../../../styles/fabric/fabric.styles";
import { type DropdownProps, Dropdown } from "../dropdown";

/**
 * DropdownFabric component
 * @param props The properties for this component
 * @returns A dropdown component for Fabric
 */
export const DropdownFabric: React.FC<DropdownProps> = function DropdownFabric(props) {
  const { customCss } = props;
  const fabricStyles = useFabricStyles();

  return <Dropdown {...props} customCss={mergeClasses(fabricStyles.formControl, customCss)} />;
};
