/**
 * Update the code string for the MSA non-prod API Test hook.
 * @param code The code string to update.
 * @returns The code string as-is if it does not contain multiple zeros, or "0" if it does.
 */
export const updateCodeStringForTestHook = (code: string) => {
  if (code.trim() === "") {
    return code;
  }

  if (+code === 0) {
    return "0";
  }

  return code;
};
