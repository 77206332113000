import React from "react";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { ViewId } from "@msidentity/SISU/constants";
import { useNavigateBack, useNavigateDirection } from "@msidentity/SISU/hooks";
import { CheckAvailableErrorCode } from "../../../../../utilities/api-helpers";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import { type AlternateMembernameSuggestionsStrings } from "../fabric/alternate-membername-suggestions-strings-fabric";

/**
 * This hook creates a callback function to use for the onClick event of the alternate member name suggestions
 * @returns a function that will update the Member Name Input in SignUpContext to the provided suggestion before navigating to the Username Collection view
 */
export const useSelectAlternateMembername = () => {
  const { dispatchStateChange } = useSignUpContext();
  const navigate = useNavigateDirection();
  return (suggestion: string) => {
    const parts = suggestion.split("@");
    if (parts.length > 1) {
      dispatchStateChange({
        actionType: SignUpActionType.SetMemberNameInput,
        payload: {
          input: parts[0],
          domain: parts[1],
        },
      });
      navigate(ViewId.SignUpAlternateMembernameSuggestions, ViewId.UsernameCollection, false, {
        replace: true,
      });
    }
  };
};

const titleId = "alternateMembernameSuggestionsTitle";

/**
 * This hook is used to generate the reusable properties for the alternate member name suggestions view
 * @param strings The strings for the alternate member name suggestions view
 * @returns the props for the title, secondaryButton, and the suggestions
 */
export const useAlternateMembernameSuggestions = (
  strings: AlternateMembernameSuggestionsStrings,
) => {
  const navigateBack = useNavigateBack();
  const {
    viewState: { memberNameSuggestions },
    dispatchStateChange,
  } = useSignUpContext();
  const selectAlternateMembername = useSelectAlternateMembername();

  const suggestions = memberNameSuggestions.map((suggestion) => (
    <div key={suggestion} data-testid="alternate-membername-suggestion">
      <LinkButton
        ariaDescribedBy={titleId}
        text={suggestion}
        onClick={() => selectAlternateMembername(suggestion)}
      />
    </div>
  ));

  const onBack = () => {
    dispatchStateChange({
      actionType: SignUpActionType.SetUsernameCollectionError,
      payload: CheckAvailableErrorCode.memberNameTaken,
    });
    dispatchStateChange({
      actionType: SignUpActionType.SetUsernameCollectionErrorShown,
      payload: false,
    });
    navigateBack();
  };

  return {
    title: {
      title: strings.title,
      titleId,
    },
    secondaryButton: {
      label: strings.secondaryButton,
      onClick: onBack,
    },
    suggestions,
  };
};
