import React from "react";
import { SecondaryContentContainerFabric as SecondaryContentContainer } from "@msidentity/SISU/components";
import { LinkButton } from "@msidentity/SISU/components/link-button";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { useCommonStylesFabric } from "@msidentity/SISU/styles/fabric";
import { FormattedTextWithBindings } from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { useUsernameRecoverySpeedbumpFabric } from "./hooks/use-username-recovery-speedbump-fabric";
import { usernameRecoverySpeedbumpViewStringsFabric as viewStrings } from "./username-recovery-speedbump-view-strings-fabric";

/**
 * UsernameRecoverySpeedbumpViewFabric component
 * @returns A rendered instance of this component
 */
export const UsernameRecoverySpeedbumpViewFabric: React.FC =
  function UsernameRecoverySpeedbumpViewFabric() {
    const { memberName, signInOnClick, recoveryOnClick, onContinueToCreate } =
      useUsernameRecoverySpeedbumpFabric({
        strings: viewStrings,
      });
    const fabricStyles = useCommonStylesFabric();

    const recoveryLinkEmbeddedBinding = (chunks: string[]) => (
      <LinkButton
        text={chunks[0]}
        onClick={recoveryOnClick}
        ariaLabel={viewStrings.recoveryAriaLabel}
      />
    );

    return (
      <ViewContainer
        title={{ title: viewStrings.title, titleId: "usernameRecoverySpeedbumpViewTitle" }}
        description={{
          description: replaceTokens(viewStrings.description, memberName),
          descriptionId: "usernameRecoverySpeedbumpViewDescription",
        }}
        primaryButton={{
          ariaDescribedBy:
            "usernameRecoverySpeedbumpViewTitle usernameRecoverySpeedbumpViewDescription",
          type: "submit",
          label: viewStrings.signInButtonText,
          onClick: signInOnClick,
          hasFocus: true,
          buttonId: "signInButton",
        }}
      >
        <div className={fabricStyles.formGroup}>
          <FormattedTextWithBindings
            text={viewStrings.recoveryLinkText}
            embeddedBindings={{
              recoveryLink: recoveryLinkEmbeddedBinding,
            }}
          />
        </div>

        <SecondaryContentContainer>
          <div className={fabricStyles.row}>
            <div className={fabricStyles.formGroup}>
              <LinkButton
                text={viewStrings.createLinkText}
                ariaLabel={viewStrings.createAriaLabel}
                onClick={onContinueToCreate}
              />
            </div>
          </div>
        </SecondaryContentContainer>
      </ViewContainer>
    );
  };
