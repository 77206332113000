import { type ChinaPiplStrings } from "../china-pipl-strings";
import { type PiplDescriptionProp } from "../hooks/use-descriptions";

const piplDescriptionStrings: PiplDescriptionProp[] = [
  {
    string: getLocalString("Signup_ChinaPIPLConsent_DataTransfer_Desc_V2"),
    embedPrivacy: false,
    dataTestId: "china-pipl-consent-description-1",
  },
  {
    string: getLocalString("Signup_ChinaPIPLConsent_DataTransfer_Desc2_V2"),
    embedPrivacy: false,
    dataTestId: "china-pipl-consent-description-2",
  },
  {
    string: getLocalString("Signup_ChinaPIPLConsent_DataTransfer_Desc3_V2"),
    embedPrivacy: true,
    dataTestId: "china-pipl-consent-description-3",
  },
];

export const ChinaPiplStringsFabric: ChinaPiplStrings = {
  title: getLocalString("Signup_ChinaPIPLConsent_DataTransfer_Title"),
  piplDescriptionStrings,
  primaryButtonLabel: getLocalString("Signup_ChinaPIPLConsent_AcceptAndContinue_Button"),
  secondaryButtonLabel: getLocalString("Signup_ChinaPIPLConsent_DeclineAndExit_Button"),
};
