import { MemberNameType } from "@msidentity/SISU/model/user";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { CheckAvailableErrorCode as ErrorCode } from "../../../../../utilities/api-helpers/check-available-signin-names/check-available-signin-names-types";
import SignUpConfig from "../../../signup-config";
import { type UsernameCollectionViewErrorStringsFabric } from "./username-collection-view-fabric-types";
/**
 * Builds the error map with flavored strings for check available sign in names
 * @param strings Fabric flavored CheckAvailableSigninNames error strings
 * @returns A callback to get the error strings given a CheckAvailableSignInNames error code
 */
export const getCheckAvailableErrorMapFabric = (
  strings: UsernameCollectionViewErrorStringsFabric,
) => {
  const { showSignInLinkInUsernameError, isPhoneSupported } = SignUpConfig.instance;

  const getCheckAvailableError = (
    errorCode: string,
    username: string,
    usernameType: string,
    hasSuggestions: boolean,
  ) => {
    let { memberNameTaken, memberNameTakenEasi, memberNameTakenPhone } = strings;

    if (showSignInLinkInUsernameError) {
      const {
        signInLinkMemberNameTaken,
        signInLinkMemberNameTakenEasi,
        signInLinkMemberNameTakenPhone,
      } = strings;

      memberNameTaken = replaceTokens(signInLinkMemberNameTaken, username);
      memberNameTakenEasi = replaceTokens(signInLinkMemberNameTakenEasi, username);
      memberNameTakenPhone = signInLinkMemberNameTakenPhone;
    } else {
      memberNameTakenEasi = replaceTokens(memberNameTakenEasi, username);
    }

    switch (errorCode) {
      case ErrorCode.domainExistsInAad:
        return strings.domainExistsInAad;
      case ErrorCode.domainExistsInAadSupportedLogin:
        return replaceTokens(strings.domainExistsInAadSupportedLogin, username);
      case ErrorCode.domainIsReserved:
      case ErrorCode.domainNotAllowed:
        return strings.domainNotAllowed;
      case ErrorCode.signupBlocked:
        return replaceTokens(strings.signupBlocked, username);
      case ErrorCode.invalidEmailFormat:
        return strings.invalidEmailFormat;
      case ErrorCode.invalidPhoneFormat:
        return strings.invalidPhoneFormat;
      case ErrorCode.invalidMemberNameFormat:
        return usernameType === MemberNameType.Phone
          ? strings.invalidPhoneFormat
          : strings.invalidEmailFormat;
      case ErrorCode.phoneSignupBlocked:
        return strings.phoneSignupBlocked;
      case ErrorCode.memberNameTaken:
        return hasSuggestions ? memberNameTaken : strings.memberNameTakenNoSuggestions;
      case ErrorCode.memberNameTakenEasi:
        return memberNameTakenEasi;
      case ErrorCode.memberNameTakenPhone:
        return isPhoneSupported ? memberNameTakenPhone : memberNameTaken;
      default:
        return "";
    }
  };

  return getCheckAvailableError;
};
