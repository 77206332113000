import { createChallengeState } from "@msidentity/SISU/challenge-context";
import { initDateConfig } from "@msidentity/SISU/config/date-config";
import { type UserOptions, getUpdatedUser } from "@msidentity/SISU/global-reducer";
import { type IUser, type MemberNameType } from "@msidentity/SISU/model/user/user-types";
import { type ServerData } from "@msidentity/SISU/utilities/server-data";
import { createSignUpState } from "../../flows/signup/signup-context";

export const initSignUpApp = (
  currentUser: IUser,
  initialMemberNameType: MemberNameType,
  isParentFlow: boolean,
  serverData: ServerData,
) => {
  let initialUser = currentUser;

  initDateConfig(serverData);

  const initialSignUpState = createSignUpState(serverData, initialMemberNameType, isParentFlow);
  // Set the user if a prefill username was chosen
  const prefillUsername = initialSignUpState.memberName;
  if (prefillUsername !== "") {
    initialUser = getUpdatedUser(initialUser, {
      username: prefillUsername,
      displayUsername: prefillUsername,
    } as UserOptions);
  }

  const initialChallengeState = createChallengeState(serverData);

  return { initialUser, initialSignUpState, initialChallengeState };
};
