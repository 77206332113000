import { FlowId, ViewId } from "@msidentity/SISU/constants";
import {
  useActivateView,
  useDocumentTitle,
  useShowProgressAndRedirect,
} from "@msidentity/SISU/hooks";
import { appendOrReplaceQueryStringParams } from "@msidentity/SISU/utilities/strings-helper";
import SignUpConfig from "../../../signup-config";
import { useSignUpContext } from "../../../signup-context";

export const useEvictionError = (documentTitle: string) => {
  const { evictionSignInUrl } = SignUpConfig.instance;
  const {
    viewState: { memberNameInput },
  } = useSignUpContext();

  useActivateView(ViewId.SignUpEvictionError, FlowId.Signup, {
    showBackButtonOnActiveView: true,
  });
  useDocumentTitle(documentTitle);

  const signInUrl = appendOrReplaceQueryStringParams(evictionSignInUrl, {
    username: memberNameInput.input,
  });
  const showProgressAndRedirect = useShowProgressAndRedirect();

  return { nextOnClick: () => showProgressAndRedirect(signInUrl) };
};
