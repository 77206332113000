import React from "react";
import { useBirthDateStyles } from "../../../styles/fabric/birthdate-fabric.styles";
import useFabricStyles from "../../../styles/fabric/fabric.styles";
import { type DateOrder, getBirthdateOrder } from "../../../utilities/birthdate-helper";
import { mergeClasses } from "../../../utilities/merge-classes";
import ErrorContainerFabric from "../../error-container/fabric/error-container-fabric";
import { type DropdownProps } from "../../inputs/dropdown/dropdown";
import { DropdownFabric } from "../../inputs/dropdown/fabric/dropdown-fabric";
import { NumberInputFabric } from "../../inputs/number-input/fabric/number-input-fabric";
import { type NumberInputProps } from "../../inputs/number-input/number-input";

export type BirthdateProps = {
  /** The day dropdown properties */
  birthDayProps: DropdownProps;
  /** The month dropdown properties */
  birthMonthProps: DropdownProps;
  /** The year input properties */
  birthYearProps: NumberInputProps;
  /** The order of the birthdate. This is based on the user locale or market (e.g., USA is "MDY", UK is "DMY", Canada is "YDM"). */
  dateOrder: DateOrder;
  /** The error message shown in the component. */
  errorMessage?: string;
  /** Whether or not focus is set on the component. When true, focus is set on the first birthdate control (default is false) */
  hasFocus?: boolean;
  /** The text to display above the birthdate controls (e.g., Birthdate) */
  labelText?: string;
};

/**
 * Birthdate component
 * @param props The properties for this component
 * @returns A birthdate component
 */
export const BirthdateFabric: React.FC<BirthdateProps> = function BirthdateFabric(props) {
  const {
    dateOrder,
    errorMessage = "",
    hasFocus = false,
    birthDayProps,
    birthMonthProps,
    birthYearProps,
    labelText,
  } = props;

  const fabricStyles = useFabricStyles();
  const birthDateStyles = useBirthDateStyles();

  const birthdateOrder = getBirthdateOrder(
    birthDayProps,
    birthMonthProps,
    birthYearProps,
    dateOrder,
  );

  const birthdateControls = () =>
    // Loop through the birthdateOrder array and create an input for the birth year and dropdowns for the birth month/day
    birthdateOrder.map((birthdateControl: DropdownProps | NumberInputProps, index) => {
      // Focus is set to the first control when hasFocus is true
      const isFirstElement = index === 0;
      return (
        <div
          className={mergeClasses(fabricStyles.formGroup, birthDateStyles.formField)}
          key={birthdateControl.id}
        >
          {dateOrder[index] === "Y" ? (
            <NumberInputFabric
              {...(birthdateControl as NumberInputProps)}
              aria-describedby="birthdateError"
              hasFocus={isFirstElement && hasFocus}
            />
          ) : (
            <DropdownFabric
              {...(birthdateControl as DropdownProps)}
              aria-describedby="birthdateError"
              hasFocus={isFirstElement && hasFocus}
            />
          )}
        </div>
      );
    });

  return (
    <div className={mergeClasses(fabricStyles.row, birthDateStyles.rowAfter)}>
      {labelText && (
        <div className={fabricStyles.containerWithPadding}>
          <div
            className={mergeClasses(
              fabricStyles.formFieldLabel,
              fabricStyles.noMarginTop,
              fabricStyles.noMarginBottom,
            )}
          >
            {labelText}
          </div>
        </div>
      )}
      <div className={fabricStyles.containerWithPadding}>
        {errorMessage && (
          <ErrorContainerFabric id="birthdateError">{errorMessage}</ErrorContainerFabric>
        )}
      </div>
      <div
        className={mergeClasses(fabricStyles.formGroup, fabricStyles.formControl)}
        data-testid="birthdateControls"
      >
        {birthdateControls()}
      </div>
    </div>
  );
};
