import { useEffect } from "react";
import { useEffectOnce } from "../../../hooks";
import { INPUT_FOCUS_TIMEOUT } from "../input/input";

export type FocusHandlingStateOptions = {
  elementRef: React.RefObject<HTMLElement>;
  hasInitialFocus?: boolean;
  hasFocus?: boolean;
};

/**
 * This hook is meant to be used to handle the focus state for the dropdown components.
 * @param options Options for the dropdown UX behavior
 * - `elementRef`: Ref for the focusable element.
 * - `hasInitialFocus`: An initial focus state for the dropdown. Defaults to false.
 * - `hasFocus`: The initial focus state for the dropdown. Defaults to false.
 */
export const useFocusHandling = (options: FocusHandlingStateOptions) => {
  const { elementRef, hasInitialFocus = false, hasFocus = false } = options;
  // Provide initial focus, if requested
  useEffectOnce(() => {
    // Delay focus until view transition animations are completed.
    if (hasInitialFocus) {
      const timeoutId = setTimeout(() => {
        if (elementRef) {
          elementRef?.current?.focus();
        }
      }, INPUT_FOCUS_TIMEOUT);
      return () => clearTimeout(timeoutId);
    }

    return () => {};
  });

  useEffect(() => {
    if (hasFocus) {
      if (elementRef) {
        elementRef?.current?.focus();
      }
    }
  }, [elementRef, hasFocus]);
};
