import { type InputValue } from "@msidentity/SISU/components/inputs/input-interface";
import {
  type ValidationErrorMessages,
  PasswordValidationResult,
  validatePassword,
} from "@msidentity/SISU/model/password/password-validator";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { type PasswordCollectionErrorStrings } from "./password-collection-view-interface";

/**
 * Provides a map of validation error messages for the password collection view
 * @param strings The strings for the password collection view
 * @param memberNameType The member name type
 * @returns A map of validation error messages
 */
export const passwordValidationErrorMap = (
  strings: PasswordCollectionErrorStrings,
  memberNameType: MemberNameType,
) => {
  const validationErrorMessages: ValidationErrorMessages = {
    [PasswordValidationResult.Empty]: strings.errorPasswordRequired,
    [PasswordValidationResult.InvalidCharacters]: strings.errorInvalidCharacters,
    [PasswordValidationResult.NotComplex]: strings.errorNotComplexEnough,
    [PasswordValidationResult.TooShort]: strings.errorPasswordShort,
    [PasswordValidationResult.ContainsMemberName]:
      memberNameType === MemberNameType.Phone
        ? strings.errorContainsMemberNamePhoneNumber
        : strings.errorContainsMemberNameEmail,
  };
  return validationErrorMessages;
};

/**
 * Provides a password validation handler for the password collection view
 * @param memberName The member name
 * @param validationErrorMap The mapped validation error messages
 * @param genericErrorMessage The generic error message to show when a mapped error message is not found
 * @returns A password validation handler for the password collection view
 */
export const passwordValidationHandler =
  (memberName: string, validationErrorMap: ValidationErrorMessages, genericErrorMessage: string) =>
  (password: InputValue) => {
    const validationResult = validatePassword(String(password), false, memberName);

    if (validationResult !== PasswordValidationResult.Ok) {
      return validationErrorMap[validationResult] || genericErrorMessage;
    }

    return "";
  };
