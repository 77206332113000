import React from "react";
import { ViewContainerFabric as ViewContainer } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import { useEffectOnce } from "@msidentity/SISU/hooks";
import {
  createEmbeddedLinkButtonWithPropsFabric,
  createEmbeddedLinkFabric,
} from "@msidentity/SISU/utilities/formatted-text-with-bindings";
import { replaceTokens } from "@msidentity/SISU/utilities/strings-helper";
import { commonSignUpStringsFabric as commonStrings } from "../../../fabric/common-signup-strings-fabric";
import { useSignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";
import { useEvictionSpeedbumpFabric } from "./hooks/use-eviction-speedbump-fabric";
import { evictionSpeedbumpViewStringsFabric as viewStrings } from "./eviction-speedbump-view-strings-fabric";

/**
 * EvictionSpeedbumpViewFabric component
 * @returns A rendered instance of this component
 */
export const EvictionSpeedbumpViewFabric: React.FC = function EvictionSpeedbumpViewFabric() {
  const { dispatchStateChange } = useSignUpContext();
  const { memberName, signInUrl, onContinueToCreate } = useEvictionSpeedbumpFabric(
    commonStrings.signUpTitle,
  );

  useEffectOnce(() => {
    dispatchStateChange({
      actionType: SignUpActionType.UpdateEvictionWarningMemberName,
      payload: memberName,
    });
  });

  const signUpLink = createEmbeddedLinkButtonWithPropsFabric({
    hasFocus: true,
    onClick: onContinueToCreate,
  });
  const signInLink = createEmbeddedLinkFabric(signInUrl);

  return (
    <ViewContainer
      title={{ title: commonStrings.signUpTitle, titleId: "evictionSpeedbumpViewTitle" }}
      description={[
        {
          description: replaceTokens(viewStrings.description, memberName),
          descriptionId: "evictionSpeedbumpViewDescription",
        },
        {
          description: viewStrings.optionToSignUp,
          embeddedBindings: { signUpLink },
          dataTestId: "signUpOption",
        },
        {
          description: viewStrings.optionToSignIn,
          embeddedBindings: { signInLink },
          dataTestId: "signInOption",
        },
      ]}
    />
  );
};
