import { type RequiredNumberInputProps } from "./number-input-types";

/**
 * Returns updated input props with the input `type` set to `number` and `aria-required` set to true
 * if not specified in the original input props
 * @param props The initial properties for the number input component
 * @returns The updated properties for the number input component
 */
export const buildNumberInputProps = <T extends RequiredNumberInputProps>(
  props: T,
): T & { type: "number" } => {
  const { "aria-required": ariaRequired = "true" } = props;

  return {
    ...props,
    type: "number",
    "aria-required": ariaRequired,
  };
};
