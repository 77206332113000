import { MemberNameType as UsernameType } from "@msidentity/SISU/model/user";
import { type AccountPrefillInfo } from "@msidentity/SISU/types/account-prefill-types";
import { useSignUpContext } from "../../../../signup-context";
import { SignUpFlowType } from "../../../../signup-types";
import { signUpFlowToInitialInputType } from "../../../../utilities/signup-utilities";
import { type UsernameCollectionViewStringsFabric } from "../username-collection-view-fabric-types";

export type UseUsernameOptionsFabricParams = {
  signUpFlow: string;
  isPhoneSupported: boolean;
  prefill: Partial<AccountPrefillInfo>;
  isParentFlow: boolean;
  strings: {
    usernameCollectionViewStrings: Partial<UsernameCollectionViewStringsFabric>;
  };
};

export type UseUsernameOptionsFabricProps = {
  initialInputType: UsernameType;
  easiSwitchLinkText?: string;
  outlookSwitchLinkText?: string;
  phoneSwitchLinkText?: string;
};

/**
 * @param props Props for useUsernameOptionsFabric
 * @param props.signUpFlow The signup flow type
 * @param props.strings Flavored strings
 * @param props.strings.usernameCollectionViewStrings Username collection view strings
 * @param props.isPhoneSupported Flag for whether phone sign up is supported or not
 * @param props.prefill The prefill object
 * @param props.isParentFlow Flag for whether the flow is in the create new child flow
 * @returns The base props to determine options shown by the username collection component for the Fabric view.
 * This will include switch links and initial input type, based on the signup flow type.
 */
export const useUsernameOptionsFabric = ({
  signUpFlow,
  isPhoneSupported,
  prefill,
  isParentFlow,
  strings: { usernameCollectionViewStrings },
}: UseUsernameOptionsFabricParams): UseUsernameOptionsFabricProps => {
  const {
    viewState: { memberNameType },
  } = useSignUpContext();
  const initialInputType =
    memberNameType !== UsernameType.Unknown
      ? memberNameType
      : signUpFlowToInitialInputType({ signUpFlow, isPhoneSupported, prefill });

  const { easiSwitchLink, outlookSwitchLink, phoneSwitchLink, easiChildSwitchLink } =
    usernameCollectionViewStrings;
  const switchLinks: Partial<UseUsernameOptionsFabricProps> = {};

  // Add switch links based on the flow type - this will determine which flavored input props are created
  if (signUpFlow === SignUpFlowType.Email) {
    switchLinks.easiSwitchLinkText = easiSwitchLink;
    switchLinks.outlookSwitchLinkText = outlookSwitchLink;
  } else if (
    signUpFlow === SignUpFlowType.DefaultEasi ||
    signUpFlow === SignUpFlowType.DefaultLive ||
    signUpFlow === SignUpFlowType.DefaultPhone ||
    signUpFlow === SignUpFlowType.AlwaysDefaultPhone
  ) {
    switchLinks.easiSwitchLinkText = isParentFlow ? easiChildSwitchLink : easiSwitchLink; // Sign up flow defaults to live if isParentFlow, so we only need to check it here
    switchLinks.outlookSwitchLinkText = outlookSwitchLink;
    switchLinks.phoneSwitchLinkText = isPhoneSupported ? phoneSwitchLink : "";
  } else if (
    signUpFlow === SignUpFlowType.DefaultEasiNoLive ||
    signUpFlow === SignUpFlowType.DefaultPhoneNoLive
  ) {
    // Only show EASI or Phone signup switch links for these signup flow types
    switchLinks.easiSwitchLinkText = easiSwitchLink;
    switchLinks.phoneSwitchLinkText = isPhoneSupported ? phoneSwitchLink : "";
  }

  return {
    initialInputType,
    ...switchLinks,
  };
};
