import React from "react";
import { UsernameCollectionFabric } from "@msidentity/SISU/components/username-collection/fabric/username-collection-fabric";
import { ViewContainerFabric } from "@msidentity/SISU/components/view-container/fabric/view-container-fabric";
import StylesConfig from "@msidentity/SISU/config/styles-config";
import { mergeClasses } from "@msidentity/SISU/utilities/merge-classes";
import { commonSignUpStringsFabric as commonSignUpStrings } from "../../../fabric/common-signup-strings-fabric";
import { createAccountErrorStringsFabric as createAccountErrorStrings } from "../../../fabric/create-account-error-strings-fabric";
import { useUsernameCollectionFabric } from "./hooks/use-username-collection-fabric";
import {
  usernameCollectionViewErrorStringsFabric as usernameCollectionViewErrorStrings,
  usernameCollectionViewStringsFabric as usernameCollectionViewStrings,
} from "./username-collection-view-strings-fabric";

/**
 * UsernameCollectionViewFabric component
 * @returns A rendered instance of this component
 */
export const UsernameCollectionViewFabric: React.FC = function UsernameCollectionViewFabric() {
  // Styles config data
  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;

  const {
    backOnClick,
    hideBackButton,
    onSubmit,
    pageTitle,
    pageDescription,
    pageImage,
    prefillSpeedbumpDescription,
    showPrefillSpeedbump,
    showSmsDisclaimer,
    usernameCollectionFabricInputProps,
  } = useUsernameCollectionFabric({
    strings: {
      commonSignUpStrings,
      usernameCollectionViewStrings,
      usernameCollectionViewErrorStrings,
      createAccountErrorStrings,
    },
  });

  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  return (
    <ViewContainerFabric
      title={{ title: pageTitle, titleId: "usernameCollectionViewTitle" }}
      image={pageImage}
      description={
        pageDescription
          ? {
              description: pageDescription,
              descriptionId: "usernameCollectionViewDescription",
            }
          : undefined
      }
      primaryButton={{
        ariaDescribedBy: "usernameCollectionViewTitle usernameCollectionViewDescription",
        type: "submit",
        label: commonSignUpStrings.nextButton,
        hasFocus: showPrefillSpeedbump,
        buttonId: "nextButton",
      }}
      secondaryButton={
        !hideBackButton
          ? {
              label: commonSignUpStrings.backButton,
              onClick: backOnClick,
              buttonId: "backButton",
            }
          : undefined
      }
      form={{
        onSubmit,
      }}
    >
      {showPrefillSpeedbump ? (
        <div className={commonStyles.row}>
          <div data-testid="prefillSpeedbumpDescription" className={commonStyles.formGroup}>
            {prefillSpeedbumpDescription}
          </div>
        </div>
      ) : (
        <UsernameCollectionFabric {...usernameCollectionFabricInputProps} />
      )}
      {showSmsDisclaimer && (
        <div className={commonStyles.positionButtons}>
          <div
            data-testid="smsDisclaimer"
            className={mergeClasses(commonStyles.textCaption, commonStyles.formGroup)}
          >
            {usernameCollectionViewStrings.smsDisclaimer}
          </div>
        </div>
      )}
    </ViewContainerFabric>
  );
};
