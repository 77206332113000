import { UserFlowType } from "@msidentity/SISU/constants";
import FeaturesConfig from "@msidentity/SISU/features-config";
import { getUpdatedUserFlowType } from "@msidentity/SISU/global-reducer";
import { SmsActionType } from "@msidentity/SISU/model/proof";
import type { AccountPrefillInfo } from "@msidentity/SISU/types/account-prefill-types";
import { ConfigWrapper } from "@msidentity/SISU/utilities/config-wrapper";
import {
  type ICountryDetail,
  type ICountryInfo,
  type ICountryRegionInfo,
} from "@msidentity/SISU/utilities/country-helper";
import { extractQueryStringParams } from "@msidentity/SISU/utilities/query-params-helper";
import type { ServerData } from "@msidentity/SISU/utilities/server-data";
import { SignUpQueryParams, SignUpScenario } from "../../constants/query-strings";
import { SignUpFlowType } from "./signup-types";

export const defaultFamilyConfig = {
  onSuccessUrl: "",
  onErrorUrl: "",
  createAndUpdateFamilyUrl: "",
  addPendingMemberToFamilyUrl: "",
  addToFamilyChildFailureUrl: "",
  familySizeLimitReachedUrl: "",
  addToFamilyParentFailureUrl: "",
  memberAlreadyInFamilyUrl: "",
  cannotInviteExistingMemberUrl: "",
  childConsentFamilyReturnUrl: "",
  memberRole: "",
  childConsentCookieDomain: "",
  childConsentCookieName: "",
  childConsentReturnUrlCookieName: "",
  isFamilyAddMemberFlow: false,
  isFamilyScenario: false,
};

export const defaultSignUpConfig = {
  appBrandedSignUpDescription: "",
  checkAvailableSigninNamesUrl: "",
  collectBirthDateCountry: false,
  collectFirstNameLastName: false,
  consumeOttUrl: "",
  countryDetailsMap: {} as Record<string, ICountryDetail>,
  countryList: [] as ICountryRegionInfo[],
  createAccountUrl: "",
  defaultPhoneCountry: "",
  domainList: [] as string[],
  emailNoPaEnabled: false,
  evictionSignInUrl: "",
  family: {
    ...defaultFamilyConfig,
  },
  familySafetyAppUrl: "",
  finalBackUrl: "",
  hideBackButton: false, // TODO: Specify UsernameCollection/SignupBlocked  here, as it only affects those views
  ignoreSso: false,
  isFirstLastOrder: true,
  isLightWeightSignUp: false,
  isOptinEmailInitialValue: false /* Used only for the Create Account request */,
  isPhoneSupported: false,
  isRDM: false,
  personalDataCollectedUrl: "",
  phoneCountryList: [] as ICountryInfo[],
  phoneNoPaEnabled: false,
  phoneNumberTakenUrl: "https://aka.ms/numbertaken",
  prefill: {} as Partial<AccountPrefillInfo>,
  privacyKRUrl: "",
  publicKey: "",
  retentionPeriodUrl: "",
  riskBlockLearnMoreUrl: "",
  sendCodeInfo: {
    sendOttAction: SmsActionType.SignUp,
    sendOttUrl: "",
  },
  showOptinEmail: false,
  showOutlookOption: false,
  showSignInLinkInUsernameError: false,
  showUsernameRecoverySpeedbump: false,
  showChinaPiplConsentView: false,
  showKoreaConsentText: false,
  showKoreaPrivacyConsentView: false,
  signUpFlow: SignUpFlowType.DefaultEasi.toString(),
  skipCredentialsPage: false,
  suggestedAccountType: "",
  useOfPersonalDataUrl: "",
  usernameRecoveryUrl: "",
  usernameRecoverySpeedbumpSignInUrl: "",
};

export type SignUpConfigType = typeof defaultSignUpConfig;

/**
 * The config wrapper that should be used to access SignUp config properties
 */
// eslint-disable-next-line deprecation/deprecation
const configWrapper = new ConfigWrapper(defaultSignUpConfig);
export default configWrapper;

/* ********* ServerData helpers ********** */

/**
 * Creates the inner `family` SignUp config from ServerData
 * @param serverData The MSA server data object that should be used to initialize the SignUp config
 * @returns The inner `family` SignUp config based on the provided ServerData
 */
export function initSignUpFamilyConfig(serverData: ServerData) {
  const family = {
    ...defaultFamilyConfig,
  };

  if (serverData?.fIsFamilyAddMemberFlow) {
    family.isFamilyAddMemberFlow = serverData.fIsFamilyAddMemberFlow;
  }

  if (serverData?.urlCreateAndUpdateFamily) {
    family.createAndUpdateFamilyUrl = serverData.urlCreateAndUpdateFamily;
  }

  if (serverData?.urlAddPendingMember) {
    family.addPendingMemberToFamilyUrl = serverData.urlAddPendingMember;
  }

  if (serverData?.urlAddToFamilyChildFailure) {
    family.addToFamilyChildFailureUrl = serverData.urlAddToFamilyChildFailure;
  }

  if (serverData?.urlAddToFamilyParentFailure) {
    family.addToFamilyParentFailureUrl = serverData.urlAddToFamilyParentFailure;
  }

  if (serverData?.urlCannotInviteExistingMember) {
    family.cannotInviteExistingMemberUrl = serverData.urlCannotInviteExistingMember;
  }

  if (serverData?.urlFamilySizeLimitReached) {
    family.familySizeLimitReachedUrl = serverData.urlFamilySizeLimitReached;
  }

  if (serverData?.urlMemberAlreadyInFamily) {
    family.memberAlreadyInFamilyUrl = serverData.urlMemberAlreadyInFamily;
  }

  if (serverData?.urlChildConsentFamilyReturn) {
    family.childConsentFamilyReturnUrl = serverData.urlChildConsentFamilyReturn;
  }

  if (serverData?.urlFamilyOnSuccess) {
    family.onSuccessUrl = serverData.urlFamilyOnSuccess;
  }

  if (serverData?.urlFamilyOnError) {
    family.onErrorUrl = serverData.urlFamilyOnError;
  }

  if (serverData?.sChildConsentCookieDomain) {
    family.childConsentCookieDomain = serverData.sChildConsentCookieDomain;
  }

  if (serverData?.sChildConsentCookieName) {
    family.childConsentCookieName = serverData.sChildConsentCookieName;
  }

  if (serverData?.sChildConsentRUCookieName) {
    family.childConsentReturnUrlCookieName = serverData.sChildConsentRUCookieName;
  }

  if (serverData?.sMemberRole) {
    family.memberRole = serverData.sMemberRole;
  }

  const queryParams = { scenarioParam: SignUpQueryParams.Scenario };
  const { scenarioParam } = extractQueryStringParams(queryParams);
  if (scenarioParam === SignUpScenario.Family) {
    family.isFamilyScenario = true;
  }

  return family;
}

/**
 * Initialize the SignUp config object from ServerData
 * @param serverData The MSA server data object that should be used to initialize the SignUp config
 */
export function initSignUpConfig(serverData: ServerData) {
  const { isSimplifiedChildAccountCreation } = FeaturesConfig.instance;
  let isParentFlow = false;

  if (isSimplifiedChildAccountCreation) {
    const queryParams = { uft: "uft" };
    const { uft } = extractQueryStringParams(queryParams);
    const userFlowType = getUpdatedUserFlowType(UserFlowType.Unknown, uft);
    isParentFlow = isSimplifiedChildAccountCreation && userFlowType === UserFlowType.Parent;
  }

  const mappedServerData: Partial<SignUpConfigType> = {};
  mappedServerData.family = initSignUpFamilyConfig(serverData);

  mappedServerData.publicKey = window.SKI || "";

  if (serverData?.arrDomainList) {
    mappedServerData.domainList = serverData.arrDomainList;
  }

  if (serverData?.arrPhoneCountryList) {
    mappedServerData.phoneCountryList = serverData.arrPhoneCountryList;
  }

  if (serverData?.fCheckOptinEmail !== undefined) {
    // This static property is used in the CreateAccount request to provide the default state of marketing preferences during initial load ("IsOptOutEmailDefault").
    // The context property "isOptInEmail" in SignUp state should be used instead for managing the user's opt-in/out marketing preferences.
    mappedServerData.isOptinEmailInitialValue = !isParentFlow && serverData.fCheckOptinEmail;
  }

  if (serverData?.fCollectBirthDateCountry !== undefined) {
    mappedServerData.collectBirthDateCountry = serverData.fCollectBirthDateCountry;
  }

  if (serverData?.fCollectFirstNameLastName !== undefined) {
    mappedServerData.collectFirstNameLastName = serverData.fCollectFirstNameLastName;
  }

  if (serverData?.fDisplaySignInLinkInMnError !== undefined) {
    mappedServerData.showSignInLinkInUsernameError = serverData.fDisplaySignInLinkInMnError;
  }

  if (serverData?.fIsFirstLastOrder !== undefined) {
    mappedServerData.isFirstLastOrder = serverData.fIsFirstLastOrder;
  }

  if (serverData?.fIgnoreSso !== undefined) {
    mappedServerData.ignoreSso = serverData.fIgnoreSso;
  }

  if (serverData?.fIsLightWeight !== undefined) {
    mappedServerData.isLightWeightSignUp = serverData.fIsLightWeight;
  }

  if (serverData?.fIsPhoneSupported) {
    mappedServerData.isPhoneSupported = !isParentFlow && serverData.fIsPhoneSupported;
  }

  if (serverData?.fIsRDM) {
    mappedServerData.isRDM = serverData.fIsRDM;
  }

  if (serverData?.fPasswordlessEmailEnabled !== undefined) {
    mappedServerData.emailNoPaEnabled = serverData.fPasswordlessEmailEnabled;
  }

  if (serverData?.fPasswordlessPhoneEnabled !== undefined) {
    mappedServerData.phoneNoPaEnabled = serverData.fPasswordlessPhoneEnabled;
  }

  if (serverData?.fShowOptinEmail !== undefined) {
    mappedServerData.showOptinEmail = !isParentFlow && serverData.fShowOptinEmail;
  }

  if (serverData?.fShowOutlookOptionOnSignup) {
    mappedServerData.showOutlookOption = serverData.fShowOutlookOptionOnSignup;
  }

  if (serverData?.fShowUsernameRecoverySpeedbump !== undefined) {
    mappedServerData.showUsernameRecoverySpeedbump = serverData.fShowUsernameRecoverySpeedbump;
  }

  if (serverData?.oAppCobranding) {
    const appBranding = serverData.oAppCobranding;
    if (appBranding?.signupDescription) {
      mappedServerData.appBrandedSignUpDescription = appBranding.signupDescription;
    }
  }

  if (serverData?.oPrefill) {
    mappedServerData.prefill = serverData.oPrefill;
  }

  if (serverData?.oSendCodeInfo) {
    const sendOttAction: SmsActionType =
      serverData.oSendCodeInfo.sSendOttAction || SmsActionType.SignUp;
    mappedServerData.sendCodeInfo = {
      sendOttAction,
      sendOttUrl: serverData.oSendCodeInfo.urlSendOtt || "",
    };
  }

  if (serverData?.sPrefSMSCountry) {
    mappedServerData.defaultPhoneCountry = serverData.sPrefSMSCountry;
  }

  if (serverData?.sSignupFlow) {
    mappedServerData.signUpFlow = isParentFlow
      ? SignUpFlowType.DefaultLive
      : serverData.sSignupFlow;
  }

  if (serverData?.urlCheckAvailableSigninNames) {
    mappedServerData.checkAvailableSigninNamesUrl = serverData.urlCheckAvailableSigninNames;
  }

  if (serverData?.urlCreateAccount) {
    mappedServerData.createAccountUrl = serverData.urlCreateAccount;
  }

  if (serverData?.urlFamilySafetyAppUrl) {
    mappedServerData.familySafetyAppUrl = serverData.urlFamilySafetyAppUrl;
  }

  if (serverData?.sSuggestedAccountType) {
    mappedServerData.suggestedAccountType = serverData.sSuggestedAccountType;
  }

  if (serverData?.arrCountryData) {
    mappedServerData.countryList = serverData.arrCountryData;
  }

  if (serverData?.arrCountryDetailMap) {
    mappedServerData.countryDetailsMap = serverData.arrCountryDetailMap;
  }

  if (serverData?.urlPrivacyKR) {
    mappedServerData.privacyKRUrl = serverData.urlPrivacyKR;
  }

  if (serverData?.urlPersonalDataCollected) {
    mappedServerData.personalDataCollectedUrl = serverData.urlPersonalDataCollected;
  }

  if (serverData?.urlRetentionPeriod) {
    mappedServerData.retentionPeriodUrl = serverData.urlRetentionPeriod;
  }

  if (serverData?.urlUseOfPersonalData) {
    mappedServerData.useOfPersonalDataUrl = serverData.urlUseOfPersonalData;
  }

  if (serverData?.urlUsernameRecovery) {
    mappedServerData.usernameRecoveryUrl = serverData.urlUsernameRecovery;
  }

  if (serverData?.urlUsernameRecoverySpeedbumpSignin) {
    mappedServerData.usernameRecoverySpeedbumpSignInUrl =
      serverData.urlUsernameRecoverySpeedbumpSignin;
  }

  if (serverData?.fHideBackButton !== undefined) {
    mappedServerData.hideBackButton = serverData.fHideBackButton;
  }

  if (serverData?.urlFinalBack) {
    mappedServerData.finalBackUrl = serverData.urlFinalBack;
  }

  if (serverData?.oConsumeCodeInfo?.urlConsumeOtt) {
    mappedServerData.consumeOttUrl = serverData.oConsumeCodeInfo.urlConsumeOtt;
  }

  if (serverData?.fShowKoreaConsentView) {
    mappedServerData.showKoreaPrivacyConsentView = true;
  }

  if (serverData?.fRequiresConsentText) {
    mappedServerData.showKoreaConsentText = true;
  }

  if (serverData?.fShowChinaPIPLConsentView) {
    mappedServerData.showChinaPiplConsentView = true;
  }

  if (serverData?.urlEvictionSignin) {
    mappedServerData.evictionSignInUrl = serverData.urlEvictionSignin;
  }

  if (serverData?.urlRiskBlockLearnMore) {
    mappedServerData.riskBlockLearnMoreUrl = serverData.urlRiskBlockLearnMore;
  }

  if (serverData?.fSkipCredentialsPage) {
    mappedServerData.skipCredentialsPage = serverData.fSkipCredentialsPage;
  }

  configWrapper.initialize(mappedServerData);
}
