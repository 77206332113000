import { ExperimentFeature, TreatmentFlight } from "@msidentity/SISU/config/experiments";
import { ApiNames, UserFlowType, ViewId } from "@msidentity/SISU/constants";
import { useCustomizationContext } from "@msidentity/SISU/context/customization-context";
import FeaturesConfig from "@msidentity/SISU/features-config";
import GlobalConfig from "@msidentity/SISU/global-config";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import {
  useExperiment,
  useNavigateDirection,
  useRedirect,
  useShowProgressAndRedirect,
} from "@msidentity/SISU/hooks";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { useTelemetryState } from "@msidentity/SISU/telemetry-helpers/use-telemetry-state";
import {
  appendOrReplaceQueryStringParams,
  extractQueryStringParam,
} from "@msidentity/sisu/utilities/strings-helper";
import {
  errorResponseAggregator,
  useBuildRepMapRequest,
  useSaveRepMapResponse,
} from "@msidentity/SISU/views/challenge/hooks/use-repmap-params";
import { useTriggerChallenge } from "@msidentity/SISU/views/challenge/hooks/use-trigger-challenge";
import { addFamilyMember } from "../../../utilities/api-helpers/add-family-member/add-family-member";
import { ErrorCode } from "../../../utilities/api-helpers/create-account/create-account-interface";
import {
  type CreateAccountApiError,
  type ICreateAccountRequest,
  type ICreateAccountResponse,
  createAccount,
} from "../../../utilities/api-helpers/create-account/create-account-request";
import { PasswordEncryptor } from "../../reset-password/model";
import SignUpConfig from "../signup-config";
import { type IMemberNameReporting, useSignUpContext } from "../signup-context";
import { SignUpActionType } from "../signup-reducer";
import { getMemberNameType } from "../utilities/signup-utilities";
import { type SignInPostData, useSignInPostRedirect } from "./use-signin-post-redirect";

export interface ICreateAccountRequestContextParams {
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  checkAvailableStateMap: string[];
  country: string;
  evictionWarningMemberName: string[];
  firstName: string;
  isOptInEmail: boolean;
  isUserConsentedToChinaPIPL: boolean;
  lastName: string;
  memberName: string;
  memberNameType: string;
  password: string;
  reporting: IMemberNameReporting;
  verificationCode: string;
  verificationSlt: string;
}

export const getCreateAccountBirthDate = (
  birthDay: string,
  birthMonth: string,
  birthYear: string,
) => {
  // Set the birthdate value only when it is collected.
  if (birthDay && birthMonth && birthYear) {
    return `${birthDay.padStart(2, "0")}:${birthMonth.padStart(2, "0")}:${birthYear}`;
  }

  return undefined;
};

/**
 * Sets up the request data for the CreateAccount API request. Values that are dynamic (e.g., properties from context) are passed in as request params. Values that are static (e.g., siteId) are read from the config.
 * @param requestParams The request params that are passed in from the context
 * @param jsDeprecationTreatment Thre treatment flight for the js deprecation experiment
 * @param partnerTag CoBranding Partner Tag
 * @returns CreateAccount request data
 * TODO: Add additional properties to the request params as we build out this method.
 */
export const setupRequestData = (
  requestParams: ICreateAccountRequestContextParams,
  jsDeprecationTreatment?: TreatmentFlight,
  partnerTag?: string,
) => {
  const {
    isLightWeightSignUp,
    isOptinEmailInitialValue,
    isRDM,
    prefill,
    publicKey,
    showOptinEmail,
    suggestedAccountType,
  } = SignUpConfig.instance;

  const { siteId } = GlobalConfig.instance;

  const {
    birthDay,
    birthMonth,
    birthYear,
    checkAvailableStateMap,
    country,
    evictionWarningMemberName,
    firstName,
    isOptInEmail,
    isUserConsentedToChinaPIPL,
    lastName,
    memberName,
    reporting,
    password,
    verificationCode,
    verificationSlt,
  } = requestParams;

  const postData: ICreateAccountRequest = {
    BirthDate: getCreateAccountBirthDate(birthDay, birthMonth, birthYear),
    CheckAvailStateMap: checkAvailableStateMap,
    Country: country,
    EvictionWarningShown: evictionWarningMemberName,
    FirstName: firstName,
    IsRDM: isRDM,
    IsOptOutEmailDefault: !isOptinEmailInitialValue,
    IsOptOutEmailShown: showOptinEmail,
    IsOptOutEmail: !isOptInEmail,
    IsUserConsentedToChinaPIPL: isUserConsentedToChinaPIPL,
    LastName: lastName,
    LW: isLightWeightSignUp,
    MemberName: memberName,
    RequestTimeStamp: new Date().toISOString(),
    ReturnUrl: extractQueryStringParam("ru", window.location.href),
    SignupReturnUrl: extractQueryStringParam("sru", window.location.href),
    SuggestedAccountType: suggestedAccountType,
    SiteId: siteId,
    VerificationCode: verificationCode,
    VerificationCodeSlt: verificationSlt,
    WReply: extractQueryStringParam("wreply", window.location.href),
  };

  if (partnerTag) {
    postData.CobrandingPartnerTag = partnerTag;
  }

  // TODO: Add upgrade flow token - only needed for mojang upgrade scenario.

  // Indicate that one or more prefill was passed and used to create the account
  if (prefill && prefill.oMemberName) {
    postData.PrefillMemberNamePassed = true;
    postData.PrefillMemberNameUsed = true;
  }

  if (reporting) {
    postData.MemberNameChangeCount = reporting.memberNameChangeCount;
    postData.MemberNameAvailableCount = reporting.memberNameAvailableCount;
    postData.MemberNameUnavailableCount = reporting.memberNameUnavailableCount;
  }

  if (password) {
    if (
      jsDeprecationTreatment !== TreatmentFlight.EnableJsPublicKeyDeprecationExperimentTreatment
    ) {
      postData.CipherValue = PasswordEncryptor.encryptNewPassword(password);
      postData.SKI = publicKey;
    }

    postData.Password = password;
  }

  // TODO: Add alternate proof collection data (phoneNumber, phoneCountry, altEmail). Not used in Fabric.

  // TODO: Add GWPC properties to request params. This is related to Windows phone.

  // TODO: Add properties for DT (Win10 only) and DFT (Xbox only).

  // TODO: Add logic for setting additional request properties for HIP flow.

  return postData;
};

/**
 * @returns success callback for the CreateAccount request
 */
export const useCreateAccountSuccessHandler = () => {
  const signInPostRedirect = useSignInPostRedirect();
  const showProgressAndRedirect = useShowProgressAndRedirect();
  const { family: familyConfig, ignoreSso } = SignUpConfig.instance;
  const { isSimplifiedChildAccountCreation, isGamingFlow } = FeaturesConfig.instance;
  const { isFamilyAddMemberFlow } = familyConfig;
  const {
    globalState: { userFlowType },
  } = useGlobalContext();
  const telemetryState = useTelemetryState();

  return (response: ICreateAccountResponse) => {
    // TODO: Add prefill telemetry.

    // TODO: Add report to Atlas logic (not needed for Fabric).

    // TODO: Add logic for add family member scenario
    // TODO: hectorj remove this symbio filter once this flow is migrated to account.live.com
    if (isFamilyAddMemberFlow && !isSimplifiedChildAccountCreation) {
      addFamilyMember(
        {
          familyConfig,
          redirectCallback: showProgressAndRedirect,
          encryptedPuid: response.encPuid,
          signInName: response.signinName,
          childInfo: response.childInfo,
          kcft: response.kcft,
          memberNameType: getMemberNameType(response.signinName),
        },
        telemetryState,
      );
    }

    // TODO: else if isAddUserFlow && CXH...
    //    Add logic for CXH scenario
    else {
      const postData: SignInPostData = {
        slt: response.slt,
      };
      if (ignoreSso) {
        postData.ignoresso = 1;
      }

      if (isSimplifiedChildAccountCreation) {
        postData.scac = "1";
        postData.uft = userFlowType.toString();

        if (userFlowType === UserFlowType.AdultWithChild) {
          postData.scacsi = "1";
        }
      }

      if (isGamingFlow) {
        postData.gf = "1";
      }

      signInPostRedirect(response.redirectUrl || "", postData);
    }
  };
};

export const useHandleFatalError = () => {
  const { errorUrl } = GlobalConfig.instance;
  const redirect = useRedirect();

  return (errorCode: string) => {
    const redirectUrl = appendOrReplaceQueryStringParams(errorUrl, {
      errcode: errorCode,
    });
    redirect(redirectUrl);
  };
};

/**
 * Updates signup state and either shows the error on the current view or navigates to a different view to show the error message. The use-create-account-error hook
 * for the view will handle displaying the error. This hook sets the SetCreateAccountErrorShown to false to ensure it can be shown
 * (e.g., error may have been shown on a first attempt, so we need to reset). It also sets the error code, so that views with multiple inputs
 * like SignUpNameCollection can determine which input handler to use. It sets error view id, so that the error won't be shown on the current view on re-render,
 * before navigating to the appropriate view.
 * @returns function for showing the CreateAccount request error in the current view or in a different view.
 */
export const useShowErrorOnView = () => {
  const { dispatchStateChange } = useSignUpContext();
  const navigate = useNavigateDirection();

  return (currentView: ViewId, errorView: ViewId, errorCode: string) => {
    dispatchStateChange({
      actionType: SignUpActionType.SetCreateAccountErrorShown,
      payload: false,
    });
    dispatchStateChange({
      actionType: SignUpActionType.SetCreateAccountErrorCode,
      payload: errorCode,
    });
    dispatchStateChange({
      actionType: SignUpActionType.SetCreateAccountErrorViewId,
      payload: errorView,
    });

    if (currentView !== errorView) {
      navigate(currentView, errorView!);
    }
  };
};

/**
 * Handles the CreateAccount request failure either by navigating to a different view, showing an error message in the current view or in a different view, or handling a fatal error.
 * @returns function for handling the CreateAccount request failure.
 */
export const useCreateAccountFailureHandler = () => {
  const showErrorOnView = useShowErrorOnView();
  const handleFatalError = useHandleFatalError();
  const triggerChallenge = useTriggerChallenge();
  const saveRepMapResponse = useSaveRepMapResponse();
  const {
    viewState: { memberName },
    dispatchStateChange,
  } = useSignUpContext();
  const navigate = useNavigateDirection();

  const handleRequestFailure = (response: CreateAccountApiError, currentView: ViewId) => {
    const { responseBody } = response;
    const error = response.responseBody?.error;
    let errorCode = "";
    let customErrorData = "";

    if (responseBody) {
      saveRepMapResponse(errorResponseAggregator(responseBody, ApiNames.CreateAccount));
    }

    if (typeof error !== "string") {
      if (error?.code) {
        errorCode = String(error.code);
      }

      if (error?.data) {
        customErrorData = error.data;
      }
    }

    if (customErrorData) {
      const parsedErrorData = JSON.parse(customErrorData);

      // Check if a verification SLT was returned as part of the error response
      // This will be used to skip round tripping another OTT and directly used to make 'CreatePassports' call
      if (parsedErrorData.verificationCodeSlt) {
        dispatchStateChange({
          actionType: SignUpActionType.SetVerificationSlt,
          payload: parsedErrorData.verificationCodeSlt,
        });
      }
    }

    switch (errorCode) {
      // Challenge errors
      case ErrorCode.FraudBlocked:
      case ErrorCode.HipEnforcementNeeded:
      case ErrorCode.HipNeeded:
      case ErrorCode.HipValidationError:
        return triggerChallenge({ response, apiName: ApiNames.CreateAccount });
      case ErrorCode.DomainExistsInAad:
      case ErrorCode.DomainExistsInAadSupportedLogin:
      case ErrorCode.DomainNotAllowed:
      case ErrorCode.EmailMustStartWithLetter:
      case ErrorCode.ForbiddenWord:
      case ErrorCode.MaximumOTTDailyError:
      case ErrorCode.MembernameTaken:
      case ErrorCode.MembernameTakenEasi:
      case ErrorCode.MembernameTakenPhone:
        return showErrorOnView(currentView, ViewId.UsernameCollection, errorCode);
      case ErrorCode.InvalidBirthDate:
        return showErrorOnView(currentView, ViewId.CountryBirthdate, errorCode);
      case ErrorCode.InvalidFirstName:
      case ErrorCode.InvalidLastName:
        return showErrorOnView(currentView, ViewId.SignupNameCollection, errorCode);
      case ErrorCode.BannedPassword:
      case ErrorCode.PasswordIncorrect:
      case ErrorCode.PasswordRequired:
        return showErrorOnView(currentView, ViewId.SignUpPasswordCollection, errorCode);
      // SMS HIP is unexpected but possible in case if RepMap API's fail on the server side
      // In this case, fallback to block view
      case ErrorCode.HipSMSNeeded:
      case ErrorCode.DailyLimitIDsReached:
        return navigate(currentView, ViewId.SignUpBlocked);
      case ErrorCode.OneTimeCodeInvalid:
        return showErrorOnView(currentView, ViewId.SignUpVerification, errorCode);
      case ErrorCode.PasswordConflict:
        // This error is returned when the password is same as the account that will get evicted (only applies to Phone/EASI)
        return navigate(currentView, ViewId.SignUpEvictionError);
      case ErrorCode.VerificationSltInvalid:
        // In this case clear the verificationSlt from context and reload the enter Ott page which should
        // send another code in the absence of the verificationSlt, no need to display an error
        dispatchStateChange({
          actionType: SignUpActionType.SetVerificationSlt,
          payload: "",
        });
        return navigate(currentView, ViewId.SignUpVerification);
      case ErrorCode.EvictionWarningRequired:
        // In this case phone number can possibly be evicted, so set available state and show eviction speedbump
        dispatchStateChange({
          actionType: SignUpActionType.UpdateCheckAvailableStateMap,
          payload: `${memberName}:true`,
        });
        return navigate(currentView, ViewId.SignUpEvictionSpeedbump);
      case ErrorCode.SignupRiskBlock:
        return navigate(currentView, ViewId.SignUpRiskApiBlocked);

      /* TODO: The below error codes are not applicable to Fabric at this time. In the future, navigate to and handle logic in Proof Collection view.
            case ErrorCode.InvalidEmailFormat:
            case ErrorCode.InvalidPhoneFormat:
            case ErrorCode.ProofAlreadyExistsError:
        */
      default:
        return handleFatalError(errorCode);
    }
  };

  return handleRequestFailure;
};

/**
 *  Sets up the request params and callbacks for the CreateAccount request.
 *
 *  Note: The hook does a check if verification is needed before calling CreateAccount.
 *  If it is, the hook navigates to verification view first, which calls sendOtt
 *  and verifies the one time code before calling CreateAccount again.
 * @returns function for making the CreateAccount request.
 */
export const useCreateAccount = () => {
  const onCreateAccountSuccess = useCreateAccountSuccessHandler();
  const onCreateAccountFailure = useCreateAccountFailureHandler();
  const buildRepMapRequest = useBuildRepMapRequest();
  const {
    customizationState: {
      styles: { partnerTag },
    },
  } = useCustomizationContext();

  const navigate = useNavigateDirection();

  const { treatment: jsDeprecationTreatment } = useExperiment(
    ExperimentFeature.EnableJsPublicKeyDeprecationExperiment,
  );

  return async (requestData: ICreateAccountRequestContextParams, viewId: ViewId) => {
    const { memberNameType, verificationCode, verificationSlt } = requestData;
    const isVerificationNeeded =
      (memberNameType === MemberNameType.EASI || memberNameType === MemberNameType.Phone) &&
      !verificationCode &&
      !verificationSlt;

    if (isVerificationNeeded) {
      return navigate(viewId, ViewId.SignUpVerification);
    }

    const requestParams = {
      ...setupRequestData(requestData, jsDeprecationTreatment, partnerTag),
      ...buildRepMapRequest(ApiNames.CreateAccount),
    };

    // In INT enviroment we have the ability to simulate errors
    const { hash } = window.location;
    if (hash) {
      const fragment = hash.replace("#", "");
      if (fragment && fragment.indexOf("errCode=") > -1) {
        requestParams.TestErrorCode = fragment.replace("errCode=", "");
        window.location.hash = "#";
      }
    }

    return createAccount(SignUpConfig.instance.createAccountUrl, requestParams).then(
      onCreateAccountSuccess,
      (error: CreateAccountApiError) => {
        onCreateAccountFailure(error, viewId);
      },
    );
  };
};
