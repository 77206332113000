/*******************************************************************
 *    FILE:        Encryptor.include.js
 *
 *    DESCRIPTION: This .js file will contain all .js needed to
 *                 encrypt data using passport encryption algorithms
 * 
 *    CONTEXT:     These functions are still currently in use on the
 *                 Knockout Password Reset flow.
 *                 This file will be also used on the React version
 *                 of the Password Reset flow until the encryption
 *                 is moved to the backend.
 *                 ADO Item: https://identitydivision.visualstudio.com/Engineering/_workitems/edit/2549701
 *
 *******************************************************************/

//Function below uses all the routines from passport to encrypt data.
function Encrypt(password, answer, enctype, newPassword) {
  /// <summary>
  /// This class is defined for type checking in CoffeeMaker.
  /// </summary>
  /// <param name="password" type="String"></param>
  /// <param name="answer" type="String"></param>
  /// <param name="enctype" type="String"></param>
  /// <param name="newPassword" type="String" optional="true"></param>
  var myArr = [];

  switch (enctype.toLowerCase()) {
    case "chgsqsa":
      if (password == null || answer == null) {
        return null;
      }
      myArr = PackageSAData(password, answer);
      break;
    case "chgpwd":
      if (password == null || newPassword == null) {
        return null;
      }
      myArr = PackageNewAndOldPwd(password, newPassword);
      break;
    case "pwd":
      if (password == null) {
        return null;
      }
      myArr = PackagePwdOnly(password);
      break;
    case "pin": //pin is password
      if (password == null) {
        return null;
      }
      myArr = PackagePinOnly(password);
      break;
    case "proof": // call this when attempting to get authz token
      if (password == null && answer == null) {
        return null;
      }
      // default to password otherwise use secret answer for login interrupt
      myArr = PackageLoginIntData(password != null ? password : answer);
      break;
    case "saproof": // This is a special case to handle the bug in packaging the secret answer for DBCS chars
      if (answer == null) {
        return null;
      }
      myArr = PackageSADataForProof(answer);
      break;
    case "newpwd":
      if (newPassword == null) {
        return null;
      }
      myArr = PackageNewPwdOnly(newPassword);
      break;
    default:
      break;
  }
  //Check for myArr being empty. If so, return to caller.
  if (myArr == null || typeof myArr == "undefined") {
    return myArr;
  }

  //Encrypt the formatted string below.
  if (typeof Key != "undefined" && parseRSAKeyFromString !== undefined) {
    var publicKey = parseRSAKeyFromString(Key);
  }

  var resultInB64 = RSAEncrypt(myArr, publicKey, randomNum);
  return resultInB64;
}

// *************************************************
// WORKING EXAMPLE - USE THIS AS A TEMPLATE FOR PACKAGING
//      ENCRYPTED DATA
//
// The array should look similar to the following when finished:
//
//      VvnNewPasswordnSecretAnswernOldPassword
//
//  Where V is a 1 byte version that corresponds to the 1 char version specified in the <p:Version>
//  node in <p:EncryptedProperties>.  This version will be rev�ed if we change encryption formats.
// The version in the node must match the 1st byte of the encrypted blob or we will discontinue
// processing of the CipherValue decrypted data.
//  The next byte, v, is a 1 byte version number for the format of the data string
//
//  For version 1 (v = 1):
//  *    Each n is a 1 byte length of the field
//  *    The hard-coded field order is { New Password, Secret Answer, Old Password }
//  *    Any empty field should have a 1 byte length (n in string above) value of 0
//      (AND NO CORRESPONDING DATA!!!!!!)
//  *    New Password and Old Password must be in ASCII (single byte)
//  *    Secret Answer must be Unicode and should be no more than 32 characters (64 bytes)
//
// meant to be used during update of the secret question  / secret
// answer
// *************************************************
function PackageSAData(oldPassword, answer) {
  /// <param name="oldPassword" type="String"></param>
  /// <param name="answer" type="String"></param>
  var myArr = [];
  var pos = 0;
  // this must match the version portion of the encrypted credentials
  // xml blob
  myArr[pos++] = 1;
  // this is the encryption type - 1.  if 2 then would need to provide
  // mobile pin too???
  myArr[pos++] = 1;
  // new password empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;

  var i;
  var answerLen = answer.length;
  // len is twice the count b/c wide chars (2 bytes each)
  myArr[pos++] = answerLen * 2;
  for (i = 0; i < answerLen; i++) {
    myArr[pos++] = answer.charCodeAt(i) & 0xff;
    myArr[pos++] = (answer.charCodeAt(i) & 0xff00) >> 8;
  }
  var passwordlen = oldPassword.length;
  // len is old password len
  myArr[pos++] = passwordlen;
  for (i = 0; i < passwordlen; i++) {
    myArr[pos++] = oldPassword.charCodeAt(i) & 0x7f;
  }
  return myArr;
}
// *************************************************
// The array should look similar to the following when finished:
//
//      VvnNewPasswordnSecretAnswernOldPassword
//
//  Where V is a 1 byte version that corresponds to the 1 char version specified in the <p:Version>
//  node in <p:EncryptedProperties>.  This version will be rev�ed if we change encryption formats.
// The version in the node must match the 1st byte of the encrypted blob or we will discontinue
// processing of the CipherValue decrypted data.
//  The next byte, v, is a 1 byte version number for the format of the data string
//
//  For version 1 (v = 1):
//  *    Each n is a 1 byte length of the field
//  *    The hard-coded field order is { New Password, Secret Answer, Old Password }
//  *    Any empty field should have a 1 byte length (n in string above) value of 0
//      (AND NO CORRESPONDING DATA!!!!!!)
//  *    New Password and Old Password must be in ASCII (single byte)
//  *    Secret Answer must be Unicode and should be no more than 32 characters (64 bytes)
//
// meant to be used during update of the alternate email flow, etc
// *************************************************
function PackagePwdOnly(oldPassword) {
  /// <param name="oldPassword" type="String"></param>
  var myArr = [];
  var pos = 0;
  // this must match the version portion of the encrypted credentials
  // xml blob
  myArr[pos++] = 1;
  // this is the encryption type - 1.  if 2 then would need to provide
  // mobile pin too???
  myArr[pos++] = 1;
  // new password empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;
  // secret answer is empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;

  var i;
  var passwordlen = oldPassword.length;
  // len is old password len
  myArr[pos++] = passwordlen;
  for (i = 0; i < passwordlen; i++) {
    myArr[pos++] = oldPassword.charCodeAt(i) & 0x7f;
  }
  return myArr;
}
// ******************************************
// The array should look similar to the following when finished:
//      V-v-nNewPassword-nSecretAnswer-nOldPassword-nOldPin
// ******************************************
function PackagePinOnly(pin) {
  /// <param name="pin" type="String"></param>
  var myArr = [];
  var pos = 0;
  // this must match the version portion of the encrypted credentials
  // xml blob
  myArr[pos++] = 1;
  // this is the encryption type - 2.
  myArr[pos++] = 2;
  // new password empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;
  // secret answer is empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;
  // password is empty
  myArr[pos++] = 0;
  //pin
  var i;
  var pinlen = pin.length;
  myArr[pos++] = pinlen;
  for (i = 0; i < pinlen; i++) {
    myArr[pos++] = pin.charCodeAt(i) & 0x7f;
  }
  return myArr;
}
// *************************************************
// The array should look similar to the following when finished:
//
//      Password
//
//          OR
//
//      SecretAnswer
//
//  in unicode (wide-byte, 2 byte chars)
// *************************************************
function PackageLoginIntData(data) {
  /// <param name="data" type="String"></param>
  var myArr = [];
  var pos = 0;

  var i;
  for (i = 0; i < data.length; i++) {
    myArr[pos++] = data.charCodeAt(i) & 0xff; // & with 0xFF will convert to ASCII range 0 - 255
    myArr[pos++] = (data.charCodeAt(i) & 0xff00) >> 8;
  }
  return myArr;
}

// *************************************************
// The array should look similar to the following when finished:
//
//      SecretAnswer
//
//  in unicode (wide-byte, 2 byte chars)
// THIS METHOD INCORRECTLY PACKAGES FOR THE SECRET ANSWER.
// THIS IS STILL REQUIRED FOR BACKWARD COMPATABILITY
// *************************************************
function PackageSADataForProof(data) {
  /// <param name="data" type="String"></param>
  var myArr = [];
  var pos = 0;

  var i;
  for (i = 0; i < data.length; i++) {
    myArr[pos++] = data.charCodeAt(i) & 0x7f; // & with 0x7F will convert to ASCII range 0 - 127
    myArr[pos++] = (data.charCodeAt(i) & 0xff00) >> 8;
  }
  return myArr;
}

// *************************************************
// The array should look similar to the following when finished:
//
//      VvnNewPasswordnSecretAnswernOldPassword
//
//  Where V is a 1 byte version that corresponds to the 1 char version specified in the <p:Version>
//  node in <p:EncryptedProperties>.  This version will be rev�ed if we change encryption formats.
// The version in the node must match the 1st byte of the encrypted blob or we will discontinue
// processing of the CipherValue decrypted data.
//  The next byte, v, is a 1 byte version number for the format of the data string
//
//  For version 1 (v = 1):
//  *    Each n is a 1 byte length of the field
//  *    The hard-coded field order is { New Password, Secret Answer, Old Password }
//  *    Any empty field should have a 1 byte length (n in string above) value of 0
//      (AND NO CORRESPONDING DATA!!!!!!)
//  *    New Password and Old Password must be in ASCII (single byte)
//  *    Secret Answer must be Unicode and should be no more than 32 characters (64 bytes)
//
// meant to be used during update of the password flow, etc
// *************************************************
function PackageNewPwdOnly(newPassword) {
  /// <param name="newPassword" type="String"></param>
  var myArr = [];
  var pos = 0;
  // this must match the version portion of the encrypted credentials
  // xml blob
  myArr[pos++] = 1;
  // this is the encryption type - 1.
  myArr[pos++] = 1;

  var i;
  // len is new password len
  var passwordlen = newPassword.length;
  myArr[pos++] = passwordlen;
  for (i = 0; i < passwordlen; i++) {
    myArr[pos++] = newPassword.charCodeAt(i) & 0x7f;
  }

  // secret answer is empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;

  // old password is empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;

  return myArr;
}

// *************************************************
// The array should look similar to the following when finished:
//
//      VvnNewPasswordnSecretAnswernOldPassword
//
//  Where V is a 1 byte version that corresponds to the 1 char version specified in the <p:Version>
//  node in <p:EncryptedProperties>.  This version will be rev�ed if we change encryption formats.
// The version in the node must match the 1st byte of the encrypted blob or we will discontinue
// processing of the CipherValue decrypted data.
//  The next byte, v, is a 1 byte version number for the format of the data string
//
//  For version 1 (v = 1):
//  *    Each n is a 1 byte length of the field
//  *    The hard-coded field order is { New Password, Secret Answer, Old Password }
//  *    Any empty field should have a 1 byte length (n in string above) value of 0
//      (AND NO CORRESPONDING DATA!!!!!!)
//  *    New Password and Old Password must be in ASCII (single byte)
//  *    Secret Answer must be Unicode and should be no more than 32 characters (64 bytes)
//
// meant to be used during update of the password flow, etc
// *************************************************
function PackageNewAndOldPwd(oldPassword, newPassword) {
  /// <param name="oldPassword" type="String"></param>
  /// <param name="newPassword" type="String"></param>
  var myArr = [];
  var pos = 0;
  // this must match the version portion of the encrypted credentials
  // xml blob
  myArr[pos++] = 1;
  // this is the encryption type - 1.  if 2 then would need to provide
  // mobile pin too???
  myArr[pos++] = 1;

  var i;
  // len is new password len
  var passwordlen = newPassword.length;
  myArr[pos++] = passwordlen;
  for (i = 0; i < passwordlen; i++) {
    myArr[pos++] = newPassword.charCodeAt(i) & 0x7f;
  }

  // secret answer is empty - provide a 0 for len and *no data*
  myArr[pos++] = 0;

  // len is old password len
  passwordlen = oldPassword.length;
  myArr[pos++] = passwordlen;
  for (i = 0; i < passwordlen; i++) {
    myArr[pos++] = oldPassword.charCodeAt(i) & 0x7f;
  }
  return myArr;
}
/*
mapByteToBase64
Map a single 6-bit integer to a string representing it in base64
Based on http://www.faqs.org/rfcs/rfc3548.html
*/
function mapByteToBase64(ch) {
  if (ch >= 0 && ch < 26) {
    return String.fromCharCode(65 + ch); // 65 is ASCII code for 'A'
  } else if (ch >= 26 && ch < 52) {
    return String.fromCharCode(97 + ch - 26); // 97 is ASCII code for 'a'
  } else if (ch >= 52 && ch < 62) {
    return String.fromCharCode(48 + ch - 52); // 48 is ASCII code for '0'
  } else if (ch == 62) {
    return "+";
  }
  return "/";
}

/*
base64Encode
*/
function base64Encode(number, symcount) {
  var k,
    result = "";

  // This achieves the 0-padding effect
  for (k = symcount; k < 4; k++) {
    number = number >> 6;
  }

  for (k = 0; k < symcount; k++) {
    result = mapByteToBase64(number & 0x3f) + result; // Extract low-order 6 bits
    number = number >> 6; // Right shift 6 bits
  }
  return result;
}

/*
byteArrayToBase64-- convert array of bytes to Base64
Input: array of bytes
Output: string containing base64 representation of the input

Array is processed from MSB towards LSB; eg left-to-right when index 0
is the right most position in the array.
*/
function byteArrayToBase64(/* @type(Array) */ arg) {
  var len = arg.length;
  var result = ""; // String accumulating base64 encoding
  var i; // Loop variables
  var number;

  for (i = len - 3; i >= 0; i -= 3) {
    number = arg[i] | (arg[i + 1] << 8) | (arg[i + 2] << 16);
    result = result + base64Encode(number, 4);
  }

  var remainder = len % 3;

  number = 0;
  for (i += 2; i >= 0; i--) {
    number = (number << 8) | arg[i];
  }

  if (remainder == 1) {
    result = result + base64Encode(number << 16, 2) + "==";
  } else if (remainder == 2) {
    result = result + base64Encode(number << 8, 3) + "=";
  }

  return result;
}

/* Passport Implementation of RSA in JS ============================================= */
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
// This JS code comes from Passport and is placed here to improve performance
// and reduce the chance that the file can not be downloaded from passport servers.

/*
RSA implementation in Javascript
Copyright MSFT corporation.
January 2005
*/

// ======================================================================
// Begin public interface
// Following 2 APIs are invoked by callers of this interface.
// ======================================================================

/*
parseRSAKeyFromString
This function parses the string representation of an RSA key
Input:
Jscript string object formatted as "e=1234;n=1234...."
where e is the exponent and n is the modulus, represented in hexadecimal
Constraints:
1. e must fit into a 32b integer
Output:
Object with two properties.
modulus is stored as JSMPnumber in property "n"
exponent is stored as integer in property "e"
*/
function parseRSAKeyFromString(keytext) {
  // Split the string into exponent/modulus pieces around the semi-colon
  var scIndex = keytext.indexOf(";"); // Find index of the semicolon
  if (scIndex < 0) {
    return null;
  }

  var left = keytext.substr(0, scIndex); // extract substring before the semicolon
  var right = keytext.substr(scIndex + 1); // ... and after the semi-colon

  // Parse the exponent
  var eqIndex = left.indexOf("=");
  if (eqIndex < 0) {
    return null;
  }
  var exponentTxt = left.substr(eqIndex + 1);

  // Parse the modulus
  eqIndex = right.indexOf("=");
  if (eqIndex < 0) {
    return null;
  }
  var modulusTxt = right.substr(eqIndex + 1);

  var key = new Object();
  key["n"] = hexStringToMP(modulusTxt);
  key["e"] = parseInt(exponentTxt, 16);
  return key;
}

function logTelemetryEvent() {
  const {
    default: { instance },
  } = require("../../global-config");

  instance?.telemetry?.addEvent({
    _table: "IDUX_ClientTelemetry_ServiceDiag",
    metricName: "EncryptorInvoke",
    metricValue: "1",
    dimensions: {
      correlationId: instance.correlationId,
      uiFlavor: instance.uiFlavor,
      flavor: instance.activeFlavor,
      hostPageId: instance.hostPageId,
      scenarioId: instance.scenarioId,
      puid: instance.puid,
      bundleVersion: instance.axisBundleVersion,
    },
  });
}

/*
RSAEncrypt function.

Input:
plaintext-- plaintext to encrypt as array of bytes
publicKey-- RSA public key in format returned by parseRSAKey function.
rndSource-- additional source of randomness to use for padding, as array of bytes
Constraints:
1. RSA modulus size is a multiple of 16 bits.
2. Plaintext has enough space for padding
Output:
Returns ciphertext as array of bytes
Plaintext array is modified in place to include padding-- caller must clone it, if
keeping a copy is required.
*/
function RSAEncrypt(
  /* @dynamic */ plaintext,
  /* @dynamic */ publicKey,
  /* @type(String) */ rndSource,
  /* @type(String) */ randomNum,
) {
  logTelemetryEvent();

  var ciphertext = [];

  // Padding required for PKCSv2 with SHA1
  // 2 * sizeof(SHA1-hash) + 1 byte for leading 0x00 + 1 byte for 0x01 before plaintext
  var minPaddingReq = 42;

  // Block size is length of modulus in bytes (2 bytes per word) minus required padding
  var blockSize = publicKey.n.size * 2 - minPaddingReq;

  for (var i = 0; i < plaintext.length; i += blockSize) {
    if (i + blockSize >= plaintext.length) {
      var encryptedBlock = RSAEncryptBlock(plaintext.slice(i), publicKey, randomNum);
      if (encryptedBlock) {
        ciphertext = encryptedBlock.concat(ciphertext);
      }
    } else {
      var encryptedBlock = RSAEncryptBlock(plaintext.slice(i, i + blockSize), publicKey, randomNum);
      if (encryptedBlock) {
        ciphertext = encryptedBlock.concat(ciphertext);
      }
    }
  }

  var resultInB64 = byteArrayToBase64(ciphertext);
  return resultInB64;
}

/*
RSAEncryptBlock function.

Input:
block-- block of plaintext to encrypt as array of bytes
publicKey-- RSA public key in format returned by parseRSAKey function.
rndSource-- additional source of randomness to use for padding, as array of bytes
Constraints:
1. RSA modulus size is a multiple of 16 bits.
2. Plaintext has enough space for padding
Output:
Returns ciphertext as array of bytes
Plaintext array is modified in place to include padding-- caller must clone it, if
keeping a copy is required.
*/
function RSAEncryptBlock(
  /* @dynamic */ block,
  /* @dynamic */ publicKey,
  /* @type(String) */ rndSource,
) {
  var modulus = publicKey.n;
  var exponent = publicKey.e;

  var ptBytes = block.length; // This is length of the block of plaintext in bytes
  var modBytes = modulus.size * 2; /*    Length of modulus in bytes-- we use the precondition that
                                            modulus size is an exact multiple of 16 bits */

  // Padding required for PKCSv2 with SHA1
  // 2 * sizeof(SHA1-hash) + 1 byte for leading 0x00 + 1 byte for 0x01 before the block of plaintext
  var minPaddingReq = 42;

  // Verify that there is enough room for padding, otherwise fail the decryption
  if (ptBytes + minPaddingReq > modBytes) {
    return null;
  }

  // Apply padding to the raw plaintext block, passing the randomness source through
  applyPKCSv2Padding(block, modBytes, rndSource);

  /*    Reverse the array here.
    This is to deal with indexing difference-- byte sequences start indexing with
    0 being left-most or most significant.
    But when we are doing arithmetic on large numbers
    we expect that 0-th word is the least significant one */
  block = block.reverse();

  // Convert final block of plaintext to big number format
  var base = byteArrayToMP(block);

  // Ready for modular exponentiation now
  var baseToExp = modularExp(base, exponent, modulus);

  // Pad on the right side with zeroes to length of modulus
  baseToExp.size = modulus.size;

  // Convert back to array of bytes
  var ciphertext = mpToByteArray(baseToExp);

  // Reverse the array to convert between representations
  // When doing modular arithmetic 0-th element of the array is the least-significant byte
  // When operating on byte sequences, 0-th element is left-most element
  ciphertext = ciphertext.reverse();

  return ciphertext;
}

// ======================================================================
// End public interface
// All methods that follow are internal APIs called by above 2 functions
// ======================================================================

/*
JSMPnumber-- JavaScript MultiplePrecision number
This is the object used to encapsulate arbitrary-precision numbers
It is implemented as an array of 16b words.
Constructor returns an object initialized to 0.
*/
function /* @constructor */ JSMPnumber() {
  this.size = 1;
  this.data = [];
  this.data[0] = 0;
}

// Added for CoffeeMaker
JSMPnumber.prototype = {
  size: 1,
  data: [0],
};

/*
duplicateMP-- create another copy of a Javascript multiprecision integer
*/
function duplicateMP(/* @type(JSMPnumber) */ input) {
  var dup = new JSMPnumber();

  dup.size = input.size;
  dup.data = input.data.slice(0);

  return dup;
}

/*
byteArrayToMP-- convert javascript Array of bytes into JSMPnumber object
*/
function byteArrayToMP(input) {
  var result = new JSMPnumber();
  var i = 0,
    bc = input.length;

  var half = bc >> 1; // Half the length of the array, truncated down

  // Process the array two bytes at a time
  for (i = 0; i < half; i++) {
    result.data[i] = input[2 * i] + (input[1 + 2 * i] << 8);
  }

  // Check for last byte and process it, if necessary
  // At this point the counter variable "i" will be pointing at the next word
  if (bc % 2) {
    result.data[i++] = input[bc - 1];
  }

  result.size = i;
  return result;
}

/*
mpToByteArray-- convert multiple-precision integer into javascript byte array
*/
function mpToByteArray(input) {
  var result = [];
  var i = 0,
    bc = input.size;

  for (i = 0; i < bc; i++) {
    result[i * 2] = input.data[i] & 0xff;
    var t = input.data[i] >>> 8;
    result[i * 2 + 1] = t;
  }
  return result;
}

/*
modularExp-- function for modular exponentiation
use the simple reverse bit pattern algorithm, by iterating
one bit of the exponent at a time and doing a side-multiply
if that bit is on.

Input:
base-- base in JSMPnumber format
power-- exponent, must fit into 32b integer
modulus-- modulus in JSMPnumber format
Output:
returns result as JSMPnumber
*/
function modularExp(base, power, modulus) {
  var bits = []; // This array will store bitwise representation of "power"
  var bc = 0; // Count of bits

  while (power > 0) {
    bits[bc] = power & 1; // AND out the least significant bit
    power = power >>> 1; // Right shift by one without propagating the sign bit
    bc++; // increment number of bits discovered so far
  }
  /* At the end of this loop, bits array has zeroes and ones corresponding to the
    representation of "power" and bc contains bitlength. */

  var result = duplicateMP(base); // Initialize result to the base

  /*    Loop counting backwards, starting at bit index bc-2
    This is because bc-1 is most significant bit and that bit is always set
    and initially we would have started with "1"
    So instead of doing a square and side-multiply we initialize to "base" directly above.
    */
  for (var i = bc - 2; i >= 0; i--) {
    result = modularMultiply(result, result, modulus); // Squaring
    if (bits[i] == 1) {
      // Optional side-multiply
      result = modularMultiply(result, base, modulus);
    }
  }

  return result;
}

/*
modularMultiply-- multiply the operands and return remainder
when divided by "modulus"

Input:
left-- first term in the product
right-- second term in the product
modulus-- modulus in JSMPnumber format
Output:
returns result as JSMPnumber
*/
function modularMultiply(left, right, modulus) {
  var product = multiplyMP(left, right);
  var divResult = divideMP(product, modulus);
  return divResult.r;
}

/*
multiplyMP--

Input:
left, right-- JSMP integer
Constraints:
Left and right represent numbers no larger than 2048 bits.
(See notes for why)
Output:

Notes:
This is straight-forward multiplication, in quadratic time
There is a one-step, non-recursive optimization implemented for squaring,
but there is no Karatsuba for generic case.
It does take advantage of one useful property of Javascript: all numbers are
represented by an IEEE "double" floating point type. That means that a jscript number
can store upto 53 bits integers exactly
(52 bits allocated for fraction + one set bit implied before the decimal point)
For this reason, pairwise word products are added directly to the result array without
splitting high and low-16 bits, and without propagating any carries.
*/
function multiplyMP(left, right) {
  var product = new JSMPnumber();

  /*    This could be an overestimate, depending on whether the most-significant word
    in the product is equal to zero */
  product.size = left.size + right.size;

  var i, j; // Counter variables

  // Initialize product to an array of all zeroes
  for (i = 0; i < product.size; i++) {
    product.data[i] = 0;
  }

  var ld = left.data,
    rd = right.data,
    pd = product.data;

  if (left == right) {
    // Optimization for squaring

    // First square each word
    for (i = 0; i < left.size; i++) {
      pd[2 * i] += ld[i] * ld[i];
    }

    // Next multiply word[i] and word[j] once for all unequal pairs i, j
    // This cuts down operations roughly in half
    for (i = 1; i < left.size; i++) {
      for (j = 0; j < i; j++) {
        pd[i + j] += 2 * ld[i] * ld[j];
      }
    }
  } else {
    // Nested loop
    for (i = 0; i < left.size; i++) {
      for (j = 0; j < right.size; j++) {
        pd[i + j] += ld[i] * rd[j]; // No carry required!
      }
    }
  }

  /*    At this point the product.data[] array contains numbers which can be
    larger than the 16b word size because we haven't distributed the carry
    at all during the multiplication step.
    We call normalizeJSMP() to do that now. */
  normalizeJSMP(product);
  return product;
}

/*
normalizeJSMP-- normalize the MP such that all words in the array are proper 16b words
Because jscript does not have typed integers, it is possible to store larger values in
the elements and this is a useful optimization during multiplication, to delay propagating
the carry until all the additions are complete. This function re-normalizes the array
by distributing the carry.

Input:
number-- multi-precision number
Output:
None, operates in place on the object.
*/
function normalizeJSMP(number) {
  var i, carry, cb, word;
  var diff, original;

  cb = number.size;
  carry = 0; // This is the carry propagated, right to left

  for (i = 0; i < cb; i++) {
    // Loop on the words, starting with least-significant one

    word = number.data[i];

    word += carry;
    original = word;

    carry = Math.floor(word / 0x10000);
    word -= carry * 0x10000;

    /*     This is debugging code: remove in production.
        Verifies that the value stored in data[i] as IEEE double type
        was properly split into its least 16b word (stored in "word") and upper bits
        stored into carry.
        diff = (carry*0x10000 + word) - original;
        if (diff!=0)
        {
        alert(diff);
        }
        */

    number.data[i] = word;
  }
}

/*
function removeLeadingZeroes-- remove leading 0 words from given number
Input:
number-- JSMP integer
Output:
none, operates in place
*/
function removeLeadingZeroes(number) {
  var i = number.size - 1;

  while (i > 0 && number.data[i--] == 0) {
    number.size--;
  }
}

/*
function divideMP-- multiple precision number division
Performs long division, by operating in place on the number

Input:
number and divisor-- as JSMPnumber format
Constraints:
divisor is normalized (most significant word is non-zero)
Output:
returns object with 2 properties:
"q" stores the qoutient,
"r" stores the remainder
*/
function divideMP(number, divisor) {
  var nw = number.size;
  var dw = divisor.size;

  // Most significant word of the divisor-- used to determine if additional subtraction is necessary
  var msw = divisor.data[dw - 1];

  // Combine two most significant words in the divisor
  // Result is used to estimate each word of the quotient
  var mswDiv = divisor.data[dw - 1] + divisor.data[dw - 2] / 0x10000;

  var quotient = new JSMPnumber();
  quotient.size = nw - dw + 1; // Initialize to maximum possible value

  // First we pad the number on the left with an extra zero word
  number.data[nw] = 0;

  for (var i = nw - 1; i >= dw - 1; i--) {
    // This keeps track of number of words to shift when multiplying
    var shift = i - dw + 1;

    // This is an estimate of the quotient word.
    // It may be below or above the correct value by one
    var estimate = Math.floor((number.data[i + 1] * 0x10000 + number.data[i]) / mswDiv);

    if (estimate > 0) {
      // multiplyAndSubstract returns sign of the difference
      var sign = multiplyAndSubtract(number, estimate, divisor, shift);

      // Check for over-estimation scenario
      if (sign < 0) {
        estimate--;
        multiplyAndSubtract(number, estimate, divisor, shift);
      }

      // In rare instances additional subtractions are necessary because of round-off errors
      while (sign > 0 && number.data[i] >= msw) {
        sign = multiplyAndSubtract(number, 1, divisor, shift);
        if (sign > 0) {
          estimate++;
        }
      }
    }

    quotient.data[shift] = estimate;
  }

  removeLeadingZeroes(number);
  var result = {
    q: quotient,
    r: number,
  };
  return result;
}

/*
function multiplyAndSubtract
Multiplies divisor by the 16b word "scalar", and subtracts it from "number"
at the specified word offset.
For example when offset==4, the substraction starts from the 4th word in number
(where word index 0 is least-significant word)
In effect the offset multiplies the divisor by another factor of 2**(16*offset)

This function creates a copy of the word array in number.
If the result is negative, it undoes the operation.

Inputs:
number-- number to subtract from, in JSMP type
scalar-- multiplier
divisor-- second operand, in JSMP type
offset-- number of words to shift before subtraction
Constraints:
Result can not be negative; at most one final carry is propagated
Such an operation would result in overflow
Output:
no return value, operates in place on number
*/
function multiplyAndSubtract(number, scalar, divisor, offset) {
  var i;

  // Create a temporary copy of the array of words
  var backup = number.data.slice(0);

  var carry = 0;
  var nd = number.data;

  for (i = 0; i < divisor.size; i++) {
    var temp = carry + divisor.data[i] * scalar;

    // Split the above result into its upper and lower 16b
    carry = temp >>> 16; // Three arrows is shift-without-sign-bit operation in Javascript
    temp = temp - carry * 0x10000; // This is more reliable way to extract lower 16 bits since logical & may fail on IEEE double

    // There are two possibilities here: either the result of subtraction is positive or
    // it is negative in which case we have to propagate one more carry to next word
    if (temp > nd[i + offset]) {
      nd[i + offset] += 0x10000 - temp;
      carry++;
    } else {
      nd[i + offset] -= temp;
    }
  }

  // This step propagates the carry one more word
  if (carry > 0) {
    nd[i + offset] -= carry;
  }

  // If the result turned out to be negative, undo whole operation and return -1
  if (nd[i + offset] < 0) {
    number.data = backup.slice(0); // Restore original array from backup
    return -1;
  }

  // Returning a positive number indicates that the operation succeeded
  // and result is positive
  return +1;
}

/*
Based on RFC 3447
See http://www.faqs.org/rfcs/rfc3447.html
*/
function applyPKCSv2Padding(message, modulusSize, rndsrc) {
  /*     DB = lHash || PaddingString || 0x01 || Message
    Required padding is equal to keysize minus message length,
    minus twice size of the hash function (20 bytes for SHA1 in this case)
    minus two bytes of overhead */
  var mlen = message.length;
  var i;

  // PKCSv2 uses empty string as its label, so we hard-code SHA1 hash of empty string here
  var lHash = [
    0xda, 0x39, 0xa3, 0xee, 0x5e, 0x6b, 0x4b, 0x0d, 0x32, 0x55, 0xbf, 0xef, 0x95, 0x60, 0x18, 0x90,
    0xaf, 0xd8, 0x07, 0x09,
  ];

  // Create the padding string
  var padlen = modulusSize - mlen - 40 - 2;
  var PS = [];
  for (i = 0; i < padlen; i++) {
    PS[i] = 0x00;
  }

  PS[padlen] = 0x01; // Add 0x01 at the end

  // Concatenate to obtain lHash || DB
  var DB = lHash.concat(PS, message);

  // Next generate the seed and masked seed
  var seed = [];

  /*     First generate a random seed by combining the Jscript built-in random
    number generator with external source of randomness provided, if one exists. */

  // Generate bytes using the built-in PRNG-- the quality of this is implementation dependent
  for (i = 0; i < 20; i++) {
    seed[i] = Math.floor(Math.random() * 256);
  }

  // Concatenate with the external source and apply SHA1 to generate 20 bytes */
  seed = SHA1(seed.concat(rndsrc));

  // Generate mask to XOR with the data block
  var dbMask = MGF(seed, modulusSize - 21);

  // XOR the datablock together with the mask
  var maskedDB = XORarrays(DB, dbMask);

  // Generate another mask for the seed
  var seedMask = MGF(maskedDB, 20);

  // XOR the seed with the seed mask
  var maskedSeed = XORarrays(seed, seedMask);

  // Final message encoding is 00 || MaskedSeed || MaskedDB
  var encodedMsg = [];

  encodedMsg[0] = 0x00;
  encodedMsg = encodedMsg.concat(maskedSeed, maskedDB);

  // Copy the encoded message back to the original input
  for (i = 0; i < encodedMsg.length; i++) {
    message[i] = encodedMsg[i];
  }
}

/*
MGF-- mask generation function
Input:
seed-- byte sequence to use starting input to the hash function
masklen-- count of bytes to generate
Constraints:
Mask length is less than 4K bytes.
*/
function MGF(seed, masklen) {
  if (masklen > 0x1000) {
    return null;
  }

  var dup = seed.slice(0); // Create writable copy of seed

  // Add 4 byte representation of the counter after seed
  var sl = dup.length;
  dup[sl++] = 0;
  dup[sl++] = 0;
  dup[sl++] = 0;
  dup[sl] = 0;

  var counter = 0;
  var T = [];

  /*    Loop until we have generated a large enough mask, by
    repeatedly adding SHA1 hash of the seed concatenated with an incremented counter */
  while (T.length < masklen) {
    dup[sl] = counter++; // Only update last byte-- by assumption of length, no overflow
    T = T.concat(SHA1(dup));
  }

  return T.slice(0, masklen);
}

/*
XORarrays-- return the exclusive OR of two arrays
*/
function XORarrays(/* @type(Array) */ left, /* @type(Array) */ right) {
  if (left.length != right.length) {
    return null;
  }

  var result = [];
  var end = left.length;

  for (var i = 0; i < end; i++) {
    result[i] = left[i] ^ right[i];
  }

  return result;
}

/*
Unoptimized SHA1 implementation in Javascript
(C) Microsoft Corporation 2005
*/

/*
SHA1
*/
function SHA1(data) {
  var i;

  // Create a copy of the data
  var dup = data.slice(0);

  // Apply padding in place
  PadSHA1Input(dup);

  // Set up IV
  var chainedState = {
    A: 0x67452301,
    B: 0xefcdab89,
    C: 0x98badcfe,
    D: 0x10325476,
    E: 0xc3d2e1f0,
  };

  // Execute the round function on the data, one block at a time
  for (i = 0; i < dup.length; i += 64) {
    SHA1RoundFunction(chainedState, dup, i);
  }

  // Convert output into an array of bytes
  var result = [];
  wordToBytes(chainedState.A, result, 0);
  wordToBytes(chainedState.B, result, 4);
  wordToBytes(chainedState.C, result, 8);
  wordToBytes(chainedState.D, result, 12);
  wordToBytes(chainedState.E, result, 16);

  return result;
}

/*
wordToBytes
*/
function wordToBytes(number, dest, offset) {
  var i;
  for (i = 3; i >= 0; i--) {
    dest[offset + i] = number & 0xff;
    number = number >>> 8;
  }
}

/*
padSHA1Input
Input:
bytes-- Javascript array of bytes
Output:
none, operates in place on the array.

Assumptions:
1. input is less than 4GB (eg first 4 bytes of length expressed as 64b value is zero)
*/
function PadSHA1Input(bytes) {
  var unpadded = bytes.length;
  var len = unpadded; // Keep track of current length as padding is added
  var inc = unpadded % 64; // Length of last incomplete block

  var completeTo = inc < 55 ? 56 : 120; // Decide if we need an additional block

  var i;

  bytes[len++] = 0x80; // Pad with one bit
  for (
    i = inc + 1;
    i < completeTo;
    i++ // Pad remainder with zeroes
  ) {
    bytes[len++] = 0;
  }

  // Write the original, unpadded length of input as 64b quadword on last eight bytes
  var unpBitCount = unpadded * 8;

  // Write bytes starting with least-significant
  for (i = 1; i < 8; i++) {
    bytes[len + 8 - i] = unpBitCount & 0xff; // Extract one byte from the length variables
    unpBitCount = unpBitCount >>> 8; // Right-shift without sign extension
  }
}

/*
SHA1RoundFunction

Notes:
Remember that Jscript does not have typed variables.
If addition of 32b integer overflows, it stores result in an IEEE double.
For this reason addition needs its own wrapping.
*/
function SHA1RoundFunction(chainVar, block, offset) {
  // Per round additive constants
  var y1 = 0x5a827999,
    y2 = 0x6ed9eba1,
    y3 = 0x8f1bbcdc,
    y4 = 0xca62c1d6;

  var r, j, w; // Various loop counters
  var words = []; // This is an array of 32 bit words

  // Copy current state into local variables
  var A = chainVar.A,
    B = chainVar.B,
    C = chainVar.C,
    D = chainVar.D,
    E = chainVar.E;

  // First convert the array of bytes in "block" into 32b words, big-endian format
  for (j = 0, w = offset; j < 16; j++, w += 4) {
    words[j] = (block[w] << 24) | (block[w + 1] << 16) | (block[w + 2] << 8) | (block[w + 3] << 0);
  }

  // Next expand the 16 word block into 80 words using the combination function
  for (j = 16; j < 80; j++) {
    words[j] = rotateLeft(words[j - 3] ^ words[j - 8] ^ words[j - 14] ^ words[j - 16], 1);
  }

  var t; // Temporary variable

  // First 20 rounds-- uses function f() defined below
  // function f(x, y, z) = (x&y) | ((~x) & z);

  for (r = 0; r < 20; r++) {
    t = (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + words[r] + y1) & 0xffffffff;

    E = D;
    D = C;
    C = rotateLeft(B, 30);
    B = A;
    A = t;
  }

  // Second twenty rounds-- uses function h() defined below
  // function h(x, y, z) = (x ^ y ^ z);
  for (r = 20; r < 40; r++) {
    t = (rotateLeft(A, 5) + (B ^ C ^ D) + E + words[r] + y2) & 0xffffffff;

    E = D;
    D = C;
    C = rotateLeft(B, 30);
    B = A;
    A = t;
  }

  // Third twenty rounds-- uses function g() defined below
  // function g(x, y ,z) = (x & y) | (x & z) | (y & z);
  for (r = 40; r < 60; r++) {
    t = (rotateLeft(A, 5) + ((B & C) | (B & D) | (C & D)) + E + words[r] + y3) & 0xffffffff;

    E = D;
    D = C;
    C = rotateLeft(B, 30);
    B = A;
    A = t;
  }

  // Final  twenty rounds-- uses function h() defined below
  // function h(x, y, z) = (x ^ y ^ z);
  for (r = 60; r < 80; r++) {
    t = (rotateLeft(A, 5) + (B ^ C ^ D) + E + words[r] + y4) & 0xffffffff;

    E = D;
    D = C;
    C = rotateLeft(B, 30);
    B = A;
    A = t;
  }

  // Update chaining variables
  chainVar.A = (chainVar.A + A) & 0xffffffff;
  chainVar.B = (chainVar.B + B) & 0xffffffff;
  chainVar.C = (chainVar.C + C) & 0xffffffff;
  chainVar.D = (chainVar.D + D) & 0xffffffff;
  chainVar.E = (chainVar.E + E) & 0xffffffff;
}

/*
rotateLeft
*/
function rotateLeft(number, shift) {
  var lower = number >>> (32 - shift);
  var mask = (1 << (32 - shift)) - 1;
  var upper = number & mask;

  return (upper << shift) | lower;
}

/*
hexStringToMP-- convert hex string to multiple precision object
*/
function hexStringToMP(hexstr) {
  var i, hexWord;
  var cWords = Math.ceil(hexstr.length / 4);
  var result = new JSMPnumber();

  result.size = cWords;

  // Process the hex string in groups of 4 digits
  // Loop reads 4-character segments left-to-right (eg starting with most-significant digits)
  // Each 4-char segment is convered into a numeric value using jscript builtin API "parseInt"
  // and result is copied to the data array, counting down from highest index.
  for (i = 0; i < cWords; i++) {
    hexWord = hexstr.substr(i * 4, 4);
    result.data[cWords - 1 - i] = parseInt(hexWord, 16);
  }

  return result;
}
/* END of Passport Implementation of RSA in JS ============================================= */
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------
//--------------------------------------------------------------------------------

module.exports = { Encrypt };
