import { ApiNames, ViewId } from "@msidentity/SISU/constants";
import { useGlobalContext } from "@msidentity/SISU/global-context";
import { GlobalActionType } from "@msidentity/SISU/global-reducer";
import { useNavigateDirection } from "@msidentity/SISU/hooks";
import { ChannelType } from "@msidentity/SISU/model/proof/proof-types";
import {
  errorResponseAggregator,
  useBuildRepMapRequest,
  useSaveRepMapResponse,
} from "@msidentity/SISU/views/challenge/hooks/use-repmap-params";
import { useTriggerChallenge } from "@msidentity/SISU/views/challenge/hooks/use-trigger-challenge";
import {
  type SendOttRequest,
  type SendOttResponse,
  type SendOttResponseError,
  sendOtt,
  SendOttErrorCode as ErrorCode,
} from "../../../utilities/api-helpers/send-ott/send-ott-request";
import SignUpConfig from "../signup-config";
import { useSignUpContext } from "../signup-context";
import { SignUpActionType } from "../signup-reducer";
import { usernameCollectionViewErrorStringsFabric as errorStrings } from "../views/username-collection/fabric/username-collection-view-strings-fabric";

export const useSendOttSuccessHandler = () => {
  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();
  const { dispatchStateChange: dispatchSignUpStateChange } = useSignUpContext();
  const saveRepMapResponse = useSaveRepMapResponse();

  return (
    response: SendOttResponse,
    memberName: string,
    setShowView?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    saveRepMapResponse(response);

    dispatchSignUpStateChange({
      actionType: SignUpActionType.SetLastMemberNameUsedOnSendOtt,
      payload: memberName,
    });

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: false,
    });

    // Setting the view to show should happen after state changes (above) are
    // complete to avoid unnecessary re-renders and loss of default focus.
    if (setShowView) {
      setShowView(true);
    }
  };
};

export const useShowErrorOnView = () => {
  const { dispatchStateChange } = useSignUpContext();
  const navigate = useNavigateDirection();

  return (errorView: ViewId, errorMessage: string) => {
    dispatchStateChange({
      actionType: SignUpActionType.SetSendOttErrorShown,
      payload: false,
    });
    dispatchStateChange({
      actionType: SignUpActionType.SetSendOttError,
      payload: errorMessage,
    });

    navigate(ViewId.SignUpVerification, errorView);
  };
};

export const useSendOttFailureHandler = () => {
  const showErrorOnView = useShowErrorOnView();
  const {
    dispatchStateChange: dispatchGlobalStateChange,
    globalState: { activeView },
  } = useGlobalContext();
  const saveRepMapResponse = useSaveRepMapResponse();
  const triggerChallenge = useTriggerChallenge();
  const navigate = useNavigateDirection();

  return (response: SendOttResponseError) => {
    const { responseBody } = response;

    dispatchGlobalStateChange({
      type: GlobalActionType.SetShowProgressIndicator,
      payload: false,
    });

    if (responseBody) {
      let errorCode = "";

      if (typeof responseBody.error !== "string") {
        saveRepMapResponse(errorResponseAggregator(responseBody));
        errorCode = String(responseBody.error?.code) || "";
      }

      switch (errorCode) {
        // challenge errors
        case ErrorCode.FraudBlocked:
        case ErrorCode.HipCaptchaNeededOnSendOTT:
        case ErrorCode.HipEnforcementNeededOnSendOTT:
        case ErrorCode.HipValidationError: {
          return triggerChallenge({ response, apiName: ApiNames.SendOtt });
        }

        case ErrorCode.MaximumOTTDailyError:
          return showErrorOnView(ViewId.UsernameCollection, errorStrings.verificationThrottled);
        case ErrorCode.DailyLimitIDsReached:
          return navigate(activeView, ViewId.SignUpBlocked);
        default:
          return showErrorOnView(ViewId.UsernameCollection, errorStrings.genericService);
      }
    } else {
      return showErrorOnView(ViewId.UsernameCollection, errorStrings.genericService);
    }
  };
};

export type SendOttOptions = {
  setShowView?: React.Dispatch<React.SetStateAction<boolean>>;
  hideProgressIndicator?: boolean;
};

export const useSignUpSendOtt = () => {
  const {
    sendCodeInfo: { sendOttAction, sendOttUrl },
    defaultPhoneCountry,
  } = SignUpConfig.instance;

  const {
    viewState: { memberNameInput, memberName },
  } = useSignUpContext();

  const { dispatchStateChange: dispatchGlobalStateChange } = useGlobalContext();
  const buildRepMapRequest = useBuildRepMapRequest();
  const onSendOttSuccess = useSendOttSuccessHandler();
  const onSendOttFailure = useSendOttFailureHandler();

  const callSendOtt = (channel: ChannelType, options?: SendOttOptions) => {
    const sendOttParams: SendOttRequest = {
      action: sendOttAction,
      channel,
      proofId: memberNameInput.input,
      ...buildRepMapRequest(ApiNames.SendOtt),
    };

    if (channel === ChannelType.SMS) {
      sendOttParams.proofCountryIso = memberNameInput.phoneCountry.iso || defaultPhoneCountry;
    }

    // In INT enviroment we have the ability to simulate errors
    const { hash } = window.location;
    if (hash) {
      const fragment = hash.replace("#", "");
      if (fragment && fragment.indexOf("ottErrCode=") > -1) {
        // Remove query parameters at the end of the URL from the TestErrorCode
        const ottErrCode = fragment.replace("ottErrCode=", "").split("&")[0];
        sendOttParams.TestErrorCode = ottErrCode;
        window.location.hash = "#";
      }
    }

    if (!options?.hideProgressIndicator) {
      dispatchGlobalStateChange({
        type: GlobalActionType.SetShowProgressIndicator,
        payload: true,
      });
    }

    sendOtt(sendOttUrl, sendOttParams).then(
      (response: SendOttResponse) => {
        onSendOttSuccess(response, memberName, options?.setShowView);
      },
      (err: SendOttResponseError) => {
        // Not setting setShowView to true here since we always navigate away from the Verification View
        // If a new error is added where we stay on the Verification view, please setShowView to true for that error

        onSendOttFailure(err);
      },
    );
  };

  return callSendOtt;
};
