import { type ChangeEvent, useContext } from "react";
import { type InputState } from "@msidentity/SISU/components/inputs/hooks/use-input";
import { SignUpContext } from "../../../signup-context";
import { SignUpActionType } from "../../../signup-reducer";

/**
 * Provides an onChange handler that updates signup context with the current password input value.
 * @param inputState The input state
 * @returns onChange handler for the password collection form
 */
export const usePasswordCollectionOnChange = (inputState: InputState) => {
  const { dispatchStateChange: dispatchSignupStateChange } = useContext(SignUpContext);
  const { onChange: originalOnChange } = inputState;

  return (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue = typeof event === "string" ? event : event.target.value;
    originalOnChange(newValue);
    dispatchSignupStateChange({
      actionType: SignUpActionType.SetPassword,
      payload: newValue,
    });

    return newValue;
  };
};
