import { type ApiResponseError, type ResponseError } from "@msidentity/SISU/constants";
import GlobalConfig from "@msidentity/SISU/global-config";
import { type TelemetryState } from "@msidentity/SISU/global-context";
import { MemberNameType } from "@msidentity/SISU/model/user";
import { setCookie } from "@msidentity/SISU/utilities/cookie-helper";
import { postApiRequest } from "@msidentity/SISU/utilities/request/request-helper";
import { addQueryStrings } from "@msidentity/SISU/utilities/strings-helper";
import { getAPIUrlWithCurrentWindowQS } from "../api-request-helper";
import { ErrorCode } from "../create-account/create-account-interface";

export type AddFamilyMemberResponse = {
  encryptedMemberName?: string;
};

export interface AddFamilyMemberResponseError extends Partial<ResponseError<string>> {
  responseBody?: AddFamilyMemberResponse & ApiResponseError<string>;
}

export type AddFamilyMemberBody = {
  uaid: string;
  memberEmailPii: string;
  memberRole: string;
  encMemberPuid?: string;
};

export type RequiredFamilyConfig = {
  onSuccessUrl: string;
  onErrorUrl: string;
  createAndUpdateFamilyUrl: string;
  addPendingMemberToFamilyUrl: string;
  addToFamilyChildFailureUrl: string;
  familySizeLimitReachedUrl: string;
  addToFamilyParentFailureUrl: string;
  memberAlreadyInFamilyUrl: string;
  cannotInviteExistingMemberUrl: string;
  childConsentFamilyReturnUrl: string;
  memberRole: string;
  childConsentCookieDomain: string;
  childConsentCookieName: string;
  childConsentReturnUrlCookieName: string;
};

export type AddFamilyMemberProps = {
  signInName?: string;
  memberNameType: MemberNameType;
  encryptedPuid?: string;
  familyConfig: RequiredFamilyConfig;
  kcft?: string;
  childInfo?: string;
  redirectCallback: (url: string) => void;
};

/**
 * @param addFamilyMemberProps the properties required to add a family member
 * @returns the body for the AddFamilyMember API call
 */
export const getAddFamilyMemberPostBody = function getAddFamilyMemberPostBody(
  addFamilyMemberProps: AddFamilyMemberProps,
) {
  const {
    signInName,
    encryptedPuid,
    memberNameType,
    familyConfig: { memberRole },
  } = addFamilyMemberProps;
  const { unauthenticatedSessionId } = GlobalConfig.instance;
  const isEasi = memberNameType === MemberNameType.EASI;

  const postBody: AddFamilyMemberBody = {
    memberEmailPii: signInName || "",
    memberRole,
    uaid: unauthenticatedSessionId,
  };

  if (!isEasi) {
    postBody.encMemberPuid = encryptedPuid;
  }

  return postBody;
};

/**
 * @param addFamilyMemberProps the properties required to add a family member
 * @returns the URL to add a family member
 */
export const getAddFamilyMemberUrl = function getAddFamilyMemberUrl(
  addFamilyMemberProps: AddFamilyMemberProps,
) {
  const {
    memberNameType,
    familyConfig: { addPendingMemberToFamilyUrl, createAndUpdateFamilyUrl },
  } = addFamilyMemberProps;
  const isEasi = memberNameType === MemberNameType.EASI;

  return isEasi ? addPendingMemberToFamilyUrl : createAndUpdateFamilyUrl;
};

/**
 * This method handles the success for the AddFamilyMember API call.
 * @param _response response reference to retreive the encryptedMemberName
 * @param addFamilyMemberProps the properties required to add a family member
 */
export const handleAddFamilyMemberSuccess = function handleAddFamilyMemberSuccess(
  _response: Partial<AddFamilyMemberResponse>, // TODO: Use this to provide the EncryptedMemberName once we support WinTsetFlow
  addFamilyMemberProps: AddFamilyMemberProps,
) {
  const {
    childInfo,
    kcft = "",
    familyConfig: {
      childConsentFamilyReturnUrl,
      childConsentCookieName,
      childConsentCookieDomain,
      childConsentReturnUrlCookieName,
      onSuccessUrl,
    },
    redirectCallback,
  } = addFamilyMemberProps;
  const { returnUrl } = GlobalConfig.instance;

  // CXH TODO: updateFamilyCache

  if (childInfo) {
    setCookie(childConsentCookieName, childInfo, childConsentCookieDomain, /* skipEncoding */ true);
    setCookie(
      childConsentReturnUrlCookieName,
      onSuccessUrl,
      childConsentCookieDomain,
      /* skipEncoding */ true,
    );

    const familyChildConsentRedirect = addQueryStrings(
      decodeURIComponent(childConsentFamilyReturnUrl),
      new Map([["kcft", kcft]]),
    );
    redirectCallback(familyChildConsentRedirect);
  } else {
    redirectCallback(decodeURIComponent(returnUrl));
  }
};

/**
 * This method handles a failure by the AddFamilyMember API
 * @param responseError The error object
 * @param addFamilyMemberProps the properties required to add a family member
 */
export const handleAddFamilyMemberFailure = function handleAddFamilyMemberFailure(
  responseError: AddFamilyMemberResponseError,
  addFamilyMemberProps: AddFamilyMemberProps,
) {
  const {
    familyConfig: {
      onErrorUrl,
      addToFamilyChildFailureUrl,
      addToFamilyParentFailureUrl,
      cannotInviteExistingMemberUrl,
      memberAlreadyInFamilyUrl,
      familySizeLimitReachedUrl,
    },
    redirectCallback,
  } = addFamilyMemberProps;
  const { returnUrl } = GlobalConfig.instance;
  const error = responseError.responseBody?.error;
  const errorCode = typeof error !== "string" ? error?.code : undefined;
  let redirectUrl = onErrorUrl;

  switch (errorCode) {
    case ErrorCode.FamilyInvitationAlreadyExists:
      // CXH TODO: updateFamilyCache
      redirectUrl = returnUrl;
      break;
    case ErrorCode.MemberAlreadyInFamily:
      redirectUrl = memberAlreadyInFamilyUrl;
      break;
    case ErrorCode.CannotInviteExistingMember:
      redirectUrl = cannotInviteExistingMemberUrl;
      break;
    case ErrorCode.NotAllowedToAddMember:
    case ErrorCode.NotAllowedToInviteMember:
      redirectUrl = addToFamilyChildFailureUrl;
      break;
    case ErrorCode.FamilySizeExceedLimit:
      redirectUrl = familySizeLimitReachedUrl;
      break;
    case ErrorCode.FirstMemberAdminMissingAccountInfo:
      redirectUrl = addToFamilyParentFailureUrl;
      break;
    default:
      break;
  }

  if (redirectUrl) {
    redirectCallback(decodeURIComponent(redirectUrl));
  }
};

/**
 * This method calls the AddFamilyMember API and handles the response by redirecting the user to the appropriate URL.
 * @param addFamilyMemberProps the properties required to add a family member
 * @param telemetryState the TelemetryState for the request
 * @returns a Promise that resolves with a success/error handler to redirect the user to the appropriate URL.
 */
export const addFamilyMember = async (
  addFamilyMemberProps: AddFamilyMemberProps,
  telemetryState: TelemetryState,
) => {
  const postBody = getAddFamilyMemberPostBody(addFamilyMemberProps);
  const url = getAddFamilyMemberUrl(addFamilyMemberProps);

  return postApiRequest<AddFamilyMemberResponse>(getAPIUrlWithCurrentWindowQS(url), {
    body: JSON.stringify(postBody),
    telemetryState,
  }).then(
    (response) => handleAddFamilyMemberSuccess(response, addFamilyMemberProps),
    (error) => handleAddFamilyMemberFailure(error, addFamilyMemberProps),
  );
};
