import { type ChallengeState } from "@msidentity/SISU/challenge-context";
import { type Flavors, FlowId, UserFlowType } from "@msidentity/SISU/constants";
import {
  type BaseSisuStateFabric,
  initBaseSisuAppDataFabric,
} from "@msidentity/SISU/context/sisu-providers";
import featuresConfig from "@msidentity/SISU/features-config";
import {
  type UserOptions,
  getUpdatedUser,
  getUpdatedUserFlowType,
} from "@msidentity/SISU/global-reducer";
import { extractQueryStringParams } from "@msidentity/SISU/utilities/query-params-helper";
import { type ServerData } from "@msidentity/SISU/utilities/server-data";
import { setUntrustedExternalInputText } from "@msidentity/SISU/utilities/untrusted-external-input-text";
import { initSignUpConfig } from "../../flows/signup/signup-config";
import { type SignUpState } from "../../flows/signup/signup-context";
import { SignUpFlowType } from "../../flows/signup/signup-types";
import { signUpFlowToInitialInputType } from "../../flows/signup/utilities/signup-utilities";
import { initSignUpApp } from "./init-signup-app";

/**
 * Parse the ServerData object sent from Account and return the initial config/state for the app contexts.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The ServerData object sent from the Account server and read from the window object.
 * @param activeFlavor The rendered flavor
 * @returns The initial state for the global and signup contexts.
 */
export function initSignupAppFabric(
  serverData: ServerData,
  activeFlavor: Flavors,
): {
  initialBaseSisuState: BaseSisuStateFabric;
  initialSignUpState: SignUpState;
  initialChallengeState: ChallengeState;
} {
  const initialBaseSisuState = initBaseSisuAppDataFabric(serverData, activeFlavor, FlowId.Signup);

  const { isSimplifiedChildAccountCreation } = featuresConfig.instance;

  let initialUserFlowType = initialBaseSisuState.initialGlobalState.userFlowType;
  let initialUser = initialBaseSisuState.initialGlobalState.user;
  let isParentFlow = false;

  if (isSimplifiedChildAccountCreation) {
    const queryParams = { userFlowType: "uft", parentUsername: "pusername" };
    const { userFlowType, parentUsername } = extractQueryStringParams(queryParams);
    initialUserFlowType = getUpdatedUserFlowType(initialUserFlowType, userFlowType);
    initialUser = getUpdatedUser(initialUser, {
      displayUsername: setUntrustedExternalInputText(decodeURIComponent(parentUsername), false),
    } as UserOptions);
    isParentFlow = isSimplifiedChildAccountCreation && initialUserFlowType === UserFlowType.Parent;
  }

  initSignUpConfig(serverData);

  // Initial membername type is determined in this way for Fabric. We will need the membername type for
  // prefill logic when creating signup state, where we decide whether to show an error or the prefill speedbump.
  const initialMemberNameType = signUpFlowToInitialInputType({
    isPhoneSupported: (!isParentFlow && serverData.fIsPhoneSupported) || false,
    prefill: serverData.oPrefill || {},
    signUpFlow: isParentFlow
      ? SignUpFlowType.DefaultLive
      : serverData.sSignupFlow || SignUpFlowType.DefaultEasi,
  });

  const { initialSignUpState, initialChallengeState } = initSignUpApp(
    initialUser,
    initialMemberNameType,
    isParentFlow,
    serverData,
  );

  return {
    initialBaseSisuState: {
      ...initialBaseSisuState,
      initialGlobalState: {
        ...initialBaseSisuState.initialGlobalState,
        user: initialUser,
        userFlowType: initialUserFlowType,
      },
    },
    initialSignUpState,
    initialChallengeState,
  };
}
