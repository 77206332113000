import type { DropdownOption, DropdownProps } from "../components/inputs/dropdown/dropdown";
import { type NumberInputProps } from "../components/inputs/number-input/number-input";
import { doubleSplit } from "./strings-helper";

export enum DateOrder {
  MDY = "MDY",
  DMY = "DMY",
  YMD = "YMD",
  YDM = "YDM",
}

export enum DatePart {
  BirthMonth = "BirthMonth",
  BirthDay = "BirthDay",
  BirthYear = "BirthYear",
}

/**
 * Orders the birthdate properties based on the date order (e.g., DMY, MDY) and sets specific attributes for day, month, or year.
 * @param birthDayProps The properties for the birth day dropdown
 * @param birthMonthProps The properties for the birth month dropdown
 * @param birthYearProps The properties for the birth year input
 * @param dateOrder The date order (e.g., DMY, MDY)
 * @returns An array with the properties for rendering the birthdate controls in the correct date order. For example, if the date order is "MDY",
 * the returned array will contain month props in index 0, day props in index 1, and year props in index 2.
 */
export const getBirthdateOrder = (
  birthDayProps: DropdownProps,
  birthMonthProps: DropdownProps,
  birthYearProps: NumberInputProps,
  dateOrder: DateOrder,
): (DropdownProps | NumberInputProps)[] => {
  const birthdateOrder: (DropdownProps | NumberInputProps)[] = new Array(3);

  birthdateOrder[dateOrder.indexOf("D")] = {
    ...birthDayProps,
    id: DatePart.BirthDay,
    name: DatePart.BirthDay,
    "aria-label": getLocalString("Birthdate_Day_AriaLabel"),
  };

  birthdateOrder[dateOrder.indexOf("M")] = {
    ...birthMonthProps,
    id: DatePart.BirthMonth,
    name: DatePart.BirthMonth,
    "aria-label": getLocalString("Birthdate_Month_AriaLabel"),
  };

  birthdateOrder[dateOrder.indexOf("Y")] = {
    ...birthYearProps,
    id: DatePart.BirthYear,
    name: DatePart.BirthYear,
    "aria-label": getLocalString("Birthdate_Year_AriaLabel"),
    placeholder: getLocalString("Birthdate_Year_PlaceholderText"),
  };

  return birthdateOrder;
};

/**
 * Parses a string containing a list of dropdown options
 * @param optionsString A string containing a list of dropdown options in text~value format
 * @returns An array of dropdown options
 */
export const parseDateOptions = (optionsString: string): DropdownOption[] => {
  const dict = doubleSplit(optionsString, "!!!", "~", false);

  return Object.entries(dict)
    .map(([key, value]) => {
      const data = value ? value.toString() : "";
      return {
        text: key,
        value: data,
      };
    })
    .sort(({ value: x }, { value: y }) => Number(x) - Number(y));
};
